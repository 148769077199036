import * as React from 'react';
import {makeStyles, darken} from '@material-ui/core';
import {useQuery} from 'react-query';
import {toast} from 'react-hot-toast';
import {useForm, Controller} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {useHistory, Link, useParams} from 'react-router-dom';
import clsx from 'clsx';

import {invalidFieldMessage} from 'utils/messages';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {
  Typography,
  Grid,
  TextField,
  Select,
  MenuItem,
  Breadcrumbs,
} from '@material-ui/core';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import {containDigits} from 'utils/String';
import {requiredFieldMessage} from 'utils/messages';

import Button from 'components/Button';
import ScreenSpinner from 'components/ScreenSpinner';
import Modal from 'components/Modal';
import {consumerService} from 'services/consumerService';
import {ALPHABET_SPACE_NUM, NAME_REGEX} from 'utils/regex';
import {useCountryOptions} from 'hooks/useCountryOptions';

type ConsumerDetails = {
  firstName: string;
  lastName: string;
  email: string;
  country: string;
  city: string;
  postcode: string;
  addressLine: string;
  addressLine2: string;
  addressLine3: string;
  lastUpdatedOn: Date | null;
  lastUpdatedBy: string;
  consumerId: string;
  status: string;
  sfId: string;
  mobileNumber: string;
};

const statusOptions = [
  {
    key: 'active',
    value: 'active',
    text: 'Active',
  },
  {
    key: 'inactive',
    value: 'inactive',
    text: 'Inactive',
  },
];

const schema = yup.object().shape({
  firstName: yup
    .string()
    .trim()
    .required(requiredFieldMessage('First Name'))
    .matches(NAME_REGEX, invalidFieldMessage('First Name')),
  lastName: yup.string().test({
    name: 'lastNameValidation',
    test: function (lastName?: string) {
      if (!lastName) return true;
      if (!NAME_REGEX.test(lastName)) {
        return this.createError({
          message: invalidFieldMessage('Last Name'),
        });
      }
      return true;
    },
  }),
  country: yup.string().trim().required(requiredFieldMessage('Country')),
  city: yup
    .string()
    .trim()
    .test({
      name: 'testCityLength',
      test: function (city?: string) {
        if (!city) return true;
        if (city.length < 3) {
          return this.createError({
            message: 'City must be at least 3 characters',
          });
        } else if (city.length > 50) {
          return this.createError({
            message: 'City must be at most 50 characters',
          });
        }
        return true;
      },
    }),
  postcode: yup
    .string()
    .required(requiredFieldMessage('Postcode'))
    .max(15, 'Maximum 15 characters are allowed'),
  addressLine: yup
    .string()
    .trim()
    .test({
      name: 'testAddressLineLength',
      test: function (addressLine?: string) {
        if (!addressLine) return true;
        if (addressLine.length > 250) {
          return this.createError({
            message: 'Address Line 1 must be at most 250 characters',
          });
        }
        return true;
      },
    }),
  addressLine2: yup
    .string()
    .trim()
    .test({
      name: 'testAddressLine2Length',
      test: function (addressLine2?: string) {
        if (!addressLine2) return true;
        if (addressLine2.length > 250) {
          return this.createError({
            message: 'Address Line 2 must be at most 250 characters',
          });
        }
        return true;
      },
    }),
  addressLine3: yup
    .string()
    .trim()
    .test({
      name: 'testAddressLine3Length',
      test: function (addressLine3?: string) {
        if (!addressLine3) return true;
        if (addressLine3.length > 250) {
          return this.createError({
            message: 'Address Line 3 must be at most 250 characters',
          });
        }
        return true;
      },
    }),
  status: yup.string().required(requiredFieldMessage('Status')),
});

const Consumer: React.FC = () => {
  const {id} = useParams<{id: string}>();
  const countryOptions = useCountryOptions();
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);
  const history = useHistory();
  const {control, handleSubmit, setValue, getValues} = useForm<ConsumerDetails>(
    {
      mode: 'all',
      resolver: yupResolver(schema),
      defaultValues: {
        firstName: '',
        lastName: '',
        email: '',
        country: '',
        city: '',
        postcode: '',
        addressLine: '',
        addressLine2: '',
        addressLine3: '',
        lastUpdatedOn: null,
        lastUpdatedBy: '',
        consumerId: '',
        status: '',
        sfId: '',
        mobileNumber: '',
      },
    }
  );

  const classes = useStyles();
  const [updating, setUpdating] = React.useState(false);

  const {data, isFetching, refetch} = useQuery(
    ['correct'],
    () => {
      return consumerService.get(id);
    },
    {
      retry: false,
    }
  );

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    setData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const setData = () => {
    if (!data) return;
    const user = data.user;

    setValue('status', user?.status || '');
    setValue('firstName', user?.firstName || '');
    setValue('lastName', user?.lastName || '');
    setValue('email', user?.email || '');
    setValue(
      'country',
      user.country
        ? user?.country.toUpperCase() === 'UK' ||
          user?.country.toUpperCase() === 'UNITED KINGDOM'
          ? 'United Kingdom'
          : `${user?.country || ''}`
        : ''
    );
    setValue('city', user?.city || '');
    setValue('postcode', user?.postcode || '');
    setValue('addressLine', user?.addressDetail || '');
    setValue('addressLine2', user?.addressDetail2 || '');
    setValue('addressLine3', user?.addressDetail3 || '');
    setValue('lastUpdatedOn', new Date(user?.updatedAt));
    setValue(
      'lastUpdatedBy',
      `${user?.updatedBy.username || ''} ${user?.updatedBy.lastName || ''} (${
        user?.updatedBy.id
      })`
    );
    setValue('consumerId', user?.id || '');
    setValue('sfId', user.sfId || '');
    setValue(
      'mobileNumber',
      user?.mobileNumber && user?.countryCode
        ? `${user.countryCode} ${user.mobileNumber}`
        : ''
    );
  };

  const handleCancel = (event: React.MouseEvent) => {
    history.push('/consumer');
  };

  const onSubmit = async () => {
    updateConsumer();
  };

  const handleCancelStatusUpdate = () => {
    setShowConfirmModal(false);
    setValue('status', 'ACTIVE');
  };

  const updateConsumer = async () => {
    try {
      setUpdating(true);

      await consumerService.update({
        id: id,
        status: getValues('status').trim(),
        firstName: getValues('firstName').trim(),
        lastName: getValues('lastName').trim(),
        country: getValues('country').trim(),
        city: getValues('city').trim(),
        postcode: getValues('postcode').trim(),
        address1: getValues('addressLine').trim(),
        address2: getValues('addressLine2').trim(),
        address3: getValues('addressLine3').trim(),
      });
      toast.success('Consumer details updated successfully', {duration: 3000});
      refetch({
        throwOnError: true,
        cancelRefetch: true,
      });
    } finally {
      setUpdating(false);
    }
  };

  return (
    <div>
      {(updating || isFetching) && <ScreenSpinner />}

      <Modal
        open={showConfirmModal}
        handleClose={() => {
          setShowConfirmModal(false);
        }}
      >
        <>
          <Grid container spacing={3} className={classes.modalHeader}>
            <Grid item xs={7}>
              <Typography component={'h3'} className={classes.modalTitle}>
                Confirm Update
              </Typography>
            </Grid>
            <Grid
              container
              xs={5}
              spacing={2}
              alignItems="center"
              justify="flex-end"
            >
              <Grid item>
                <Button
                  variant="outlined"
                  color="inherit"
                  fullWidth
                  minWidth="0px"
                  onClick={handleCancelStatusUpdate}
                  className={classes.cancelButton}
                >
                  {'No'}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  minWidth="0px"
                  fullWidth
                  onClick={() => {
                    setShowConfirmModal(false);
                  }}
                >
                  {'Yes'}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item>
              <Typography component={'p'} className={classes.modalText}>
                Are you sure you want to make this user inactive?
              </Typography>
            </Grid>
          </Grid>
        </>
      </Modal>

      <Typography component={'h1'} className={classes.title}>
        {'Consumer'}
      </Typography>
      <Breadcrumbs className={classes.breadcrumb} aria-label="breadcrumb">
        <Typography className={classes.breadcrumbText}>Reports</Typography>
        <Link className={classes.breadcrumbLink} to="/consumer">
          Consumers
        </Link>
        <Typography className={classes.breadcrumbText}>{id}</Typography>
      </Breadcrumbs>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container className={classes.filterContainer} spacing={2}>
          <Grid item xs={7}>
            <Typography className={classes.filterText} component={'h2'}>
              Consumer Details
            </Typography>
          </Grid>
          <Grid
            container
            xs={5}
            spacing={2}
            alignItems="center"
            justify="flex-end"
          >
            <Grid item>
              <Button
                variant="outlined"
                color="inherit"
                fullWidth
                minWidth="0px"
                onClick={handleCancel}
                className={classes.cancelButton}
              >
                {'Close'}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                minWidth="0px"
                fullWidth
              >
                {'Save'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={classes.filterOptionContainer} spacing={2}>
          <Grid item xs={3} className={classes.formElement}>
            <Typography className={classes.formLabel}>First Name</Typography>
            <Controller
              name="firstName"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <>
                  <TextField
                    variant="outlined"
                    type="text"
                    placeholder="Add First Name"
                    fullWidth
                    margin="dense"
                    value={value}
                    onChange={event => {
                      if (containDigits(event.target.value)) return;
                      onChange(event);
                    }}
                    error={!!error}
                    helperText={error?.message}
                  />
                </>
              )}
            />
          </Grid>
          <Grid item xs={3} className={classes.formElement}>
            <Typography className={classes.formLabel}>Last Name</Typography>
            <Controller
              name="lastName"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <>
                  <TextField
                    variant="outlined"
                    type="text"
                    placeholder="Add Last Name"
                    fullWidth
                    margin="dense"
                    value={value}
                    onChange={event => {
                      if (containDigits(event.target.value)) return;
                      onChange(event);
                    }}
                    error={!!error}
                    helperText={error?.message}
                  />
                </>
              )}
            />
          </Grid>
          <Grid item xs={3} className={classes.formElement}>
            <Typography
              className={clsx(classes.formLabel, classes.disableLabel)}
            >
              Email
            </Typography>
            <Controller
              name="email"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <>
                  <TextField
                    variant="outlined"
                    type="text"
                    placeholder="Email"
                    fullWidth
                    margin="dense"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    disabled
                    helperText={error?.message}
                  />
                </>
              )}
            />
          </Grid>
          <Grid item xs={3} className={classes.formElement}>
            <Typography className={classes.formLabel}>Country</Typography>
            <Controller
              name="country"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <>
                  <Select
                    value={value}
                    onChange={onChange}
                    displayEmpty
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    className={classes.select}
                    error={!!error}
                  >
                    {countryOptions.map(country => (
                      <MenuItem value={country.value} key={country.key}>
                        {country.text}
                      </MenuItem>
                    ))}
                  </Select>
                  {error?.message && (
                    <Typography
                      component={'p'}
                      className={classes.errorMessage}
                    >
                      {error.message}
                    </Typography>
                  )}
                </>
              )}
            />
          </Grid>
          <Grid item xs={3} className={classes.formElement}>
            <Typography className={classes.formLabel}>City</Typography>
            <Controller
              name="city"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <>
                  <TextField
                    variant="outlined"
                    type="text"
                    placeholder="Add City"
                    fullWidth
                    margin="dense"
                    value={value}
                    onChange={event => {
                      if (!ALPHABET_SPACE_NUM.test(event.target.value)) return;
                      onChange(event);
                    }}
                    error={!!error}
                    helperText={error?.message}
                  />
                </>
              )}
            />
          </Grid>
          <Grid item xs={3} className={classes.formElement}>
            <Typography className={classes.formLabel}>Postcode</Typography>
            <Controller
              name="postcode"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <>
                  <TextField
                    variant="outlined"
                    type="text"
                    placeholder="Add Postcode"
                    fullWidth
                    margin="dense"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error?.message}
                  />
                </>
              )}
            />
          </Grid>
          <Grid item xs={3} className={classes.formElement}>
            <Typography className={classes.formLabel}>
              Address Line 1
            </Typography>
            <Controller
              name="addressLine"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <>
                  <TextField
                    variant="outlined"
                    type="text"
                    placeholder="Add Address Line 1"
                    fullWidth
                    margin="dense"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error?.message}
                  />
                </>
              )}
            />
          </Grid>
          <Grid item xs={3} className={classes.formElement}>
            <Typography className={classes.formLabel}>
              Address Line 2
            </Typography>
            <Controller
              name="addressLine2"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <>
                  <TextField
                    variant="outlined"
                    type="text"
                    placeholder="Add Address Line 2"
                    fullWidth
                    margin="dense"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error?.message}
                  />
                </>
              )}
            />
          </Grid>
          <Grid item xs={3} className={classes.formElement}>
            <Typography className={classes.formLabel}>
              Address Line 3
            </Typography>
            <Controller
              name="addressLine3"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <>
                  <TextField
                    variant="outlined"
                    type="text"
                    placeholder="Add Address Line 3"
                    fullWidth
                    margin="dense"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error?.message}
                  />
                </>
              )}
            />
          </Grid>
          <Grid item xs={3} className={classes.formElement}>
            <Typography
              className={clsx(classes.formLabel, classes.disableLabel)}
            >
              Last Updated On
            </Typography>
            <Controller
              name="lastUpdatedOn"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    inputFormat="dd-MMM-yyyy"
                    className={classes.datePicker}
                    disabled
                    value={value}
                    onChange={onChange}
                    renderInput={(params: any) => (
                      <TextField
                        variant="outlined"
                        type="text"
                        placeholder="Add Created Date"
                        fullWidth
                        margin="dense"
                        value={value}
                        onChange={onChange}
                        error={!!error}
                        helperText={error?.message}
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              )}
            />
          </Grid>

          <Grid item xs={3} className={classes.formElement}>
            <Typography
              className={clsx(classes.formLabel, classes.disableLabel)}
            >
              Last Updated By
            </Typography>
            <Controller
              name="lastUpdatedBy"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <>
                  <TextField
                    variant="outlined"
                    type="text"
                    placeholder="Last Updated By"
                    fullWidth
                    margin="dense"
                    value={value}
                    disabled
                    onChange={onChange}
                    error={!!error}
                    helperText={error?.message}
                  />
                </>
              )}
            />
          </Grid>
          <Grid item xs={3} className={classes.formElement}>
            <Typography
              className={clsx(classes.formLabel, classes.disableLabel)}
            >
              Consumer ID
            </Typography>
            <Controller
              name="consumerId"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <>
                  <TextField
                    variant="outlined"
                    type="text"
                    placeholder="Add Consumer ID"
                    fullWidth
                    margin="dense"
                    value={value}
                    onChange={onChange}
                    disabled
                    error={!!error}
                    helperText={error?.message}
                  />
                </>
              )}
            />
          </Grid>
          <Grid item xs={3} className={classes.formElement}>
            <Typography className={classes.formLabel}>Status</Typography>
            <Controller
              name="status"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <>
                  <Select
                    value={value}
                    onChange={event => {
                      event.target.value === 'INACTIVE' &&
                        setShowConfirmModal(true);
                      onChange(event);
                    }}
                    displayEmpty
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    className={classes.select}
                    error={!!error}
                  >
                    {statusOptions.map(status => (
                      <MenuItem value={status.value} key={status.key}>
                        {status.text}
                      </MenuItem>
                    ))}
                  </Select>
                  {error?.message && (
                    <Typography
                      component={'p'}
                      className={classes.errorMessage}
                    >
                      {error.message}
                    </Typography>
                  )}
                </>
              )}
            />
          </Grid>
          <Grid item xs={3} className={classes.formElement}>
            <Typography
              className={clsx(classes.formLabel, classes.disableLabel)}
            >
              Stamp free Id
            </Typography>
            <Controller
              name="sfId"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <>
                  <TextField
                    variant="outlined"
                    type="text"
                    placeholder="Stamp free Id"
                    fullWidth
                    margin="dense"
                    value={value}
                    disabled
                    onChange={onChange}
                    error={!!error}
                    helperText={error?.message}
                  />
                </>
              )}
            />
          </Grid>
          <Grid item xs={3} className={classes.formElement}>
            <Typography
              className={clsx(classes.formLabel, classes.disableLabel)}
            >
              Mobile Number
            </Typography>
            <Controller
              name="mobileNumber"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <>
                  <TextField
                    variant="outlined"
                    type="text"
                    placeholder="Mobile Number"
                    fullWidth
                    margin="dense"
                    value={value}
                    disabled
                    onChange={onChange}
                    error={!!error}
                    helperText={error?.message}
                  />
                </>
              )}
            />
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 26,
    lineHeight: '40px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
      lineHeight: '28px',
    },
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(3),
  },
  disableLabel: {
    color: 'rgba(0, 0, 0, 0.38)',
  },
  errorMessage: {
    color: theme.palette.error.main,
    margin: '0 14px',
    fontSize: '0.75rem',
  },
  errorInfo: {
    color: theme.palette.error.main,
    fontSize: 18,
  },
  deleteButton: {
    backgroundColor: theme.palette.error.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: darken(theme.palette.error.main, 0.1),
      color: theme.palette.common.white,
    },
  },
  modalTitle: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 21,
    lineHeight: '32px',
  },
  modalText: {
    fontSize: 18,
    margin: '10px 0px',
  },
  breadcrumb: {
    marginBottom: theme.spacing(10),
  },
  breadcrumbLink: {
    color: 'inherit',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  breadcrumbText: {
    color: 'inherit',
    fontSize: 'inherit',
  },
  filterContainer: {
    width: '100%',
    background: '#fff',
    borderRadius: '5px 5px 0 0',
    padding: '12px 16px 15px',
    margin: 0,
    border: '1px solid',
    borderBottom: '0px',
    borderColor: theme.palette.grey[300],
  },
  filterOptionContainer: {
    width: '100%',
    background: '#fff',
    borderRadius: '0 0 5px 5px',
    padding: '12px 16px 15px',
    margin: 0,
    marginBottom: theme.spacing(3),
    border: '1px solid',
    borderColor: theme.palette.grey[300],
  },
  filterText: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '21px',
    lineHeight: '32px',
  },
  formElement: {},
  formLabel: {
    fontSize: 16,
    lineHeight: '28px',
  },

  select: {
    marginTop: '8px',
    marginBottom: '4px',
  },
  datePicker: {
    backgroundColor: 'pink',
    '&& .MuiPickersDay-root.Mui-selected': {
      backgroundColor: 'red !important',
      '&:hover': {
        backgroundColor: 'red !important',
      },
      '&:focus': {
        backgroundColor: 'red !important',
      },
    },
  },

  modalHeader: {
    borderBottom: '1px solid #E0E0E0',
  },

  picture: {
    width: '100%',
    maxWidth: '100%',
    height: 288,
    objectFit: 'contain',
    [theme.breakpoints.down('xs')]: {
      height: 200,
    },
    '& img': {
      objectFit: 'contain',
    },
  },
  cancelButton: {
    color: theme.palette.grey[500],
  },
}));

export default Consumer;
