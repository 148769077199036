import {useMemo} from 'react';
import {ICountry} from 'types';
import {useConfigData} from './useConfigData';
import {sort} from 'utils/Array';

const formatCountryListToOptions = (
  countryList: ICountry[]
): {key: string; value: string; text: string}[] => {
  return sort(countryList, 'name').map(country => ({
    key: country.id,
    text: country.name,
    value: country.name,
  }));
};

export const useCountryOptions = () => {
  const {countryList} = useConfigData();
  const countryOptions = useMemo(() => {
    return formatCountryListToOptions(countryList);
  }, [countryList]);

  return countryOptions;
};
