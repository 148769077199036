import axios from 'lib/axios';

import type {CurrencyResponseMock} from 'types';

class CurrencyService {
  async list() {
    const response = await axios.get<CurrencyResponseMock>('/currency/list');
    return response.data;
  }
}

export const currencyService = new CurrencyService();
