import * as React from 'react';
import {makeStyles, darken} from '@material-ui/core';
import {useQuery} from 'react-query';
import {toast} from 'react-hot-toast';
import {useHistory, Link, useParams} from 'react-router-dom';
import {useForm, Controller} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ScreenSpinner from 'components/ScreenSpinner';
import * as yup from 'yup';
import {Trash2, Edit3, X} from 'react-feather';
import {
  Typography,
  Grid,
  TextField,
  Select,
  IconButton,
  MenuItem,
  Breadcrumbs,
  Modal,
} from '@material-ui/core';
import Button from 'components/Button';
import DaterangeIcon from '@material-ui/icons/DateRange';
import {pudoService} from 'services/pudoService';
import {
  PUDO_INACTIVE_WARNING,
  PUDO_PASSWORD_UPDATE,
  requiredFieldMessage,
} from 'utils/messages';
import {format, parseISO} from 'date-fns';
import clsx from 'clsx';
import {removeWhiteSpaces} from 'utils/String';
import {carrierService} from 'services/carrierService';
import {Alert, Autocomplete} from '@mui/material';
import {Carrier_List_Mock} from 'types';
import {sort} from 'utils/Array';
import {useCountryOptions} from 'hooks/useCountryOptions';

type Status = 'active' | 'inactive';
type PudoDetails = {
  pudoId: string;
  pudoName: string;
  lastUpdatedBy: string;
  lastUpdatedOn: string;
  status: Status;
  iconUrl: string;
};

type LocationDetails = {
  id: string;
  country: string;
  name: string;
  postcode: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  email: string;
  firstName: string;
  status: 'active' | 'inactive';
};

type CarrierDetails = {
  id: string;
  status: 'active' | 'inactive';
  name: string;
  type: 'international' | 'domestic';
  clientFlag?: boolean;
};

const statusOptions = [
  {
    key: 'active',
    value: 'active',
    text: 'Active',
  },
  {
    key: 'inactive',
    value: 'inactive',
    text: 'Inactive',
  },
];

const filterSchema = yup.object().shape({
  pudoName: (yup.string().trim() as any)
    .stripEmptyString()
    .required(requiredFieldMessage('Pudo Name'))
    .min(3, 'Pudo Name should have minimum 3 and maximum 100 characters')
    .max(100, 'Pudo Name should have minimum 3 and maximum 100 characters'),
  iconUrl: yup.string().trim().required(requiredFieldMessage('Icon Url')),
  status: yup.string().required(requiredFieldMessage('Status')),
});

const locationSchema = yup.object().shape({
  status: yup.string().required(requiredFieldMessage('Status')),
  country: yup.string().required(requiredFieldMessage('Country')),
  name: yup
    .string()
    .trim()
    .required(requiredFieldMessage('City'))
    .min(3, 'City should have minimum 3 and maximum 50 characters')
    .max(50, 'City should have minimum 3 and maximum 50 characters'),
  postcode: yup
    .string()
    .trim()
    .required(requiredFieldMessage('Postcode'))
    .max(15, 'Maximum 15 characters are allowed'),

  addressLine1: yup
    .string()
    .trim()
    .required(requiredFieldMessage('Address Line 1'))
    .max(250, 'Address Line 1 must be at most 250 characters'),
  addressLine2: yup
    .string()
    .trim()
    .test({
      name: 'testAddressLineLength',
      test: function (addressLine?: string) {
        if (!addressLine) return true;
        if (addressLine.length > 250) {
          return this.createError({
            message: 'Address Line 2 must be at most 250 characters',
          });
        }
        return true;
      },
    }),
  addressLine3: yup
    .string()
    .trim()
    .test({
      name: 'testAddressLineLength',
      test: function (addressLine?: string) {
        if (!addressLine) return true;
        if (addressLine.length > 250) {
          return this.createError({
            message: 'Address Line 3 must be at most 250 characters',
          });
        }
        return true;
      },
    }),
  firstName: (yup.string().trim() as any)
    .stripEmptyString()
    .required(requiredFieldMessage('First Name'))
    .min(3, 'First Name should have minimum 3 and maximum 100 characters')
    .max(100, 'First Name should have minimum 3 and maximum 100 characters'),
  email: (yup.string().trim() as any)
    .stripEmptyString()
    .required(requiredFieldMessage('Email'))
    .email('Invalid email format'),
});

const Pudo = () => {
  const {id: pudoId} = useParams<{id: string}>();
  const isEdit = () => pudoId !== 'add';
  const countryOptions = useCountryOptions();

  const history = useHistory();
  const [carriers, setCarriers] = React.useState<CarrierDetails[]>([]);
  const [selectedCarrierIds, setSelectedCarrierIds] = React.useState<string[]>(
    []
  );
  const [pudoStatus, setPudoStatus] = React.useState<'inactive' | 'active'>();
  type DeletePopupTitle = 'location' | 'carrier';

  const {control, handleSubmit, reset, setValue} = useForm<PudoDetails>({
    mode: 'all',
    resolver: yupResolver(filterSchema),
    defaultValues: {
      pudoName: '',
      status: 'active',
      iconUrl: '',
    },
  });

  const {
    control: locationControl,
    handleSubmit: handleLocationSubmit,
    reset: locationReset,
    setValue: setLocationValue,
  } = useForm<LocationDetails>({
    resolver: yupResolver(locationSchema),
    mode: 'all',
    defaultValues: {
      id: '',
      status: 'active',
      country: 'United Kingdom',
      name: '',
      postcode: '',
      addressLine1: '',
      addressLine2: '',
      addressLine3: '',
      email: '',
      firstName: '',
    },
  });

  const classes = useStyles();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {data: pudoData, refetch, isFetching} = useQuery(
    ['Pudo', pudoId],
    () => {
      return pudoService.get(pudoId);
    },
    {
      enabled: isEdit(),
    }
  );

  const {data: carriersData} = useQuery(
    ['carriers'],
    () => {
      return carrierService.getCarriers();
    },
    {retry: false}
  );

  const setData = () => {
    if (!pudoData) return;
    setValue('pudoId', pudoData.id);
    setValue('pudoName', pudoData.name);
    setValue('iconUrl', pudoData.iconUrl);
    setValue('status', pudoData.status);
    setValue(
      'lastUpdatedBy',
      `${pudoData.updatedBy?.firstName || ''} ${
        pudoData.updatedBy?.lastName || ''
      } (${pudoData.updatedBy?.id})`
    );
    setValue(
      'lastUpdatedOn',
      format(parseISO(pudoData.updatedAt), 'dd-MMM-yyyy')
    );
    setLocations(
      pudoData.locations.map(location => ({
        id: location.id,
        addressLine1: location.address.addressLine1 || '',
        addressLine2: location.address.addressLine2 || '',
        addressLine3: location.address.addressLine3 || '',
        country: location.address.country || '',
        name: location.address.city || '',
        postcode: location.address.postcode || '',
        email: location.user?.email || '',
        firstName: location.user?.firstName || '',
        status: location.status,
      }))
    );
    setCarriers(
      pudoData.carrier.map(carrier => ({
        id: carrier.id,
        status: carrier.status || 'active',
        name: carrier.name,
        createdAt: carrier.createdAt,
        updatedAt: carrier.updatedAt,
        type: carrier.type,
        clientFlag: carrier.clientFlag,
      }))
    );
    setPudoStatus(pudoData.status);
    setLocationValue('status', pudoData.status);
  };

  const [isLoading, setIsLoading] = React.useState(false);
  const [showCarrierPopup, setShowCarrierPopup] = React.useState(false);
  const [selectedCarriers, setSelectedCarriers] = React.useState<
    Carrier_List_Mock[]
  >([]);
  const [showLocationPopup, setShowLocationPopup] = React.useState(false);
  const [locations, setLocations] = React.useState<LocationDetails[]>([]);
  const [selectedLocation, setSelectedLocation] = React.useState('');

  const [
    inActiveConfirmationPopup,
    setInActiveConfirmationPopup,
  ] = React.useState(false);

  const [
    showLocationInactiveConfirmationPopUp,
    setLocationInactiveConfirmationPopUp,
  ] = React.useState(false);

  const [
    showDeleteConfirmationPopup,
    setShowDeleteConfirmationPopup,
  ] = React.useState(false);

  const [
    deleteConfirmationContent,
    setDeleteConfirmationContent,
  ] = React.useState<{title: DeletePopupTitle; item: string}>({
    title: 'carrier',
    item: '',
  });

  React.useEffect(() => {
    setData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pudoData]);

  const closePopup = () => {
    locationReset();
    setShowCarrierPopup(false);
    setShowLocationPopup(false);
    setSelectedLocation('');
    setSelectedCarriers([]);
    setSelectedCarrierIds([]);
  };

  React.useEffect(() => {
    return () => {
      reset();
      locationReset();
    };
  }, [locationReset, reset]);

  const toggleDeleteConfirmationPopup = (
    type: DeletePopupTitle,
    selectedValue: string,
    show: boolean
  ) => {
    setShowDeleteConfirmationPopup(show);
    setDeleteConfirmationContent({title: type, item: selectedValue});
  };

  const onSubmit = async (data: PudoDetails) => {
    if (locations.length === 0) {
      toast.error('Please add at least 1 Location.');
      return;
    }
    setIsLoading(true);
    try {
      const {pudoName, iconUrl, status, pudoId} = data;

      if (isEdit()) {
        await pudoService.editPudo(pudoId, {
          name: pudoName.trim(),
          iconUrl: iconUrl.trim(),
          status,
        });
        refetch({
          throwOnError: true,
          cancelRefetch: true,
        });

        toast.success('Pudo details updated successfully', {duration: 3000});
      } else {
        await pudoService.addPudo({
          name: pudoName.trim(),
          iconUrl: iconUrl.trim(),
          status,
          pudoUsers: locations.map(location => ({
            addressLine1: location.addressLine1,
            ...(location.addressLine2
              ? {addressLine2: location.addressLine2}
              : {}),
            ...(location.addressLine3
              ? {addressLine3: location.addressLine3}
              : {}),
            city: location.name,
            country: location.country,
            postcode: removeWhiteSpaces(location.postcode),
            firstName: location.firstName,
            email: location.email,
            status: pudoStatus === 'inactive' ? 'inactive' : location.status,
          })),
          carriers: {
            carriers: selectedCarrierIds,
          },
        });
        reset();
        closePopup();
        setLocations([]);
        setCarriers([]);
        setSelectedCarrierIds([]);
        setSelectedCarriers([]);
        toast.success('Pudo details added successfully', {duration: 3000});
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const handleDelete = async () => {
    try {
      setIsLoading(true);
      if (isEdit()) {
        if (deleteConfirmationContent.title === 'carrier') {
          await pudoService.delete(pudoId, deleteConfirmationContent.item);
          setCarriers(
            carriers.filter(
              carrier => carrier.id === deleteConfirmationContent.item
            )
          );
          toast.success('Carrier deleted successfully', {duration: 3000});
        }
        refetch({
          throwOnError: true,
          cancelRefetch: true,
        });
      } else {
        if (deleteConfirmationContent.title === 'location') {
          setLocations(
            locations.filter(
              location => location.id !== deleteConfirmationContent.item
            )
          );
        } else {
          setSelectedCarriers(
            selectedCarriers.filter(
              carrier => carrier.id !== deleteConfirmationContent.item
            )
          );

          setSelectedCarrierIds(
            selectedCarrierIds.filter(
              carrier => carrier !== deleteConfirmationContent.item
            )
          );
          setCarriers(
            carriers.filter(
              carrier => carrier.id !== deleteConfirmationContent.item
            )
          );
        }
      }
    } finally {
      setShowDeleteConfirmationPopup(false);
      setIsLoading(false);
    }
  };

  const deleteConfirmationPopup = () => {
    return (
      <>
        <Modal
          open={showDeleteConfirmationPopup}
          className={classes.popupWrapper}
        >
          <form className={classes.modalForm}>
            <Grid
              container
              className={classes.formContainerWhiteBackground}
              spacing={2}
            >
              <Grid item xs={9} component={'h3'}>
                {deleteConfirmationContent.title === 'location'
                  ? 'Delete Location'
                  : 'Delete Carrier'}
              </Grid>
              <Grid
                container
                xs={3}
                spacing={2}
                alignItems="center"
                justify="flex-end"
              >
                <Grid item>
                  <Button
                    variant="outlined"
                    color="inherit"
                    fullWidth
                    minWidth="0px"
                    onClick={() => setShowDeleteConfirmationPopup(false)}
                    className={classes.cancelButton}
                  >
                    No
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    minWidth="0px"
                    fullWidth
                    onClick={handleDelete}
                  >
                    Yes
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              className={classes.filterOptionContainer}
              spacing={2}
            >
              <Grid item xs={12} className={classes.formElement}>
                {`Are you sure you want to ${
                  deleteConfirmationContent.title === 'location'
                    ? 'delete the location'
                    : 'delete the carrier'
                }?`}
              </Grid>
            </Grid>
          </form>
        </Modal>
      </>
    );
  };

  const handleAddCarrier = () => {
    setShowCarrierPopup(true);
  };

  const toggleCarrierPopUp = () => {
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setIsLoading(true);
      try {
        if (isEdit()) {
          await pudoService.addCarriers({
            carriers: selectedCarrierIds,
            pudoId: pudoId,
          });
          toast.success('Carrier added successfully', {duration: 3000});
          refetch();
          closePopup();
          setSelectedCarrierIds([]);
          setSelectedCarriers([]);
        } else {
          if (!!selectedCarriers) {
            setCarriers(prev => {
              return prev.concat(selectedCarriers).map(carrier => ({
                id: carrier.id,
                status: carrier.status,
                name: carrier.name,
                type: carrier.type,
              }));
            });
          }
        }
      } finally {
        closePopup();
        setIsLoading(false);
      }
    };
    return (
      <>
        <Modal open={showCarrierPopup} className={classes.carrierPopupWrapper}>
          <form className={classes.modalForm} onSubmit={handleSubmit}>
            <Grid
              container
              className={classes.formContainerWhiteBackground}
              spacing={2}
            >
              <Grid item xs={3} component={'h3'}>
                {'Add carrier'}
              </Grid>
              <Grid
                container
                xs={9}
                spacing={2}
                alignItems="center"
                justify="flex-end"
              >
                <Grid item>
                  <Button
                    variant="outlined"
                    color="inherit"
                    fullWidth
                    minWidth="0px"
                    onClick={() => closePopup()}
                    className={classes.cancelButton}
                  >
                    Close
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    minWidth="0px"
                    fullWidth
                    type="submit"
                  >
                    {'Save'}
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              className={classes.filterOptionContainer}
              spacing={2}
            >
              <Grid
                item
                className={classes.formElement}
                style={{minWidth: '232px', width: '100%'}}
              >
                <Grid item className={classes.formElement}>
                  <Typography className={classes.formLabel}>
                    Carrier Name
                  </Typography>
                  <Autocomplete
                    multiple
                    placeholder="carriers"
                    limitTags={2}
                    options={sort(carriersData?.carriers ?? [], 'name').filter(
                      option =>
                        !carriers.some(
                          existingCarrier => existingCarrier.id === option.id
                        )
                    )}
                    getOptionLabel={option => option.name}
                    value={selectedCarriers}
                    disableClearable
                    onChange={(_, newValue) => {
                      setSelectedCarriers(newValue);
                      setSelectedCarrierIds(prevDefaultCarrierId => {
                        const newIds = newValue.map(item => item.id);
                        const uniqueIds = Array.from(
                          new Set([...prevDefaultCarrierId, ...newIds])
                        );
                        return uniqueIds;
                      });
                    }}
                    renderInput={params => (
                      <TextField {...params} variant="outlined" />
                    )}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <div
                          key={option.id}
                          style={{
                            backgroundColor: '#36a6ff',
                            borderRadius: '4px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            position: 'relative',
                            color: 'white',
                            margin: '2px',
                            padding: '3px 8px',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          <span style={{color: 'white'}}>{option.name}</span>
                          <IconButton
                            size="small"
                            onClick={() => handleRemoveTag(option)}
                            style={{
                              color: 'white',
                              marginLeft: '4px',
                              paddingRight: '0',
                            }}
                          >
                            <X
                              style={{
                                width: '18px',
                                height: '18px',
                                fill: '#FFFFFF',
                              }}
                            />
                          </IconButton>
                        </div>
                      ))
                    }
                    classes={{
                      paper: classes.dropdownPaper,
                      root: classes.autocomplete,
                      popupIndicator: classes.hiddenClearIcon,
                    }}
                    css={`
                      .MuiAutocomplete-tagLabel {
                        color: white !important;
                      }
                    `}
                  />
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Modal>
      </>
    );
  };

  const handleLocation = async (data: LocationDetails) => {
    setIsLoading(true);
    try {
      const locationData: LocationDetails = {
        id: data.id,
        name: data.name.trim(),
        postcode: removeWhiteSpaces(data.postcode.trim()),
        country: data.country.trim(),
        addressLine1: data.addressLine1.trim(),
        addressLine2: data.addressLine2.trim(),
        addressLine3: data.addressLine3.trim(),
        firstName: data.firstName.trim(),
        email: data.email.trim(),
        status: data.status,
      };
      if (isEdit()) {
        if (!!selectedLocation) {
          await pudoService.editLocation(pudoId, selectedLocation, {
            city: locationData.name,
            postcode: locationData.postcode,
            country: locationData.country,
            addressLine1: locationData.addressLine1,
            ...(locationData.addressLine2
              ? {addressLine2: locationData.addressLine2}
              : {}),
            ...(locationData.addressLine3
              ? {addressLine3: locationData.addressLine3}
              : {}),
            firstName: locationData.firstName,
            status: locationData.status,
          });
          setLocations(
            locations.map(location => {
              if (location.id === selectedLocation) {
                return {...location, ...locationData};
              }
              return location;
            })
          );
          toast.success('Location updated successfully', {duration: 3000});
        } else {
          const addedLocation = await pudoService.addLocation(pudoId, {
            city: locationData.name,
            postcode: locationData.postcode,
            country: locationData.country,
            addressLine1: locationData.addressLine1,
            ...(locationData.addressLine2
              ? {addressLine2: locationData.addressLine2}
              : {}),
            ...(locationData.addressLine3
              ? {addressLine3: locationData.addressLine3}
              : {}),
            firstName: locationData.firstName,
            email: locationData.email,
            status:
              pudoStatus === 'inactive' ? 'inactive' : locationData.status,
          });
          setLocations([
            ...locations,
            {
              ...locationData,
              id: addedLocation.id,
            },
          ]);
        }
      } else {
        if (!!selectedLocation) {
          setLocations(
            locations.map(location => {
              if (location.id === selectedLocation) {
                return {...location, ...locationData};
              }
              return location;
            })
          );
        } else {
          setLocations([...locations, {...locationData, id: `${Date.now()}`}]);
        }
      }
    } finally {
      closePopup();
      setIsLoading(false);
    }
  };

  const toggleLocationPopUp = () => {
    return (
      <>
        <Modal open={showLocationPopup} className={classes.popupWrapper}>
          <form
            className={classes.modalForm}
            onSubmit={handleLocationSubmit(handleLocation)}
          >
            <Grid
              container
              className={classes.formContainerWhiteBackground}
              spacing={2}
            >
              <Grid item xs={9} component={'h3'}>
                {selectedLocation ? 'Edit Location' : 'Add Location'}
              </Grid>
              <Grid
                container
                xs={3}
                spacing={2}
                alignItems="center"
                justify="flex-end"
              >
                <Grid item>
                  <Button
                    variant="outlined"
                    color="inherit"
                    fullWidth
                    minWidth="0px"
                    onClick={() => closePopup()}
                    className={classes.cancelButton}
                  >
                    Close
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    minWidth="0px"
                    fullWidth
                    type="submit"
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              className={classes.filterOptionContainer}
              spacing={2}
            >
              <Grid item xs={4} className={classes.formElement}>
                <Typography className={classes.formLabel}>Status</Typography>
                <Controller
                  name="status"
                  control={locationControl}
                  render={({field: {value, onChange}, fieldState: {error}}) => {
                    let initialValue =
                      pudoStatus === 'inactive' ? 'inactive' : value;

                    if (
                      !isEdit() &&
                      pudoStatus === 'active' &&
                      initialValue === 'inactive'
                    ) {
                      initialValue = value;
                    }

                    return (
                      <Select
                        value={initialValue}
                        onChange={event => {
                          event.target.value === 'inactive' &&
                            setLocationInactiveConfirmationPopUp(true);

                          onChange(event);
                        }}
                        displayEmpty
                        fullWidth
                        margin="dense"
                        variant="outlined"
                        disabled={pudoStatus === 'inactive'}
                        className={classes.select}
                      >
                        {statusOptions.map(status => (
                          <MenuItem value={status.value} key={status.key}>
                            {status.text}
                          </MenuItem>
                        ))}
                      </Select>
                    );
                  }}
                />
              </Grid>
              <Grid item xs={4} className={classes.formElement}>
                <Typography className={classes.formLabel}>Country</Typography>
                <Controller
                  name="country"
                  control={locationControl}
                  render={({field: {value, onChange}, fieldState: {error}}) => (
                    <>
                      <Select
                        value={value}
                        onChange={onChange}
                        displayEmpty
                        fullWidth
                        margin="dense"
                        variant="outlined"
                        className={classes.select}
                        error={!!error}
                      >
                        {countryOptions.map(country => (
                          <MenuItem value={country.value} key={country.key}>
                            {country.text}
                          </MenuItem>
                        ))}
                      </Select>
                      {error?.message && (
                        <Typography
                          component={'p'}
                          className={classes.errorMessage}
                        >
                          {error.message}
                        </Typography>
                      )}
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={4} className={classes.formElement}>
                <Typography className={classes.formLabel}>City</Typography>
                <Controller
                  name="name"
                  control={locationControl}
                  render={({field: {value, onChange}, fieldState: {error}}) => (
                    <>
                      <TextField
                        variant="outlined"
                        type="text"
                        placeholder="Add City"
                        fullWidth
                        margin="dense"
                        value={value}
                        onChange={onChange}
                        error={!!error}
                        helperText={error?.message}
                      />
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={4} className={classes.formElement}>
                <Typography className={classes.formLabel}>Postcode</Typography>
                <Controller
                  name="postcode"
                  control={locationControl}
                  render={({field: {value, onChange}, fieldState: {error}}) => (
                    <>
                      <TextField
                        variant="outlined"
                        type="text"
                        placeholder="Add Postcode"
                        fullWidth
                        margin="dense"
                        value={value}
                        onChange={onChange}
                        error={!!error}
                        helperText={error?.message}
                      />
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={4} className={classes.formElement}>
                <Typography className={classes.formLabel}>
                  Address Line 1
                </Typography>
                <Controller
                  name="addressLine1"
                  control={locationControl}
                  render={({field: {value, onChange}, fieldState: {error}}) => (
                    <>
                      <TextField
                        variant="outlined"
                        type="text"
                        placeholder="Add Address Line 1"
                        fullWidth
                        margin="dense"
                        value={value}
                        onChange={onChange}
                        error={!!error}
                        helperText={error?.message}
                      />
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={4} className={classes.formElement}>
                <Typography className={classes.formLabel}>
                  Address Line 2
                </Typography>
                <Controller
                  name="addressLine2"
                  control={locationControl}
                  render={({field: {value, onChange}, fieldState: {error}}) => (
                    <>
                      <TextField
                        variant="outlined"
                        type="text"
                        placeholder="Add Address Line 2"
                        fullWidth
                        margin="dense"
                        value={value}
                        onChange={onChange}
                        error={!!error}
                        helperText={error?.message}
                      />
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={4} className={classes.formElement}>
                <Typography className={classes.formLabel}>
                  Address Line 3
                </Typography>
                <Controller
                  name="addressLine3"
                  control={locationControl}
                  render={({field: {value, onChange}, fieldState: {error}}) => (
                    <>
                      <TextField
                        variant="outlined"
                        type="text"
                        placeholder="Add Address Line 3"
                        fullWidth
                        margin="dense"
                        value={value}
                        onChange={onChange}
                        error={!!error}
                        helperText={error?.message}
                      />
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={4} className={classes.formElement}>
                <Typography className={classes.formLabel}>FirstName</Typography>
                <Controller
                  name="firstName"
                  control={locationControl}
                  render={({field: {value, onChange}, fieldState: {error}}) => (
                    <>
                      <TextField
                        variant="outlined"
                        type="text"
                        placeholder="Add First Name"
                        fullWidth
                        margin="dense"
                        value={value}
                        onChange={onChange}
                        error={!!error}
                        helperText={error?.message}
                      />
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={4} className={classes.formElement}>
                <Typography
                  className={`${classes.formLabel} ${
                    isEdit() && Boolean(selectedLocation)
                      ? classes.formLabelDisabled
                      : ''
                  }`}
                >
                  Email
                </Typography>

                <Controller
                  name="email"
                  control={locationControl}
                  render={({field: {value, onChange}, fieldState: {error}}) => (
                    <>
                      <TextField
                        variant="outlined"
                        type="text"
                        placeholder="Add Email"
                        fullWidth
                        margin="dense"
                        value={value}
                        onChange={onChange}
                        error={!!error}
                        helperText={error?.message}
                        disabled={isEdit() && Boolean(selectedLocation)}
                      />
                    </>
                  )}
                />
              </Grid>
              {!selectedLocation && (
                <Grid item xs={12}>
                  <Alert severity="info">{PUDO_PASSWORD_UPDATE}</Alert>
                </Grid>
              )}
            </Grid>
          </form>
        </Modal>
      </>
    );
  };

  const editLocation = (location: LocationDetails) => {
    setLocationValue('id', location.id);
    setLocationValue('country', location.country);
    setLocationValue('name', location.name);
    setLocationValue('postcode', location.postcode);
    setLocationValue('addressLine1', location.addressLine1);
    setLocationValue('addressLine2', location.addressLine2);
    setLocationValue('addressLine3', location.addressLine3);
    setLocationValue('firstName', location.firstName);
    setLocationValue('email', location.email);
    setLocationValue('status', location.status);
    setSelectedLocation(location.id);
    setShowLocationPopup(true);
  };

  const inActiveStatusConfirmation = () => {
    setInActiveConfirmationPopup(false);

    setValue('status', 'active');
    setPudoStatus('active');
  };

  const inActiveStatusConfirmationPopup = () => {
    return (
      <>
        <Modal
          open={inActiveConfirmationPopup}
          className={classes.popupWrapper}
        >
          <form className={classes.modalForm}>
            <Grid
              container
              className={classes.formContainerWhiteBackground}
              spacing={2}
            >
              <Grid item xs={9} component={'h3'}>
                Revoke confirmation
              </Grid>
              <Grid
                container
                xs={3}
                spacing={2}
                alignItems="center"
                justify="flex-end"
              >
                <Grid item>
                  <Button
                    variant="outlined"
                    color="inherit"
                    fullWidth
                    minWidth="0px"
                    onClick={() => inActiveStatusConfirmation()}
                    className={classes.cancelButton}
                  >
                    No
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    minWidth="0px"
                    fullWidth
                    onClick={() => setInActiveConfirmationPopup(false)}
                  >
                    Yes
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              className={classes.filterOptionContainer}
              spacing={2}
            >
              <Grid item xs={12} className={classes.formElement}>
                Are you sure you want to make the pudo inactive/active?
              </Grid>
            </Grid>
          </form>
        </Modal>
      </>
    );
  };

  const locationInactiveConfirmation = () => {
    setLocationInactiveConfirmationPopUp(false);

    setLocationValue('status', 'active');
  };

  const locationInactiveConfirmationPopUp = () => {
    return (
      <>
        <Modal
          open={showLocationInactiveConfirmationPopUp}
          className={classes.popupWrapper}
        >
          <form className={classes.modalForm}>
            <Grid
              container
              className={classes.formContainerWhiteBackground}
              spacing={2}
            >
              <Grid item xs={9} component={'h3'}>
                Revoke confirmation
              </Grid>
              <Grid
                container
                xs={3}
                spacing={2}
                alignItems="center"
                justify="flex-end"
              >
                <Grid item>
                  <Button
                    variant="outlined"
                    color="inherit"
                    fullWidth
                    minWidth="0px"
                    onClick={() => locationInactiveConfirmation()}
                    className={classes.cancelButton}
                  >
                    No
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    minWidth="0px"
                    fullWidth
                    onClick={() => setLocationInactiveConfirmationPopUp(false)}
                  >
                    Yes
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              className={classes.filterOptionContainer}
              spacing={2}
            >
              <Grid item xs={12} className={classes.formElement}>
                Are you sure you want to make the location inactive/active?
              </Grid>
            </Grid>
          </form>
        </Modal>
      </>
    );
  };

  const handleRemoveTag = (option: any) => {
    const updatedSelectedCarriers = selectedCarriers.filter(
      carrier => carrier.id !== option.id
    );
    setSelectedCarriers(updatedSelectedCarriers);

    const updatedSelectedCarrierIds = selectedCarrierIds.filter(
      id => id !== option.id
    );
    setSelectedCarrierIds(updatedSelectedCarrierIds);
  };

  return (
    <div>
      {(isLoading || isFetching) && <ScreenSpinner />}
      <Typography component={'h1'} className={classes.title}>
        {isEdit() ? 'PUDO Details' : 'Add PUDO'}
      </Typography>
      <Breadcrumbs className={classes.breadcrumb} aria-label="breadcrumb">
        <Typography className={classes.breadcrumbText}>Configure</Typography>
        <Link className={classes.breadcrumbLink} to="/pudo">
          PUDO
        </Link>
        <Typography className={classes.breadcrumbText}>
          {isEdit() ? pudoId : 'Add PUDO'}
        </Typography>
      </Breadcrumbs>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container className={classes.formContainer} spacing={2}>
          <Grid item xs={9}></Grid>
          <Grid
            container
            xs={3}
            spacing={2}
            alignItems="center"
            justify="flex-end"
          >
            <Grid item>
              <Button
                variant="outlined"
                color="inherit"
                fullWidth
                minWidth="0px"
                onClick={() => history.push('/pudo')}
                className={classes.cancelButton}
              >
                Close
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                minWidth="0px"
                fullWidth
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={classes.filterOptionContainer} spacing={2}>
          <Grid item xs={3} className={classes.formElement}>
            <Typography className={classes.formLabel}>PUDO Name</Typography>
            <Controller
              name="pudoName"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <>
                  <TextField
                    variant="outlined"
                    type="text"
                    placeholder="Add Name"
                    fullWidth
                    margin="dense"
                    value={value}
                    onChange={e => {
                      if (!/^[a-zA-Z0-9\w -]*$/.test(e.target.value)) return;
                      onChange(e);
                    }}
                    error={!!error}
                    helperText={error?.message}
                  />
                </>
              )}
            />
          </Grid>
          <Grid item xs={3} className={classes.formElement}>
            <Typography className={classes.formLabel}>{'Icon URL'}</Typography>
            <Controller
              name="iconUrl"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <>
                  <TextField
                    variant="outlined"
                    type="text"
                    placeholder="Add Link"
                    fullWidth
                    margin="dense"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error?.message}
                  />
                </>
              )}
            />
          </Grid>

          {isEdit() && (
            <Grid item xs={3} className={classes.formElement}>
              <Typography className={classes.formLabelDisabled}>
                Last Updated On
              </Typography>
              <Controller
                name="lastUpdatedOn"
                control={control}
                render={({field: {value, onChange}, fieldState: {error}}) => (
                  <>
                    <TextField
                      variant="outlined"
                      type="text"
                      placeholder="Last Updated On"
                      fullWidth
                      margin="dense"
                      disabled
                      value={value}
                      onChange={onChange}
                      InputProps={{
                        endAdornment: <DaterangeIcon />,
                      }}
                      error={!!error}
                      helperText={error?.message}
                    />
                  </>
                )}
              />
            </Grid>
          )}
          {isEdit() && (
            <Grid item xs={3} className={classes.formElement}>
              <Typography className={classes.formLabelDisabled}>
                Last Updated By
              </Typography>
              <Controller
                name="lastUpdatedBy"
                control={control}
                render={({field: {value, onChange}, fieldState: {error}}) => (
                  <>
                    <TextField
                      variant="outlined"
                      type="text"
                      placeholder="Last Updated By"
                      fullWidth
                      margin="dense"
                      value={value}
                      disabled
                      onChange={onChange}
                      error={!!error}
                      helperText={error?.message}
                    />
                  </>
                )}
              />
            </Grid>
          )}
          {
            <Grid item xs={3} className={classes.formElement}>
              <Typography className={classes.formLabel}>Status</Typography>
              <Controller
                name="status"
                control={control}
                render={({field: {value, onChange}, fieldState: {error}}) => {
                  const handleStatusChange = (
                    event: React.ChangeEvent<{value: unknown}>
                  ) => {
                    const selectedValue = event.target.value;

                    if (selectedValue === 'inactive') {
                      setInActiveConfirmationPopup(true);
                    }
                    setPudoStatus(selectedValue as 'active' | 'inactive');
                    onChange(event);
                  };
                  return (
                    <Select
                      value={value}
                      onChange={handleStatusChange}
                      displayEmpty
                      fullWidth
                      margin="dense"
                      variant="outlined"
                      className={classes.select}
                    >
                      {statusOptions.map(status => (
                        <MenuItem value={status.value} key={status.key}>
                          {status.text}
                        </MenuItem>
                      ))}
                    </Select>
                  );
                }}
              />
            </Grid>
          }
          {isEdit() && (
            <Grid item xs={3} className={classes.formElement}>
              <Typography className={classes.formLabelDisabled}>
                PUDO ID
              </Typography>
              <Controller
                name="pudoId"
                control={control}
                render={({field: {value, onChange}, fieldState: {error}}) => (
                  <>
                    <TextField
                      variant="outlined"
                      type="text"
                      placeholder="Add Number"
                      fullWidth
                      margin="dense"
                      value={value}
                      disabled
                      onChange={onChange}
                      error={!!error}
                      helperText={error?.message}
                    />
                  </>
                )}
              />
            </Grid>
          )}
          {isEdit() && pudoData?.status === 'inactive' && (
            <Grid item xs={12}>
              <Alert severity="warning">{PUDO_INACTIVE_WARNING}</Alert>
            </Grid>
          )}
        </Grid>

        <Grid
          container
          className={classes.formContainerWhiteBackground}
          spacing={2}
        >
          <Grid item xs={9} component={'h2'}>
            {'Location'}
          </Grid>
          <Grid
            container
            xs={3}
            spacing={2}
            alignItems="center"
            justify="flex-end"
          >
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                minWidth="0px"
                fullWidth
                onClick={() => setShowLocationPopup(true)}
              >
                Add Location
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          className={clsx(classes.filterOptionContainer, classes.listWrapper)}
          spacing={2}
        >
          {locations.length > 0 ? (
            locations.map(location => (
              <Grid item key={location.id} xs={3}>
                <div className={classes.editableItemInner}>
                  <Typography className={classes.itemTitle}>
                    {location.name}
                  </Typography>
                  <Typography className={classes.itemTitle}>
                    {location.postcode}
                  </Typography>
                  <div className={classes.actionButtonWrapper}>
                    <IconButton
                      className={
                        locations.length === 1
                          ? classes.disableIconButton
                          : classes.iconButton
                      }
                      onClick={() => editLocation(location)}
                    >
                      <Edit3 />
                    </IconButton>
                    {!isEdit() && (
                      <IconButton
                        className={classes.iconButton}
                        disabled={locations.length === 1}
                        onClick={() =>
                          toggleDeleteConfirmationPopup(
                            'location',
                            location.id,
                            true
                          )
                        }
                      >
                        <Trash2 />
                      </IconButton>
                    )}
                  </div>
                </div>
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <Typography className={classes.noLocationText}>
                Add Location by clicking <span>“Add Location”</span> button
              </Typography>
            </Grid>
          )}
        </Grid>

        <Grid
          container
          className={classes.formContainerWhiteBackground}
          spacing={2}
        >
          <Grid item xs={9} component={'h2'}>
            {'Carrier'}
          </Grid>
          <Grid
            container
            xs={3}
            spacing={2}
            alignItems="center"
            justify="flex-end"
          >
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                minWidth="0px"
                fullWidth
                onClick={() => handleAddCarrier()}
              >
                Add Carrier
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          className={clsx(classes.filterOptionContainer, classes.listWrapper)}
          spacing={2}
        >
          {carriers.length > 0 ? (
            carriers.map((carrier, index) => (
              <Grid item key={carrier.id} xs={3}>
                <div
                  className={clsx(
                    classes.editableCarrierInner,
                    classes.carrierItem
                  )}
                >
                  <Typography
                    className={clsx(
                      classes.itemTitle,
                      carrier.status === 'inactive' && classes.carrierDisabled
                    )}
                  >
                    {(carrier.clientFlag ? 'Client - ' : '') + carrier.name}
                  </Typography>
                  <div className={classes.actionButtonWrapper}>
                    <IconButton
                      className={classes.iconButton}
                      onClick={() => {
                        toggleDeleteConfirmationPopup(
                          'carrier',
                          carrier.id,
                          true
                        );
                      }}
                    >
                      <Trash2 />
                    </IconButton>
                  </div>
                </div>
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <Typography className={classes.noLocationText}>
                Add carriers by clicking <span>"Add Carrier"</span> button
              </Typography>
            </Grid>
          )}
        </Grid>
      </form>
      {deleteConfirmationPopup()}
      {toggleLocationPopUp()}
      {toggleCarrierPopUp()}
      {inActiveStatusConfirmationPopup()}
      {locationInactiveConfirmationPopUp()}
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 26,
    lineHeight: '40px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
      lineHeight: '28px',
    },
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(3),
  },
  button: {
    marginBottom: theme.spacing(3),
  },

  formContainer: {
    width: '100%',
    borderRadius: '5px 5px 0 0',
    padding: '12px 16px 15px',
    margin: 0,
    border: '1px solid',
    borderBottom: '0px',
    borderColor: theme.palette.grey[300],
  },
  formContainerWhiteBackground: {
    width: '100%',
    background: '#fff',
    borderRadius: '5px 5px 0 0',
    padding: '12px 16px 15px',
    margin: 0,
    border: '1px solid',
    borderBottom: '0px',
    borderColor: theme.palette.grey[300],
  },
  filterOptionContainer: {
    width: '100%',
    background: '#fff',
    borderRadius: '0 0 5px 5px',
    padding: '12px 16px 15px',
    margin: 0,
    marginBottom: theme.spacing(3),
    border: '1px solid',
    borderColor: theme.palette.grey[300],
  },

  listWrapper: {
    maxHeight: '250px',
    overflowX: 'hidden',
    overflowY: 'auto',
  },

  filterText: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '21px',
    lineHeight: '32px',
  },

  formElement: {},
  formLabel: {
    fontSize: 16,
    lineHeight: '28px',
  },
  formLabelDisabled: {
    color: '#9E9E9E',
    fontSize: 16,
    lineHeight: '28px',
  },

  hash: {
    backgroundColor: theme.palette.grey[400],
  },
  adornmentTextField: {
    width: '100%',
    '& span': {
      backgroundColor: theme.palette.grey[400],
    },
  },
  select: {
    marginTop: '8px',
    marginBottom: '4px',
  },
  datePicker: {
    backgroundColor: 'pink',
    '&& .MuiPickersDay-root.Mui-selected': {
      backgroundColor: 'red !important',
      '&:hover': {
        backgroundColor: 'red !important',
      },
      '&:focus': {
        backgroundColor: 'red !important',
      },
    },
  },
  textFieldSkeleton: {
    width: '100%',
    height: 40,
    borderRadius: 4,
    marginTop: 8,
    marginBottom: 4,
  },
  tableContainer: {
    marginTop: theme.spacing(3),
  },
  name: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.primary.main,
  },
  dashboardText: {
    fontSize: 14,
    lineHeight: '20px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      lineHeight: '14px',
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginBottom: theme.spacing(1),
  },
  subjectText: {
    fontSize: 21,
    lineHeight: '32px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
      lineHeight: '24px',
    },
    fontWeight: theme.typography.fontWeightBold,
  },
  subjectInput: {
    margin: 0,
  },
  cancelButton: {
    color: theme.palette.grey[500],
  },
  editButton: {
    minWidth: '0px',
    width: 'auto',
    padding: 9,
    fontSize: 18,
    backgroundColor: theme.palette.primary.light,
    '&:hover': {
      backgroundColor: darken(theme.palette.primary.light, 0.1),
    },
  },
  updateButton: {
    minWidth: '0px',
    width: 'auto',
  },
  toolbar: {
    borderRadius: '5px',
  },
  editorError: {
    fontSize: '0.75rem',
    color: theme.palette.error.main,
    margin: '4px 14px 0px',
  },
  content: {
    backgroundColor: '#fff',
    height: 'calc(100vh - 220px)',
    minHeight: '300px',
    padding: 30,
  },
  breadcrumb: {
    marginBottom: theme.spacing(10),
  },
  breadcrumbLink: {
    color: 'inherit',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  breadcrumbText: {
    color: 'inherit',
    fontSize: 'inherit',
  },
  editableItemInner: {
    background: '#F5F5F5',
    borderRadius: '5px',
    position: 'relative',
    minHeight: '57px',
    padding: '7px 81px 7px 19px',
  },
  actionButtonWrapper: {
    position: 'absolute',
    right: '9px',
    top: '16px',
    '& button': {
      padding: '6px',
      width: '26px',
      height: '26px',
      background: '#fff',
      marginRight: '9px',
      '&:last-child': {
        marginRight: '0',
      },
    },
    '& svg': {
      width: '20px',
      height: '20px',
    },
  },
  itemTitle: {
    fontSize: '14px',
    lineHeight: '22px',
    color: '#212121',
    'white-space': 'nowrap',
    overflow: 'hidden',
    'text-overflow': 'ellipsis',
  },
  popupWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& form': {
      maxWidth: '830px',
      padding: '0 15px',
      margin: '0 auto',
      flex: 'auto',
    },
  },
  noLocationText: {
    textAlign: 'center',
    fontSize: '21px',
    margin: '13.25px 0px',
    '& span': {
      fontWeight: theme.typography.fontWeightBold,
    },
  },
  errorMessage: {
    color: theme.palette.error.main,
    margin: '0 14px',
    fontSize: '0.75rem',
  },
  disableProductIcon: {
    backgroundColor: '#9E9E9E',
    color: '#fff',
  },
  iconButton: {
    border: '1px solid #757575',
  },
  disableIconButton: {
    border: '1px solid rgba(0, 0, 0, 0.26)',
  },
  productDisableIconButton: {
    '& svg': {
      width: '26px',
      height: '26px',
      '&:hover': {
        fill: 'rgba(0, 0, 0, 0.04)',
      },
    },
  },
  modalForm: {
    outline: 'none',
    boxShadow: 'none',
  },
  carrierDisabled: {
    color: '#9E9E9E',
  },
  dropdownPaper: {
    width: '232px',
    border: '1px solid #E0E0E0',
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 4px 4px 0px rgba(0,0,0,0.25)', // Drop shadow
    '& .MuiAutocomplete-listbox': {
      maxHeight: '250px',
    },
  },
  carrierPopupWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& form': {
      maxWidth: '460px',
      minHeight: '221px',
      padding: '0 15px',
      margin: '0 auto',
      flex: 'auto',
    },
  },
  autocomplete: {
    width: '100%',
    '& .MuiAutocomplete-inputRoot': {
      minWidth: '232px',
      paddingTop: '4px !important',
      paddingBottom: '4px !important',
      paddingLeft: '4px !important',
    },
  },
  selectedTags: {
    backgroundColor: '#36A6FF',
    borderRadius: '15px',
    margin: '4px',
    '& .MuiChip-label': {
      color: 'white',
    },
  },
  hiddenClearIcon: {
    display: 'none',
  },
  customButton: {
    width: '208px',
    height: '45px',
    top: '897px',
    left: '521px',
    borderRadius: '4px',
    border: '1px solid #36A6FF',
    backgroundColor: '#36A6FF',
    color: 'white',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    position: 'relative',
    '&:hover': {
      backgroundColor: '#36A6FF', // Adjust the hover background color
    },
  },
  editableCarrierInner: {
    background: '#F5F5F5',
    borderRadius: '5px',
    position: 'relative',
    minHeight: '57px',
    padding: '7px 81px 7px 19px',
    display: 'flex',
    alignItems: 'center',
  },
  carrierItem: {
    paddingRight: '45px',
  },
}));

export default Pudo;
