import axios from 'lib/axios';

import type {
  DashboardDetailsResponse_Mock,
  UserResponse_Mock,
  ItemsResponse_Mock,
  PatchUserDto_Mock,
  PaginationParams,
} from 'types';

class DashboardService {
  async updateDetails(data: {subject: string; details: string}): Promise<void> {
    await axios.put<DashboardDetailsResponse_Mock>(
      '/cc/dashboard-content',
      data
    );
  }

  async getDetails(): Promise<any> {
    const response = await axios.get('/cc/dashboard-content');
    return response.data;
  }

  async getUser(id: string) {
    const response = await axios.get<UserResponse_Mock>(`/users/${id}`);

    return response.data;
  }

  async editUser(id: string, patchUserDto: PatchUserDto_Mock) {
    const response = await axios.patch<UserResponse_Mock>(
      `/users/${id}`,
      patchUserDto
    );

    return response.data;
  }

  async getUserPostedItems({
    userId,
    start = 0,
    limit = 10,
  }: {userId: string} & PaginationParams) {
    const response = await axios.get<ItemsResponse_Mock>(
      `/users/${userId}/items`
    );

    const {items = []} = response.data;

    return {
      count: Array.isArray(items) ? items.length : 0,
      records: Array.isArray(items) ? items.slice(start, start + limit) : [],
    };
  }
}

export const dashboardService = new DashboardService();
