import axios from 'lib/axios';

import type {ConsumerSearchResponses_Mock, ConsumerResponses_Mock} from 'types';
import {removeWhiteSpaces} from 'utils/String';

class ConsumerService {
  async search({
    status,
    name,
    createdFrom,
    createdTo,
    country,
    city,
    postcode,
  }: {
    status: 'active' | 'inactive';
    name?: string;
    postcode?: string;
    country?: string;
    city?: string;
    createdFrom?: number;
    createdTo?: number;
  }) {
    const response = await axios.post<ConsumerSearchResponses_Mock>(
      '/cc/consumers',
      {
        status,
        ...(name ? {name} : {}),
        ...(createdFrom ? {createdFrom} : {}),
        ...(createdTo ? {createdTo} : {}),
        ...(country ? {country} : {}),
        ...(city ? {city} : {}),
        ...(postcode ? {postcode} : {}),
      }
    );
    return response.data;
  }

  async get(id: string) {
    const response = await axios.get<ConsumerResponses_Mock>(
      `/cc/consumers/${id}`
    );
    return response.data;
  }

  async delete(carrierId: string, statusUpdateTransactionId: string) {
    const response = await axios.put(
      `/cc/correctItem/${carrierId}/${statusUpdateTransactionId}`
    );
    return response.data;
  }

  async update({
    id,
    status,
    firstName,
    lastName,
    postcode,
    country,
    city,
    address1,
    address2,
    address3,
  }: {
    status: string;
    id: string;
    firstName: string;
    lastName?: string;
    postcode: string;
    country: string;
    city?: string; // mandatory for easycode
    address1?: string;
    address2?: string;
    address3?: string;
  }) {
    const response = await axios.put(`/cc/consumers/${id}`, {
      status,
      firstName,
      ...(lastName ? {lastName} : {}),
      country,
      ...(city ? {city} : {}),
      postcode: removeWhiteSpaces(postcode),
      ...(address1 ? {address1} : {}),
      ...(address2 ? {address2} : {}),
      ...(address3 ? {address3} : {}),
    });
    return response.data;
  }
}

export const consumerService = new ConsumerService();
