import * as React from 'react';
import {Switch, Route, RouteProps, Redirect} from 'react-router-dom';
import {Helmet} from 'react-helmet-async';

import ErrorBoundary from 'components/utils/ErrorBoundary';

import GuestGuard from 'components/auth/guards/GuestGuard';
import AuthGuard, {AuthGuardProps} from 'components/auth/guards/AuthGuard';
// import WorkInProgress from 'components/WorkInProgress';

import GuestLayout from 'layouts/GuestLayout';
import DrawerLayout from 'layouts/DrawerLayout';

import Login from 'pages/Login';
import ForgotPassword from 'pages/ForgotPassword';
import ResetPassword from 'pages/ResetPassword';
import PaymentSuccess from 'pages/PaymentSuccess';
import PaymentFailed from 'pages/PaymentFailed';
import Dashboard from 'pages/Dashboard';
import Lookup from 'pages/Lookup';
import LookupItem from 'pages/LookupItem';
import Correct from 'pages/Correct';
import CorrectItem from 'pages/CorrectItem';
import Consumers from 'pages/Consumers';
import ConsumerActivities from 'pages/ConsumerActivities';
import Consumer from 'pages/Consumer';
import Retailers from 'pages/Retailers';
import Carriers from 'pages/Carriers';
import Carrier from 'pages/Carrier';
import Retailer from 'pages/Retailer';
import Transactions from 'pages/Transactions';
import Pudo from 'pages/Pudo';
import Pudos from 'pages/Pudos';
import CarrierActivities from 'pages/CarrierActivities';
import CarrierActivity from 'pages/CarrierActivity';

type RouteConfig = {
  path: string | readonly string[];
  exact?: boolean;
  title?: string;
  guard?: React.FC<AuthGuardProps> | null;
  guardProps?: AuthGuardProps;
  layout?: React.FC | null;
  component?: React.FC<RouteProps>;
  routes?: RouteConfig[];
};

const routesConfig: RouteConfig[] = [
  {
    path: '/',
    exact: true,
    title: 'Login',
    guard: GuestGuard,
    layout: GuestLayout,
    component: Login,
  },
  {
    path: '/forgot-password',
    exact: true,
    title: 'Forgot password',
    guard: GuestGuard,
    layout: GuestLayout,
    component: ForgotPassword,
  },
  {
    path: '/reset-password',
    exact: true,
    title: 'Reset password',
    guard: GuestGuard,
    layout: GuestLayout,
    component: ResetPassword,
  },
  {
    path: '/payment-success',
    exact: true,
    title: 'Payment success',
    layout: GuestLayout,
    component: PaymentSuccess,
  },
  {
    path: '/payment-failed',
    exact: true,
    title: 'Payment failed',
    layout: GuestLayout,
    component: PaymentFailed,
  },
  {
    path: '*',
    layout: DrawerLayout,
    routes: [
      {
        path: '/dashboard',
        exact: true,
        title: 'Dashboard',
        guard: AuthGuard,
        guardProps: {
          allowedFor: ['admin', 'carrierAdmin'],
        },
        component: Dashboard,
      },
      {
        path: '/look-up',
        exact: true,
        title: 'Lookup',
        guard: AuthGuard,
        guardProps: {
          allowedFor: ['admin', 'carrierAdmin'],
        },
        component: Lookup,
      },
      {
        path: '/retailer',
        exact: true,
        title: 'Retailers',
        guard: AuthGuard,
        guardProps: {
          allowedFor: ['admin'],
        },
        component: Retailers,
      },
      {
        path: '/retailer/:id',
        exact: true,
        title: 'Retailer',
        guard: AuthGuard,
        guardProps: {
          allowedFor: ['admin', 'carrierAdmin'],
        },
        component: Retailer,
      },
      {
        path: ['/look-up/sent/:codeId', '/look-up/returned/:codeId'],
        exact: true,
        title: 'Lookup Item',
        guard: AuthGuard,
        guardProps: {
          allowedFor: ['admin', 'carrierAdmin'],
        },
        component: LookupItem,
      },
      {
        path: '/correct',
        exact: true,
        title: 'Correct',
        guard: AuthGuard,
        guardProps: {
          allowedFor: ['admin', 'carrierAdmin'],
        },
        component: Correct,
      },
      {
        path: '/correct/:carrierId/:statusUpdateTransactionId',
        exact: true,
        title: 'Correct Item',
        guard: AuthGuard,
        guardProps: {
          allowedFor: ['admin', 'carrierAdmin'],
        },
        component: CorrectItem,
      },
      {
        path: '/consumer',
        exact: true,
        title: 'Consumer',
        guard: AuthGuard,
        guardProps: {
          allowedFor: ['admin'],
        },
        component: Consumers,
      },
      {
        path: '/CActivities',
        exact: true,
        title: 'ConsumerActivitie',
        guard: AuthGuard,
        guardProps: {
          allowedFor: ['admin'],
        },
        component: ConsumerActivities,
      },
      {
        path: '/consumer/:id',
        exact: true,
        title: 'Consumer',
        guard: AuthGuard,
        guardProps: {
          allowedFor: ['admin'],
        },
        component: Consumer,
      },
      {
        path: '/carrier',
        exact: true,
        title: 'Carrier',
        guard: AuthGuard,
        guardProps: {
          allowedFor: ['admin'],
        },
        component: Carriers,
      },
      {
        path: '/carrier/:id',
        exact: true,
        title: 'Carrier',
        guard: AuthGuard,
        guardProps: {
          allowedFor: ['admin'],
        },
        component: Carrier,
      },
      {
        path: '/transactions',
        exact: true,
        title: 'Transactions',
        guard: AuthGuard,
        guardProps: {
          allowedFor: ['admin'],
        },
        component: Transactions,
      },
      {
        path: '/pudo',
        exact: true,
        title: 'Pudos',
        guard: AuthGuard,
        guardProps: {
          allowedFor: ['admin'],
        },
        component: Pudos,
      },
      {
        path: '/pudo/:id',
        exact: true,
        title: 'Pudo',
        guard: AuthGuard,
        guardProps: {
          allowedFor: ['admin'],
        },
        component: Pudo,
      },
      {
        path: '/caReport',
        exact: true,
        title: 'carrierActivity',
        guard: AuthGuard,
        guardProps: {
          allowedFor: ['admin'],
        },
        component: CarrierActivities,
      },
      {
        path: '/caReport/:id',
        exact: true,
        title: 'carrierActivity',
        guard: AuthGuard,
        guardProps: {
          allowedFor: ['admin'],
        },
        component: CarrierActivity,
      },
    ],
  },
];

const renderRoutes = (routes: RouteConfig[]) =>
  routes ? (
    <Switch>
      {routes.map((route, index) => {
        const Guard = route.guard ?? React.Fragment;
        const Layout = route.layout ?? React.Fragment;
        const Component = route.component ?? React.Fragment;

        return (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            render={props => (
              <Guard {...route.guardProps}>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <ErrorBoundary>
                      {route.title ? (
                        <Helmet>
                          <title>{route.title}</title>
                        </Helmet>
                      ) : null}

                      <Component {...props} />
                    </ErrorBoundary>
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
      <Route render={() => <Redirect to="/" />} />
    </Switch>
  ) : null;

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
