import {
  createMuiTheme,
  unstable_createMuiStrictModeTheme,
  responsiveFontSizes,
} from '@material-ui/core';

import {NEUE_HAAS_GROTESK} from './fonts';

const createTheme =
  process.env.NODE_ENV === 'production'
    ? createMuiTheme
    : unstable_createMuiStrictModeTheme;

const defaultTheme = createMuiTheme();

const theme = createTheme({
  typography: {
    fontFamily: NEUE_HAAS_GROTESK,
  },
  palette: {
    primary: {
      light: '#EBF6FF',
      main: '#36A6FF',
    },
    secondary: {
      light: '#FCE8EC',
      main: '#DF1642',
    },
    error: {
      light: '#FCE8EC',
      main: '#DF1642',
    },
    success: {
      light: '#E8F6EE',
      main: '#18A957',
    },
    divider: defaultTheme.palette.grey[200],
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '*': {
          boxSizing: 'border-box',
          margin: 0,
          padding: 0,
        },
        html: {
          '-webkit-font-smoothing': 'antialiased',
          '-moz-osx-font-smoothing': 'grayscale',
          width: '100%',
          height: '100%',
        },
        body: {
          width: '100%',
          height: '100%',
        },
        '#root': {
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        },
        '.ps__rail-y': {
          zIndex: 3,
        },
        '.ps__rail-x': {
          zIndex: 3,
        },
        // '.MuiPickersDay-root.Mui-selected': {
        //   backgroundColor: '#EBF6FF !important',
        //   '&:hover': {
        //     backgroundColor: '#EBF6FF !important',
        //   },
        //   '&:focus': {
        //     backgroundColor: '#EBF6FF !important',
        //   },
        // },
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',
        minWidth: 125,
        fontWeight: 'bold',
        borderRadius: 4,
      },
      containedPrimary: {
        color: '#ffffff !important', // "!important" to fix Safari bug
      },
    },
    MuiInput: {},
    MuiOutlinedInput: {
      root: {
        borderRadius: 4,
      },
    },
    MuiFilledInput: {
      root: {
        borderRadius: 4,
      },
    },
    MuiDrawer: {
      paperAnchorDockedLeft: {
        borderRight: `1px solid ${defaultTheme.palette.grey[300]}`,
      },
    },
    MuiTable: {
      root: {
        borderLeft: '1px solid #E0E0E0',
        borderRight: '1px solid #E0E0E0',
      },
    },
    MuiTableRow: {
      root: {
        backgroundColor: '#ffffff',
        '&:nth-of-type(even)': {
          backgroundColor: '#FCFCFC',
        },
      },
    },
    MuiTableCell: {
      root: {
        fontSize: 16,
        lineHeight: '24px',
        fontWeight: 400,
        borderBottom: '1px solid #E0E0E0',
      },
      stickyHeader: {
        backgroundColor: '#EBF6FF',
        borderTop: '1px solid #E0E0E0',
      },
      head: {
        backgroundColor: '#EBF6FF',
        fontWeight: 700,
      },
    },
    MuiTablePagination: {
      root: {
        backgroundColor: '#ffffff',
        border: '1px solid #E0E0E0',
        fontSize: 16,
        lineHeight: '24px',
        fontWeight: 400,
      },
      caption: {
        fontSize: 16,
        lineHeight: '24px',
        fontWeight: 400,
      },
      input: {
        fontSize: 16,
        lineHeight: '24px',
        fontWeight: 400,
      },
    },
    MuiBreadcrumbs: {
      root: {
        fontSize: 14,
      },
      separator: {
        color: '#36A6FF',
      },
      li: {
        color: '#212121',
      },
    },
  },
  props: {
    MuiButton: {
      disableElevation: true,
    },
  },
});

export default responsiveFontSizes(theme);
