import axios from 'lib/axios';

import type {
  ConsumerActivitySearchResponses_Mock,
  ConsumerResponses_Mock,
} from 'types';

class ConsumerActivitieService {
  async search({
    status,
    name,
    sfId,
    acivityFrom,
    acivityTo,
    country,
    city,
    activiy,
  }: {
    status: 'active' | 'inactive';
    name?: string;
    sfId?: string;
    activiy?: string;
    country?: string;
    city?: string;
    acivityFrom?: number;
    acivityTo?: number;
  }) {
    const response = await axios.post<ConsumerActivitySearchResponses_Mock>(
      '/cc/consumers-activities',
      {
        status,
        ...(name ? {name} : {}),
        ...(sfId ? {sfId} : {}),
        ...(acivityFrom ? {acivityFrom} : {}),
        ...(acivityTo ? {acivityTo} : {}),
        ...(country ? {country} : {}),
        ...(city ? {city} : {}),
        ...(activiy ? {activiy} : {}),
      }
    );
    return response.data;
  }

  async get(id: string) {
    const response = await axios.get<ConsumerResponses_Mock>(
      `/cc/consumers/${id}`
    );
    return response.data;
  }
}

export const consumerActivitieService = new ConsumerActivitieService();
