import axios from 'axios';

import {handleError} from 'utils/errors';

const client = axios.create({
  baseURL:
    process.env.REACT_APP_MIRAGEJS_ENABLE === 'true'
      ? '/api'
      : process.env.REACT_APP_AWS_API_URL,
  headers: {'Content-Type': 'application/json'},
});

client.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    handleError(error);
    throw error;
  }
);

export default client;
