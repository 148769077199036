import {makeStyles} from '@material-ui/core';

import {CircularProgress} from '@material-ui/core';

const ScreenSpinner: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress className={classes.loader} />
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: 'rgba(0,0,0,0.5)',
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    minHeight: '100%',
    position: 'fixed',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    zIndex: 9999,
  },
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    margin: '-20px 0 0 -20px',
  },
}));

export default ScreenSpinner;
