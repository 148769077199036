export const currency: any = {
  AFN: {
    id: 1,
    name: 'Afghanistan',
    isoAlpha2: 'AF',
    isoAlpha3: 'AFG',
    isoNumeric: 4,
    symbol: '؋',
    currency: {
      code: 'AFN',
      name: 'Afghani',
    },
  },
  ALL: {
    id: 2,
    name: 'Albania',
    isoAlpha2: 'AL',
    isoAlpha3: 'ALB',
    isoNumeric: 8,
    symbol: 'Lek',
    currency: {
      code: 'ALL',
      name: 'Lek',
    },
  },
  DZD: {
    id: 3,
    name: 'Algeria',
    isoAlpha2: 'DZ',
    isoAlpha3: 'DZA',
    isoNumeric: 12,
    symbol: false,
    currency: {
      code: 'DZD',
      name: 'Dinar',
    },
  },
  EUR: {
    id: 5,
    name: 'Andorra',
    isoAlpha2: 'AD',
    isoAlpha3: 'AND',
    isoNumeric: 20,
    symbol: '€',
    currency: {
      code: 'EUR',
      name: 'Euro',
    },
  },
  AOA: {
    id: 6,
    name: 'Angola',
    isoAlpha2: 'AO',
    isoAlpha3: 'AGO',
    isoNumeric: 24,
    symbol: 'Kz',
    currency: {
      code: 'AOA',
      name: 'Kwanza',
    },
  },
  XCD: {
    id: 7,
    name: 'Anguilla',
    isoAlpha2: 'AI',
    isoAlpha3: 'AIA',
    isoNumeric: 660,
    symbol: '$',
    currency: {
      code: 'XCD',
      name: 'Dollar',
    },
  },
  ARS: {
    id: 10,
    name: 'Argentina',
    isoAlpha2: 'AR',
    isoAlpha3: 'ARG',
    isoNumeric: 32,
    symbol: '$',
    currency: {
      code: 'ARS',
      name: 'Peso',
    },
  },
  AMD: {
    id: 11,
    name: 'Armenia',
    isoAlpha2: 'AM',
    isoAlpha3: 'ARM',
    isoNumeric: 51,
    symbol: false,
    currency: {
      code: 'AMD',
      name: 'Dram',
    },
  },
  AWG: {
    id: 12,
    name: 'Aruba',
    isoAlpha2: 'AW',
    isoAlpha3: 'ABW',
    isoNumeric: 533,
    symbol: 'ƒ',
    currency: {
      code: 'AWG',
      name: 'Guilder',
    },
  },

  AZN: {
    id: 15,
    name: 'Azerbaijan',
    isoAlpha2: 'AZ',
    isoAlpha3: 'AZE',
    isoNumeric: 31,
    symbol: 'ман',
    currency: {
      code: 'AZN',
      name: 'Manat',
    },
  },
  BSD: {
    id: 16,
    name: 'Bahamas',
    isoAlpha2: 'BS',
    isoAlpha3: 'BHS',
    isoNumeric: 44,
    symbol: '$',
    currency: {
      code: 'BSD',
      name: 'Dollar',
    },
  },
  BHD: {
    id: 17,
    name: 'Bahrain',
    isoAlpha2: 'BH',
    isoAlpha3: 'BHR',
    isoNumeric: 48,
    symbol: false,
    currency: {
      code: 'BHD',
      name: 'Dinar',
    },
  },
  BDT: {
    id: 18,
    name: 'Bangladesh',
    isoAlpha2: 'BD',
    isoAlpha3: 'BGD',
    isoNumeric: 50,
    symbol: false,
    currency: {
      code: 'BDT',
      name: 'Taka',
    },
  },
  BBD: {
    id: 19,
    name: 'Barbados',
    isoAlpha2: 'BB',
    isoAlpha3: 'BRB',
    isoNumeric: 52,
    symbol: '$',
    currency: {
      code: 'BBD',
      name: 'Dollar',
    },
  },
  BYR: {
    id: 20,
    name: 'Belarus',
    isoAlpha2: 'BY',
    isoAlpha3: 'BLR',
    isoNumeric: 112,
    symbol: 'p.',
    currency: {
      code: 'BYR',
      name: 'Ruble',
    },
  },
  BZD: {
    id: 22,
    name: 'Belize',
    isoAlpha2: 'BZ',
    isoAlpha3: 'BLZ',
    isoNumeric: 84,
    symbol: 'BZ$',
    currency: {
      code: 'BZD',
      name: 'Dollar',
    },
  },
  BMD: {
    id: 24,
    name: 'Bermuda',
    isoAlpha2: 'BM',
    isoAlpha3: 'BMU',
    isoNumeric: 60,
    symbol: '$',
    currency: {
      code: 'BMD',
      name: 'Dollar',
    },
  },
  BTN: {
    id: 25,
    name: 'Bhutan',
    isoAlpha2: 'BT',
    isoAlpha3: 'BTN',
    isoNumeric: 64,
    symbol: false,
    currency: {
      code: 'BTN',
      name: 'Ngultrum',
    },
  },
  BOB: {
    id: 26,
    name: 'Bolivia',
    isoAlpha2: 'BO',
    isoAlpha3: 'BOL',
    isoNumeric: 68,
    symbol: '$b',
    currency: {
      code: 'BOB',
      name: 'Boliviano',
    },
  },
  BAM: {
    id: 27,
    name: 'Bosnia and Herzegovina',
    isoAlpha2: 'BA',
    isoAlpha3: 'BIH',
    isoNumeric: 70,
    symbol: 'KM',
    currency: {
      code: 'BAM',
      name: 'Marka',
    },
  },
  BWP: {
    id: 28,
    name: 'Botswana',
    isoAlpha2: 'BW',
    isoAlpha3: 'BWA',
    isoNumeric: 72,
    symbol: 'P',
    currency: {
      code: 'BWP',
      name: 'Pula',
    },
  },
  NOK: {
    id: 29,
    name: 'Bouvet Island',
    isoAlpha2: 'BV',
    isoAlpha3: 'BVT',
    isoNumeric: 74,
    symbol: 'kr',
    currency: {
      code: 'NOK',
      name: 'Krone',
    },
  },
  BRL: {
    id: 30,
    name: 'Brazil',
    isoAlpha2: 'BR',
    isoAlpha3: 'BRA',
    isoNumeric: 76,
    symbol: 'R$',
    currency: {
      code: 'BRL',
      name: 'Real',
    },
  },
  BND: {
    id: 33,
    name: 'Brunei',
    isoAlpha2: 'BN',
    isoAlpha3: 'BRN',
    isoNumeric: 96,
    symbol: '$',
    currency: {
      code: 'BND',
      name: 'Dollar',
    },
  },
  USD: {
    id: 31,
    name: 'British Indian Ocean Territory',
    isoAlpha2: 'IO',
    isoAlpha3: 'IOT',
    isoNumeric: 86,
    symbol: '$',
    currency: {
      code: 'USD',
      name: 'Dollar',
    },
  },
  BGN: {
    id: 34,
    name: 'Bulgaria',
    isoAlpha2: 'BG',
    isoAlpha3: 'BGR',
    isoNumeric: 100,
    symbol: 'лв',
    currency: {
      code: 'BGN',
      name: 'Lev',
    },
  },
  BIF: {
    id: 36,
    name: 'Burundi',
    isoAlpha2: 'BI',
    isoAlpha3: 'BDI',
    isoNumeric: 108,
    symbol: false,
    currency: {
      code: 'BIF',
      name: 'Franc',
    },
  },
  KHR: {
    id: 37,
    name: 'Cambodia',
    isoAlpha2: 'KH',
    isoAlpha3: 'KHM',
    isoNumeric: 116,
    symbol: '៛',
    currency: {
      code: 'KHR',
      name: 'Riels',
    },
  },
  XAF: {
    id: 38,
    name: 'Cameroon',
    isoAlpha2: 'CM',
    isoAlpha3: 'CMR',
    isoNumeric: 120,
    symbol: 'FCF',
    currency: {
      code: 'XAF',
      name: 'Franc',
    },
  },
  CAD: {
    id: 39,
    name: 'Canada',
    isoAlpha2: 'CA',
    isoAlpha3: 'CAN',
    isoNumeric: 124,
    symbol: '$',
    currency: {
      code: 'CAD',
      name: 'Dollar',
    },
  },
  CVE: {
    id: 40,
    name: 'Cape Verde',
    isoAlpha2: 'CV',
    isoAlpha3: 'CPV',
    isoNumeric: 132,
    symbol: false,
    currency: {
      code: 'CVE',
      name: 'Escudo',
    },
  },
  KYD: {
    id: 41,
    name: 'Cayman Islands',
    isoAlpha2: 'KY',
    isoAlpha3: 'CYM',
    isoNumeric: 136,
    symbol: '$',
    currency: {
      code: 'KYD',
      name: 'Dollar',
    },
  },

  CLP: {
    id: 44,
    name: 'Chile',
    isoAlpha2: 'CL',
    isoAlpha3: 'CHL',
    isoNumeric: 152,
    symbol: false,
    currency: {
      code: 'CLP',
      name: 'Peso',
    },
  },
  CNY: {
    id: 45,
    name: 'China',
    isoAlpha2: 'CN',
    isoAlpha3: 'CHN',
    isoNumeric: 156,
    symbol: '¥',
    currency: {
      code: 'CNY',
      name: 'YuanRenminbi',
    },
  },

  COP: {
    id: 48,
    name: 'Colombia',
    isoAlpha2: 'CO',
    isoAlpha3: 'COL',
    isoNumeric: 170,
    symbol: '$',
    currency: {
      code: 'COP',
      name: 'Peso',
    },
  },
  KMF: {
    id: 49,
    name: 'Comoros',
    isoAlpha2: 'KM',
    isoAlpha3: 'COM',
    isoNumeric: 174,
    symbol: false,
    currency: {
      code: 'KMF',
      name: 'Franc',
    },
  },
  AUD: {
    id: 47,
    name: 'Cocos Islands',
    isoAlpha2: 'CC',
    isoAlpha3: 'CCK',
    isoNumeric: 166,
    symbol: '$',
    currency: {
      code: 'AUD',
      name: 'Dollar',
    },
  },
  NZD: {
    id: 50,
    name: 'Cook Islands',
    isoAlpha2: 'CK',
    isoAlpha3: 'COK',
    isoNumeric: 184,
    symbol: '$',
    currency: {
      code: 'NZD',
      name: 'Dollar',
    },
  },
  CRC: {
    id: 51,
    name: 'Costa Rica',
    isoAlpha2: 'CR',
    isoAlpha3: 'CRI',
    isoNumeric: 188,
    symbol: '₡',
    currency: {
      code: 'CRC',
      name: 'Colon',
    },
  },
  HRK: {
    id: 52,
    name: 'Croatia',
    isoAlpha2: 'HR',
    isoAlpha3: 'HRV',
    isoNumeric: 191,
    symbol: 'kn',
    currency: {
      code: 'HRK',
      name: 'Kuna',
    },
  },
  CUP: {
    id: 53,
    name: 'Cuba',
    isoAlpha2: 'CU',
    isoAlpha3: 'CUB',
    isoNumeric: 192,
    symbol: '₱',
    currency: {
      code: 'CUP',
      name: 'Peso',
    },
  },
  CYP: {
    id: 54,
    name: 'Cyprus',
    isoAlpha2: 'CY',
    isoAlpha3: 'CYP',
    isoNumeric: 196,
    symbol: false,
    currency: {
      code: 'CYP',
      name: 'Pound',
    },
  },
  CZK: {
    id: 55,
    name: 'Czech Republic',
    isoAlpha2: 'CZ',
    isoAlpha3: 'CZE',
    isoNumeric: 203,
    symbol: 'Kč',
    currency: {
      code: 'CZK',
      name: 'Koruna',
    },
  },
  CDF: {
    id: 56,
    name: 'Democratic Republic of the Congo',
    isoAlpha2: 'CD',
    isoAlpha3: 'COD',
    isoNumeric: 180,
    symbol: false,
    currency: {
      code: 'CDF',
      name: 'Franc',
    },
  },
  DKK: {
    id: 57,
    name: 'Denmark',
    isoAlpha2: 'DK',
    isoAlpha3: 'DNK',
    isoNumeric: 208,
    symbol: 'kr',
    currency: {
      code: 'DKK',
      name: 'Krone',
    },
  },
  DJF: {
    id: 58,
    name: 'Djibouti',
    isoAlpha2: 'DJ',
    isoAlpha3: 'DJI',
    isoNumeric: 262,
    symbol: false,
    currency: {
      code: 'DJF',
      name: 'Franc',
    },
  },

  DOP: {
    id: 60,
    name: 'Dominican Republic',
    isoAlpha2: 'DO',
    isoAlpha3: 'DOM',
    isoNumeric: 214,
    symbol: 'RD$',
    currency: {
      code: 'DOP',
      name: 'Peso',
    },
  },
  EGP: {
    id: 63,
    name: 'Egypt',
    isoAlpha2: 'EG',
    isoAlpha3: 'EGY',
    isoNumeric: 818,
    symbol: '£',
    currency: {
      code: 'EGP',
      name: 'Pound',
    },
  },
  SVC: {
    id: 64,
    name: 'El Salvador',
    isoAlpha2: 'SV',
    isoAlpha3: 'SLV',
    isoNumeric: 222,
    symbol: '$',
    currency: {
      code: 'SVC',
      name: 'Colone',
    },
  },

  ERN: {
    id: 66,
    name: 'Eritrea',
    isoAlpha2: 'ER',
    isoAlpha3: 'ERI',
    isoNumeric: 232,
    symbol: 'Nfk',
    currency: {
      code: 'ERN',
      name: 'Nakfa',
    },
  },
  EEK: {
    id: 67,
    name: 'Estonia',
    isoAlpha2: 'EE',
    isoAlpha3: 'EST',
    isoNumeric: 233,
    symbol: 'kr',
    currency: {
      code: 'EEK',
      name: 'Kroon',
    },
  },
  ETB: {
    id: 68,
    name: 'Ethiopia',
    isoAlpha2: 'ET',
    isoAlpha3: 'ETH',
    isoNumeric: 231,
    symbol: false,
    currency: {
      code: 'ETB',
      name: 'Birr',
    },
  },
  FKP: {
    id: 69,
    name: 'Falkland Islands',
    isoAlpha2: 'FK',
    isoAlpha3: 'FLK',
    isoNumeric: 238,
    symbol: '£',
    currency: {
      code: 'FKP',
      name: 'Pound',
    },
  },

  FJD: {
    id: 71,
    name: 'Fiji',
    isoAlpha2: 'FJ',
    isoAlpha3: 'FJI',
    isoNumeric: 242,
    symbol: '$',
    currency: {
      code: 'FJD',
      name: 'Dollar',
    },
  },
  XPF: {
    id: 75,
    name: 'French Polynesia',
    isoAlpha2: 'PF',
    isoAlpha3: 'PYF',
    isoNumeric: 258,
    symbol: false,
    currency: {
      code: 'XPF',
      name: 'Franc',
    },
  },

  GMD: {
    id: 78,
    name: 'Gambia',
    isoAlpha2: 'GM',
    isoAlpha3: 'GMB',
    isoNumeric: 270,
    symbol: 'D',
    currency: {
      code: 'GMD',
      name: 'Dalasi',
    },
  },
  GEL: {
    id: 79,
    name: 'Georgia',
    isoAlpha2: 'GE',
    isoAlpha3: 'GEO',
    isoNumeric: 268,
    symbol: false,
    currency: {
      code: 'GEL',
      name: 'Lari',
    },
  },
  GHC: {
    id: 81,
    name: 'Ghana',
    isoAlpha2: 'GH',
    isoAlpha3: 'GHA',
    isoNumeric: 288,
    symbol: '¢',
    currency: {
      code: 'GHC',
      name: 'Cedi',
    },
  },
  GIP: {
    id: 82,
    name: 'Gibraltar',
    isoAlpha2: 'GI',
    isoAlpha3: 'GIB',
    isoNumeric: 292,
    symbol: '£',
    currency: {
      code: 'GIP',
      name: 'Pound',
    },
  },

  GTQ: {
    id: 88,
    name: 'Guatemala',
    isoAlpha2: 'GT',
    isoAlpha3: 'GTM',
    isoNumeric: 320,
    symbol: 'Q',
    currency: {
      code: 'GTQ',
      name: 'Quetzal',
    },
  },
  GNF: {
    id: 89,
    name: 'Guinea',
    isoAlpha2: 'GN',
    isoAlpha3: 'GIN',
    isoNumeric: 324,
    symbol: false,
    currency: {
      code: 'GNF',
      name: 'Franc',
    },
  },
  GYD: {
    id: 91,
    name: 'Guyana',
    isoAlpha2: 'GY',
    isoAlpha3: 'GUY',
    isoNumeric: 328,
    symbol: '$',
    currency: {
      code: 'GYD',
      name: 'Dollar',
    },
  },
  HTG: {
    id: 92,
    name: 'Haiti',
    isoAlpha2: 'HT',
    isoAlpha3: 'HTI',
    isoNumeric: 332,
    symbol: 'G',
    currency: {
      code: 'HTG',
      name: 'Gourde',
    },
  },

  HNL: {
    id: 94,
    name: 'Honduras',
    isoAlpha2: 'HN',
    isoAlpha3: 'HND',
    isoNumeric: 340,
    symbol: 'L',
    currency: {
      code: 'HNL',
      name: 'Lempira',
    },
  },
  HKD: {
    id: 95,
    name: 'Hong Kong',
    isoAlpha2: 'HK',
    isoAlpha3: 'HKG',
    isoNumeric: 344,
    symbol: '$',
    currency: {
      code: 'HKD',
      name: 'Dollar',
    },
  },
  HUF: {
    id: 96,
    name: 'Hungary',
    isoAlpha2: 'HU',
    isoAlpha3: 'HUN',
    isoNumeric: 348,
    symbol: 'Ft',
    currency: {
      code: 'HUF',
      name: 'Forint',
    },
  },
  ISK: {
    id: 97,
    name: 'Iceland',
    isoAlpha2: 'IS',
    isoAlpha3: 'ISL',
    isoNumeric: 352,
    symbol: 'kr',
    currency: {
      code: 'ISK',
      name: 'Krona',
    },
  },
  INR: {
    id: 98,
    name: 'India',
    isoAlpha2: 'IN',
    isoAlpha3: 'IND',
    isoNumeric: 356,
    symbol: '₹',
    currency: {
      code: 'INR',
      name: 'Rupee',
    },
  },
  IDR: {
    id: 99,
    name: 'Indonesia',
    isoAlpha2: 'ID',
    isoAlpha3: 'IDN',
    isoNumeric: 360,
    symbol: 'Rp',
    currency: {
      code: 'IDR',
      name: 'Rupiah',
    },
  },
  IRR: {
    id: 100,
    name: 'Iran',
    isoAlpha2: 'IR',
    isoAlpha3: 'IRN',
    isoNumeric: 364,
    symbol: '﷼',
    currency: {
      code: 'IRR',
      name: 'Rial',
    },
  },
  IQD: {
    id: 101,
    name: 'Iraq',
    isoAlpha2: 'IQ',
    isoAlpha3: 'IRQ',
    isoNumeric: 368,
    symbol: false,
    currency: {
      code: 'IQD',
      name: 'Dinar',
    },
  },
  ILS: {
    id: 103,
    name: 'Israel',
    isoAlpha2: 'IL',
    isoAlpha3: 'ISR',
    isoNumeric: 376,
    symbol: '₪',
    currency: {
      code: 'ILS',
      name: 'Shekel',
    },
  },
  JMD: {
    id: 106,
    name: 'Jamaica',
    isoAlpha2: 'JM',
    isoAlpha3: 'JAM',
    isoNumeric: 388,
    symbol: '$',
    currency: {
      code: 'JMD',
      name: 'Dollar',
    },
  },
  JPY: {
    id: 107,
    name: 'Japan',
    isoAlpha2: 'JP',
    isoAlpha3: 'JPN',
    isoNumeric: 392,
    symbol: '¥',
    currency: {
      code: 'JPY',
      name: 'Yen',
    },
  },
  JOD: {
    id: 108,
    name: 'Jordan',
    isoAlpha2: 'JO',
    isoAlpha3: 'JOR',
    isoNumeric: 400,
    symbol: false,
    currency: {
      code: 'JOD',
      name: 'Dinar',
    },
  },
  KZT: {
    id: 109,
    name: 'Kazakhstan',
    isoAlpha2: 'KZ',
    isoAlpha3: 'KAZ',
    isoNumeric: 398,
    symbol: '₸',
    currency: {
      code: 'KZT',
      name: 'Tenge',
    },
  },
  KES: {
    id: 110,
    name: 'Kenya',
    isoAlpha2: 'KE',
    isoAlpha3: 'KEN',
    isoNumeric: 404,
    symbol: false,
    currency: {
      code: 'KES',
      name: 'Shilling',
    },
  },

  KWD: {
    id: 112,
    name: 'Kuwait',
    isoAlpha2: 'KW',
    isoAlpha3: 'KWT',
    isoNumeric: 414,
    symbol: false,
    currency: {
      code: 'KWD',
      name: 'Dinar',
    },
  },
  KGS: {
    id: 113,
    name: 'Kyrgyzstan',
    isoAlpha2: 'KG',
    isoAlpha3: 'KGZ',
    isoNumeric: 417,
    symbol: 'лв',
    currency: {
      code: 'KGS',
      name: 'Som',
    },
  },
  LAK: {
    id: 114,
    name: 'Laos',
    isoAlpha2: 'LA',
    isoAlpha3: 'LAO',
    isoNumeric: 418,
    symbol: '₭',
    currency: {
      code: 'LAK',
      name: 'Kip',
    },
  },
  LVL: {
    id: 115,
    name: 'Latvia',
    isoAlpha2: 'LV',
    isoAlpha3: 'LVA',
    isoNumeric: 428,
    symbol: 'Ls',
    currency: {
      code: 'LVL',
      name: 'Lat',
    },
  },
  LBP: {
    id: 116,
    name: 'Lebanon',
    isoAlpha2: 'LB',
    isoAlpha3: 'LBN',
    isoNumeric: 422,
    symbol: '£',
    currency: {
      code: 'LBP',
      name: 'Pound',
    },
  },
  LSL: {
    id: 117,
    name: 'Lesotho',
    isoAlpha2: 'LS',
    isoAlpha3: 'LSO',
    isoNumeric: 426,
    symbol: 'L',
    currency: {
      code: 'LSL',
      name: 'Loti',
    },
  },
  LRD: {
    id: 118,
    name: 'Liberia',
    isoAlpha2: 'LR',
    isoAlpha3: 'LBR',
    isoNumeric: 430,
    symbol: '$',
    currency: {
      code: 'LRD',
      name: 'Dollar',
    },
  },
  LYD: {
    id: 119,
    name: 'Libya',
    isoAlpha2: 'LY',
    isoAlpha3: 'LBY',
    isoNumeric: 434,
    symbol: false,
    currency: {
      code: 'LYD',
      name: 'Dinar',
    },
  },
  CHF: {
    id: 120,
    name: 'Liechtenstein',
    isoAlpha2: 'LI',
    isoAlpha3: 'LIE',
    isoNumeric: 438,
    symbol: 'CHF',
    currency: {
      code: 'CHF',
      name: 'Franc',
    },
  },
  LTL: {
    id: 121,
    name: 'Lithuania',
    isoAlpha2: 'LT',
    isoAlpha3: 'LTU',
    isoNumeric: 440,
    symbol: 'Lt',
    currency: {
      code: 'LTL',
      name: 'Litas',
    },
  },
  MOP: {
    id: 123,
    name: 'Macao',
    isoAlpha2: 'MO',
    isoAlpha3: 'MAC',
    isoNumeric: 446,
    symbol: 'MOP',
    currency: {
      code: 'MOP',
      name: 'Pataca',
    },
  },
  MKD: {
    id: 124,
    name: 'Macedonia',
    isoAlpha2: 'MK',
    isoAlpha3: 'MKD',
    isoNumeric: 807,
    symbol: 'ден',
    currency: {
      code: 'MKD',
      name: 'Denar',
    },
  },
  MGA: {
    id: 125,
    name: 'Madagascar',
    isoAlpha2: 'MG',
    isoAlpha3: 'MDG',
    isoNumeric: 450,
    symbol: false,
    currency: {
      code: 'MGA',
      name: 'Ariary',
    },
  },
  MWK: {
    id: 126,
    name: 'Malawi',
    isoAlpha2: 'MW',
    isoAlpha3: 'MWI',
    isoNumeric: 454,
    symbol: 'MK',
    currency: {
      code: 'MWK',
      name: 'Kwacha',
    },
  },
  MYR: {
    id: 127,
    name: 'Malaysia',
    isoAlpha2: 'MY',
    isoAlpha3: 'MYS',
    isoNumeric: 458,
    symbol: 'RM',
    currency: {
      code: 'MYR',
      name: 'Ringgit',
    },
  },
  MVR: {
    id: 128,
    name: 'Maldives',
    isoAlpha2: 'MV',
    isoAlpha3: 'MDV',
    isoNumeric: 462,
    symbol: 'Rf',
    currency: {
      code: 'MVR',
      name: 'Rufiyaa',
    },
  },
  MTL: {
    id: 130,
    name: 'Malta',
    isoAlpha2: 'MT',
    isoAlpha3: 'MLT',
    isoNumeric: 470,
    symbol: false,
    currency: {
      code: 'MTL',
      name: 'Lira',
    },
  },
  MRO: {
    id: 133,
    name: 'Mauritania',
    isoAlpha2: 'MR',
    isoAlpha3: 'MRT',
    isoNumeric: 478,
    symbol: 'UM',
    currency: {
      code: 'MRO',
      name: 'Ouguiya',
    },
  },
  MUR: {
    id: 134,
    name: 'Mauritius',
    isoAlpha2: 'MU',
    isoAlpha3: 'MUS',
    isoNumeric: 480,
    symbol: '₨',
    currency: {
      code: 'MUR',
      name: 'Rupee',
    },
  },
  MXN: {
    id: 136,
    name: 'Mexico',
    isoAlpha2: 'MX',
    isoAlpha3: 'MEX',
    isoNumeric: 484,
    symbol: '$',
    currency: {
      code: 'MXN',
      name: 'Peso',
    },
  },
  MDL: {
    id: 138,
    name: 'Moldova',
    isoAlpha2: 'MD',
    isoAlpha3: 'MDA',
    isoNumeric: 498,
    symbol: false,
    currency: {
      code: 'MDL',
      name: 'Leu',
    },
  },
  MNT: {
    id: 140,
    name: 'Mongolia',
    isoAlpha2: 'MN',
    isoAlpha3: 'MNG',
    isoNumeric: 496,
    symbol: '₮',
    currency: {
      code: 'MNT',
      name: 'Tugrik',
    },
  },

  MAD: {
    id: 142,
    name: 'Morocco',
    isoAlpha2: 'MA',
    isoAlpha3: 'MAR',
    isoNumeric: 504,
    symbol: false,
    currency: {
      code: 'MAD',
      name: 'Dirham',
    },
  },
  MZN: {
    id: 143,
    name: 'Mozambique',
    isoAlpha2: 'MZ',
    isoAlpha3: 'MOZ',
    isoNumeric: 508,
    symbol: 'MT',
    currency: {
      code: 'MZN',
      name: 'Meticail',
    },
  },
  MMK: {
    id: 144,
    name: 'Myanmar',
    isoAlpha2: 'MM',
    isoAlpha3: 'MMR',
    isoNumeric: 104,
    symbol: 'K',
    currency: {
      code: 'MMK',
      name: 'Kyat',
    },
  },
  NAD: {
    id: 145,
    name: 'Namibia',
    isoAlpha2: 'NA',
    isoAlpha3: 'NAM',
    isoNumeric: 516,
    symbol: '$',
    currency: {
      code: 'NAD',
      name: 'Dollar',
    },
  },

  NPR: {
    id: 147,
    name: 'Nepal',
    isoAlpha2: 'NP',
    isoAlpha3: 'NPL',
    isoNumeric: 524,
    symbol: '₨',
    currency: {
      code: 'NPR',
      name: 'Rupee',
    },
  },
  ANG: {
    id: 149,
    name: 'Netherlands Antilles',
    isoAlpha2: 'AN',
    isoAlpha3: 'ANT',
    isoNumeric: 530,
    symbol: 'ƒ',
    currency: {
      code: 'ANG',
      name: 'Guilder',
    },
  },

  NIO: {
    id: 152,
    name: 'Nicaragua',
    isoAlpha2: 'NI',
    isoAlpha3: 'NIC',
    isoNumeric: 558,
    symbol: 'C$',
    currency: {
      code: 'NIO',
      name: 'Cordoba',
    },
  },
  NGN: {
    id: 154,
    name: 'Nigeria',
    isoAlpha2: 'NG',
    isoAlpha3: 'NGA',
    isoNumeric: 566,
    symbol: '₦',
    currency: {
      code: 'NGN',
      name: 'Naira',
    },
  },

  KPW: {
    id: 157,
    name: 'North Korea',
    isoAlpha2: 'KP',
    isoAlpha3: 'PRK',
    isoNumeric: 408,
    symbol: '₩',
    currency: {
      code: 'KPW',
      name: 'Won',
    },
  },

  OMR: {
    id: 160,
    name: 'Oman',
    isoAlpha2: 'OM',
    isoAlpha3: 'OMN',
    isoNumeric: 512,
    symbol: '﷼',
    currency: {
      code: 'OMR',
      name: 'Rial',
    },
  },
  PKR: {
    id: 161,
    name: 'Pakistan',
    isoAlpha2: 'PK',
    isoAlpha3: 'PAK',
    isoNumeric: 586,
    symbol: '₨',
    currency: {
      code: 'PKR',
      name: 'Rupee',
    },
  },

  PAB: {
    id: 164,
    name: 'Panama',
    isoAlpha2: 'PA',
    isoAlpha3: 'PAN',
    isoNumeric: 591,
    symbol: 'B/.',
    currency: {
      code: 'PAB',
      name: 'Balboa',
    },
  },
  PGK: {
    id: 165,
    name: 'Papua New Guinea',
    isoAlpha2: 'PG',
    isoAlpha3: 'PNG',
    isoNumeric: 598,
    symbol: false,
    currency: {
      code: 'PGK',
      name: 'Kina',
    },
  },
  PYG: {
    id: 166,
    name: 'Paraguay',
    isoAlpha2: 'PY',
    isoAlpha3: 'PRY',
    isoNumeric: 600,
    symbol: 'Gs',
    currency: {
      code: 'PYG',
      name: 'Guarani',
    },
  },
  PEN: {
    id: 167,
    name: 'Peru',
    isoAlpha2: 'PE',
    isoAlpha3: 'PER',
    isoNumeric: 604,
    symbol: 'S/.',
    currency: {
      code: 'PEN',
      name: 'Sol',
    },
  },
  PHP: {
    id: 168,
    name: 'Philippines',
    isoAlpha2: 'PH',
    isoAlpha3: 'PHL',
    isoNumeric: 608,
    symbol: 'Php',
    currency: {
      code: 'PHP',
      name: 'Peso',
    },
  },

  PLN: {
    id: 170,
    name: 'Poland',
    isoAlpha2: 'PL',
    isoAlpha3: 'POL',
    isoNumeric: 616,
    symbol: 'zł',
    currency: {
      code: 'PLN',
      name: 'Zloty',
    },
  },
  QAR: {
    id: 173,
    name: 'Qatar',
    isoAlpha2: 'QA',
    isoAlpha3: 'QAT',
    isoNumeric: 634,
    symbol: '﷼',
    currency: {
      code: 'QAR',
      name: 'Rial',
    },
  },

  RON: {
    id: 176,
    name: 'Romania',
    isoAlpha2: 'RO',
    isoAlpha3: 'ROU',
    isoNumeric: 642,
    symbol: 'lei',
    currency: {
      code: 'RON',
      name: 'Leu',
    },
  },
  RUB: {
    id: 177,
    name: 'Russia',
    isoAlpha2: 'RU',
    isoAlpha3: 'RUS',
    isoNumeric: 643,
    symbol: 'руб',
    currency: {
      code: 'RUB',
      name: 'Ruble',
    },
  },
  RWF: {
    id: 178,
    name: 'Rwanda',
    isoAlpha2: 'RW',
    isoAlpha3: 'RWA',
    isoNumeric: 646,
    symbol: false,
    currency: {
      code: 'RWF',
      name: 'Franc',
    },
  },
  SHP: {
    id: 179,
    name: 'Saint Helena',
    isoAlpha2: 'SH',
    isoAlpha3: 'SHN',
    isoNumeric: 654,
    symbol: '£',
    currency: {
      code: 'SHP',
      name: 'Pound',
    },
  },

  WST: {
    id: 184,
    name: 'Samoa',
    isoAlpha2: 'WS',
    isoAlpha3: 'WSM',
    isoNumeric: 882,
    symbol: 'WS$',
    currency: {
      code: 'WST',
      name: 'Tala',
    },
  },
  STD: {
    id: 186,
    name: 'Sao Tome and Principe',
    isoAlpha2: 'ST',
    isoAlpha3: 'STP',
    isoNumeric: 678,
    symbol: 'Db',
    currency: {
      code: 'STD',
      name: 'Dobra',
    },
  },
  SAR: {
    id: 187,
    name: 'Saudi Arabia',
    isoAlpha2: 'SA',
    isoAlpha3: 'SAU',
    isoNumeric: 682,
    symbol: '﷼',
    currency: {
      code: 'SAR',
      name: 'Rial',
    },
  },
  RSD: {
    id: 189,
    name: 'Serbia and Montenegro',
    isoAlpha2: 'CS',
    isoAlpha3: 'SCG',
    isoNumeric: 891,
    symbol: 'Дин',
    currency: {
      code: 'RSD',
      name: 'Dinar',
    },
  },
  SCR: {
    id: 190,
    name: 'Seychelles',
    isoAlpha2: 'SC',
    isoAlpha3: 'SYC',
    isoNumeric: 690,
    symbol: '₨',
    currency: {
      code: 'SCR',
      name: 'Rupee',
    },
  },
  SLL: {
    id: 191,
    name: 'Sierra Leone',
    isoAlpha2: 'SL',
    isoAlpha3: 'SLE',
    isoNumeric: 694,
    symbol: 'Le',
    currency: {
      code: 'SLL',
      name: 'Leone',
    },
  },
  SGD: {
    id: 192,
    name: 'Singapore',
    isoAlpha2: 'SG',
    isoAlpha3: 'SGP',
    isoNumeric: 702,
    symbol: '$',
    currency: {
      code: 'SGD',
      name: 'Dollar',
    },
  },
  SKK: {
    id: 193,
    name: 'Slovakia',
    isoAlpha2: 'SK',
    isoAlpha3: 'SVK',
    isoNumeric: 703,
    symbol: 'Sk',
    currency: {
      code: 'SKK',
      name: 'Koruna',
    },
  },
  SBD: {
    id: 195,
    name: 'Solomon Islands',
    isoAlpha2: 'SB',
    isoAlpha3: 'SLB',
    isoNumeric: 90,
    symbol: '$',
    currency: {
      code: 'SBD',
      name: 'Dollar',
    },
  },
  SOS: {
    id: 196,
    name: 'Somalia',
    isoAlpha2: 'SO',
    isoAlpha3: 'SOM',
    isoNumeric: 706,
    symbol: 'S',
    currency: {
      code: 'SOS',
      name: 'Shilling',
    },
  },
  ZAR: {
    id: 197,
    name: 'South Africa',
    isoAlpha2: 'ZA',
    isoAlpha3: 'ZAF',
    isoNumeric: 710,
    symbol: 'R',
    currency: {
      code: 'ZAR',
      name: 'Rand',
    },
  },
  GBP: {
    id: 198,
    name: 'South Georgia and the South Sandwich Islands',
    isoAlpha2: 'GS',
    isoAlpha3: 'SGS',
    isoNumeric: 239,
    symbol: '£',
    currency: {
      code: 'GBP',
      name: 'Pound',
    },
  },
  KRW: {
    id: 199,
    name: 'South Korea',
    isoAlpha2: 'KR',
    isoAlpha3: 'KOR',
    isoNumeric: 410,
    symbol: '₩',
    currency: {
      code: 'KRW',
      name: 'Won',
    },
  },
  LKR: {
    id: 201,
    name: 'Sri Lanka',
    isoAlpha2: 'LK',
    isoAlpha3: 'LKA',
    isoNumeric: 144,
    symbol: '₨',
    currency: {
      code: 'LKR',
      name: 'Rupee',
    },
  },
  SDD: {
    id: 202,
    name: 'Sudan',
    isoAlpha2: 'SD',
    isoAlpha3: 'SDN',
    isoNumeric: 736,
    symbol: false,
    currency: {
      code: 'SDD',
      name: 'Dinar',
    },
  },
  SRD: {
    id: 203,
    name: 'Suriname',
    isoAlpha2: 'SR',
    isoAlpha3: 'SUR',
    isoNumeric: 740,
    symbol: '$',
    currency: {
      code: 'SRD',
      name: 'Dollar',
    },
  },
  SZL: {
    id: 205,
    name: 'Swaziland',
    isoAlpha2: 'SZ',
    isoAlpha3: 'SWZ',
    isoNumeric: 748,
    symbol: false,
    currency: {
      code: 'SZL',
      name: 'Lilangeni',
    },
  },
  SEK: {
    id: 206,
    name: 'Sweden',
    isoAlpha2: 'SE',
    isoAlpha3: 'SWE',
    isoNumeric: 752,
    symbol: 'kr',
    currency: {
      code: 'SEK',
      name: 'Krona',
    },
  },

  SYP: {
    id: 208,
    name: 'Syria',
    isoAlpha2: 'SY',
    isoAlpha3: 'SYR',
    isoNumeric: 760,
    symbol: '£',
    currency: {
      code: 'SYP',
      name: 'Pound',
    },
  },
  TWD: {
    id: 209,
    name: 'Taiwan',
    isoAlpha2: 'TW',
    isoAlpha3: 'TWN',
    isoNumeric: 158,
    symbol: 'NT$',
    currency: {
      code: 'TWD',
      name: 'Dollar',
    },
  },
  TJS: {
    id: 210,
    name: 'Tajikistan',
    isoAlpha2: 'TJ',
    isoAlpha3: 'TJK',
    isoNumeric: 762,
    symbol: false,
    currency: {
      code: 'TJS',
      name: 'Somoni',
    },
  },
  TZS: {
    id: 211,
    name: 'Tanzania',
    isoAlpha2: 'TZ',
    isoAlpha3: 'TZA',
    isoNumeric: 834,
    symbol: false,
    currency: {
      code: 'TZS',
      name: 'Shilling',
    },
  },
  THB: {
    id: 212,
    name: 'Thailand',
    isoAlpha2: 'TH',
    isoAlpha3: 'THA',
    isoNumeric: 764,
    symbol: '฿',
    currency: {
      code: 'THB',
      name: 'Baht',
    },
  },

  TOP: {
    id: 215,
    name: 'Tonga',
    isoAlpha2: 'TO',
    isoAlpha3: 'TON',
    isoNumeric: 776,
    symbol: 'T$',
    currency: {
      code: 'TOP',
      name: 'Paanga',
    },
  },
  TTD: {
    id: 216,
    name: 'Trinidad and Tobago',
    isoAlpha2: 'TT',
    isoAlpha3: 'TTO',
    isoNumeric: 780,
    symbol: 'TT$',
    currency: {
      code: 'TTD',
      name: 'Dollar',
    },
  },
  TND: {
    id: 217,
    name: 'Tunisia',
    isoAlpha2: 'TN',
    isoAlpha3: 'TUN',
    isoNumeric: 788,
    symbol: false,
    currency: {
      code: 'TND',
      name: 'Dinar',
    },
  },
  TRY: {
    id: 218,
    name: 'Turkey',
    isoAlpha2: 'TR',
    isoAlpha3: 'TUR',
    isoNumeric: 792,
    symbol: 'YTL',
    currency: {
      code: 'TRY',
      name: 'Lira',
    },
  },
  TMM: {
    id: 219,
    name: 'Turkmenistan',
    isoAlpha2: 'TM',
    isoAlpha3: 'TKM',
    isoNumeric: 795,
    symbol: 'm',
    currency: {
      code: 'TMM',
      name: 'Manat',
    },
  },

  UGX: {
    id: 223,
    name: 'Uganda',
    isoAlpha2: 'UG',
    isoAlpha3: 'UGA',
    isoNumeric: 800,
    symbol: false,
    currency: {
      code: 'UGX',
      name: 'Shilling',
    },
  },
  UAH: {
    id: 224,
    name: 'Ukraine',
    isoAlpha2: 'UA',
    isoAlpha3: 'UKR',
    isoNumeric: 804,
    symbol: '₴',
    currency: {
      code: 'UAH',
      name: 'Hryvnia',
    },
  },
  AED: {
    id: 225,
    name: 'United Arab Emirates',
    isoAlpha2: 'AE',
    isoAlpha3: 'ARE',
    isoNumeric: 784,
    symbol: false,
    currency: {
      code: 'AED',
      name: 'Dirham',
    },
  },

  UYU: {
    id: 229,
    name: 'Uruguay',
    isoAlpha2: 'UY',
    isoAlpha3: 'URY',
    isoNumeric: 858,
    symbol: '$U',
    currency: {
      code: 'UYU',
      name: 'Peso',
    },
  },
  UZS: {
    id: 230,
    name: 'Uzbekistan',
    isoAlpha2: 'UZ',
    isoAlpha3: 'UZB',
    isoNumeric: 860,
    symbol: 'лв',
    currency: {
      code: 'UZS',
      name: 'Som',
    },
  },
  VUV: {
    id: 231,
    name: 'Vanuatu',
    isoAlpha2: 'VU',
    isoAlpha3: 'VUT',
    isoNumeric: 548,
    symbol: 'Vt',
    currency: {
      code: 'VUV',
      name: 'Vatu',
    },
  },
  VEF: {
    id: 233,
    name: 'Venezuela',
    isoAlpha2: 'VE',
    isoAlpha3: 'VEN',
    isoNumeric: 862,
    symbol: 'Bs',
    currency: {
      code: 'VEF',
      name: 'Bolivar',
    },
  },
  VND: {
    id: 234,
    name: 'Vietnam',
    isoAlpha2: 'VN',
    isoAlpha3: 'VNM',
    isoNumeric: 704,
    symbol: '₫',
    currency: {
      code: 'VND',
      name: 'Dong',
    },
  },

  YER: {
    id: 237,
    name: 'Yemen',
    isoAlpha2: 'YE',
    isoAlpha3: 'YEM',
    isoNumeric: 887,
    symbol: '﷼',
    currency: {
      code: 'YER',
      name: 'Rial',
    },
  },
  ZMK: {
    id: 238,
    name: 'Zambia',
    isoAlpha2: 'ZM',
    isoAlpha3: 'ZMB',
    isoNumeric: 894,
    symbol: 'ZK',
    currency: {
      code: 'ZMK',
      name: 'Kwacha',
    },
  },
  ZWD: {
    id: 239,
    name: 'Zimbabwe',
    isoAlpha2: 'ZW',
    isoAlpha3: 'ZWE',
    isoNumeric: 716,
    symbol: 'Z$',
    currency: {
      code: 'ZWD',
      name: 'Dollar',
    },
  },
  XOF: {
    id: 35,
    name: 'Burkina Faso',
    isoAlpha2: 'BF',
    isoAlpha3: 'BFA',
    isoNumeric: 854,
    symbol: false,
    currency: {
      code: 'XOF',
      name: 'Franc',
    },
  },
};
