import {Localization} from 'locales/helper';

const ptBR: Localization = {
  payment_failed_desc: 'We were unable to process your payment',
  payment_failed_title: 'Payment Failed',
  payment_success_desc: 'Pagamento realizado com sucesso',
  payment_success_title: 'Pagou!',
};

export default ptBR;
