import {makeStyles} from '@material-ui/core';

import {
  Modal as MuiModal,
  ModalProps as MuiModalProps,
  Box,
} from '@material-ui/core';

export type ModalProps = MuiModalProps & {
  handleClose: () => void;
};

const Modal: React.FC<ModalProps> = ({
  className,
  handleClose,
  children,
  open,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <MuiModal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      {...rest}
    >
      <Box className={classes.box}>{children}</Box>
    </MuiModal>
  );
};

const useStyles = makeStyles(theme => ({
  root: {},
  loader: {},
  box: {
    minWidth: 500,
    borderRadius: 5,
    padding: 12,
    backgroundColor: '#fff',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: 'none',
  },
}));

export default Modal;
