export const containDigits = (string: string): boolean =>
  !/^([^0-9]*)$/.test(string);

export const removeWhiteSpaces = (string: string): string =>
  string.replace(/\s/g, '');

export const capitalizeString = (string: string): string => {
  if (!string) return '';

  const [firstLetter, ...letters] = string.toString().split('');
  const capitalizedString = `${firstLetter.toUpperCase()}${letters
    .join('')
    .toLowerCase()}`;
  return capitalizedString;
};
