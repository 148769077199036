import axios from 'lib/axios';

import type {CountryResponseMock} from 'types';

class CountryService {
  async list() {
    const response = await axios.get<CountryResponseMock>('/cc/country');
    return response.data;
  }
}

export const countryService = new CountryService();
