import * as React from 'react';
import {makeStyles, darken} from '@material-ui/core';
import {useHistory, useLocation} from 'react-router-dom';
import {useForm, Controller} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {toast} from 'react-hot-toast';

import logo from 'assets/logo.png';

import type {AsyncStatus} from 'types';
import useToggle from 'hooks/useToggle';
import {NO_SPACE_REGEX} from 'utils/regex';
import {
  INVALID_PASSWORD,
  PASSWORD_NOT_MATCH,
  RESET_PASSWORD_SUCCESS,
} from 'utils/messages';

import {
  Typography,
  TextField,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import ReplayIcon from '@material-ui/icons/Replay';
import Visibility from '@material-ui/icons/VisibilityOutlined';
import VisibilityOff from '@material-ui/icons/VisibilityOffOutlined';

import Button from 'components/Button';
import {useAuth} from 'hooks/useAuth';
import {getAuthErrorMEssage} from 'utils/errors';

type ResetPasswordInputs = {
  password: string;
  confirmPassword: string;
};

type ResetError = null | string;

const schema = yup.object().shape({
  password: yup
    .string()
    .required('New Password is a required field')
    .min(8, INVALID_PASSWORD)
    .matches(NO_SPACE_REGEX, INVALID_PASSWORD),
  confirmPassword: yup
    .string()
    .required('Confirm Password is a required field')
    .oneOf([yup.ref('password')], PASSWORD_NOT_MATCH),
});

const ResetPassword: React.FC = () => {
  const history = useHistory();

  const {resetPassword} = useAuth();

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const resetRequestEmail = query.get('email') ?? '';
  const resetRequestCode = query.get('code') ?? '';

  const isResetPasswordRequestValid = Boolean(
    resetRequestEmail && resetRequestCode
  );

  if (!isResetPasswordRequestValid) {
    history.push('/forgot-password');
  }

  const [resetError, setResetError] = React.useState<ResetError>(null);
  const [status, setStatus] = React.useState<AsyncStatus>('IDLE');
  const classes = useStyles({status});

  const [passwordVisible, togglePasswordVisibility] = useToggle(false);
  const [confirmPasswordVisible, toggleConfirmPasswordVisibility] = useToggle(
    false
  );

  const [hovering, setHovering] = React.useState(false);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    control,
    handleSubmit,
    formState: {dirtyFields, submitCount},
    setError,
    clearErrors,
    watch,
  } = useForm<ResetPasswordInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  });

  const fieldChange = React.useCallback(
    (value: any) => {
      if (value.password !== value.confirmPassword && submitCount > 0) {
        setError('confirmPassword', {
          type: 'onChange',
          message: PASSWORD_NOT_MATCH,
        });
      } else {
        clearErrors('confirmPassword');
      }
    },
    [submitCount, setError, clearErrors]
  );

  // Listen to input field changes
  React.useEffect(() => {
    const subscription = watch(value => fieldChange(value));
    return () => subscription.unsubscribe();
  }, [watch, fieldChange]);

  const handleNavigateBack = () => {
    history.push('/');
  };

  const onSubmit = async (data: ResetPasswordInputs) => {
    if (!isResetPasswordRequestValid) {
      return;
    }

    try {
      setStatus('LOADING');
      setResetError(null);

      await resetPassword(resetRequestEmail, resetRequestCode, data.password);

      history.push('/');
      toast.success(RESET_PASSWORD_SUCCESS);
      setStatus('SUCCESS');
    } catch (error) {
      setStatus('FAILURE');
      setResetError(getAuthErrorMEssage(error));
    }
  };

  const getButtonIcon = () => {
    switch (status) {
      case 'FAILURE':
        if (hovering) {
          return <ReplayIcon color="inherit" />;
        }

        return <CloseIcon color="inherit" />;
      case 'SUCCESS':
        return <CheckIcon color="inherit" />;
      default:
        return undefined;
    }
  };
  const getButtonText = () => {
    switch (status) {
      case 'IDLE':
        return 'Set New Password';
      case 'LOADING':
        return 'Resetting password';
      case 'FAILURE':
        if (hovering) {
          return 'Retry';
        }

        return 'An error happened while resetting password';
      case 'SUCCESS':
        return 'Successful reset';
      default:
        return undefined;
    }
  };

  return (
    <div className={classes.root}>
      <Button
        variant="text"
        disableRipple
        disableTouchRipple
        className={classes.navigateBackButton}
        startIcon={<KeyboardBackspaceIcon color="inherit" />}
        onClick={handleNavigateBack}
      >
        {'Back to Login'}
      </Button>

      <div className={classes.form}>
        <img src={logo} alt="Stamp Free" className={classes.logo} />
        <Typography className={classes.loginTitle}>{'New Password'}</Typography>
        <Typography className={classes.loginError}>{resetError}</Typography>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={classes.formElement}>
            <Typography className={classes.formLabel}>
              {'New Password'}
            </Typography>
            <Controller
              name="password"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <TextField
                  variant="outlined"
                  type={passwordVisible ? 'text' : 'password'}
                  placeholder="*******"
                  fullWidth
                  margin="dense"
                  autoFocus
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error?.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={togglePasswordVisibility}
                          size="small"
                        >
                          {!passwordVisible ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </div>
          <div className={classes.formElement}>
            <Typography className={classes.formLabel}>
              {'Confirm Password'}
            </Typography>
            <Controller
              name="confirmPassword"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <TextField
                  variant="outlined"
                  type={confirmPasswordVisible ? 'text' : 'password'}
                  placeholder="*******"
                  fullWidth
                  margin="dense"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error?.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={toggleConfirmPasswordVisibility}
                          size="small"
                        >
                          {!confirmPasswordVisible ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </div>

          {/* {!isResetPasswordRequestValid && (
            <Alert severity="warning" className={classes.invalidRequestAlert}>
              {
                'It seems that your reset password link has been expired or is no longer valid.'
              }
            </Alert>
          )} */}

          <Button
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            disabled={
              !(dirtyFields.password && dirtyFields.confirmPassword) ||
              status === 'LOADING' ||
              status === 'SUCCESS' ||
              !isResetPasswordRequestValid
            }
            isLoading={status === 'LOADING'}
            endIcon={getButtonIcon()}
            className={classes.submitButton}
            onMouseOver={() => setHovering(true)}
            onMouseLeave={() => {
              setHovering(false);
            }}
          >
            {getButtonText()}
          </Button>
        </form>
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  navigateBackButton: {
    position: 'absolute',
    left: 50,
    top: 50,
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.grey[600],
    fontSize: 16,
    lineHeight: '28px',
    fontWeight: theme.typography.fontWeightRegular,
    [theme.breakpoints.down('sm')]: {
      left: 20,
      top: 20,
    },
  },
  form: {
    width: 350,
    [theme.breakpoints.down('sm')]: {
      width: '70%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '90%',
    },
  },
  logo: {
    width: 200,
    height: 'auto',
    marginBottom: 32,
  },
  loginTitle: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 21,
    lineHeight: '32px',
    marginBottom: 32,
  },
  loginError: ({status}: {status: AsyncStatus}) => ({
    ...(status === 'FAILURE'
      ? null
      : {
          display: 'none',
        }),
    ...{
      fontSize: 16,
      color: theme.palette.error.main,
      lineHeight: '28px',
      marginBottom: 32,
    },
  }),
  formElement: {
    marginBottom: 32,
  },
  formLabel: {
    fontSize: 16,
    lineHeight: '28px',
  },
  invalidRequestAlert: {
    marginBottom: 16,
  },
  submitButton: ({status}: {status: AsyncStatus}) => ({
    ...(status === 'SUCCESS'
      ? {
          '&:disabled': {
            backgroundColor: theme.palette.success.main,
            color: theme.palette.common.white,
          },
        }
      : null),
    ...(status === 'FAILURE'
      ? {
          backgroundColor: theme.palette.error.main,
          color: theme.palette.common.white,
          '&:hover': {
            backgroundColor: darken(theme.palette.error.main, 0.1),
            color: theme.palette.common.white,
          },
        }
      : null),
  }),
}));

export default ResetPassword;
