import {ThemeProvider, CssBaseline} from '@material-ui/core';
import {HelmetProvider, Helmet} from 'react-helmet-async';
import {BrowserRouter} from 'react-router-dom';
import {QueryClientProvider} from 'react-query';
import {Toaster} from 'react-hot-toast';
import {LocalizationProvider as MuiPickersProvider} from '@material-ui/pickers';
import DateFnsUtils from '@material-ui/pickers/adapter/date-fns';

import {APP_TITLE} from 'config/app.config';
import theme from 'theme';
import {queryClient} from 'lib/react-query';
import {AuthProvider} from 'hooks/useAuth';
import {ConfigDataProvider} from 'hooks/useConfigData';

import ErrorBoundary from 'components/utils/ErrorBoundary';

import Routes from 'Routes';
import {LocalizationProvider} from 'hooks/useLocalization';

const App: React.FC = () => {
  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <LocalizationProvider>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Toaster position="bottom-right" reverseOrder={false} />

            <MuiPickersProvider dateAdapter={DateFnsUtils}>
              <HelmetProvider>
                <Helmet
                  titleTemplate={`%s | ${APP_TITLE}`}
                  defaultTitle={APP_TITLE}
                />

                <AuthProvider>
                  <ConfigDataProvider>
                    <BrowserRouter>
                      <Routes />
                    </BrowserRouter>
                  </ConfigDataProvider>
                </AuthProvider>
              </HelmetProvider>
            </MuiPickersProvider>
          </ThemeProvider>
        </LocalizationProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  );
};

export default App;
