import React from 'react';
import ReactDOM from 'react-dom';
import Amplify from 'aws-amplify';
import 'react-perfect-scrollbar/dist/css/styles.css';

import {AWS_AMPLIFY_CONFIG} from 'config/aws/amplify.config';

import reportWebVitals from 'reportWebVitals';
import {makeServer} from 'server';

import App from 'App';

if (process.env.REACT_APP_MIRAGEJS_ENABLE === 'true') {
  makeServer({environment: 'development'});
}
Amplify.configure(AWS_AMPLIFY_CONFIG);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
