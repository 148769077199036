export const parcelStatusOptions = [
  {
    key: 'COLLECTED',
    value: 'collected',
    text: 'Collected',
  },
  {
    key: 'VALIDATED',
    value: 'validated',
    text: 'Validated',
  },
  {
    key: 'SORT',
    value: 'sort',
    text: 'Sort',
  },
  {
    key: 'AT LOCAL DEPOT',
    value: 'atLocalDepot',
    text: 'At local depot',
  },
  {
    key: 'OUT FOR DELIVERY',
    value: 'outForDelivery',
    text: 'Out for delivery',
  },
  {
    key: 'UNDELIVERED / READY FOR COLLECTION',
    value: 'undeliverd/readyForCollection',
    text: 'Undelivered / Ready for collection',
  },
  {
    key: 'DELIVERED',
    value: 'delivered',
    text: 'Delivered',
  },
];

export const getS3ObjectId = (s3ObjectId: string): string =>
  s3ObjectId.startsWith('public/') ? s3ObjectId.substring(7) : s3ObjectId;

const DIFF_DOUBLED_DIGITS_SUM = Object.freeze([
  0,
  1,
  2,
  3,
  4,
  -4,
  -3,
  -2,
  -1,
  0,
]);

export const computeChecksum = (codeStart: any): number => {
  // Retrieve the list of digits from the digicode start
  const digits = [...codeStart].map(digitStr => parseInt(digitStr, 10));
  const reversedDigits = [...digits].reverse();

  // Sum all digits from the right-hand side
  const sum = reversedDigits.reduce((sum, digit, index): number => {
    // Every two numbers, multiply the digit per two and sum its digits if >= 10
    const value =
      index % 2 === 0 ? digit + DIFF_DOUBLED_DIGITS_SUM[digit] : digit;

    return sum + value;
  }, 0);

  // Substract the digit of the sum from 10, and make it 0 if result is 10
  const checksum = (10 - (sum % 10)) % 10;
  return checksum;
};

export const validateSfCode = (code: string): boolean => {
  const regeX = /^#[A-Z]{3}\d{5}$/;

  if (typeof code !== 'string' || code.length !== 9) {
    return false;
  }

  if (!regeX.test(code)) {
    return false;
  }
  return true;
};

export const formatDate = (serverDate: string) => {
  const newDate = serverDate.split('T')[0];
  return newDate.split('-').reverse().join('/');
};
