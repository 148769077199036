import * as React from 'react';
import {makeStyles, darken} from '@material-ui/core';
import {useForm, Controller} from 'react-hook-form';
import {useQuery} from 'react-query';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {toast} from 'react-hot-toast';
import {useHistory, Link, useParams} from 'react-router-dom';
import {
  Typography,
  Grid,
  TextField,
  Select,
  MenuItem,
  Breadcrumbs,
} from '@material-ui/core';
import clsx from 'clsx';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {requiredFieldMessage} from 'utils/messages';
import {invalidFieldMessage} from 'utils/messages';
import {ALPHABET_SPACE_NUM, NAME_REGEX} from 'utils/regex';
import Button from 'components/Button';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import {capitalizeString, containDigits} from 'utils/String';
import {carrierActivityService} from 'services/carrierActivity';

type CarrierActivityDetails = {
  firstName: string;
  lastName: string;
  email: string;
  country: string;
  postcode: string;
  city: string;
  lastUpdatedOn: Date | null;
  lastUpdatedBy: string;
  carrierUserId: string;
  status: string;
  lastLogin: Date | null;
};

const countryOptions = [
  {
    key: 'France',
    value: 'France',
    text: 'France',
  },
  {
    key: 'Germany',
    value: 'Germany',
    text: 'Germany',
  },
  {
    key: 'United Kingdom',
    value: 'United Kingdom',
    text: 'United Kingdom',
  },
];

const schema = yup.object().shape({
  firstName: yup
    .string()
    .trim()
    .required(requiredFieldMessage('First Name'))
    .matches(NAME_REGEX, invalidFieldMessage('First Name')),
  lastName: yup.string().test({
    name: 'lastNameValidation',
    test: function (lastName?: string) {
      if (!lastName) return true;
      if (!NAME_REGEX.test(lastName)) {
        return this.createError({
          message: invalidFieldMessage('Last Name'),
        });
      }
      return true;
    },
  }),
});

const CarrierActivity: React.FC = () => {
  const history = useHistory();
  const classes = useStyles();
  const {id} = useParams<{id: string}>();

  const [carrierName, setCarrierName] = React.useState('');
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
  } = useForm<CarrierActivityDetails>({
    resolver: yupResolver(schema),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      country: '',
      postcode: '',
      city: '',
      lastUpdatedOn: null,
      lastUpdatedBy: '',
      carrierUserId: '',
      status: '',
      lastLogin: '',
    },
  });

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {data, refetch} = useQuery(
    ['carrierActivity'],
    () => {
      return carrierActivityService.get(id);
    },
    {
      retry: false,
    }
  );

  React.useEffect(() => {
    setData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleCancel = (event: React.MouseEvent) => {
    history.push('/caReport');
  };

  const setData = () => {
    if (!data) return;
    const user = data.user;
    setCarrierName(
      `${capitalizeString(user.firstName)} ${
        user.lastName ? capitalizeString(user.lastName) : ''
      }` || ''
    );
    setValue('status', user.status);
    setValue('firstName', user.firstName);
    setValue('lastName', user?.lastName || '');
    setValue('email', user.email);
    setValue('city', data.location ? data.location.city : '');
    setValue('country', data.location ? data.location.country : '');
    setValue('postcode', data.location ? data.location.postcode : '');
    setValue('lastUpdatedOn', new Date(data.updatedAt));
    setValue(
      'lastUpdatedBy',
      `${data.updatedBy.firstName || ''} ${data.updatedBy.lastName || ''} (${
        data.updatedBy.id
      })`
    );
    setValue('carrierUserId', user.id);
    setValue('lastLogin', data.createdAt);
  };

  const updateCarrierActivity = async () => {
    try {
      await carrierActivityService.update({
        id: id,
        firstName: getValues('firstName').trim(),
        lastName: getValues('lastName').trim(),
        city: getValues('city').trim(),
        country: getValues('country').trim(),
        postcode: getValues('postcode').trim(),
      });
      toast.success('Carrier User details updated successfully', {
        duration: 3000,
      });
      refetch({
        throwOnError: true,
        cancelRefetch: true,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = async () => {
    updateCarrierActivity();
  };

  return (
    <div>
      <Typography component={'h1'} className={classes.title}>
        {carrierName}
      </Typography>
      <Breadcrumbs className={classes.breadcrumb} aria-label="breadcrumb">
        <Typography className={classes.breadcrumbText}>Reports</Typography>
        <Link className={classes.breadcrumbLink} to="/caReport">
          {carrierName}
        </Link>
        <Typography className={classes.breadcrumbText}>{id}</Typography>
      </Breadcrumbs>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container className={classes.filterContainer} spacing={2}>
          <Grid item xs={7}>
            <Typography className={classes.filterText} component={'h2'}>
              Carrier User Details
            </Typography>
          </Grid>
          <Grid
            container
            xs={5}
            spacing={2}
            alignItems="center"
            justify="flex-end"
          >
            <Grid item>
              <Button
                variant="outlined"
                color="inherit"
                fullWidth
                minWidth="0px"
                onClick={handleCancel}
                className={classes.cancelButton}
              >
                {'Close'}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                minWidth="0px"
                fullWidth
              >
                {'Save'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={classes.filterOptionContainer} spacing={2}>
          <Grid item xs={3} className={classes.formElement}>
            <Typography className={classes.formLabel}>First Name</Typography>
            <Controller
              name="firstName"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <>
                  <TextField
                    variant="outlined"
                    type="text"
                    placeholder="Add First Name"
                    fullWidth
                    margin="dense"
                    value={value}
                    onChange={event => {
                      if (containDigits(event.target.value)) return;
                      onChange(event);
                    }}
                    error={!!error}
                    helperText={error?.message}
                  />
                </>
              )}
            />
          </Grid>
          <Grid item xs={3} className={classes.formElement}>
            <Typography className={classes.formLabel}>Last Name</Typography>
            <Controller
              name="lastName"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <>
                  <TextField
                    variant="outlined"
                    type="text"
                    placeholder="Add Last Name"
                    fullWidth
                    margin="dense"
                    value={value}
                    onChange={event => {
                      if (containDigits(event.target.value)) return;
                      onChange(event);
                    }}
                    error={!!error}
                    helperText={error?.message}
                  />
                </>
              )}
            />
          </Grid>
          <Grid item xs={3} className={classes.formElement}>
            <Typography
              className={clsx(classes.formLabel, classes.disableLabel)}
            >
              Email
            </Typography>
            <Controller
              name="email"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <>
                  <TextField
                    variant="outlined"
                    type="text"
                    placeholder="Email"
                    fullWidth
                    margin="dense"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    disabled
                    helperText={error?.message}
                  />
                </>
              )}
            />
          </Grid>
          <Grid item xs={3} className={classes.formElement}>
            <Typography
              className={clsx(classes.formLabel, classes.disableLabel)}
            >
              Country
            </Typography>
            <Controller
              name="country"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <>
                  <Select
                    value={value}
                    onChange={onChange}
                    displayEmpty
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    className={classes.select}
                    error={!!error}
                    disabled
                  >
                    {countryOptions.map(country => (
                      <MenuItem value={country.value} key={country.key}>
                        {country.text}
                      </MenuItem>
                    ))}
                  </Select>
                  {error?.message && (
                    <Typography
                      component={'p'}
                      className={classes.errorMessage}
                    >
                      {error.message}
                    </Typography>
                  )}
                </>
              )}
            />
          </Grid>
          <Grid item xs={3} className={classes.formElement}>
            <Typography
              className={clsx(classes.formLabel, classes.disableLabel)}
            >
              City
            </Typography>
            <Controller
              name="city"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <>
                  <TextField
                    variant="outlined"
                    type="text"
                    placeholder="Add City"
                    fullWidth
                    margin="dense"
                    disabled
                    value={value}
                    onChange={event => {
                      if (!ALPHABET_SPACE_NUM.test(event.target.value)) return;
                      onChange(event);
                    }}
                    error={!!error}
                    helperText={error?.message}
                  />
                </>
              )}
            />
          </Grid>
          <Grid item xs={3} className={classes.formElement}>
            <Typography
              className={clsx(classes.formLabel, classes.disableLabel)}
            >
              Postcode
            </Typography>
            <Controller
              name="postcode"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <>
                  <TextField
                    variant="outlined"
                    type="text"
                    placeholder="Add Postcode"
                    fullWidth
                    margin="dense"
                    disabled
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error?.message}
                  />
                </>
              )}
            />
          </Grid>
          <Grid item xs={3} className={classes.formElement}>
            <Typography
              className={clsx(classes.formLabel, classes.disableLabel)}
            >
              Last Updated On
            </Typography>
            <Controller
              name="lastUpdatedOn"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    inputFormat="dd-MMM-yyyy"
                    className={classes.datePicker}
                    disabled
                    value={value}
                    onChange={onChange}
                    renderInput={(params: any) => (
                      <TextField
                        variant="outlined"
                        type="text"
                        placeholder="Add Created Date"
                        fullWidth
                        margin="dense"
                        value={value}
                        onChange={onChange}
                        error={!!error}
                        helperText={error?.message}
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              )}
            />
          </Grid>

          <Grid item xs={3} className={classes.formElement}>
            <Typography
              className={clsx(classes.formLabel, classes.disableLabel)}
            >
              Last Updated By
            </Typography>
            <Controller
              name="lastUpdatedBy"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <>
                  <TextField
                    variant="outlined"
                    type="text"
                    placeholder="Last Updated By"
                    fullWidth
                    margin="dense"
                    value={value}
                    disabled
                    onChange={onChange}
                    error={!!error}
                    helperText={error?.message}
                  />
                </>
              )}
            />
          </Grid>
          <Grid item xs={3} className={classes.formElement}>
            <Typography
              className={clsx(classes.formLabel, classes.disableLabel)}
            >
              Last Login
            </Typography>
            <Controller
              name="lastLogin"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    inputFormat="dd-MMM-yyyy"
                    className={classes.datePicker}
                    disabled
                    value={value}
                    onChange={onChange}
                    renderInput={(params: any) => (
                      <TextField
                        variant="outlined"
                        type="text"
                        placeholder="Add Created Date"
                        fullWidth
                        margin="dense"
                        value={value}
                        onChange={onChange}
                        error={!!error}
                        helperText={error?.message}
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              )}
            />
          </Grid>
          <Grid item xs={3} className={classes.formElement}>
            <Typography
              className={clsx(classes.formLabel, classes.disableLabel)}
            >
              Carrier User ID
            </Typography>
            <Controller
              name="carrierUserId"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <>
                  <TextField
                    variant="outlined"
                    type="text"
                    placeholder="Add Consumer ID"
                    fullWidth
                    margin="dense"
                    value={value}
                    onChange={onChange}
                    disabled
                    error={!!error}
                    helperText={error?.message}
                  />
                </>
              )}
            />
          </Grid>
          <Grid
            item
            xs={3}
            className={clsx(classes.formElement, classes.disableLabel)}
          >
            <Typography className={classes.formLabel}>Status</Typography>
            <Controller
              name="status"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <>
                  <TextField
                    variant="outlined"
                    type="text"
                    placeholder="Add Consumer ID"
                    fullWidth
                    margin="dense"
                    value={value}
                    onChange={onChange}
                    disabled
                    error={!!error}
                    helperText={error?.message}
                  />
                </>
              )}
            />
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 26,
    lineHeight: '40px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
      lineHeight: '28px',
    },
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(3),
  },
  disableLabel: {
    color: 'rgba(0, 0, 0, 0.38)',
  },
  errorMessage: {
    color: theme.palette.error.main,
    margin: '0 14px',
    fontSize: '0.75rem',
  },
  errorInfo: {
    color: theme.palette.error.main,
    fontSize: 18,
  },
  deleteButton: {
    backgroundColor: theme.palette.error.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: darken(theme.palette.error.main, 0.1),
      color: theme.palette.common.white,
    },
  },
  modalTitle: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 21,
    lineHeight: '32px',
  },
  modalText: {
    fontSize: 18,
    margin: '10px 0px',
  },
  breadcrumb: {
    marginBottom: theme.spacing(10),
  },
  breadcrumbLink: {
    color: 'inherit',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  breadcrumbText: {
    color: 'inherit',
    fontSize: 'inherit',
  },
  filterContainer: {
    width: '100%',
    background: '#fff',
    borderRadius: '5px 5px 0 0',
    padding: '12px 16px 15px',
    margin: 0,
    border: '1px solid',
    borderBottom: '0px',
    borderColor: theme.palette.grey[300],
  },
  filterOptionContainer: {
    width: '100%',
    background: '#fff',
    borderRadius: '0 0 5px 5px',
    padding: '12px 16px 15px',
    margin: 0,
    marginBottom: theme.spacing(3),
    border: '1px solid',
    borderColor: theme.palette.grey[300],
  },
  filterText: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '21px',
    lineHeight: '32px',
  },
  formElement: {},
  formLabel: {
    fontSize: 16,
    lineHeight: '28px',
  },

  select: {
    marginTop: '8px',
    marginBottom: '4px',
  },
  datePicker: {
    backgroundColor: 'pink',
    '&& .MuiPickersDay-root.Mui-selected': {
      backgroundColor: 'red !important',
      '&:hover': {
        backgroundColor: 'red !important',
      },
      '&:focus': {
        backgroundColor: 'red !important',
      },
    },
  },

  modalHeader: {
    borderBottom: '1px solid #E0E0E0',
  },
  cancelButton: {
    color: theme.palette.grey[500],
  },
}));

export default CarrierActivity;
