import {makeStyles} from '@material-ui/core';

import logo from 'assets/logo.png';

import {CircularProgress} from '@material-ui/core';

const SplashScreen: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <img src={logo} alt="Stamp Free" className={classes.logo} />
      <CircularProgress />
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    minHeight: '100%',
    zIndex: 9999,
  },
  logo: {
    width: 200,
    height: 'auto',
    marginBottom: 32,
  },
}));

export default SplashScreen;
