/**
 * TS type-checking is disabled for Mirage.js because
 * Mirage.js lacks proper TS support.
 */
// @ts-nocheck

import {
  createServer,
  Model,
  Factory,
  hasMany,
  belongsTo,
  RestSerializer,
} from 'miragejs';
import faker from 'faker';
import {randexp} from 'randexp';
import {sample} from 'lodash';
import {isAfter, isBefore, isSameDay, isValid} from 'date-fns';

faker.locale = 'en_GB';

export function makeServer({environment = 'test'}) {
  return createServer({
    environment,
    timing: 1500,

    serializers: {
      application: RestSerializer,
      item: RestSerializer.extend({
        include: ['user', 'productType', 'carrier'],
        embed: true,
      }),
    },

    models: {
      user: Model.extend({
        items: hasMany(),
      }),
      productType: Model.extend({
        items: hasMany(),
      }),
      carrier: Model.extend({
        items: hasMany(),
      }),
      item: Model.extend({
        user: belongsTo(),
        productType: belongsTo(),
        carrier: belongsTo(),
      }),
    },

    factories: {
      user: Factory.extend({
        // not used, required for faker generation
        firstName() {
          return faker.name.firstName();
        },
        // not used, required for faker generation
        lastName() {
          return faker.name.lastName();
        },
        name() {
          return faker.name.findName(this.firstName, this.lastName);
        },
        email() {
          return faker.internet.email(this.firstName, this.lastName);
        },
      }),

      item: Factory.extend({
        digicode() {
          return randexp(/[1-9][1-9][1-9][1-9][1-9][1-9]/);
        },
        postcode() {
          return faker.address.zipCode();
        },
        date() {
          return faker.date.recent();
        },
        purchaseDate() {
          return faker.date.recent();
        },
        picture() {
          return faker.image.abstract();
        },
        status() {
          return sample(['Posted', 'Validated', 'Pending']);
        },
        price() {
          return faker.finance.amount(10, 99);
        },
      }),
    },

    seeds(server) {
      const productTypes = [
        'Next day tracked',
        'Lorem ipsum',
        'Dolor sit amet',
      ].map(name => {
        return server.create('productType', {name});
      });

      const carriers = ['Royal Mail', 'General Post Office', 'Parcelforce'].map(
        name => {
          return server.create('carrier', {name});
        }
      );

      server
        .createList(
          'user',
          faker.datatype.number({
            min: 25,
            max: 50,
          })
        )
        .forEach(user => {
          server.createList(
            'item',
            faker.datatype.number({
              min: 5,
              max: 15,
            }),
            {
              user,
              productType: sample(productTypes),
              carrier: sample(carriers),
            }
          );
        });
    },

    routes() {
      this.namespace = 'api';

      this.get('/overview', schema => {
        return {
          userCount: schema.all('user').length,
          transactionsCompleted: schema.all('item').length,
          verificationErrors: faker.datatype.number({
            min: 10,
            max: 99,
          }),
          preAdviceFiles: faker.datatype.number({
            min: 10,
            max: 99,
          }),
        };
      });

      this.get('/users', function (schema, request) {
        const {searchTerm} = request.queryParams;

        const usersCollection = schema.all('user');

        if (searchTerm) {
          usersCollection.models = usersCollection.models.filter(user => {
            return (
              (user.id && user.id.includes(searchTerm)) ||
              (user.email &&
                user.email.toLowerCase().includes(searchTerm.toLowerCase()))
            );
          });
        }

        return usersCollection;
      });

      this.get('/users/:id');

      this.patch('/users/:id', function (schema, request) {
        const id = request.params.id;
        const attrs = JSON.parse(request.requestBody);

        return schema.users.find(id).update(attrs);
      });

      this.get('/users/:id/items', function (schema, request) {
        const userId = request.params.id;

        return schema.where('item', {
          userId,
          status: 'Posted',
        });
      });

      this.get('/product-types');

      this.get('/carriers');

      this.get('/items', function (schema, request) {
        const {
          searchTerm,
          postcode,
          digicode,
          status,
          carrier,
          productType,
          purchaseDateFrom,
          purchaseDateTo,
        } = request.queryParams;

        const itemsCollection = schema.all('item');

        if (searchTerm) {
          const searchTermLower = searchTerm.toLowerCase();

          itemsCollection.models = itemsCollection.models.filter(item => {
            return (
              (item.postcode &&
                item.postcode.toLowerCase().includes(searchTermLower)) ||
              (item.digicode &&
                item.digicode.toLowerCase().includes(searchTermLower)) ||
              (item.userId && item.userId.includes(searchTermLower))
            );
          });

          return itemsCollection;
        }

        if (postcode) {
          itemsCollection.models = itemsCollection.models.filter(item => {
            return (
              item.postcode &&
              item.postcode.toLowerCase().includes(postcode.toLowerCase())
            );
          });
        }
        if (digicode) {
          itemsCollection.models = itemsCollection.models.filter(item => {
            return (
              item.digicode &&
              item.digicode.toLowerCase().includes(digicode.toLowerCase())
            );
          });
        }
        if (status) {
          itemsCollection.models = itemsCollection.models.filter(item => {
            return (
              item.status &&
              item.status.toLowerCase().includes(status.toLowerCase())
            );
          });
        }
        if (carrier) {
          itemsCollection.models = itemsCollection.models.filter(item => {
            return (
              item.carrier &&
              item.carrier.name &&
              item.carrier.name.toLowerCase().includes(carrier.toLowerCase())
            );
          });
        }
        if (productType) {
          itemsCollection.models = itemsCollection.models.filter(item => {
            return (
              item.productType &&
              item.productType.name &&
              item.productType.name
                .toLowerCase()
                .includes(productType.toLowerCase())
            );
          });
        }
        if (purchaseDateFrom && isValid(new Date(purchaseDateFrom))) {
          itemsCollection.models = itemsCollection.models.filter(item => {
            return (
              isAfter(
                new Date(item.purchaseDate),
                new Date(purchaseDateFrom)
              ) ||
              isSameDay(new Date(item.purchaseDate), new Date(purchaseDateFrom))
            );
          });
        }
        if (purchaseDateTo && isValid(new Date(purchaseDateTo))) {
          itemsCollection.models = itemsCollection.models.filter(item => {
            return (
              isBefore(new Date(item.purchaseDate), new Date(purchaseDateTo)) ||
              isSameDay(new Date(item.purchaseDate), new Date(purchaseDateTo))
            );
          });
        }

        return itemsCollection;
      });

      this.get('/items/:id');
    },
  });
}
