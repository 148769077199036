import * as React from 'react';
import {makeStyles} from '@material-ui/core';
import {useLocation} from 'react-router-dom';

import logo from 'assets/logo.png';
import {Typography} from '@material-ui/core';
import useLocalization from 'hooks/useLocalization';
import {convertStringToLanguage} from 'locales';

const PaymentFailed: React.FC = () => {
  const classes = useStyles();
  const {search} = useLocation();
  const {localeText} = useLocalization();

  const searchParams = new URLSearchParams(search);
  const localeParam = searchParams.get('locale');
  const language = localeParam
    ? convertStringToLanguage(localeParam)
    : undefined;

  return (
    <div className={classes.root}>
      <img src={logo} alt="Stamp Free" className={classes.logo} />
      <Typography className={classes.title}>
        {localeText('payment_failed_title', language)}
      </Typography>
      <Typography className={classes.description}>
        {localeText('payment_failed_desc', language)}
      </Typography>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    flexDirection: 'column',
  },
  form: {
    width: 350,
    [theme.breakpoints.down('sm')]: {
      width: '70%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '90%',
    },
  },
  logo: {
    width: 200,
    height: 'auto',
    marginBottom: 32,
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 21,
    lineHeight: '32px',
    marginBottom: 32,
  },
  description: {
    fontSize: 16,
    lineHeight: '28px',
    marginBottom: 32,
  },
}));

export default PaymentFailed;
