import React, {useState} from 'react';
import languageMap, {Language, MessageType} from 'locales';

type LocalizationContextValue = {
  language: Language;
  updateLanguage: (language: Language) => Promise<void>;
  localeText: (key: MessageType, lang?: Language) => string;
};

const LocalizationContext = React.createContext<
  LocalizationContextValue | undefined
>(undefined);

export const LocalizationProvider: React.FC = ({children}) => {
  const [language, setLanguage] = useState<Language>('en');

  const updateLanguage = async (language: Language) => {
    setLanguage(language);
  };

  const localeText = React.useCallback(
    (key: MessageType, lang: Language = language) => {
      return languageMap[lang][key];
    },
    [language]
  );

  return (
    <LocalizationContext.Provider
      value={{language, updateLanguage, localeText}}
    >
      {children}
    </LocalizationContext.Provider>
  );
};

const useLocalization = () => {
  const context = React.useContext(LocalizationContext);

  if (!context) {
    throw new Error('Context not provided');
  }

  return context;
};

export default useLocalization;
