export const AWS_AMPLIFY_CONFIG = Object.freeze({
  Auth: {
    region: process.env.REACT_APP_AWS_REGION ?? '',
    identityPoolId: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID ?? '',
    userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID ?? '',
    userPoolWebClientId:
      process.env.REACT_APP_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID ?? '',
  },
  Storage: {
    AWSS3: {
      bucket: process.env.REACT_APP_PHOTO_BUCKET_NAME, //REQUIRED -  Amazon S3 bucket name
      region: process.env.REACT_APP_PHOTO_BUCKET_REGION, //OPTIONAL -  Amazon service region
    },
  },
});
