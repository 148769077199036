import toast from 'react-hot-toast';

import {DEFAULT_MESSAGES} from 'config/toast.config';
import {
  COGNITO_USER_DISABLE_MESSAGE,
  ERROR_MESSAGE,
  INVALID_CREDENTIALS,
  USER_DISABLED,
} from './messages';

export const getErrorMessage = (error: any) => {
  if (!error) {
    return DEFAULT_MESSAGES.ERROR;
  }

  if (typeof error === 'string') {
    return error;
  }

  if (typeof error.title === 'string') {
    return error.title;
  }

  if (typeof error.description === 'string') {
    return error.description;
  }

  if (typeof error.message === 'string') {
    if (error.message === COGNITO_USER_DISABLE_MESSAGE) return USER_DISABLED;
    return error.message;
  }

  return DEFAULT_MESSAGES.ERROR;
};

export const getAuthErrorMEssage = (error: any): string => {
  if (!error) {
    return ERROR_MESSAGE;
  }

  if (typeof error === 'object' && error && error.code) {
    switch (error.code) {
      case 'NotAuthorizedException':
        if (error.message === COGNITO_USER_DISABLE_MESSAGE)
          return USER_DISABLED;
        return INVALID_CREDENTIALS;

      case 'LimitExceededException':
      case 'TooManyRequestsException':
      case 'TooManyFailedAttemptsException':
      case 'ExpiredCodeException':
      case 'CodeMismatchException':
        return error.message;
    }
  }

  return ERROR_MESSAGE;
};

export const handleError = (error: any) => {
  if (error?.message === 'Not authorized') {
    return;
  }
  if (
    error &&
    error.response &&
    error.response.data &&
    error.response.data.detail
  ) {
    toast.error(error.response.data.detail);
    return;
  }
  toast.error(ERROR_MESSAGE);
  return;
};
