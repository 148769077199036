import axios from 'lib/axios';

import type {
  ProductTypesResponse_Mock,
  CarrierProductTypesResponse_Mock,
} from 'types';

type CreateProductDTO = {
  type: string;
  title: string;
  description?: string;
  currency: string;
  deliveryTime: string;
  status: 'active' | 'inactive';
  price: number;
};

class ProductTypeService {
  async getProductTypes() {
    const response = await axios.get<ProductTypesResponse_Mock>(
      '/product-types'
    );

    return response.data;
  }

  async getCarrierProducts(carrierId: string) {
    if (!carrierId) {
      return {
        itemTypes: [],
      };
    }
    const response = await axios.get<CarrierProductTypesResponse_Mock>(
      `/cc/carrierProducts?carrier=${carrierId}`
    );

    return response.data;
  }

  async add(carrierId: string, data: CreateProductDTO) {
    const response = await axios.post(`/cc/carrier/${carrierId}/product`, data);
    return response.data;
  }

  async edit(carrierId: string, productId: string, data: CreateProductDTO) {
    const response = await axios.put(
      `/cc/carrier/${carrierId}/product/${productId}`,
      data
    );
    return response.data;
  }
}

export const productTypeService = new ProductTypeService();
