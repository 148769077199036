import {makeStyles} from '@material-ui/core';

import {CircularProgress} from '@material-ui/core';

const Spinner: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress />
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: 'transparent',
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    minHeight: '100%',
  },
}));

export default Spinner;
