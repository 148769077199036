import * as React from 'react';
import {useAuth} from './useAuth';
import {countryService} from 'services/countryService';
import {ICountry, ICurrency} from 'types';
import {currencyService} from 'services/currencyService';

interface ConfigDataContextValue {
  countryList: ICountry[];
  currencyList: ICurrency[];
}

const ConfigDataContext = React.createContext<
  ConfigDataContextValue | undefined
>(undefined);

const ConfigDataProvider: React.FC = ({children}) => {
  const {authUser} = useAuth();
  const [countryList, setCountryList] = React.useState<ICountry[]>([]);
  const [currencyList, setCurrencyList] = React.useState<ICurrency[]>([]);

  React.useEffect(() => {
    if (authUser && countryList.length === 0) {
      fetchCountryList();
    }
    if (authUser && currencyList.length === 0) {
      fetchCurrencyList();
    }
  }, [authUser]);

  const fetchCountryList = async () => {
    try {
      const countryResponse = await countryService.list();
      setCountryList(countryResponse);
    } catch (error: any) {
      console.log(error);
    }
  };

  const fetchCurrencyList = async () => {
    try {
      const currencyResponse = await currencyService.list();
      setCurrencyList(currencyResponse);
    } catch (error: any) {
      console.log(error);
    }
  };

  return (
    <ConfigDataContext.Provider
      value={{
        countryList,
        currencyList,
      }}
    >
      {children}
    </ConfigDataContext.Provider>
  );
};

const useConfigData = () => {
  const context = React.useContext(ConfigDataContext);

  if (context === undefined) {
    throw new Error('useConfigData must be used within an ConfigDataProvider');
  }

  return context;
};

export {ConfigDataProvider, useConfigData};
