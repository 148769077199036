export type MessageType =
  | 'payment_success_title'
  | 'payment_success_desc'
  | 'payment_failed_title'
  | 'payment_failed_desc';

export type Localization = Record<MessageType, string>;

export type Language = 'en' | 'ptBR';

export function convertStringToLanguage(str: string): Language | undefined {
  const mapping: Record<string, Language> = {
    en: 'en',
    'pt-BR': 'ptBR',
  };
  return mapping[str];
}
