import axios from 'lib/axios';
import type {CarrierActivityResponse} from 'types';

class CarrierActivityService {
  async search({
    status,
    name,
    loggedInFrom,
    loggedInTo,
    location,
  }: {
    status: 'active' | 'inactive';
    name?: string;
    loggedInFrom: number;
    loggedInTo: number;
    location?: string;
  }) {
    const response = await axios.post<CarrierActivityResponse>(
      'cc/carrier-activity',
      {
        status,
        ...(name ? {name} : {}),
        loggedInFrom,
        loggedInTo,
        ...(location ? {location} : {}),
      }
    );
    return response.data;
  }

  async get(id: string) {
    const response = await axios.get(`/cc/carrier-activity/${id}`);
    return response.data;
  }

  async update({
    id,
    firstName,
    lastName,
    city,
    country,
    postcode,
  }: {
    id: string;
    firstName: string;
    lastName?: string;
    city?: string;
    country: string;
    postcode: string;
  }) {
    const response = await axios.put(`/cc/carrier-activity/${id}`, {
      firstName,
      ...(lastName ? {lastName} : {}),
      ...(city ? {city} : {}),
      postcode,
      country,
    });
    return response.data;
  }
}

export const carrierActivityService = new CarrierActivityService();
