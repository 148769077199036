import * as React from 'react';
import {makeStyles, darken} from '@material-ui/core';
import {useQuery} from 'react-query';
import {toast} from 'react-hot-toast';
import {useForm, Controller} from 'react-hook-form';
import {Row} from 'react-table';
import {useHistory} from 'react-router-dom';
import * as yup from 'yup';
import {
  addDays,
  format,
  parseISO,
  subDays,
  differenceInMilliseconds,
  isValid,
} from 'date-fns';
import {yupResolver} from '@hookform/resolvers/yup';

import DataGrid from 'components/DataGrid';
import {correctService} from 'services/correctService';
import {ERROR_MESSAGE} from 'utils/messages';
import {useAuth} from 'hooks/useAuth';
import {startOfDayTimestamp, getDate} from 'utils/Date';
import {containDigits} from 'utils/String';

import {Typography, Grid, TextField, Select, MenuItem} from '@material-ui/core';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import VisibilityIcon from '@material-ui/icons/Visibility';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import {Skeleton} from '@material-ui/lab';

import Button from 'components/Button';
import {DATE_FORMAT} from 'utils/regex';
import {useCarrierOptions} from 'hooks/useCarrierOptions';

type FilterInputs = {
  carrier: string;
  createdFrom: Date;
  createdTo: Date;
  uploadedBy: string;
};

const schema = yup.object().shape({
  createdFrom: yup
    .date()
    .typeError('Invalid date')
    .test('createdFromDateInvalid', 'Invalid date', function () {
      // validate Date
      const yupContext = this as any;
      if (typeof yupContext.originalValue === 'string') {
        return DATE_FORMAT.test(yupContext.originalValue);
      }
      return true;
    })
    .test(
      'createdToMin',
      'Uploaded From should not be a future date',
      function () {
        // Min date validation
        if (isValid(this.parent.createdFrom)) {
          return (
            differenceInMilliseconds(new Date(), this.parent.createdFrom) >= 0
          );
        }
        return true;
      }
    ),

  createdTo: yup
    .date()
    .typeError('Invalid date')
    .test('createdFromDateInvalid', 'Invalid date', function () {
      // validate Date
      const yupContext = this as any;
      if (typeof yupContext.originalValue === 'string') {
        return DATE_FORMAT.test(yupContext.originalValue);
      }
      return true;
    })
    .test(
      'createdToMin',
      'Uploaded To should not be a future date',
      function () {
        // Min date validation
        if (isValid(this.parent.createdTo)) {
          return (
            differenceInMilliseconds(new Date(), this.parent.createdTo) >= 0
          );
        }
        return true;
      }
    )
    .test(
      'createdToMin',
      'Uploaded To cannot be before Uploaded From',
      function () {
        // Min date validation
        if (isValid(this.parent.createdFrom)) {
          return (
            differenceInMilliseconds(
              this.parent.createdTo,
              this.parent.createdFrom
            ) >= 0
          );
        }
        return true;
      }
    ),
});

const Correct: React.FC = () => {
  const history = useHistory();
  const {authUser} = useAuth();

  const {carrierOptions, carriersStatus} = useCarrierOptions();

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    getValues,
  } = useForm<FilterInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      carrier: '',
      createdFrom: subDays(new Date(startOfDayTimestamp(new Date())), 15),
      createdTo: new Date(startOfDayTimestamp(new Date())),
      uploadedBy: '',
    },
  });

  const classes = useStyles();

  const [defaultCarrierId, setDefaultCarrierId] = React.useState('');

  const {data, refetch, isFetching} = useQuery(
    ['correct', defaultCarrierId],
    () => {
      return correctService.list({
        carrier: getValues('carrier'),
        createdFrom: startOfDayTimestamp(getValues('createdFrom')),
        createdTo: startOfDayTimestamp(
          addDays(new Date(getValues('createdTo')), 1)
        ),
        updatedBy: getValues('uploadedBy').trim(),
      });
    },
    {
      enabled: !!defaultCarrierId,
      retry: false,
    }
  );

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    if (authUser?.attributes['custom:user_role'] === 'carrierAdmin') {
      setDefaultCarrierId(authUser?.attributes['custom:carrier_id'] || '');
      setValue('carrier', authUser?.attributes['custom:carrier_id'] || '');
    } else if (carrierOptions?.length > 0) {
      setDefaultCarrierId(carrierOptions[0]?.value);
      setValue('carrier', carrierOptions[0]?.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carrierOptions]);

  const resetFilters = () => {
    reset({
      createdFrom: subDays(new Date(startOfDayTimestamp(new Date())), 15),
      createdTo: new Date(startOfDayTimestamp(new Date())),
      uploadedBy: '',
      carrier: defaultCarrierId,
    });
  };

  const handleClearFilter = async (event: React.MouseEvent) => {
    resetFilters();
    refetch({
      throwOnError: true,
      cancelRefetch: true,
    });
  };

  const onSubmit = async (data: FilterInputs) => {
    refetch({
      throwOnError: true,
      cancelRefetch: true,
    });
  };

  return (
    <div>
      {/* {status === 'LOADING' && <ScreenSpinner />} */}
      <Typography component={'h1'} className={classes.title}>
        Correct
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container className={classes.filterContainer} spacing={2}>
          <Grid item xs={9}>
            <Typography className={classes.filterText} component={'h2'}>
              Filter
            </Typography>
          </Grid>
          <Grid
            container
            xs={3}
            spacing={2}
            alignItems="center"
            justify="flex-end"
          >
            <Grid item>
              <Button
                variant="outlined"
                color="inherit"
                fullWidth
                minWidth="0px"
                onClick={handleClearFilter}
                className={classes.cancelButton}
              >
                Clear
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                minWidth="0px"
                fullWidth
              >
                {'Apply'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={classes.filterOptionContainer} spacing={2}>
          <Grid item xs={3} className={classes.formElement}>
            <Typography className={classes.formLabel}>Carrier</Typography>
            {carriersStatus === 'loading' ? (
              <Skeleton variant="rect" className={classes.textFieldSkeleton} />
            ) : (
              <Controller
                name="carrier"
                control={control}
                render={({field: {value, onChange}, fieldState: {error}}) => (
                  <Select
                    value={value}
                    onChange={onChange}
                    displayEmpty
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    disabled={
                      authUser?.attributes['custom:user_role'] ===
                      'carrierAdmin'
                    }
                    className={classes.select}
                  >
                    {carrierOptions.map(carrier => (
                      <MenuItem value={carrier.value} key={carrier.key}>
                        {carrier.text}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            )}
          </Grid>
          <Grid item xs={3} className={classes.formElement}>
            <Typography className={classes.formLabel}>Uploaded By</Typography>
            <Controller
              name="uploadedBy"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <>
                  <TextField
                    variant="outlined"
                    type="text"
                    placeholder="Add Username"
                    fullWidth
                    margin="dense"
                    value={value}
                    onChange={event => {
                      if (containDigits(event.target.value)) return;
                      onChange(event);
                    }}
                    error={!!error}
                    helperText={error?.message}
                  />
                </>
              )}
            />
          </Grid>
          <Grid item xs={3} className={classes.formElement}>
            <Typography className={classes.formLabel}>Uploaded From</Typography>
            <Controller
              name="createdFrom"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    inputFormat="dd-MMM-yyyy"
                    className={classes.datePicker}
                    disableFuture
                    allowSameDateSelection
                    value={value}
                    onChange={onChange}
                    maxDate={getDate(watch('createdTo'))}
                    renderInput={(params: any) => (
                      <TextField
                        variant="outlined"
                        type="text"
                        fullWidth
                        margin="dense"
                        value={value}
                        onChange={onChange}
                        {...params}
                        error={!!error}
                        helperText={error?.message}
                        placeholder="Add Uploaded From"
                      />
                    )}
                  />
                </LocalizationProvider>
              )}
            />
          </Grid>
          <Grid item xs={3} className={classes.formElement}>
            <Typography className={classes.formLabel}>Uploaded To</Typography>
            <Controller
              name="createdTo"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    inputFormat="dd-MMM-yyyy"
                    className={classes.datePicker}
                    disableFuture
                    allowSameDateSelection
                    minDate={getDate(watch('createdFrom'))}
                    value={value}
                    onChange={onChange}
                    renderInput={(params: any) => (
                      <TextField
                        variant="outlined"
                        type="text"
                        fullWidth
                        margin="dense"
                        value={value}
                        onChange={onChange}
                        {...params}
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                </LocalizationProvider>
              )}
            />
          </Grid>
        </Grid>
      </form>
      <div className={classes.tableContainer}>
        <DataGrid
          columns={[
            {
              Header: 'Uploaded On',
              accessor: 'createdAt',
              Cell: ({value}) => format(parseISO(value), 'dd-MMM-yyyy'),
            },
            {
              Header: 'Last Updated',
              accessor: 'updatedAt',
              Cell: ({value}) => format(parseISO(value), 'dd-MMM-yyyy'),
            },
            {
              Header: 'Last Updated By',
              accessor: (row: any) =>
                row.updatedBy
                  ? `${
                      row.updatedBy.firstName
                        ? row.updatedBy.firstName
                        : row.updatedBy.name
                    } ${row.updatedBy.lastName || ''}`
                  : '',
            },
          ]}
          data={data || []}
          recordCount={isFetching ? 0 : data?.length || 0}
          defaultPageSize={5}
          isLoading={isFetching}
          onPaginationChange={() => {}}
          extraActions={[
            {
              label: 'Action',
              type: 'icon-button',
              buttonContent: <VisibilityIcon />,
              handler: (row: Row<any>) => {
                if (row.original.id) {
                  history.push(
                    `/correct/${getValues('carrier')}/${row.original.id}`
                  );
                } else {
                  toast.error(ERROR_MESSAGE);
                }
              },
            },
          ]}
          // maxHeight="65vh"
          noDataMessage={'No items available'}
        />
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 26,
    lineHeight: '40px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
      lineHeight: '28px',
    },
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(3),
  },
  cancelButton: {
    color: theme.palette.grey[500],
  },
  tabs: {
    marginBottom: '43px',
  },
  tab: {
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: 14,
    lineHeight: '20px',
  },
  filterContainer: {
    width: '100%',
    background: '#fff',
    borderRadius: '5px 5px 0 0',
    padding: '12px 16px 15px',
    margin: 0,
    border: '1px solid',
    borderBottom: '0px',
    borderColor: theme.palette.grey[300],
  },
  filterOptionContainer: {
    width: '100%',
    background: '#fff',
    borderRadius: '0 0 5px 5px',
    padding: '12px 16px 15px',
    margin: 0,
    marginBottom: theme.spacing(3),
    border: '1px solid',
    borderColor: theme.palette.grey[300],
  },
  filterText: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '21px',
    lineHeight: '32px',
  },
  formElement: {},
  formLabel: {
    fontSize: 16,
    lineHeight: '28px',
  },
  hash: {
    backgroundColor: theme.palette.grey[400],
  },
  adornmentTextField: {
    '& span': {
      backgroundColor: theme.palette.grey[400],
    },
  },
  select: {
    marginTop: '8px',
    marginBottom: '4px',
  },
  datePicker: {
    backgroundColor: 'pink',
    '&& .MuiPickersDay-root.Mui-selected': {
      backgroundColor: 'red !important',
      '&:hover': {
        backgroundColor: 'red !important',
      },
      '&:focus': {
        backgroundColor: 'red !important',
      },
    },
  },
  textFieldSkeleton: {
    width: '100%',
    height: 40,
    borderRadius: 4,
    marginTop: 8,
    marginBottom: 4,
  },
  tableContainer: {
    marginTop: theme.spacing(3),
  },
  name: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.primary.main,
  },
  dashboardText: {
    fontSize: 14,
    lineHeight: '20px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      lineHeight: '14px',
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginBottom: theme.spacing(1),
  },
  subjectText: {
    fontSize: 21,
    lineHeight: '32px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
      lineHeight: '24px',
    },
    fontWeight: theme.typography.fontWeightBold,
  },
  subjectInput: {
    margin: 0,
  },
  editButton: {
    minWidth: '0px',
    width: 'auto',
    padding: 9,
    fontSize: 18,
    backgroundColor: theme.palette.primary.light,
    '&:hover': {
      backgroundColor: darken(theme.palette.primary.light, 0.1),
    },
  },
  updateButton: {
    minWidth: '0px',
    width: 'auto',
  },
  toolbar: {
    borderRadius: '5px',
  },
  editorError: {
    fontSize: '0.75rem',
    color: theme.palette.error.main,
    margin: '4px 14px 0px',
  },
  content: {
    backgroundColor: '#fff',
    height: 'calc(100vh - 220px)',
    minHeight: '300px',
    padding: 30,
  },
}));

export default Correct;
