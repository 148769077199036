import {Localization} from 'locales/helper';

const en: Localization = {
  payment_failed_desc: 'We were unable to process your payment',
  payment_failed_title: 'Payment Failed',
  payment_success_desc: 'Thank you for completing payment',
  payment_success_title: 'Payment Successful',
};

export default en;
