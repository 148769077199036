export const ERROR_MESSAGE = 'Something went wrong, please try again later';
export const FETCH_RETAILER_ERROR_MESSAGE =
  'Something went wrong, please try again later';

export const INVALID_EMAIL = 'Please enter a valid email ID';
export const INVALID_PASSWORD = 'Please enter a valid password';
export const INVALID_CREDENTIALS =
  'Unable to login. Email or password incorrect';
export const FORGOT_PASSWORD_SUCCESS =
  'If your email matches we will send instructions on how to reset your password. Remember to also check your junk folder';
export const RESET_PASSWORD_SUCCESS = 'Password updated successfully';
export const PASSWORD_NOT_MATCH = 'Password does not match';
export const SUBJECT_MAX_CHAR_ERROR = 'Maximum 100 characters are allowed';
export const EDITOR_MAX_CHAR_ERROR = 'Maximum 1000 characters are allowed ';
export const CONTENT_UPDATE_SUCCESS = 'Details updated successfully';
export const FETCH_USER_ERROR = 'Unable to fetch user details';
export const CORRECT_ITEM_DELETE_SUCCESS = 'Image deleted successfully';
export const EASYCODE_LENGTH_MESSAGE =
  'Easycode should have minimum 3 and maximum 9 digits';
export const SFCODE_LENGTH_MESSAGE =
  'Stamp Free code should have minimum 3 and maximum 8 characters';
export const FILE_EXPORT_SUCCESS = 'File exported successfully';
export const POSTCODE_INVALID = 'Maximum 15 characters are allowed';
export const CARRIER_INACTIVE_WARNING =
  'This carrier is disabled so the relevant details will be unavailable to the consumers';
export const USER_DISABLED =
  'Your account is currently inactive. Please contact the administrator';
export const COGNITO_USER_DISABLE_MESSAGE = 'User is disabled.';
export const PUDO_PASSWORD_UPDATE =
  'Note: Shop user password will be "Test@123". Ask the user to set a new password once they login';
export const PUDO_INACTIVE_WARNING =
  'This PUDO is disabled so the relevant details will be unavailable to the consumers';

export const requiredFieldMessage = (fieldName: string) =>
  `${fieldName} is a required field`;

export const invalidFieldMessage = (fieldName: string) =>
  `Please enter a valid ${fieldName}`;
