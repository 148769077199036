import * as React from 'react';
import {makeStyles, darken} from '@material-ui/core';
import {useQuery} from 'react-query';
import {toast} from 'react-hot-toast';
import {useHistory, Link, useParams} from 'react-router-dom';
import {useForm, Controller} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import clsx from 'clsx';
import * as yup from 'yup';
import {retailerService} from 'services/retailerService';
import {requiredFieldMessage} from 'utils/messages';
import {formatDate} from 'utils/helper';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {
  Typography,
  Grid,
  TextField,
  Select,
  IconButton,
  MenuItem,
  Breadcrumbs,
  Modal,
} from '@material-ui/core';
import DaterangeIcon from '@material-ui/icons/DateRange';
import Button from 'components/Button';
import ScreenSpinner from 'components/ScreenSpinner';
import {compare, sort} from 'utils/Array';
import {Autocomplete} from '@mui/material';
import {carrierService} from 'services/carrierService';
import {Carrier_List_Mock, RetailerParcelType} from 'types';
import {Trash2, Edit3, X} from 'react-feather';
import {useCountryOptions} from 'hooks/useCountryOptions';

type RetailerDetails = {
  retailerName: string;
  retailerID: string;
  country: string;
  city: string;
  address: string;
  status: 'active' | 'inactive';
  lastupdatedOn: string;
  lastupdatedBy: string;
  createdOn: string;
  postcode: string;
  carriers: Array<{id: string; name: string; clientFlag?: boolean}>;
  iconURL?: string;
};

// Add method to handle empty string
yup.addMethod(yup.string, 'stripEmptyString', function () {
  return this.transform(value => (value === '' ? undefined : value));
});

const filterSchema = yup.object().shape({
  retailerName: (yup.string().trim() as any)
    .stripEmptyString()
    .required(requiredFieldMessage('Retailer Name'))
    .min(3, 'Retailer Name should have minimum 3 and maximum 50 characters')
    .max(50, 'Retailer Name should have minimum 3 and maximum 50 characters'),
  city: (yup.string().trim() as any)
    .stripEmptyString()
    .min(3, 'City should have minimum 3 and maximum 50 characters')
    .max(50, 'City should have minimum 3 and maximum 50 characters'),
  address: (yup.string().trim() as any)
    .stripEmptyString()
    .max(250, 'Address Line 1 must be at most 250 characters'),
  postcode: yup
    .string()
    .trim()
    .notRequired()
    .max(15, 'Maximum 15 characters are allowed'),
  carriers: yup.array().of(yup.string().required()),
});

const parcelTypeSchema = yup.object().shape({
  parcelType: (yup
    .string()
    .trim()
    .max(50, 'Parcel type should have maximum 50 characters') as any)
    .stripEmptyString()
    .required(requiredFieldMessage('Parcel Type')),
  parcelDescription: yup
    .string()
    .trim()
    .test({
      name: 'testDescriptionLength',
      test: function (parcelDescription?: string) {
        if (!parcelDescription) return true;
        if (parcelDescription.length < 3) {
          return this.createError({
            message:
              'Description should have minimum 3 and maximum 255 characters',
          });
        } else if (parcelDescription.length > 255) {
          return this.createError({
            message:
              'Description should have minimum 3 and maximum 255 characters',
          });
        }
        return true;
      },
    }),
});

const reasonForReturnSchema = yup.object().shape({
  reasonforReturn: (yup
    .string()
    .trim()
    .max(50, 'Reason for return should have maximum 50 characters') as any)
    .stripEmptyString()
    .required(requiredFieldMessage('Reason for Return')),
});

const statusOptions = [
  {
    key: 'active',
    value: 'active',
    text: 'Active',
  },
  {
    key: 'inactive',
    value: 'inactive',
    text: 'Inactive',
  },
];

const Retailer: React.FC = () => {
  const history = useHistory();
  const {id: retailerId} = useParams<{id: string}>();
  const isEdit = () => retailerId !== 'add';
  const countryOptions = useCountryOptions();

  const {
    control,
    handleSubmit,
    reset,
    getValues,
    setValue,
  } = useForm<RetailerDetails>({
    mode: 'all',
    resolver: yupResolver(filterSchema),
    defaultValues: {
      retailerName: '',
      retailerID: '',
      country: 'United Kingdom',
      city: '',
      address: '',
      status: 'active',
      lastupdatedOn: '',
      lastupdatedBy: '',
      createdOn: '',
      postcode: '',
    },
  });

  const {
    control: parcelTypeControl,
    getValues: getParcelTypeValue,
    setValue: setParcelTypeValue,
    handleSubmit: handleParcelTypeSubmit,
    reset: resetParcelType,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(parcelTypeSchema),
    defaultValues: {
      parcelType: '',
      parcelDescription: '',
    },
  });

  const {
    control: reasonforReturnControl,
    getValues: getReasonforReturnValue,
    setValue: setReasonforReturnValue,
    handleSubmit: handleReasonforReturnSubmit,
    reset: resetReasonforReturn,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(reasonForReturnSchema),
    defaultValues: {
      reasonforReturn: '',
    },
  });

  const classes = useStyles();
  const [showParcelTypePopup, setShowParcelTypePopup] = React.useState(false);
  const [showCarrierPopup, setShowCarrierPopup] = React.useState(false);

  const [
    showReasonForReturnPopup,
    setShowReasonForReturnPopup,
  ] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [
    inActiveConfirmationPopup,
    setInActiveConfirmationPopup,
  ] = React.useState(false);
  const [
    showDeleteConfirmationPopup,
    setShowDeleteConfirmationPopup,
  ] = React.useState(false);
  const [
    deleteConfirmationContent,
    setDeleteConfirmationContent,
  ] = React.useState<{title: string; item: string | RetailerParcelType}>({
    title: '',
    item: '',
  });
  const [parcelTypes, setParcelTypes] = React.useState<
    Array<RetailerParcelType>
  >([]);
  const [carriers, setCarriers] = React.useState<RetailerDetails['carriers']>(
    []
  );
  const [reasonsforReturn, setReasonsforReturn] = React.useState<Array<string>>(
    []
  );
  const [
    selectedParcelType,
    setSelectedParcelType,
  ] = React.useState<RetailerParcelType | null>(null);
  const [selectedCarriers, setSelectedCarriers] = React.useState<
    Carrier_List_Mock[]
  >([]);
  const [selectedCarrierIds, setSelectedCarrierIds] = React.useState<string[]>(
    []
  );
  const [selectedReasonforReturn, setSelectedReasonforReturn] = React.useState(
    ''
  );

  const resetForm = () => {
    setParcelTypes([]);
    setReasonsforReturn([]);
    setCarriers([]);
    reset({
      retailerName: '',
      retailerID: '',
      country: 'UK',
      city: '',
      address: '',
      status: 'active',
      lastupdatedOn: '',
      lastupdatedBy: '',
      createdOn: '',
      postcode: '',
      iconURL: '',
    });
  };

  const {data: retailersData, refetch, isFetching} = useQuery(
    ['Retailer', retailerId],
    () => {
      return retailerService.getRetailerDetails(retailerId);
    },
    {
      enabled: isEdit(),
    }
  );

  const {data: carriersData} = useQuery(
    ['carriers'],
    () => {
      return carrierService.getCarriers();
    },
    {retry: false}
  );

  const setData = () => {
    if (!retailersData) return;
    setValue('retailerName', retailersData.name);
    setValue('retailerID', retailersData.id);
    setValue('country', retailersData.address.country);
    setValue('city', retailersData.address.city);
    setValue('address', retailersData.address.addressLine1 || '');
    setValue('status', retailersData.status && retailersData.status);
    setValue('lastupdatedOn', retailersData.updatedAt || '');
    setValue('lastupdatedBy', retailersData.updatedBy);
    setValue('createdOn', retailersData.createdAt || '');
    setValue('postcode', retailersData.address.postcode || '');
    setReasonsforReturn(retailersData.returnReasons);
    setParcelTypes(retailersData.parcelType);
    setValue('iconURL', retailersData.iconURL || '');
    setCarriers(retailersData.carriers);
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    setData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retailersData]);

  const onSubmit = async (data: RetailerDetails) => {
    if (parcelTypes.length === 0) {
      toast.error('Please add at least 1 Parcel Type.');
      return;
    }
    if (reasonsforReturn.length === 0) {
      toast.error('Please add at least 1 Reason for Return.');
      return;
    }
    setIsLoading(true);
    const tempParcelType = parcelTypes.sort(compare);
    const tempCarriers = carriers.sort(compare);
    const tempReasonForReturn = reasonsforReturn.sort(compare);
    try {
      const {
        retailerName,
        city,
        country,
        postcode,
        status,
        address,
        retailerID,
        iconURL,
      } = data;

      if (isEdit()) {
        await retailerService.updateRetailerDetails({
          retailerId: retailerID,
          retailerName,
          city: city || '',
          country: country || '',
          postcode: postcode || '',
          status: status,
          addressLine1: address || '',
          parcelType: tempParcelType,
          returnReasons: tempReasonForReturn,
          iconURL,
        });
        setIsLoading(false);
        setParcelTypes(tempParcelType);
        setCarriers(tempCarriers);
        setReasonsforReturn(tempReasonForReturn);
        toast.success('Item details updated successfully', {duration: 3000});
      } else {
        setIsLoading(true);
        await retailerService.add({
          retailerName,
          city: city || '',
          country: country || '',
          postcode: postcode || '',
          status: status,
          addressLine1: address || '',
          parcelType: tempParcelType,
          returnReasons: tempReasonForReturn,
          carriers: tempCarriers.map(carrier => carrier.id),
          iconURL,
        });
        setIsLoading(false);
        resetForm();
        toast.success('Retailer details added successfully', {duration: 3000});
      }

      // setUpdating(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      // setUpdating(false);
    }
  };

  const updateRetailer = async (
    reasonForReturnArray: string[],
    parcelTypeArray: RetailerParcelType[]
  ) => {
    const tempReasonForReturn = reasonForReturnArray.sort(compare);
    const tempParcelType = parcelTypeArray.sort(compare);
    const {
      retailerName,
      city,
      country,
      postcode,
      status,
      address,
      retailerID,
      iconURL,
    } = getValues();
    if (!retailerID) return;

    try {
      setIsLoading(true);
      await retailerService.updateRetailerDetails({
        retailerId: retailerID,
        retailerName,
        city: city || '',
        country: country || '',
        postcode: postcode || '',
        status: status,
        addressLine1: address || '',
        parcelType: tempParcelType,
        returnReasons: tempReasonForReturn,
        iconURL,
      });

      toast.success('Item details updated successfully', {duration: 3000});
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const editReasonforReturn = async (selectedValue: string) => {
    setReasonforReturnValue('reasonforReturn', selectedValue);
    setSelectedReasonforReturn(selectedValue);
    setShowReasonForReturnPopup(true);
  };

  const editparcelType = async (selectedValue: RetailerParcelType) => {
    setParcelTypeValue('parcelType', selectedValue.title);
    setParcelTypeValue('parcelDescription', selectedValue?.description || '');
    setSelectedParcelType(selectedValue);
    setShowParcelTypePopup(true);
  };

  const toggleDeleteConfirmationPopup = (
    type: string,
    selectedValue: string | RetailerParcelType,
    show: boolean
  ) => {
    setShowDeleteConfirmationPopup(show);
    setDeleteConfirmationContent({title: type, item: selectedValue});
  };

  const handleDeleteCarrier = async (id: string) => {
    if (isEdit()) {
      setIsLoading(true);
      await retailerService.deleteCarrierRetailer(retailerId, id);
      setIsLoading(false);
      toast.success('Carrier deleted successfully.', {duration: 3000});
    }
    const filterCarriers = carriers.filter(carrier => carrier.id !== id);
    setCarriers(filterCarriers);
    setShowDeleteConfirmationPopup(false);
  };

  const deleteConfirmationPopup = () => {
    return (
      <>
        <Modal
          open={showDeleteConfirmationPopup}
          className={classes.popupWrapper}
        >
          <form className={classes.modalForm}>
            <Grid
              container
              className={classes.formContainerWhiteBackground}
              spacing={2}
            >
              <Grid item xs={9} component={'h3'}>
                {deleteConfirmationContent.title === 'reasonforReturn'
                  ? 'Delete Reason for Return'
                  : deleteConfirmationContent.title === 'carrier'
                  ? 'Delete carrier'
                  : 'Delete Parcel Type'}
              </Grid>
              <Grid
                container
                xs={3}
                spacing={2}
                alignItems="center"
                justify="flex-end"
              >
                <Grid item>
                  <Button
                    variant="outlined"
                    color="inherit"
                    fullWidth
                    minWidth="0px"
                    onClick={() => setShowDeleteConfirmationPopup(false)}
                    className={classes.cancelButton}
                  >
                    No
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    minWidth="0px"
                    fullWidth
                    onClick={() =>
                      deleteConfirmationContent.title === 'reasonforReturn'
                        ? removeItemfromArray(
                            reasonsforReturn,
                            deleteConfirmationContent.item as string,
                            'reasonforReturn'
                          )
                        : deleteConfirmationContent.title === 'carrier'
                        ? handleDeleteCarrier(
                            deleteConfirmationContent.item as string
                          )
                        : removeParcelTypefromArray(
                            parcelTypes,
                            deleteConfirmationContent.item as RetailerParcelType
                          )
                    }
                  >
                    Yes
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              className={classes.filterOptionContainer}
              spacing={2}
            >
              <Grid item xs={12} className={classes.formElement}>
                {`Are you sure you want to delete the  ${
                  deleteConfirmationContent.title === 'reasonforReturn'
                    ? 'reason for return'
                    : deleteConfirmationContent.title === 'carrier'
                    ? 'carrier'
                    : 'parcel type'
                }?`}
              </Grid>
            </Grid>
          </form>
        </Modal>
      </>
    );
  };

  const inActiveStatusConfirmation = () => {
    setInActiveConfirmationPopup(false);
    setValue('status', 'active');
  };

  const inActiveStatusConfirmationPopup = () => {
    return (
      <>
        <Modal
          open={inActiveConfirmationPopup}
          className={classes.popupWrapper}
        >
          <form className={classes.modalForm}>
            <Grid
              container
              className={classes.formContainerWhiteBackground}
              spacing={2}
            >
              <Grid item xs={9} component={'h3'}>
                Revoke confirmation
              </Grid>
              <Grid
                container
                xs={3}
                spacing={2}
                alignItems="center"
                justify="flex-end"
              >
                <Grid item>
                  <Button
                    variant="outlined"
                    color="inherit"
                    fullWidth
                    minWidth="0px"
                    onClick={() => inActiveStatusConfirmation()}
                    className={classes.cancelButton}
                  >
                    No
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    minWidth="0px"
                    fullWidth
                    onClick={() => setInActiveConfirmationPopup(false)}
                  >
                    Yes
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              className={classes.filterOptionContainer}
              spacing={2}
            >
              <Grid item xs={12} className={classes.formElement}>
                Are you sure you want to make the retailer inactive/active?
              </Grid>
            </Grid>
          </form>
        </Modal>
      </>
    );
  };

  const addItemToArray = (
    item: string,
    array: Array<string>,
    arrayName: string
  ) => {
    let alreadyExist = false;
    let reasonForReturnArray = reasonsforReturn;
    let parcelTypeArray = parcelTypes;
    if (arrayName === 'reasonsforReturn') {
      if (
        selectedReasonforReturn.length > 0 &&
        array.includes(selectedReasonforReturn)
      ) {
        let index = array.indexOf(selectedReasonforReturn);
        array[index] = item;
      } else {
        if (array.indexOf(item) > -1) {
          alreadyExist = true;
          toast.error('This reason for return already exists.');
        } else {
          alreadyExist = false;
          array.push(item);
        }
      }
      reasonForReturnArray = array as any;
    }
    if (!alreadyExist) {
      setShowParcelTypePopup(false);
      setShowReasonForReturnPopup(false);
      resetParcelType();
      resetReasonforReturn();
      setSelectedParcelType(null);
      setSelectedReasonforReturn('');
      updateRetailer(reasonForReturnArray, parcelTypeArray);
    }
  };

  const addParcelTypeToArray = (
    array: RetailerParcelType[],
    item: RetailerParcelType
  ) => {
    let alreadyExist = false;
    if (
      selectedParcelType &&
      array.find(a => a.title === selectedParcelType.title)
    ) {
      let index = array.indexOf(selectedParcelType);
      array[index] = item;
    } else {
      if (array.indexOf(item) > -1) {
        alreadyExist = true;
        toast.error('This parcel type already exists.');
      } else {
        alreadyExist = false;
        array.push(item);
      }
    }
    if (!alreadyExist) {
      setShowParcelTypePopup(false);
      setShowReasonForReturnPopup(false);
      resetParcelType();
      resetReasonforReturn();
      setSelectedParcelType(null);
      setSelectedReasonforReturn('');
      updateRetailer(reasonsforReturn, array);
    }
  };

  const removeItemfromArray = (
    array: Array<string>,
    item: string,
    type: 'reasonforReturn'
  ) => {
    const index = array.indexOf(item);
    array.splice(index, 1);
    setShowDeleteConfirmationPopup(false);
    const reasonForReturnArray = array;
    const parcelTypeArray = parcelTypes;
    updateRetailer(reasonForReturnArray, parcelTypeArray);
  };

  const removeParcelTypefromArray = (
    array: Array<RetailerParcelType>,
    item: RetailerParcelType
  ) => {
    const index = array.findIndex(a => a.title === item.title);
    array.splice(index, 1);
    setShowDeleteConfirmationPopup(false);
    const reasonForReturnArray = reasonsforReturn;
    const parcelTypeArray = array;
    updateRetailer(reasonForReturnArray, parcelTypeArray);
  };

  const closePopup = () => {
    setShowParcelTypePopup(false);
    setShowCarrierPopup(false);
    setShowReasonForReturnPopup(false);
    resetParcelType();
    resetReasonforReturn();
    setSelectedParcelType(null);
    setSelectedReasonforReturn('');
    setSelectedCarriers([]);
  };

  const handleRemoveTag = (option: any) => {
    const updatedSelectedCarriers = selectedCarriers.filter(
      carrier => carrier.id !== option.id
    );
    setSelectedCarriers(updatedSelectedCarriers);

    const updatedSelectedCarrierIds = selectedCarrierIds.filter(
      id => id !== option.id
    );
    setSelectedCarrierIds(updatedSelectedCarrierIds);
  };

  const toggleParcelTypePopUp = () => {
    return (
      <>
        <Modal open={showParcelTypePopup} className={classes.popupWrapper}>
          <form
            className={classes.modalForm}
            onSubmit={handleParcelTypeSubmit(() =>
              addParcelTypeToArray(parcelTypes, {
                title: getParcelTypeValue('parcelType').trim(),
                description: getParcelTypeValue('parcelDescription').trim(),
              })
            )}
          >
            <Grid
              container
              className={classes.formContainerWhiteBackground}
              spacing={2}
            >
              <Grid item xs={9} component={'h3'}>
                {selectedParcelType ? 'Edit Parcel Type' : 'Add Parcel Type'}
              </Grid>
              <Grid
                container
                xs={3}
                spacing={2}
                alignItems="center"
                justify="flex-end"
              >
                <Grid item>
                  <Button
                    variant="outlined"
                    color="inherit"
                    fullWidth
                    minWidth="0px"
                    onClick={() => closePopup()}
                    className={classes.cancelButton}
                  >
                    Close
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    minWidth="0px"
                    fullWidth
                    type="submit"
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              className={classes.filterOptionContainer}
              spacing={2}
            >
              <Grid item xs={12} className={classes.formElement}>
                <Typography className={classes.formLabel}>
                  {selectedParcelType ? 'Parcel Type' : 'Parcel Type'}
                </Typography>
                <Controller
                  name="parcelType"
                  control={parcelTypeControl}
                  render={({field: {value, onChange}, fieldState: {error}}) => (
                    <>
                      <TextField
                        variant="outlined"
                        type="text"
                        placeholder="Add Parcel Type"
                        fullWidth
                        margin="dense"
                        value={value}
                        onChange={onChange}
                        // onChange={e => {
                        //   if (!/^[A-Z0-9 ]*$/.test(e.target.value)) return;
                        //   onChange(e);
                        // }}
                        error={!!error}
                        helperText={error?.message}
                      />
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={12} className={classes.formElement}>
                <Typography className={classes.formLabel}>
                  {selectedParcelType ? 'Description' : 'Description'}
                </Typography>
                <Controller
                  name="parcelDescription"
                  control={parcelTypeControl}
                  render={({field: {value, onChange}, fieldState: {error}}) => (
                    <>
                      <TextField
                        variant="outlined"
                        type="text"
                        placeholder="Add Parcel Description"
                        fullWidth
                        margin="dense"
                        value={value}
                        onChange={onChange}
                        error={!!error}
                        helperText={error?.message}
                      />
                    </>
                  )}
                />
              </Grid>
            </Grid>
          </form>
        </Modal>
      </>
    );
  };

  const toggleReasonforReturnPopUp = () => {
    return (
      <>
        <Modal open={showReasonForReturnPopup} className={classes.popupWrapper}>
          <form
            className={classes.modalForm}
            onSubmit={handleReasonforReturnSubmit(() =>
              addItemToArray(
                getReasonforReturnValue('reasonforReturn').trim(),
                reasonsforReturn,
                'reasonsforReturn'
              )
            )}
          >
            <Grid
              container
              className={classes.formContainerWhiteBackground}
              spacing={2}
            >
              <Grid item xs={9} component={'h3'}>
                {selectedReasonforReturn
                  ? 'Edit Reason for Return'
                  : 'Add Reason for Return'}
              </Grid>
              <Grid
                container
                xs={3}
                spacing={2}
                alignItems="center"
                justify="flex-end"
              >
                <Grid item>
                  <Button
                    variant="outlined"
                    color="inherit"
                    fullWidth
                    minWidth="0px"
                    onClick={() => closePopup()}
                    className={classes.cancelButton}
                  >
                    Close
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    minWidth="0px"
                    fullWidth
                    type="submit"
                  >
                    {'Save'}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              className={classes.filterOptionContainer}
              spacing={2}
            >
              <Grid item xs={12} className={classes.formElement}>
                <Typography className={classes.formLabel}>
                  {selectedReasonforReturn
                    ? 'Reason for Return'
                    : 'Reason for Return'}
                </Typography>
                <Controller
                  name="reasonforReturn"
                  control={reasonforReturnControl}
                  render={({field: {value, onChange}, fieldState: {error}}) => (
                    <>
                      <TextField
                        variant="outlined"
                        type="text"
                        placeholder="Add Reason for Return"
                        fullWidth
                        margin="dense"
                        value={value}
                        onChange={onChange}
                        // onChange={e => {
                        //   if (!/^[A-Z0-9 ]*$/.test(e.target.value)) return;
                        //   onChange(e);
                        // }}
                        error={!!error}
                        helperText={error?.message}
                      />
                    </>
                  )}
                />
              </Grid>
            </Grid>
          </form>
        </Modal>
      </>
    );
  };

  const handleAddCarrier = () => {
    setShowCarrierPopup(true);
  };

  const toggleCarrierPopUp = () => {
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault(); // Prevent the default form submission behavior
      setIsLoading(true);
      try {
        if (isEdit()) {
          setIsLoading(true);
          await retailerService.addCarrierRetailer(
            retailerId,
            selectedCarrierIds
          );
          setIsLoading(false);
          refetch();
          closePopup();
          setSelectedCarrierIds([]);
          toast.success('Carrier added successfully', {duration: 3000});
        } else {
          if (!!selectedCarriers) {
            setCarriers(prev => {
              return prev.concat(selectedCarriers).map(carrier => ({
                id: carrier.id,
                name: carrier.name,
              }));
            });
          }
          setSelectedCarrierIds([]);
        }
      } finally {
        closePopup();
        setIsLoading(false);
      }
    };
    return (
      <>
        <Modal open={showCarrierPopup} className={classes.carrierPopupWrapper}>
          <form className={classes.modalForm} onSubmit={handleSubmit}>
            <Grid
              container
              className={classes.formContainerWhiteBackground}
              spacing={2}
            >
              <Grid item xs={3} component={'h3'}>
                {'Add carrier'}
              </Grid>
              <Grid
                container
                xs={9}
                spacing={2}
                alignItems="center"
                justify="flex-end"
              >
                <Grid item>
                  <Button
                    variant="outlined"
                    color="inherit"
                    fullWidth
                    minWidth="0px"
                    onClick={() => closePopup()}
                    className={classes.cancelButton}
                  >
                    Close
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    minWidth="0px"
                    fullWidth
                    type="submit"
                  >
                    {'Save'}
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              className={classes.filterOptionContainer}
              spacing={2}
            >
              <Grid
                item
                className={classes.formElement}
                style={{minWidth: '232px', width: '100%'}}
              >
                <Grid item className={classes.formElement}>
                  <Typography className={classes.formLabel}>
                    Carrier Name
                  </Typography>
                  <Autocomplete
                    multiple
                    placeholder="carriers"
                    limitTags={2}
                    options={sort(carriersData?.carriers ?? [], 'name').filter(
                      option =>
                        !carriers.some(
                          existingCarrier => existingCarrier.id === option.id
                        )
                    )}
                    getOptionLabel={option =>
                      option.client
                        ? `Client - ${option.name}`
                        : `${option.name}`
                    }
                    value={selectedCarriers}
                    disableClearable
                    onChange={(_, newValue) => {
                      setSelectedCarriers(newValue);
                      setSelectedCarrierIds(prevDefaultCarrierId => {
                        const newIds = newValue.map(item => item.id);
                        const uniqueIds = Array.from(
                          new Set([...prevDefaultCarrierId, ...newIds])
                        );
                        return uniqueIds;
                      });
                    }}
                    renderInput={params => (
                      <TextField {...params} variant="outlined" />
                    )}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <div
                          key={option.id}
                          style={{
                            backgroundColor: '#36a6ff',
                            borderRadius: '4px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            position: 'relative',
                            color: 'white',
                            margin: '2px',
                            padding: '3px 8px',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          <span style={{color: 'white'}}>{option.name}</span>
                          <IconButton
                            size="small"
                            onClick={() => handleRemoveTag(option)}
                            style={{
                              color: 'white',
                              marginLeft: '4px',
                              paddingRight: '0',
                            }}
                          >
                            <X
                              style={{
                                width: '18px',
                                height: '18px',
                                fill: '#FFFFFF',
                              }}
                            />
                          </IconButton>
                        </div>
                      ))
                    }
                    classes={{
                      paper: classes.dropdownPaper,
                      root: classes.autocomplete,
                      popupIndicator: classes.hiddenClearIcon,
                    }}
                    css={`
                      .MuiAutocomplete-tagLabel {
                        color: white !important;
                      }
                    `}
                  />
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Modal>
      </>
    );
  };

  return (
    <div>
      {(isLoading || isFetching) && <ScreenSpinner />}
      <Typography component={'h1'} className={classes.title}>
        {isEdit() ? 'Retailer Details' : 'Add Retailer'}
      </Typography>
      <Breadcrumbs className={classes.breadcrumb} aria-label="breadcrumb">
        <Typography className={classes.breadcrumbText}>Configure</Typography>
        <Link className={classes.breadcrumbLink} to="/retailer">
          Retailers
        </Link>
        <Typography className={classes.breadcrumbText}>
          {isEdit() ? retailerId : 'Add'}
        </Typography>
      </Breadcrumbs>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container className={classes.formContainer} spacing={2}>
          <Grid item xs={9}></Grid>
          <Grid
            container
            xs={3}
            spacing={2}
            alignItems="center"
            justify="flex-end"
          >
            <Grid item>
              <Button
                variant="outlined"
                color="inherit"
                fullWidth
                minWidth="0px"
                onClick={() => history.goBack()}
                className={classes.cancelButton}
              >
                Close
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                minWidth="0px"
                fullWidth
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={classes.filterOptionContainer} spacing={2}>
          <Grid item xs={3} className={classes.formElement}>
            <Typography className={classes.formLabel}>Retailer Name</Typography>
            <Controller
              name="retailerName"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <>
                  <TextField
                    variant="outlined"
                    type="text"
                    placeholder="Add Name"
                    fullWidth
                    margin="dense"
                    value={value}
                    onChange={e => {
                      if (!/^[a-zA-Z0-9\w -]*$/.test(e.target.value)) return;
                      onChange(e);
                    }}
                    error={!!error}
                    helperText={error?.message}
                  />
                </>
              )}
            />
          </Grid>
          <Grid item xs={3} className={classes.formElement}>
            <Typography className={classes.formLabel}>Icon URL</Typography>
            <Controller
              name="iconURL"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <>
                  <TextField
                    variant="outlined"
                    type="text"
                    placeholder="Add Link"
                    fullWidth
                    margin="dense"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error?.message}
                  />
                </>
              )}
            />
          </Grid>
          {isEdit() && (
            <Grid item xs={3} className={classes.formElement}>
              <Typography className={classes.formLabelDisabled}>
                Retailer ID
              </Typography>
              <Controller
                name="retailerID"
                control={control}
                render={({field: {value, onChange}, fieldState: {error}}) => (
                  <>
                    <TextField
                      variant="outlined"
                      type="text"
                      placeholder="Add Number"
                      fullWidth
                      margin="dense"
                      value={value}
                      disabled={isEdit()}
                      onChange={onChange}
                      error={!!error}
                      helperText={error?.message}
                    />
                  </>
                )}
              />
            </Grid>
          )}
          <Grid item xs={3} className={classes.formElement}>
            <Typography className={classes.formLabel}>Country</Typography>
            <Controller
              name="country"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <Select
                  value={value}
                  onChange={onChange}
                  displayEmpty
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  className={classes.select}
                >
                  {countryOptions.map(country => (
                    <MenuItem value={country.value} key={country.key}>
                      {country.text}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Grid>
          <Grid item xs={3} className={classes.formElement}>
            <Typography className={classes.formLabel}>City</Typography>
            <Controller
              name="city"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <>
                  <TextField
                    variant="outlined"
                    type="text"
                    placeholder="Add City"
                    fullWidth
                    margin="dense"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error?.message}
                  />
                </>
              )}
            />
          </Grid>
          <Grid item xs={3} className={classes.formElement}>
            <Typography className={classes.formLabel}>Postcode</Typography>
            <Controller
              name="postcode"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <>
                  <TextField
                    variant="outlined"
                    type="text"
                    placeholder="Add Postcode"
                    fullWidth
                    margin="dense"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error?.message}
                  />
                </>
              )}
            />
          </Grid>
          <Grid item xs={3} className={classes.formElement}>
            <Typography className={classes.formLabel}>
              Address Line 1
            </Typography>
            <Controller
              name="address"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <>
                  <TextField
                    variant="outlined"
                    type="text"
                    placeholder="Add Address"
                    fullWidth
                    margin="dense"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error?.message}
                  />
                </>
              )}
            />
          </Grid>
          <Grid item xs={3} className={classes.formElement}>
            <Typography className={classes.formLabel}>Status</Typography>
            <Controller
              name="status"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <Select
                  value={value}
                  onChange={event => {
                    event.target.value === 'inactive' &&
                      setInActiveConfirmationPopup(true);
                    onChange(event);
                  }}
                  displayEmpty
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  className={classes.select}
                >
                  {statusOptions.map(status => (
                    <MenuItem value={status.value} key={status.key}>
                      {status.text}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Grid>
          {isEdit() && (
            <Grid item xs={3} className={classes.formElement}>
              <Typography className={classes.formLabelDisabled}>
                Last Updated On
              </Typography>
              <Controller
                name="lastupdatedOn"
                control={control}
                render={({field: {value, onChange}, fieldState: {error}}) => (
                  <>
                    <TextField
                      variant="outlined"
                      type="text"
                      placeholder=""
                      fullWidth
                      margin="dense"
                      disabled
                      value={value && formatDate(value)}
                      onChange={onChange}
                      // onChange={e => {
                      //   if (!/^[A-Z0-9 ]*$/.test(e.target.value)) return;
                      //   onChange(e);
                      // }}
                      InputProps={{
                        endAdornment: <DaterangeIcon />,
                      }}
                      error={!!error}
                      helperText={error?.message}
                    />
                  </>
                )}
              />
            </Grid>
          )}
          {isEdit() && (
            <Grid item xs={3} className={classes.formElement}>
              <Typography className={classes.formLabelDisabled}>
                Last Updated By
              </Typography>
              <Controller
                name="lastupdatedBy"
                control={control}
                render={({field: {value, onChange}, fieldState: {error}}) => (
                  <>
                    <TextField
                      variant="outlined"
                      type="text"
                      placeholder=""
                      fullWidth
                      margin="dense"
                      value={value}
                      disabled
                      onChange={onChange}
                      // onChange={e => {
                      //   if (!/^[A-Z0-9 ]*$/.test(e.target.value)) return;
                      //   onChange(e);
                      // }}
                      error={!!error}
                      helperText={error?.message}
                    />
                  </>
                )}
              />
            </Grid>
          )}
          <Grid item xs={3} className={classes.formElement}>
            <Typography className={classes.formLabelDisabled}>
              Created On
            </Typography>
            <Controller
              name="createdOn"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <>
                  <TextField
                    variant="outlined"
                    type="text"
                    placeholder=""
                    fullWidth
                    margin="dense"
                    value={
                      isEdit()
                        ? formatDate(value)
                        : new Date(Date.now()).toLocaleString().split(',')[0]
                    }
                    disabled
                    onChange={onChange}
                    InputProps={{
                      endAdornment: <DaterangeIcon />,
                    }}
                    error={!!error}
                    helperText={error?.message}
                  />
                </>
              )}
            />
          </Grid>
        </Grid>

        <Grid
          container
          className={classes.formContainerWhiteBackground}
          spacing={2}
        >
          <Grid item xs={9} component={'h2'}>
            {'Parcel Type'}
          </Grid>
          <Grid
            container
            xs={3}
            spacing={2}
            alignItems="center"
            justify="flex-end"
          >
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                minWidth="0px"
                fullWidth
                onClick={() => setShowParcelTypePopup(true)}
              >
                Add Parcel Type
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          className={clsx(classes.filterOptionContainer, classes.listWrapper)}
          spacing={2}
        >
          {parcelTypes.length > 0 ? (
            parcelTypes.map(item => (
              <Grid item xs={3}>
                <div className={classes.editableItemInner}>
                  <Typography className={classes.itemTitle}>
                    {item.title}
                  </Typography>
                  <Typography className={classes.itemTitle}>
                    {item.description}
                  </Typography>
                  <div className={classes.actionButtonWrapper}>
                    <IconButton
                      className={classes.iconButton}
                      onClick={() => editparcelType(item)}
                    >
                      <Edit3 />
                    </IconButton>
                    <IconButton
                      className={classes.iconButton}
                      disabled={parcelTypes.length === 1}
                      onClick={() =>
                        toggleDeleteConfirmationPopup('parcelType', item, true)
                      }
                    >
                      <Trash2 />
                    </IconButton>
                  </div>
                </div>
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <Typography className={classes.noLocationText}>
                Add Parcel Type by clicking <span>“Add Parcel Type”</span>{' '}
                button
              </Typography>
            </Grid>
          )}
        </Grid>

        <Grid
          container
          className={classes.formContainerWhiteBackground}
          spacing={2}
        >
          <Grid item xs={9} component={'h2'}>
            {'Reason for Return'}
          </Grid>
          <Grid
            container
            xs={3}
            spacing={2}
            alignItems="center"
            justify="flex-end"
          >
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                minWidth="0px"
                fullWidth
                onClick={() => setShowReasonForReturnPopup(true)}
              >
                Add Reason for Return
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          className={clsx(classes.filterOptionContainer, classes.listWrapper)}
          spacing={2}
        >
          {reasonsforReturn.length > 0 ? (
            reasonsforReturn.map(item => (
              <Grid item xs={3}>
                <div className={classes.editableItemInner}>
                  <Typography className={classes.item}>{item}</Typography>
                  <div className={classes.actionButtonWrapper}>
                    <IconButton
                      className={classes.iconButton}
                      onClick={() => editReasonforReturn(item)}
                    >
                      <Edit3 />
                    </IconButton>
                    <IconButton
                      className={classes.iconButton}
                      disabled={reasonsforReturn.length === 1}
                      onClick={() =>
                        toggleDeleteConfirmationPopup(
                          'reasonforReturn',
                          item,
                          true
                        )
                      }
                    >
                      <Trash2 />
                    </IconButton>
                  </div>
                </div>
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <Typography className={classes.noLocationText}>
                Add Reason for Return by clicking{' '}
                <span>"Add Reason for Return"</span> button
              </Typography>
            </Grid>
          )}
        </Grid>

        <Grid
          container
          className={classes.formContainerWhiteBackground}
          spacing={2}
        >
          <Grid item xs={9} component={'h2'}>
            {'Carrier'}
          </Grid>
          <Grid
            container
            xs={3}
            spacing={2}
            alignItems="center"
            justify="flex-end"
          >
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                minWidth="0px"
                fullWidth
                onClick={() => handleAddCarrier()}
              >
                Add Carrier
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          className={clsx(classes.filterOptionContainer, classes.listWrapper)}
          spacing={2}
        >
          {carriers.length > 0 ? (
            carriers.map(item => (
              <Grid item xs={3}>
                <div className={classes.editableItemInner}>
                  <Typography
                    className={clsx(classes.item, classes.carrierItem)}
                  >
                    {(item.clientFlag ? 'Client - ' : '') + item.name}
                  </Typography>
                  <div className={classes.actionButtonWrapper}>
                    <IconButton
                      className={classes.iconButton}
                      onClick={() =>
                        toggleDeleteConfirmationPopup('carrier', item.id, true)
                      }
                    >
                      <Trash2 />
                    </IconButton>
                  </div>
                </div>
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <Typography className={classes.noLocationText}>
                Add carriers by clicking <span>"Add Carrier"</span> button
              </Typography>
            </Grid>
          )}
        </Grid>
      </form>
      {toggleParcelTypePopUp()}
      {toggleReasonforReturnPopUp()}
      {toggleCarrierPopUp()}
      {deleteConfirmationPopup()}
      {inActiveStatusConfirmationPopup()}
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 26,
    lineHeight: '40px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
      lineHeight: '28px',
    },
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(3),
  },
  button: {
    marginBottom: theme.spacing(3),
  },
  hiddenClearIcon: {
    display: 'none',
  },
  dropdownPaper: {
    width: '232px',
    border: '1px solid #E0E0E0',
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 4px 4px 0px rgba(0,0,0,0.25)', // Drop shadow
    '& .MuiAutocomplete-listbox': {
      maxHeight: '250px',
    },
  },
  autocomplete: {
    width: '100%',
    '& .MuiAutocomplete-inputRoot': {
      minWidth: '232px',
      paddingTop: '4px !important',
      paddingBottom: '4px !important',
      paddingLeft: '4px !important',
    },
  },

  formContainer: {
    width: '100%',
    borderRadius: '5px 5px 0 0',
    padding: '12px 16px 15px',
    margin: 0,
    border: '1px solid',
    borderBottom: '0px',
    borderColor: theme.palette.grey[300],
  },
  formContainerWhiteBackground: {
    width: '100%',
    background: '#fff',
    borderRadius: '5px 5px 0 0',
    padding: '12px 16px 15px',
    margin: 0,
    border: '1px solid',
    borderBottom: '0px',
    borderColor: theme.palette.grey[300],
  },
  filterOptionContainer: {
    width: '100%',
    background: '#fff',
    borderRadius: '0 0 5px 5px',
    padding: '12px 16px 15px',
    margin: 0,
    marginBottom: theme.spacing(3),
    border: '1px solid',
    borderColor: theme.palette.grey[300],
  },

  listWrapper: {
    maxHeight: '250px',
    overflowX: 'hidden',
    overflowY: 'auto',
  },

  filterText: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '21px',
    lineHeight: '32px',
  },

  formElement: {},
  formLabel: {
    fontSize: 16,
    lineHeight: '28px',
  },
  formLabelDisabled: {
    color: '#9E9E9E',
    fontSize: 16,
    lineHeight: '28px',
  },

  hash: {
    backgroundColor: theme.palette.grey[400],
  },
  adornmentTextField: {
    width: '100%',
    '& span': {
      backgroundColor: theme.palette.grey[400],
    },
  },
  select: {
    marginTop: '8px',
    marginBottom: '4px',
  },
  datePicker: {
    backgroundColor: 'pink',
    '&& .MuiPickersDay-root.Mui-selected': {
      backgroundColor: 'red !important',
      '&:hover': {
        backgroundColor: 'red !important',
      },
      '&:focus': {
        backgroundColor: 'red !important',
      },
    },
  },
  textFieldSkeleton: {
    width: '100%',
    height: 40,
    borderRadius: 4,
    marginTop: 8,
    marginBottom: 4,
  },
  tableContainer: {
    marginTop: theme.spacing(3),
  },
  name: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.primary.main,
  },
  dashboardText: {
    fontSize: 14,
    lineHeight: '20px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      lineHeight: '14px',
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginBottom: theme.spacing(1),
  },
  subjectText: {
    fontSize: 21,
    lineHeight: '32px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
      lineHeight: '24px',
    },
    fontWeight: theme.typography.fontWeightBold,
  },
  subjectInput: {
    margin: 0,
  },
  cancelButton: {
    color: theme.palette.grey[500],
  },
  editButton: {
    minWidth: '0px',
    width: 'auto',
    padding: 9,
    fontSize: 18,
    backgroundColor: theme.palette.primary.light,
    '&:hover': {
      backgroundColor: darken(theme.palette.primary.light, 0.1),
    },
  },
  updateButton: {
    minWidth: '0px',
    width: 'auto',
  },
  toolbar: {
    borderRadius: '5px',
  },
  editorError: {
    fontSize: '0.75rem',
    color: theme.palette.error.main,
    margin: '4px 14px 0px',
  },
  content: {
    backgroundColor: '#fff',
    height: 'calc(100vh - 220px)',
    minHeight: '300px',
    padding: 30,
  },
  breadcrumb: {
    marginBottom: theme.spacing(10),
  },
  breadcrumbLink: {
    color: 'inherit',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  breadcrumbText: {
    color: 'inherit',
    fontSize: 'inherit',
  },
  editableItemInner: {
    background: '#F5F5F5',
    borderRadius: '5px',
    position: 'relative',
    minHeight: '57px',
    padding: '7px 81px 7px 19px !important',
  },
  actionButtonWrapper: {
    position: 'absolute',
    right: '9px',
    top: '16px',
    '& button': {
      padding: '6px',
      width: '26px',
      height: '26px',
      background: '#fff',
      marginRight: '9px',
      '&:last-child': {
        marginRight: '0',
      },
    },
    '& svg': {
      width: '20px',
      height: '20px',
    },
  },
  itemTitle: {
    fontSize: '14px',
    lineHeight: '22px',
    color: '#212121',
    'white-space': 'nowrap',
    overflow: 'hidden',
    'text-overflow': 'ellipsis',
  },
  item: {
    lineHeight: '22px',
    color: '#212121',
    'white-space': 'nowrap',
    overflow: 'hidden',
    'text-overflow': 'ellipsis',
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    padding: '18px 81px 18px 19px',
  },
  popupWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& form': {
      maxWidth: '830px',
      padding: '0 15px',
      margin: '0 auto',
      flex: 'auto',
    },
  },
  modalForm: {
    outline: 'none',
    boxShadow: 'none',
  },
  iconButton: {
    border: '1px solid #757575',
  },
  noLocationText: {
    textAlign: 'center',
    fontSize: '21px',
    margin: '13.25px 0px',
    '& span': {
      fontWeight: theme.typography.fontWeightBold,
    },
  },
  carrierPopupWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& form': {
      maxWidth: '460px',
      minHeight: '221px',
      padding: '0 15px',
      margin: '0 auto',
      flex: 'auto',
    },
  },
  editableCarrierInner: {
    position: 'relative',
    minHeight: '57px',
    padding: '7px 81px 7px 19px',
  },
  carrierItem: {
    paddingRight: '45px',
  },
}));

export default Retailer;
