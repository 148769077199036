import * as React from 'react';
import {makeStyles, darken} from '@material-ui/core';
import {useQuery} from 'react-query';
import {toast} from 'react-hot-toast';
import {useForm, Controller} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  addDays,
  format,
  parseISO,
  subDays,
  differenceInMilliseconds,
  isValid,
} from 'date-fns';
import * as XLSX from 'xlsx';

import DataGrid from 'components/DataGrid';
import {transactionService} from 'services/transactionService';
import {retailerService} from 'services/retailerService';
import {
  ERROR_MESSAGE,
  FILE_EXPORT_SUCCESS,
  requiredFieldMessage,
} from 'utils/messages';
import {useAuth} from 'hooks/useAuth';
import {startOfDayTimestamp, getDate} from 'utils/Date';
import {currency} from 'utils/currency';
import {DATE_FORMAT} from 'utils/regex';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {
  Typography,
  Grid,
  TextField,
  FormControlLabel,
  Radio,
  RadioGroup,
  Select,
  MenuItem,
} from '@material-ui/core';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import {Skeleton} from '@material-ui/lab';

import Button from 'components/Button';
import {Retailer} from 'types';
import {useCountryOptions} from 'hooks/useCountryOptions';
import {useCarrierOptions} from 'hooks/useCarrierOptions';

type CodeType = 'sendParcel' | 'returnParcel';
type FilterInputs = {
  codeType: CodeType;
  country: string;
  city: string;
  postcode: string;
  carrier: string;
  retailer: string;
  createdFrom: Date;
  createdTo: Date;
};

// Add method to handle empty string
yup.addMethod(yup.string, 'stripEmptyString', function () {
  return this.transform(value => (value === '' ? undefined : value));
});

const filterSchema = yup.object().shape({
  codeType: yup.string().required(requiredFieldMessage('Code Type')),
  carrier: yup.string().when(['codeType'], {
    is: (codeType: CodeType) => codeType === 'sendParcel',
    then: yup.string().required(requiredFieldMessage('Carrier')),
  }),
  retailer: yup.string().when(['codeType'], {
    is: (codeType: CodeType) => codeType === 'returnParcel',
    then: yup.string().required(requiredFieldMessage('Retailer')),
  }),
  country: yup.string(),
  city: yup.string(),

  postcode: yup
    .string()
    .notRequired()
    .max(15, 'Maximum 15 characters are allowed'),

  createdFrom: yup
    .date()
    .typeError('Invalid date')
    .test('createdFromDateInvalid', 'Invalid date', function () {
      // validate Date
      const yupContext = this as any;
      if (typeof yupContext.originalValue === 'string') {
        return DATE_FORMAT.test(yupContext.originalValue);
      }
      return true;
    })
    .test(
      'createdToMin',
      'Created From should not be a future date',
      function () {
        // Min date validation
        if (isValid(this.parent.createdFrom)) {
          return (
            differenceInMilliseconds(new Date(), this.parent.createdFrom) >= 0
          );
        }
        return true;
      }
    ),

  createdTo: yup
    .date()
    .typeError('Invalid date')
    .test('createdFromDateInvalid', 'Invalid date', function () {
      // validate Date
      const yupContext = this as any;
      if (typeof yupContext.originalValue === 'string') {
        return DATE_FORMAT.test(yupContext.originalValue);
      }
      return true;
    })
    .test(
      'createdToMin',
      'Created To should not be a future date',
      function () {
        // Min date validation
        if (isValid(this.parent.createdTo)) {
          return (
            differenceInMilliseconds(new Date(), this.parent.createdTo) >= 0
          );
        }
        return true;
      }
    )
    .test(
      'createdToMin',
      'Created To cannot be before Created From',
      function () {
        // Min date validation
        if (isValid(this.parent.createdFrom)) {
          return (
            differenceInMilliseconds(
              this.parent.createdTo,
              this.parent.createdFrom
            ) >= 0
          );
        }
        return true;
      }
    ),
});

const formatPaymentDate = (row: any): string => {
  if (!row?.payment || !row.payment?.paymentMethod) {
    return 'NA';
  }
  let paymentDate: string | null | undefined = null;
  if (typeof row.payment.paymentMethod === 'string') {
    paymentDate = JSON.parse(row.payment.paymentMethod)?.paymentDate;
  } else {
    paymentDate = row.payment.paymentMethod?.paymentDate;
  }
  if (!paymentDate) {
    return 'NA';
  }

  return format(new Date(paymentDate), 'dd-MMM-yyyy');
};

const userRatingMap: Record<string, string> = {
  yes: 'liked',
  no: 'disliked',
};

const Transactions: React.FC = () => {
  const {authUser} = useAuth();

  const countryOptions = useCountryOptions();
  const {carrierOptions, carriersStatus} = useCarrierOptions();

  const {
    control,
    handleSubmit,
    reset,
    watch,
    getValues,
    setValue,
  } = useForm<FilterInputs>({
    resolver: yupResolver(filterSchema),
    defaultValues: {
      codeType: 'sendParcel',
      country: 'United Kingdom',
      city: '',
      postcode: '',
      carrier: '',
      retailer: '',
      createdFrom: subDays(new Date(startOfDayTimestamp(new Date())), 30),
      createdTo: new Date(startOfDayTimestamp(new Date())),
    },
  });

  const classes = useStyles();
  const [defaultCarrierId, setDefaultCarrierId] = React.useState('');
  const [defaultRetailerId, setDefaultRetailerId] = React.useState('');

  const {data, refetch, isFetching} = useQuery(
    ['transaction'],
    () => {
      return transactionService.get({
        codeType: getValues('codeType'),
        createdFrom: startOfDayTimestamp(getValues('createdFrom')),
        createdTo: startOfDayTimestamp(
          addDays(new Date(getValues('createdTo')), 1)
        ),
        carrier: getValues('carrier'),
        retailer: getValues('retailer'),
        country: getValues('country').trim(),
        postcode: getValues('postcode').trim(),
        city: getValues('city').trim(),
      });
    },
    {
      enabled:
        watch('codeType') === 'sendParcel'
          ? !!watch('carrier')
          : !!watch('retailer'),
      retry: false,
    }
  );

  const {data: retailersData, status: retailerStatus} = useQuery(
    ['retailers'],
    () => {
      return retailerService.getRetailers();
    },
    {retry: false}
  );

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    if (authUser?.attributes['custom:user_role'] === 'carrierAdmin') {
      setDefaultCarrierId(authUser?.attributes['custom:carrier_id'] || '');
      setValue('carrier', authUser?.attributes['custom:carrier_id'] || '');
    } else if (carrierOptions?.length > 0) {
      setDefaultCarrierId(carrierOptions[0]?.value);
      setValue('carrier', carrierOptions[0]?.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carrierOptions]);

  React.useEffect(() => {
    if (retailersData && retailersData?.length > 0) {
      const retailerId = retailersData[0].id;
      setDefaultRetailerId(retailerId);
      setValue('retailer', retailerId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retailersData]);

  const resetFilters = () => {
    reset({
      codeType: 'sendParcel',
      country: 'United Kingdom',
      city: '',
      postcode: '',
      createdFrom: subDays(new Date(startOfDayTimestamp(new Date())), 30),
      createdTo: new Date(startOfDayTimestamp(new Date())),
      carrier: defaultCarrierId,
      retailer: defaultRetailerId,
    });
  };

  const handleClearFilter = async (event: React.MouseEvent) => {
    resetFilters();
    refetch({
      throwOnError: true,
      cancelRefetch: true,
    });
  };

  const onSubmit = async (data: FilterInputs) => {
    refetch({
      throwOnError: true,
      cancelRefetch: true,
    });
  };

  const exportExcel = () => {
    try {
      if (!(data?.codeList?.length > 0)) return;
      //User ID, Digicode or Easycode, Destination Postcode, Carrier, Product type, Shipping Cost, Date created, User name
      let createXLSLFormatObj = [];
      const isEasycode = data?.codeList[0].retailer;
      /* XLS Head Columns */
      const xlsHeader = [
        'User SF ID',
        'Sampfree Code',
        'Destination Postcode',
        !isEasycode ? 'Carrier' : 'Retailer',
        'Product type',
        'Shipping Cost',
        'Date created',
        'User name',
        'Feedback',
      ];
      createXLSLFormatObj.push(xlsHeader);
      for (const code of data.codeList) {
        let innerRowData = [];
        innerRowData.push(`${code.consumerUser?.sfId}` || '');
        innerRowData.push(code.sfCode || '');
        innerRowData.push(
          (!isEasycode ? code.deliveryAddressPostcode : code.postcode) || ''
        );
        innerRowData.push(
          (!isEasycode ? code.carrier?.name : code.retailer?.name) || ''
        );
        innerRowData.push(
          (!isEasycode ? code.product?.name : code.parcelType) || ''
        );
        innerRowData.push(
          code.payment
            ? `${currency[code.payment?.currencyCode]?.symbol || ''} ${(code
                .payment?.currencyCode === 'KZT'
                ? code.payment?.amount
                : code.payment?.amount / 100
              ).toFixed(2)}`
            : 'NA'
        );
        innerRowData.push(
          code.createdAt
            ? format(parseISO(code.createdAt), 'dd-MMM-yyyy')
            : 'NA'
        );
        innerRowData.push(
          `${code.consumerUser?.firstName || ''} ${
            code.consumerUser?.lastName || ''
          }`
        );
        const userRating = code.shipment?.details?.userRating;
        innerRowData.push(userRatingMap[userRating] ?? 'NA');
        createXLSLFormatObj.push(innerRowData);
      }
      /* File Name */
      const fileName = `Transactions_${format(new Date(), 'dd-MMM-yyyy')}.xlsx`;
      /* Sheet Name */
      const wsName = fileName;

      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj);

      /* Add worksheet to workbook */
      XLSX.utils.book_append_sheet(wb, ws, wsName);

      /* Write workbook and Download */
      XLSX.writeFile(wb, fileName);
      toast.success(FILE_EXPORT_SUCCESS, {duration: 3000});
    } catch (error) {
      toast.error(ERROR_MESSAGE);
    }
  };

  return (
    <div>
      <Typography component={'h1'} className={classes.title}>
        Transactions
      </Typography>
      <Button
        variant="contained"
        color="primary"
        minWidth="0px"
        className={classes.exportButton}
        onClick={exportExcel}
        disabled={!(data?.codeList?.length > 0)}
      >
        Export Data
      </Button>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container className={classes.filterContainer} spacing={2}>
          <Grid item xs={9}>
            <Typography className={classes.filterText} component={'h2'}>
              Filter
            </Typography>
          </Grid>
          <Grid
            container
            xs={3}
            spacing={2}
            alignItems="center"
            justify="flex-end"
          >
            <Grid item>
              <Button
                variant="outlined"
                color="inherit"
                fullWidth
                minWidth="0px"
                onClick={handleClearFilter}
                className={classes.cancelButton}
              >
                Clear
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                minWidth="0px"
                fullWidth
              >
                {'Apply'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={classes.filterOptionContainer} spacing={2}>
          <Grid item xs={3} className={classes.formElement}>
            <Typography className={classes.formLabel}>
              {'Parcel Type'}
            </Typography>
            <Controller
              name="codeType"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={value}
                  onChange={onChange}
                >
                  <FormControlLabel
                    value="sendParcel"
                    control={<Radio color="primary" />}
                    label={`Sent Items${
                      value === 'sendParcel' ? ' (selected)' : ''
                    }`}
                  />
                  <FormControlLabel
                    value="returnParcel"
                    control={<Radio color="primary" />}
                    label={`Returned Items${
                      value === 'returnParcel' ? ' (selected)' : ''
                    }`}
                  />
                </RadioGroup>
              )}
            />
          </Grid>
          {watch('codeType') === 'sendParcel' && (
            <Grid item xs={3} className={classes.formElement}>
              <Typography className={classes.formLabel}>Carrier</Typography>
              {carriersStatus === 'loading' ? (
                <Skeleton
                  variant="rect"
                  className={classes.textFieldSkeleton}
                />
              ) : (
                <Controller
                  name="carrier"
                  control={control}
                  render={({field: {value, onChange}, fieldState: {error}}) => (
                    <Select
                      value={value}
                      onChange={onChange}
                      displayEmpty
                      fullWidth
                      margin="dense"
                      disabled={
                        authUser?.attributes['custom:user_role'] ===
                        'carrierAdmin'
                      }
                      variant="outlined"
                      className={classes.select}
                    >
                      {carrierOptions.map(carrier => (
                        <MenuItem value={carrier.value} key={carrier.key}>
                          {carrier.text}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              )}
            </Grid>
          )}
          {watch('codeType') === 'returnParcel' && (
            <Grid item xs={3} className={classes.formElement}>
              <Typography className={classes.formLabel}>Retailer</Typography>
              {retailerStatus === 'loading' ? (
                <Skeleton
                  variant="rect"
                  className={classes.textFieldSkeleton}
                />
              ) : (
                <Controller
                  name="retailer"
                  control={control}
                  render={({field: {value, onChange}, fieldState: {error}}) => (
                    <Select
                      value={value}
                      onChange={onChange}
                      displayEmpty
                      fullWidth
                      margin="dense"
                      variant="outlined"
                      className={classes.select}
                    >
                      {retailersData &&
                        retailersData.map((retailer: Retailer) => (
                          <MenuItem value={retailer.id} key={retailer.id}>
                            {retailer.name}
                          </MenuItem>
                        ))}
                    </Select>
                  )}
                />
              )}
            </Grid>
          )}
          <Grid item xs={3} className={classes.formElement}>
            <Typography className={classes.formLabel}>Country</Typography>
            <Controller
              name="country"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <Select
                  value={value}
                  onChange={onChange}
                  displayEmpty
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  className={classes.select}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {countryOptions.map(country => (
                    <MenuItem value={country.value} key={country.key}>
                      {country.text}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Grid>
          <Grid item xs={3} className={classes.formElement}>
            <Typography className={classes.formLabel}>City</Typography>
            <Controller
              name="city"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <>
                  <TextField
                    variant="outlined"
                    type="text"
                    placeholder="Add City"
                    fullWidth
                    margin="dense"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error?.message}
                  />
                </>
              )}
            />
          </Grid>
          <Grid item xs={3} className={classes.formElement}>
            <Typography className={classes.formLabel}>
              Destination Postcode
            </Typography>
            <Controller
              name="postcode"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <>
                  <TextField
                    variant="outlined"
                    type="text"
                    placeholder="Add Postcode"
                    fullWidth
                    margin="dense"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error?.message}
                  />
                </>
              )}
            />
          </Grid>
          <Grid item xs={3} className={classes.formElement}>
            <Typography className={classes.formLabel}>Created From</Typography>
            <Controller
              name="createdFrom"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    inputFormat="dd-MMM-yyyy"
                    className={classes.datePicker}
                    disableFuture
                    allowSameDateSelection
                    value={value}
                    onChange={onChange}
                    maxDate={getDate(watch('createdTo'))}
                    renderInput={(params: any) => (
                      <TextField
                        variant="outlined"
                        type="text"
                        placeholder="Add Postcode"
                        fullWidth
                        margin="dense"
                        value={value}
                        onChange={onChange}
                        {...params}
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                </LocalizationProvider>
              )}
            />
          </Grid>
          <Grid item xs={3} className={classes.formElement}>
            <Typography className={classes.formLabel}>Created To</Typography>
            <Controller
              name="createdTo"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    inputFormat="dd-MMM-yyyy"
                    className={classes.datePicker}
                    disableFuture
                    allowSameDateSelection
                    minDate={getDate(watch('createdFrom'))}
                    value={value}
                    onChange={onChange}
                    renderInput={(params: any) => (
                      <TextField
                        variant="outlined"
                        type="text"
                        placeholder="Add Postcode"
                        fullWidth
                        margin="dense"
                        value={value}
                        {...params}
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                </LocalizationProvider>
              )}
            />
          </Grid>
        </Grid>
      </form>
      <div className={classes.tableContainer}>
        <DataGrid
          columns={[
            {
              Header: 'Code#',
              accessor: (row: any) => row.sfCode,
            },
            {
              Header: 'Postcode',
              accessor: (row: any) =>
                `${row.deliveryAddressPostcode || row.postcode || ''}`,
            },
            {
              Header: 'Product',
              accessor: (row: any) =>
                `${row.product?.name || row.parcelType || ''}`,
            },
            {
              Header: 'Payment Amt.',
              accessor: (row: any) =>
                // Kaz Post currency is not stored in Stripe's format
                row.payment
                  ? `${currency[row.payment?.currencyCode]?.symbol || ''} ${(row
                      .payment?.currencyCode === 'KZT'
                      ? row.payment?.amount
                      : row.payment?.amount / 100
                    ).toFixed(2)}`
                  : 'NA',
            },
            {
              Header: 'Payment Date',
              accessor: (row: any) => formatPaymentDate(row),
            },
            {
              Header: 'User SF ID',
              accessor: (row: any) => row.consumerUser?.sfId || '',
            },
          ]}
          data={data?.codeList || []}
          recordCount={isFetching ? 0 : data?.codeList?.length || 0}
          defaultPageSize={5}
          isLoading={isFetching}
          onPaginationChange={() => {}}
          noDataMessage={'No items available'}
        />
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 26,
    lineHeight: '40px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
      lineHeight: '28px',
    },
    fontWeight: theme.typography.fontWeightBold,
  },
  exportButton: {
    float: 'right',
    marginBottom: '20px',
  },
  tabs: {
    marginBottom: '43px',
  },
  tab: {
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: 14,
    lineHeight: '20px',
  },
  filterContainer: {
    width: '100%',
    background: '#fff',
    borderRadius: '5px 5px 0 0',
    padding: '12px 16px 15px',
    margin: 0,
    border: '1px solid',
    borderBottom: '0px',
    borderColor: theme.palette.grey[300],
  },
  filterOptionContainer: {
    width: '100%',
    background: '#fff',
    borderRadius: '0 0 5px 5px',
    padding: '12px 16px 15px',
    margin: 0,
    marginBottom: theme.spacing(3),
    border: '1px solid',
    borderColor: theme.palette.grey[300],
  },
  filterText: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '21px',
    lineHeight: '32px',
  },
  formElement: {},
  formLabel: {
    fontSize: 16,
    lineHeight: '28px',
  },
  hash: {
    backgroundColor: theme.palette.grey[400],
  },
  adornmentTextField: {
    width: '100%',
    '& span': {
      backgroundColor: theme.palette.grey[400],
    },
  },
  select: {
    marginTop: '8px',
    marginBottom: '4px',
  },
  datePicker: {
    backgroundColor: 'pink',
    '&& .MuiPickersDay-root.Mui-selected': {
      backgroundColor: 'red !important',
      '&:hover': {
        backgroundColor: 'red !important',
      },
      '&:focus': {
        backgroundColor: 'red !important',
      },
    },
  },
  textFieldSkeleton: {
    width: '100%',
    height: 40,
    borderRadius: 4,
    marginTop: 8,
    marginBottom: 4,
  },
  tableContainer: {
    marginTop: theme.spacing(3),
  },
  name: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.primary.main,
  },
  dashboardText: {
    fontSize: 14,
    lineHeight: '20px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      lineHeight: '14px',
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginBottom: theme.spacing(1),
  },
  subjectText: {
    fontSize: 21,
    lineHeight: '32px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
      lineHeight: '24px',
    },
    fontWeight: theme.typography.fontWeightBold,
  },
  subjectInput: {
    margin: 0,
  },
  cancelButton: {
    color: theme.palette.grey[500],
  },
  editButton: {
    minWidth: '0px',
    width: 'auto',
    padding: 9,
    fontSize: 18,
    backgroundColor: theme.palette.primary.light,
    '&:hover': {
      backgroundColor: darken(theme.palette.primary.light, 0.1),
    },
  },
  updateButton: {
    minWidth: '0px',
    width: 'auto',
  },
  toolbar: {
    borderRadius: '5px',
  },
  editorError: {
    fontSize: '0.75rem',
    color: theme.palette.error.main,
    margin: '4px 14px 0px',
  },
  content: {
    backgroundColor: '#fff',
    height: 'calc(100vh - 220px)',
    minHeight: '300px',
    padding: 30,
  },
}));

export default Transactions;
