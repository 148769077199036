import * as React from 'react';
import {Redirect} from 'react-router-dom';

import {useAuth} from 'hooks/useAuth';

const GuestGuard: React.FC = ({children}) => {
  const {authUser} = useAuth();

  if (authUser) {
    if (
      authUser.attributes['custom:user_role'] === 'admin' ||
      authUser.attributes['custom:user_role'] === 'carrierAdmin'
    ) {
      return <Redirect to="/dashboard" />;
    }
    // if (authUser.attributes['custom:user_role'] === 'CARRIER_ADMIN') {
    //   return <Redirect to="/mail-item-search" />;
    // }
  }

  return <React.Fragment>{children}</React.Fragment>;
};

export default GuestGuard;
