import {makeStyles} from '@material-ui/core';

import loginImg from 'assets/login_img.png';
import loginBg from 'assets/login_bg.svg';

import {Grid, Paper, Hidden} from '@material-ui/core';

const GuestLayout: React.FC = ({children}) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} md={6} component={Paper} square>
        {children}
      </Grid>

      <Hidden smDown>
        <Grid item md={6} className={classes.right}>
          <img
            src={loginImg}
            alt="Stamp Free Login"
            className={classes.loginImg}
          />
        </Grid>
      </Hidden>
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
  },
  right: {
    backgroundImage: `url(${loginBg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loginImg: {
    width: 350,
    height: 'auto',
  },
}));

export default GuestLayout;
