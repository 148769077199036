export const sort = <T extends {}>(arr: T[], key: keyof T): T[] => {
  function compare(a: T, b: T) {
    if (a[key] < b[key]) {
      return -1;
    }
    if (a[key] > b[key]) {
      return 1;
    }
    return 0;
  }

  return arr.sort(compare);
};

export const compare = (a: any, b: any) => {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
};
