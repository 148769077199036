import {makeStyles} from '@material-ui/core';
import clsx from 'clsx';

import {
  Button as MuiButton,
  CircularProgress,
  ButtonProps as MuiButtonProps,
} from '@material-ui/core';

export type ButtonProps = MuiButtonProps & {
  isLoading?: boolean;
  minWidth?: string;
};

const Button: React.FC<ButtonProps> = ({
  className,
  isLoading,
  children,
  minWidth,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <MuiButton
      className={clsx(className, classes.root)}
      style={{...(minWidth ? {minWidth} : {})}}
      {...rest}
    >
      {children}

      {isLoading ? (
        <CircularProgress
          color="inherit"
          size={14}
          thickness={5}
          className={classes.loader}
        />
      ) : null}
    </MuiButton>
  );
};

const useStyles = makeStyles(theme => ({
  root: {},
  loader: {
    marginLeft: theme.spacing(1.75),
  },
}));

export default Button;
