import axios from 'lib/axios';

import {removeWhiteSpaces} from 'utils/String';
import type {CorrectItemResponse_Mock, CorrectSearchResponse_Mock} from 'types';

class CorrectService {
  async list({
    carrier,
    createdFrom,
    createdTo,
    updatedBy,
  }: {
    carrier: string;
    createdFrom: number;
    createdTo: number;
    updatedBy?: string;
  }) {
    const response = await axios.post<CorrectSearchResponse_Mock>(
      '/cc/correctItems',
      {
        carrier,
        createdFrom,
        createdTo,
        ...(updatedBy ? {updatedBy} : {}),
      }
    );
    return response.data.response;
  }

  async get(carrierId: string, statusUpdateTransactionId: string) {
    const response = await axios.get<CorrectItemResponse_Mock>(
      `/cc/correctItems/${carrierId}/${statusUpdateTransactionId}`
    );
    return response.data;
  }

  async delete(carrierId: string, statusUpdateTransactionId: string) {
    const response = await axios.put(
      `/cc/correctItem/${carrierId}/${statusUpdateTransactionId}`
    );
    return response.data;
  }

  async update({
    carrierId,
    statusUpdateTransactionId,
    code,
    parcelStatus,
    postcode,
  }: {
    carrierId: string;
    statusUpdateTransactionId: string;
    code: string;
    parcelStatus: string;
    postcode?: string;
  }) {
    const response = await axios.post(
      `/cc/correctItem/${carrierId}/${statusUpdateTransactionId}`,
      {
        code,
        parcelStatus,
        ...(postcode ? {postcode: removeWhiteSpaces(postcode)} : {}),
      }
    );
    return response.data;
  }
}

export const correctService = new CorrectService();
