import * as React from 'react';
import {Redirect} from 'react-router-dom';

import type {AuthUserType} from 'types';
import {useAuth} from 'hooks/useAuth';

export type AuthGuardProps = {
  allowedFor?: AuthUserType[];
};

const AuthGuard: React.FC<AuthGuardProps> = ({children, allowedFor = []}) => {
  const {authUser} = useAuth();

  if (!authUser) {
    return <Redirect to="/" />;
  }

  if (!allowedFor.includes(authUser.attributes['custom:user_role'])) {
    return <Redirect to="/" />;
  }

  return <React.Fragment>{children}</React.Fragment>;
};

export default AuthGuard;
