import {Avatar, makeStyles} from '@material-ui/core';
import * as React from 'react';

// import * as React from 'react';
import {Link, useLocation, useHistory} from 'react-router-dom';
import clsx from 'clsx';

import logo from 'assets/logo.png';

import type {AuthUserType} from 'types';
import {useAuth} from 'hooks/useAuth';

import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Hidden,
  IconButton,
  Divider,
  Card,
} from '@material-ui/core';
import {
  Search,
  LogOut,
  CheckSquare,
  FileText,
  ChevronRight,
  ChevronDown,
  Settings,
} from 'react-feather';

type SubMenuItem = {
  title: string;
  to: string;
};
type MenuItem = {
  title: string;
  to: string;
  icon: React.ReactNode;
  allowedFor: AuthUserType[];
  subMenus?: SubMenuItem[];
};

const ReportsSubMenuItems: SubMenuItem[] = [
  {
    title: 'Transactions',
    to: '/transactions',
  },
  {
    title: 'Consumers',
    to: '/consumer',
  },
  {
    title: 'ConsumerActivities',
    to: '/CActivities',
  },
  {
    title: 'Carrier Activities',
    to: '/caReport',
  },
];

const ConfigureSubMenuItems: SubMenuItem[] = [
  {
    title: 'Carriers',
    to: '/carrier',
  },
  {
    title: 'Retailers',
    to: '/retailer',
  },
  {
    title: 'Pudo',
    to: '/pudo',
  },
];

const menuItems: MenuItem[] = [
  {
    title: 'Lookup',
    to: '/look-up',
    icon: <Search />,
    allowedFor: ['admin', 'carrierAdmin'],
  },
  {
    title: 'Correct',
    to: '/correct',
    icon: <CheckSquare />,
    allowedFor: ['admin', 'carrierAdmin'],
  },
  {
    title: 'Configure',
    to: '#',
    icon: <Settings />,
    allowedFor: ['admin'],
    subMenus: ConfigureSubMenuItems,
  },
  {
    title: 'Reports',
    to: '#',
    icon: <FileText />,
    allowedFor: ['admin'],
    subMenus: ReportsSubMenuItems,
  },
];

const DrawerLayout: React.FC = ({children}) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [expandedReports, setExpandedReports] = React.useState(false);
  const [expandedConfigure, setExpandedConfigure] = React.useState(false);

  const {authUser, signOut} = useAuth();

  const handleLogout = () => {
    signOut();
  };

  const toggleMainMenu = (key: string) => {
    switch (key) {
      case 'Reports':
        setExpandedReports(!expandedReports);
        return;

      case 'Configure':
        setExpandedConfigure(!expandedConfigure);
        return;

      default:
        return;
    }
  };

  const isMenuSelected = (item: MenuItem): boolean => {
    if (location.pathname.includes(item.to)) return true;

    if (!item.subMenus) return false;

    for (const subItem of item.subMenus) {
      if (location.pathname.includes(subItem.to)) return true;
    }
    return false;
  };

  return (
    <div className={classes.root}>
      <Drawer
        variant="permanent"
        anchor="left"
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        {/* top */}
        <div className={classes.drawerBlock}>
          <div className={classes.logoContainer}>
            <Link to="/" className={classes.logoLink}>
              <img src={logo} alt="Stamp Free" className={classes.logo} />
            </Link>
          </div>

          <Hidden smDown>
            <List>
              {menuItems
                .filter(item =>
                  item.allowedFor.includes(
                    authUser?.attributes['custom:user_role']
                  )
                )
                .map(item => (
                  <>
                    <ListItem
                      key={item.title}
                      button
                      component={Link}
                      to={item.to}
                      onClick={() => toggleMainMenu(item.title)}
                      className={classes.listItem}
                      // <ListItem
                      //   key={item.title}
                      //   button
                      //   component={Link}
                      //   to={item.to}
                      //   onClick={() => item.title === 'Configure' && handleClick()}
                      //   className={
                      //     item.title === 'Carrier' || item.title === 'Retailers'
                      //       ? open
                      //         ? classes.listItem
                      //         : classes.listItemhidden
                      //       : classes.listItem
                      //   }
                      // >
                      //   <ListItemIcon
                      //     className={clsx(
                      //       classes.itemIcon,
                      //       location.pathname.includes(item.to) &&
                      //         classes.activeItemIcon
                      //     )}
                    >
                      <ListItemIcon
                        className={clsx(
                          classes.itemIcon,
                          isMenuSelected(item) && classes.activeItemIcon
                        )}
                      >
                        {item.icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={item.title}
                        className={clsx(
                          classes.itemText,
                          isMenuSelected(item) && classes.activeItemText
                        )}
                      />
                      {item.subMenus &&
                        ((item.title === 'Reports' && expandedReports) ||
                        (item.title === 'Configure' && expandedConfigure) ? (
                          <ChevronRight
                            className={clsx(
                              classes.itemIcon,
                              isMenuSelected(item) && classes.activeItemIcon
                            )}
                          />
                        ) : (
                          <ChevronDown
                            className={clsx(
                              classes.itemIcon,
                              isMenuSelected(item) && classes.activeItemIcon
                            )}
                          />
                        ))}
                    </ListItem>
                    {item.subMenus?.map(subItem => (
                      <>
                        {((item.title === 'Reports' && expandedReports) ||
                          (item.title === 'Configure' &&
                            expandedConfigure)) && (
                          <ListItem
                            key={subItem.title}
                            button
                            component={Link}
                            to={subItem.to}
                            onClick={() => toggleMainMenu(subItem.title)}
                            className={classes.listItem}
                          >
                            <ListItemText
                              primary={subItem.title}
                              className={clsx(
                                classes.itemText,
                                classes.subItemText,
                                location.pathname.includes(subItem.to) &&
                                  classes.activeSubItemText
                              )}
                            />
                          </ListItem>
                        )}
                      </>
                    ))}
                  </>
                ))}
              <ListItem
                button
                onClick={handleLogout}
                className={classes.listItem}
              >
                <ListItemIcon className={classes.itemIcon}>
                  <LogOut />
                </ListItemIcon>
                <ListItemText primary="Logout" className={classes.itemText} />
              </ListItem>
            </List>
          </Hidden>

          <Hidden mdUp>
            {menuItems
              .filter(item =>
                item.allowedFor.includes(
                  authUser?.attributes['custom:user_role']
                )
              )
              .map(item => (
                <IconButton
                  key={item.title}
                  className={clsx(
                    classes.iconButton,
                    location.pathname.includes(item.to) &&
                      classes.activeItemIcon
                  )}
                  onClick={() => history.push(item.to)}
                >
                  {item.icon}
                </IconButton>
              ))}
            <IconButton className={classes.iconButton} onClick={handleLogout}>
              <LogOut />
            </IconButton>
          </Hidden>
        </div>

        {/* bottom */}
        <div className={clsx(classes.drawerBlock, classes.bottom)}>
          <Divider className={classes.divider} />

          <Hidden smDown>
            <List>
              <ListItem button className={classes.listItem}>
                <Card className={classes.userCard}>
                  <ListItemIcon className={classes.itemIcon}>
                    <Avatar
                      src="/broken-image.jpg"
                      className={classes.avatar}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={authUser?.firstName || ''}
                    primaryTypographyProps={{className: classes.userFirstName}}
                    secondary={authUser?.lastName || ''}
                    secondaryTypographyProps={{style: {fontSize: '16px'}}}
                    className={classes.itemText}
                    style={{margin: '0px'}}
                  />
                </Card>
              </ListItem>
            </List>
          </Hidden>

          <Hidden mdUp>
            <IconButton className={classes.iconButton}>
              <Avatar src="/broken-image.jpg" className={classes.avatar} />
            </IconButton>
          </Hidden>
        </div>
      </Drawer>

      <main className={classes.main}>{children}</main>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  drawer: {},
  drawerPaper: {
    justifyContent: 'space-between',
    width: 300,
    flexShrink: 0,
    [theme.breakpoints.down('sm')]: {
      width: 75,
    },
  },
  drawerBlock: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  bottom: {
    paddingBottom: 66,
  },
  logoContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  logoLink: {
    marginTop: 48,
    marginLeft: 48,
    marginRight: 48,
    marginBottom: 95,
    [theme.breakpoints.down('sm')]: {
      marginTop: 24,
      marginLeft: 24,
      marginRight: 24,
      marginBottom: 48,
    },
  },
  logo: {
    width: 200,
    height: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: 70,
    },
  },
  listItem: {
    paddingLeft: 32,
    paddingRight: 32,
  },
  listItemhidden: {
    paddingLeft: 32,
    paddingRight: 32,
    display: 'none',
  },
  iconButton: {
    color: theme.palette.grey[600],
  },
  itemIcon: {
    minWidth: 35,
    color: theme.palette.grey[600],
    '& > svg': {
      height: 16,
    },
  },
  activeItemIcon: {
    color: theme.palette.primary.main,
  },
  itemText: {
    fontSize: 16,
    color: theme.palette.grey[600],
    lineHeight: 21,
  },
  subItemText: {
    marginLeft: '35px',
  },
  activeItemText: {
    color: theme.palette.primary.main,
  },
  activeSubItemText: {
    color: '#212121',
    '& span': {
      fontWeight: theme.typography.fontWeightBold,
    },
  },
  divider: {
    marginLeft: 32,
    marginRight: 32,
  },
  main: {
    flexGrow: 1,
    marginLeft: 300,
    padding: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      marginLeft: 75,
      padding: theme.spacing(2),
    },
  },
  userCard: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    padding: '15px 20px',
    borderRadius: 10,
    boxShadow: '0px 6px 30px rgba(33, 33, 33, 0.12)',
  },
  avatar: {
    marginRight: 15,
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
  },
  userFirstName: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.primary.main,
  },
}));

export default DrawerLayout;
