import axios from 'lib/axios';
import {differenceInMilliseconds} from 'date-fns';

import type {LookupSearchResponse_Mock} from 'types';
import {removeWhiteSpaces} from 'utils/String';

type returnReason = {
  id: string;
  reason: string;
};
class LookupService {
  async search({
    parcelType,
    codeStatus,
    createdFrom,
    createdTo,
    retailer,
    code,
    postcode,
    country,
    carrier,
    itemType,
  }: {
    parcelType: 'returnParcel' | 'sendParcel';
    codeStatus: 'active' | 'expired';
    createdFrom: number;
    createdTo: number;
    retailer: string;
    code: string | null;
    postcode: string;
    country: string;
    carrier: string;
    itemType: string;
  }) {
    const response = await axios.post<LookupSearchResponse_Mock>('/cc/lookup', {
      parcelType,
      codeStatus,
      createdFrom,
      createdTo,
      ...(retailer ? {retailer} : {}),
      ...(code ? {code: `#${code}`} : {}),
      ...(postcode ? {postcode: removeWhiteSpaces(postcode)} : {}),
      ...(country ? {country} : {}),
      ...(carrier ? {carrier} : {}),
      ...(itemType ? {itemType} : {}),
    });

    response.data.codeList = response.data.codeList.sort(function (a, b) {
      return differenceInMilliseconds(
        new Date(b.updatedAt),
        new Date(a.updatedAt)
      );
    });

    return response.data;
  }

  async getCodeDetails(codeType: string, codeId: string): Promise<any> {
    const response = await axios.get(`/cc/lookup/${codeType}/${codeId}`);
    return response.data;
  }
  async updateCodeDetails({
    codeType,
    codeId,
    country,
    city,
    parcelStatus,
    shippingCost,
    recipientName,
    quantity,
    description,
    parcelType,
    postcode,
    returnReasons,
    speedOfService,
  }: {
    codeType: string;
    codeId: string;
    country: string;
    city: string;
    parcelStatus: string;
    shippingCost: number;
    recipientName: string;
    quantity: string;
    description: string;
    parcelType: string;
    postcode: string;
    returnReasons: returnReason[];
    speedOfService: string;
  }) {
    await axios.put(`/cc/lookup/${codeType}/${codeId}`, {
      parcelStatus,
      ...(country ? {country} : {}),
      ...(city ? {city} : {}),
      ...(shippingCost ? {shippingCost} : {}),
      ...(recipientName ? {recipientName} : {}),
      ...(quantity ? {quantity: Math.floor(Number(quantity))} : {}),
      ...(description ? {description} : {}),
      ...(parcelType ? {parcelType} : {}),
      ...(postcode ? {postcode: removeWhiteSpaces(postcode)} : {}),
      ...(returnReasons ? {returnReasons} : {}),
      ...(speedOfService ? {deliveryTime: speedOfService} : {}),
    });
  }
}

export const lookupService = new LookupService();
