import * as React from 'react';
import clsx from 'clsx';
import {makeStyles, darken} from '@material-ui/core';
import {EditorState, convertToRaw, convertFromRaw} from 'draft-js';
import {Editor} from 'react-draft-wysiwyg';
import {toast} from 'react-hot-toast';

import useToggle from 'hooks/useToggle';
import {
  CONTENT_UPDATE_SUCCESS,
  EDITOR_MAX_CHAR_ERROR,
  ERROR_MESSAGE,
  SUBJECT_MAX_CHAR_ERROR,
} from 'utils/messages';
import {ErrorState, AsyncStatus} from 'types';
import {useAuth} from 'hooks/useAuth';

import {dashboardService} from 'services/dashboardService';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {Typography, Grid, TextField} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import BorderColorOutlined from '@material-ui/icons/BorderColorOutlined';

import Button from 'components/Button';
import ScreenSpinner from 'components/ScreenSpinner';

const MAX_CONTENT_LENGTH = 1000;
const MAX_SUBJECT_LENGTH = 100;

const Dashboard: React.FC = () => {
  const {authUser} = useAuth();

  const [editMode, toggleEditMode] = useToggle();

  const [status, setStatus] = React.useState<AsyncStatus>('LOADING');
  const [subject, setSubject] = React.useState<string>('');
  const [tempSubject, setTempSubject] = React.useState<string>('');
  const [subjectError, setSubjectError] = React.useState<ErrorState>(null);
  const [editorState, setEditorState] = React.useState<EditorState>(() =>
    EditorState.createEmpty()
  );
  const [tempEditorState, setTempEditorState] = React.useState<EditorState>(
    () => EditorState.createEmpty()
  );
  const [editorError, setEditorError] = React.useState<ErrorState>(null);

  const classes = useStyles({editorError});

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    fetchDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchDetails = async () => {
    try {
      const response = await dashboardService.getDetails();
      setSubject(response.subject);
      const parsedData: any = JSON.parse(response.details);
      setEditorState(EditorState.createWithContent(convertFromRaw(parsedData)));
    } catch (error) {
      toast.error(ERROR_MESSAGE);
    }
    setStatus('IDLE');
  };

  const handleSubjectChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    // Subject validation
    if (!event.currentTarget.value.trim()) {
      setSubjectError('Please add Subject');
    } else if (event.currentTarget.value.trim().length > MAX_SUBJECT_LENGTH) {
      setSubjectError(SUBJECT_MAX_CHAR_ERROR);
    } else {
      setSubjectError(null);
    }

    setTempSubject(event.currentTarget.value);
  };

  const handleEditorChange = (newEditorState: EditorState): void => {
    const currentContent = newEditorState.getCurrentContent();
    const editorText = currentContent.getPlainText();
    setTempEditorState(newEditorState);

    // Editor validation
    if (!editorText) {
      setEditorError('Please add Content for Dashboard');
    } else if (editorText.length > MAX_CONTENT_LENGTH) {
      setEditorError(EDITOR_MAX_CHAR_ERROR);
    } else {
      setEditorError(null);
    }
  };

  const handleEdit = () => {
    setTempSubject(subject);
    setTempEditorState(editorState);
    toggleEditMode();
  };

  const handleCancel = () => {
    setTempSubject('');
    setTempEditorState(EditorState.createEmpty());
    toggleEditMode();
  };

  const handleUpdate = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): Promise<void> => {
    try {
      setStatus('LOADING');

      const currentContent = tempEditorState.getCurrentContent();
      const editorRawContent = convertToRaw(currentContent);
      const details = JSON.stringify(editorRawContent);

      await dashboardService.updateDetails({
        subject: tempSubject.trim(),
        details,
      });
      setSubject(tempSubject.trim());
      setEditorState(tempEditorState);
      toast.success(CONTENT_UPDATE_SUCCESS);
      handleCancel();
    } catch (error) {
      toast.error(ERROR_MESSAGE);
    }
    setStatus('IDLE');
  };

  return (
    <div>
      {status === 'LOADING' && <ScreenSpinner />}
      <Typography component={'h1'} className={classes.title}>
        Welcome,{' '}
        <Typography
          component={'span'}
          className={clsx(classes.title, classes.name)}
        >
          {`${authUser?.firstName || ''} ${authUser?.lastName || ''}`}
        </Typography>
      </Typography>
      <Typography component={'h6'} className={classes.dashboardText}>
        Dashboard
      </Typography>

      <Grid container spacing={2} alignItems="flex-start">
        <Grid
          item
          xs={authUser?.attributes['custom:user_role'] === 'admin' ? 10 : 12}
        >
          {editMode ? (
            <TextField
              variant="outlined"
              type="text"
              placeholder="Subject"
              fullWidth
              margin="dense"
              className={classes.subjectInput}
              autoFocus
              value={tempSubject}
              onChange={handleSubjectChange}
              error={!!subjectError}
              helperText={subjectError}
            />
          ) : (
            <Typography component={'h2'} className={classes.subjectText}>
              {subject}
            </Typography>
          )}
        </Grid>
        {authUser?.attributes['custom:user_role'] === 'admin' && (
          <Grid item xs={2}>
            <Grid container spacing={2} alignItems="center" justify="flex-end">
              <Grid item>
                <Button
                  variant="contained"
                  className={classes.editButton}
                  color="primary"
                  type="submit"
                  fullWidth
                  onClick={() => (editMode ? handleCancel() : handleEdit())}
                >
                  {editMode ? (
                    <CloseIcon fontSize="inherit" color={'primary'} />
                  ) : (
                    <BorderColorOutlined fontSize="inherit" color={'primary'} />
                  )}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.updateButton}
                  type="submit"
                  fullWidth
                  disabled={!editMode || !!subjectError || !!editorError}
                  onClick={handleUpdate}
                >
                  {'Save'}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12} className="editor">
          <Editor
            editorState={editMode ? tempEditorState : editorState}
            readOnly={!editMode}
            editorClassName={classes.editor}
            toolbarClassName={classes.toolbar}
            onEditorStateChange={handleEditorChange}
            toolbarHidden={!editMode}
            toolbar={{
              inline: {inDropdown: true},
              image: {display: false},
              fontFamily: {
                options: [
                  'Arial',
                  'Georgia',
                  'Impact',
                  'neue-haas-grotesk-display, sans-serif',
                  'Tahoma',
                  'Times New Roman',
                  'Verdana',
                ],
              },
            }}
          />
          {!!editorError && (
            <Typography paragraph className={classes.editorError}>
              {editorError}
            </Typography>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 26,
    lineHeight: '40px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
      lineHeight: '28px',
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginBottom: theme.spacing(1),
  },
  name: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.primary.main,
  },
  dashboardText: {
    fontSize: 14,
    lineHeight: '20px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      lineHeight: '14px',
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginBottom: theme.spacing(1),
  },
  subjectText: {
    fontSize: 21,
    lineHeight: '32px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
      lineHeight: '24px',
    },
    fontWeight: theme.typography.fontWeightBold,
  },
  subjectInput: {
    margin: 0,
  },
  editButton: {
    minWidth: '0px',
    width: 'auto',
    padding: 9,
    fontSize: 18,
    backgroundColor: theme.palette.primary.light,
    '&:hover': {
      backgroundColor: darken(theme.palette.primary.light, 0.1),
    },
  },
  updateButton: {
    minWidth: '0px',
    width: 'auto',
  },
  editor: ({editorError}: {editorError: ErrorState}) => ({
    backgroundColor: '#fff',
    padding: '16px 30px',
    minHeight: 'calc(100vh - 269px)',
    borderRadius: '5px',
    ...(editorError
      ? {
          border: '1px solid',
          borderColor: theme.palette.error.main,
        }
      : null),
  }),
  toolbar: {
    borderRadius: '5px',
  },
  editorError: {
    fontSize: '0.75rem',
    color: theme.palette.error.main,
    margin: '4px 14px 0px',
  },
  content: {
    backgroundColor: '#fff',
    height: 'calc(100vh - 220px)',
    minHeight: '300px',
    padding: 30,
  },
}));

export default Dashboard;
