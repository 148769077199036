import * as React from 'react';
import {makeStyles, darken} from '@material-ui/core';
import {Link} from 'react-router-dom';
import {useForm, Controller} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';

import logo from 'assets/logo.png';

import {useAuth} from 'hooks/useAuth';
import {INVALID_CREDENTIALS} from 'utils/messages';
import {getAuthErrorMEssage} from 'utils/errors';

import {
  Typography,
  TextField,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/VisibilityOutlined';
import VisibilityOff from '@material-ui/icons/VisibilityOffOutlined';

import Button from 'components/Button';

type LoginInputs = {
  email: string;
  password: string;
};
type LoginError = null | string;

const schema = yup.object().shape({
  email: yup
    .string()
    .email('Unable to login. Email or password incorrect')
    .required('Email is a required field'),
  password: yup.string().required('Password is a required field'),
});

const Login: React.FC = () => {
  const {signInWithEmailAndPassword, signOut} = useAuth();
  const [loginError, setLoginError] = React.useState<LoginError>(null);
  const classes = useStyles({loginError});

  const {
    control,
    handleSubmit,
    formState: {dirtyFields, isSubmitting},
  } = useForm<LoginInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const [passwordVisible, setPasswordVisible] = React.useState(false);
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onSubmit = async (data: LoginInputs) => {
    const {email, password} = data;
    try {
      setLoginError(null);
      const user = await signInWithEmailAndPassword(email, password);

      if (
        user?.attributes['custom:user_role'] !== 'admin' &&
        user?.attributes['custom:user_role'] !== 'carrierAdmin'
      ) {
        await signOut();
        setLoginError(INVALID_CREDENTIALS);
        return;
      }
    } catch (error) {
      setLoginError(getAuthErrorMEssage(error));
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.loginForm}>
        <img src={logo} alt="Stamp Free" className={classes.logo} />
        <Typography className={classes.loginTitle}>
          {'Admin Dashboard'}
        </Typography>
        <Typography className={classes.loginError}>{loginError}</Typography>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={classes.formElement}>
            <Typography className={classes.formLabel}>
              {'Email Address'}
            </Typography>
            <Controller
              name="email"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <TextField
                  variant="outlined"
                  type="text"
                  placeholder="admin@email.com"
                  fullWidth
                  margin="dense"
                  autoFocus
                  value={value}
                  onChange={onChange}
                  error={!!error || !!loginError}
                  helperText={error?.message}
                />
              )}
            />
          </div>

          <div className={classes.formElement}>
            <Typography className={classes.formLabel}>{'Password'}</Typography>
            <Controller
              name="password"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <TextField
                  variant="outlined"
                  type={passwordVisible ? 'text' : 'password'}
                  placeholder="*******"
                  fullWidth
                  margin="dense"
                  value={value}
                  onChange={onChange}
                  error={!!error || !!loginError}
                  helperText={error?.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={togglePasswordVisibility}
                          size="small"
                        >
                          {!passwordVisible ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </div>

          <div className={classes.formElement}>
            <Typography component="span" className={classes.forgotPassword}>
              {'Forgot password?'}
            </Typography>
            <Link to="/forgot-password" className={classes.forgotPasswordLink}>
              {'Reset Here'}
            </Link>
          </div>

          <Button
            variant="contained"
            color="primary"
            className={classes.submitButton}
            type="submit"
            fullWidth
            disabled={
              !(dirtyFields.email && dirtyFields.password) || isSubmitting
            }
            isLoading={isSubmitting}
          >
            {'Login'}
          </Button>
        </form>
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loginForm: {
    width: 350,
    [theme.breakpoints.down('sm')]: {
      width: '70%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '90%',
    },
  },
  loginError: ({loginError}: {loginError: LoginError}) => ({
    ...(!loginError
      ? {
          display: 'none',
        }
      : null),
    ...{
      fontSize: 16,
      color: theme.palette.error.main,
      lineHeight: '28px',
      marginBottom: 32,
    },
  }),
  logo: {
    width: 200,
    height: 'auto',
    marginBottom: 32,
  },
  loginTitle: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 21,
    lineHeight: '32px',
    marginBottom: 32,
  },
  formElement: {
    marginBottom: 32,
  },
  formLabel: {
    fontSize: 16,
    lineHeight: '28px',
  },
  forgotPassword: {
    fontSize: 16,
    lineHeight: '28px',
  },
  forgotPasswordLink: {
    fontSize: 16,
    lineHeight: '28px',
    color: theme.palette.primary.main,
    textDecoration: 'none',
    marginLeft: theme.spacing(1),
  },
  submitButton: ({loginError}: {loginError: LoginError}) => ({
    ...(loginError
      ? {
          backgroundColor: theme.palette.error.main,
          color: theme.palette.common.white,
          '&:hover': {
            backgroundColor: darken(theme.palette.error.main, 0.1),
            color: theme.palette.common.white,
          },
        }
      : null),
  }),
}));

export default Login;
