import {makeStyles} from '@material-ui/core';
import clsx from 'clsx';

import {Img, ImgProps} from 'react-image';
import {CircularProgress, CircularProgressProps} from '@material-ui/core';

export type ImageProps = {
  className?: string;
  src: string | string[];
  alt: string;
  imgProps?: Partial<ImgProps>;
  progressContainerProps?: any;
  progressProps?: Partial<CircularProgressProps>;
  cover?: boolean;
  onClick?: () => any;
};

const Image: React.FC<ImageProps> = ({
  className,
  src,
  alt,
  imgProps,
  progressContainerProps,
  progressProps,
  cover,
  ...rest
}) => {
  const classes = useStyles({cover});

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Img
        src={src}
        alt={alt}
        loader={
          <div className={classes.loaderContainer} {...progressContainerProps}>
            <CircularProgress className={classes.loader} {...progressProps} />
          </div>
        }
        className={classes.image}
        {...imgProps}
      />
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: 'auto',
  },
  image: ({cover}: Partial<ImageProps>) => ({
    width: '100%',
    height: '100%',
    ...(cover
      ? {
          objectFit: 'cover',
        }
      : null),
  }),
  loaderContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loader: {},
}));

export default Image;
