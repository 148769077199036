import * as React from 'react';
import {makeStyles, darken} from '@material-ui/core';
import {useHistory} from 'react-router-dom';
import {useForm, Controller} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {toast} from 'react-hot-toast';

import logo from 'assets/logo.png';

import type {AsyncStatus} from 'types';
import {useAuth} from 'hooks/useAuth';
import {FORGOT_PASSWORD_SUCCESS, INVALID_EMAIL} from 'utils/messages';
import {getAuthErrorMEssage} from 'utils/errors';

import {Typography, TextField} from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import ReplayIcon from '@material-ui/icons/Replay';

import Button from 'components/Button';

type ForgotPasswordInputs = {
  email: string;
};

const schema = yup.object().shape({
  email: yup
    .string()
    .email(INVALID_EMAIL)
    .required('Email is a required field'),
});

const ForgotPassword: React.FC = () => {
  const history = useHistory();

  const {forgotPassword} = useAuth();

  const [status, setStatus] = React.useState<AsyncStatus>('IDLE');
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const classes = useStyles({status});

  const [hovering, setHovering] = React.useState(false);

  const {
    control,
    handleSubmit,
    formState: {isDirty},
  } = useForm<ForgotPasswordInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
    },
  });

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleNavigateBack = () => {
    history.push('/');
  };

  const onSubmit = async (data: ForgotPasswordInputs) => {
    try {
      setStatus('LOADING');

      await forgotPassword(data.email);

      toast.success(FORGOT_PASSWORD_SUCCESS, {duration: 4000});
      setStatus('IDLE');
    } catch (error) {
      setStatus('FAILURE');
      setErrorMessage(getAuthErrorMEssage(error));
    }
  };

  const getButtonIcon = () => {
    switch (status) {
      case 'FAILURE':
        if (hovering) {
          return <ReplayIcon color="inherit" />;
        }

        return <CloseIcon color="inherit" />;
      case 'SUCCESS':
        return <CheckIcon color="inherit" />;
      default:
        return undefined;
    }
  };
  const getButtonText = () => {
    switch (status) {
      case 'IDLE':
        return 'Request Reset';
      case 'LOADING':
        return 'Sending Request';
      case 'FAILURE':
        if (hovering) {
          return 'Retry';
        }

        return 'An error happened while sending request';
      case 'SUCCESS':
        return 'Request sent';
      default:
        return undefined;
    }
  };

  return (
    <div className={classes.root}>
      <Button
        variant="text"
        disableRipple
        disableTouchRipple
        className={classes.navigateBackButton}
        startIcon={<KeyboardBackspaceIcon color="inherit" />}
        onClick={handleNavigateBack}
      >
        {'Back to Login'}
      </Button>

      <div className={classes.form}>
        <img src={logo} alt="Stamp Free" className={classes.logo} />
        <Typography className={classes.loginTitle}>
          {'Reset Password'}
        </Typography>
        <Typography className={classes.loginError}>{errorMessage}</Typography>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={classes.formElement}>
            <Typography className={classes.formLabel}>
              {'Email Address'}
            </Typography>
            <Controller
              name="email"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <TextField
                  variant="outlined"
                  type="text"
                  placeholder="admin@email.com"
                  fullWidth
                  margin="dense"
                  autoFocus
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </div>

          <Button
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            disabled={!isDirty || status === 'LOADING' || status === 'SUCCESS'}
            isLoading={status === 'LOADING'}
            endIcon={getButtonIcon()}
            className={classes.submitButton}
            onMouseOver={() => setHovering(true)}
            onMouseLeave={() => {
              setHovering(false);
            }}
          >
            {getButtonText()}
          </Button>
        </form>
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  navigateBackButton: {
    position: 'absolute',
    left: 50,
    top: 50,
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.grey[600],
    fontSize: 16,
    lineHeight: '28px',
    fontWeight: theme.typography.fontWeightRegular,
    [theme.breakpoints.down('sm')]: {
      left: 20,
      top: 20,
    },
  },
  form: {
    width: 350,
    [theme.breakpoints.down('sm')]: {
      width: '70%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '90%',
    },
  },
  logo: {
    width: 200,
    height: 'auto',
    marginBottom: 32,
  },
  loginTitle: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 21,
    lineHeight: '32px',
    marginBottom: 32,
  },
  loginError: ({status}: {status: AsyncStatus}) => ({
    ...(status === 'FAILURE'
      ? null
      : {
          display: 'none',
        }),
    ...{fontSize: 16, color: '#DF1642', lineHeight: '28px', marginBottom: 32},
  }),
  formElement: {
    marginBottom: 32,
  },
  formLabel: {
    fontSize: 16,
    lineHeight: '28px',
  },
  submitButton: ({status}: {status: AsyncStatus}) => ({
    ...(status === 'SUCCESS'
      ? {
          '&:disabled': {
            backgroundColor: theme.palette.success.main,
            color: theme.palette.common.white,
          },
        }
      : null),
    ...(status === 'FAILURE'
      ? {
          backgroundColor: theme.palette.error.main,
          color: theme.palette.common.white,
          '&:hover': {
            backgroundColor: darken(theme.palette.error.main, 0.1),
            color: theme.palette.common.white,
          },
        }
      : null),
  }),
}));

export default ForgotPassword;
