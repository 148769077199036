import * as React from 'react';
import {makeStyles, darken} from '@material-ui/core';
import {useQuery} from 'react-query';
import {toast} from 'react-hot-toast';
import {useForm, Controller} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {useHistory, Link, useParams} from 'react-router-dom';
import {Storage} from 'aws-amplify';
import clsx from 'clsx';

import {
  CORRECT_ITEM_DELETE_SUCCESS,
  requiredFieldMessage,
} from 'utils/messages';
import imagePlaceholder from 'assets/image-placeholder.png';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {
  Typography,
  Grid,
  TextField,
  Select,
  MenuItem,
  Breadcrumbs,
} from '@material-ui/core';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import {getS3ObjectId, parcelStatusOptions, validateSfCode} from 'utils/helper';

import Button from 'components/Button';
import Image from 'components/Image';
import ScreenSpinner from 'components/ScreenSpinner';
import {correctService} from 'services/correctService';
import Modal from 'components/Modal';

type TransactionDetails = {
  code: string;
  postcode: string;
  itemStatus: string;
  uploadedOn: Date | null;
  lastUploadedBy: string;
  lastUpdatedBy: string;
  parcelType: 'sendParcel' | 'returnParcel' | string;
  location?: string;
};

type CorrectPathParams = {
  carrierId: string;
  statusUpdateTransactionId: string;
};
const schema = yup.object().shape({
  code: yup
    .string()
    .min(2, 'Code is a required field')
    .test('codeInvalid', 'Please add valid Code', function (code?: string) {
      if (!code) return false;
      if (!validateSfCode(code)) {
        return false;
      }

      return true;
    }),
  postcode: yup.string().when('parcelType', {
    is: (parcelType: 'sendParcel' | 'returnParcel') =>
      parcelType === 'sendParcel',
    then: yup.string().trim().required(requiredFieldMessage('Postcode')),
  }),
});

const CorrectItem: React.FC = () => {
  const {carrierId, statusUpdateTransactionId} = useParams<CorrectPathParams>();
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const history = useHistory();
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    clearErrors,
  } = useForm<TransactionDetails>({
    resolver: yupResolver(schema),
    defaultValues: {
      code: '#',
      postcode: '',
      itemStatus: '',
      uploadedOn: null,
      lastUploadedBy: '',
      lastUpdatedBy: '',
      parcelType: '',
      location: '-',
    },
  });

  const classes = useStyles();
  const [updating, setUpdating] = React.useState(false);
  const [photoUrl, setPhotoUrl] = React.useState('');
  const [parcelType, setParcelType] = React.useState<
    'sendParcel' | 'returnParcel'
  >('sendParcel');

  const {data, isFetching} = useQuery(
    ['correct'],
    () => {
      return correctService.get(carrierId, statusUpdateTransactionId);
    },
    {
      retry: false,
    }
  );

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    const subscription = watch(value => fieldChange(value));
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setData();
    (async () => {
      if (data && data.response.url) {
        const signedUrl = (await Storage.get(
          getS3ObjectId(data.response.url)
        )) as string;
        setPhotoUrl(signedUrl);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const fieldChange = (transaction: TransactionDetails) => {
    const parcelType =
      data?.response.type === 'sendParcel' ? 'sendParcel' : 'returnParcel';
    if (parcelType !== transaction.parcelType) {
      setValue('parcelType', parcelType);
      setParcelType(parcelType);
    }
    if (parcelType === 'returnParcel') {
      clearErrors('postcode');
    }
  };

  const setData = () => {
    if (!data) return;
    const transaction = data.response;
    setValue('code', transaction?.code || '#');
    setValue('postcode', transaction?.postcode || '');
    setValue('itemStatus', transaction?.action);
    setValue('uploadedOn', new Date(transaction?.createdAt));
    setValue(
      'lastUploadedBy',
      `${transaction?.user.firstName || ''} (${transaction?.user.id || ''})`
    );
    setValue(
      'lastUpdatedBy',
      transaction?.updatedBy
        ? `${transaction.updatedBy.firstName || ''} (${
            transaction.updatedBy.id || ''
          })`
        : ''
    );
    setValue(
      'location',
      transaction?.location ? `${transaction.location || '-'}` : '-'
    );
  };

  const handleCancel = (event: React.MouseEvent) => {
    history.push('/correct');
  };

  const hanldeDelete = async () => {
    setShowDeleteModal(false);
    try {
      setUpdating(true);
      await correctService.delete(carrierId, statusUpdateTransactionId);
      toast.success(CORRECT_ITEM_DELETE_SUCCESS);
      history.push('/correct');
    } finally {
      setUpdating(false);
    }
  };

  const onSubmit = async (data: TransactionDetails) => {
    try {
      const {code, postcode, itemStatus} = data;

      setUpdating(true);

      await correctService.update({
        carrierId,
        statusUpdateTransactionId,
        code: code.trim(),
        postcode: postcode.trim(),
        parcelStatus: itemStatus,
      });

      toast.success('Item details updated successfully', {duration: 3000});
      history.push('/correct');
    } finally {
      setUpdating(false);
    }
  };

  return (
    <div>
      {(updating || isFetching) && <ScreenSpinner />}
      <Modal
        open={showDeleteModal}
        handleClose={() => {
          setShowDeleteModal(false);
        }}
      >
        <>
          <Grid container spacing={3} className={classes.modalHeader}>
            <Grid item xs={7}>
              <Typography component={'h3'} className={classes.modalTitle}>
                Delete Item
              </Typography>
            </Grid>
            <Grid
              container
              xs={5}
              spacing={2}
              alignItems="center"
              justify="flex-end"
            >
              <Grid item>
                <Button
                  variant="outlined"
                  color="inherit"
                  fullWidth
                  minWidth="0px"
                  onClick={() => setShowDeleteModal(false)}
                  className={classes.cancelButton}
                >
                  {'Close'}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  className={classes.deleteButton}
                  minWidth="0px"
                  fullWidth
                  onClick={hanldeDelete}
                >
                  {'Delete'}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item>
              <Typography component={'p'} className={classes.modalText}>
                The image and details will be deleted. Are you sure?
              </Typography>
            </Grid>
          </Grid>
        </>
      </Modal>

      <Typography component={'h1'} className={classes.title}>
        {'Item Details'}
      </Typography>
      <Breadcrumbs className={classes.breadcrumb} aria-label="breadcrumb">
        <Link className={classes.breadcrumbLink} to="/correct">
          Correct
        </Link>
        <Typography className={classes.breadcrumbText}>Item Details</Typography>
      </Breadcrumbs>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container className={classes.filterContainer} spacing={2}>
          <Grid item xs={7}>
            <Typography className={classes.filterText} component={'h2'}>
              Item Details
            </Typography>
          </Grid>
          <Grid
            container
            xs={5}
            spacing={2}
            alignItems="center"
            justify="flex-end"
          >
            <Grid item>
              <Button
                variant="outlined"
                color="inherit"
                fullWidth
                minWidth="0px"
                onClick={handleCancel}
                className={classes.cancelButton}
              >
                {'Close'}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                className={classes.deleteButton}
                minWidth="0px"
                fullWidth
                onClick={() => setShowDeleteModal(true)}
              >
                {'Delete'}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                minWidth="0px"
                fullWidth
              >
                {'Save'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={classes.filterOptionContainer} spacing={2}>
          <Grid item xs={3} className={classes.formElement}>
            <Typography className={classes.formLabel}>Add Code</Typography>
            <Controller
              name="code"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <>
                  <TextField
                    variant="outlined"
                    type="text"
                    placeholder="Add Code"
                    fullWidth
                    margin="dense"
                    value={value}
                    onChange={event => {
                      validateSfCode(event.target.value);

                      onChange(event);
                    }}
                    error={!!error}
                    helperText={error?.message}
                  />
                </>
              )}
            />
          </Grid>
          {parcelType !== 'returnParcel' && (
            <Grid item xs={3} className={classes.formElement}>
              <Typography className={classes.formLabel}>Postcode</Typography>
              <Controller
                name="postcode"
                control={control}
                render={({field: {value, onChange}, fieldState: {error}}) => (
                  <>
                    <TextField
                      variant="outlined"
                      type="text"
                      placeholder="Add Postcode"
                      fullWidth
                      margin="dense"
                      value={value}
                      onChange={event => {
                        event.target.value = event.target.value.replace(
                          /[a-z]/g,
                          ''
                        );
                        onChange(event);
                      }}
                      error={!!error}
                      helperText={error?.message}
                    />
                  </>
                )}
              />
            </Grid>
          )}

          <Grid item xs={3} className={classes.formElement}>
            <Typography className={classes.formLabel}>Item Status</Typography>
            <Controller
              name="itemStatus"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <Select
                  value={value}
                  onChange={onChange}
                  displayEmpty
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  className={classes.select}
                  error={!!error}
                >
                  {parcelStatusOptions.map((parcelStatus: any) => (
                    <MenuItem value={parcelStatus.value} key={parcelStatus.key}>
                      {parcelStatus.text}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Grid>
          <Grid item xs={3} className={classes.formElement}>
            <Typography
              className={clsx(classes.formLabel, classes.disableLabel)}
            >
              Uploaded On
            </Typography>
            <Controller
              name="uploadedOn"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    inputFormat="dd/MM/yyyy"
                    className={classes.datePicker}
                    disabled
                    value={value}
                    onChange={onChange}
                    renderInput={(params: any) => (
                      <TextField
                        variant="outlined"
                        type="text"
                        placeholder="Uploaded on"
                        fullWidth
                        margin="dense"
                        value={value}
                        onChange={onChange}
                        error={!!error}
                        helperText={error?.message}
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              )}
            />
          </Grid>
          <Grid item xs={3} className={classes.formElement}>
            <Typography
              className={clsx(classes.formLabel, classes.disableLabel)}
            >
              Last Uploaded By
            </Typography>
            <Controller
              name="lastUploadedBy"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <>
                  <TextField
                    variant="outlined"
                    type="text"
                    placeholder="Last Uploaded By"
                    fullWidth
                    disabled
                    margin="dense"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error?.message}
                  />
                </>
              )}
            />
          </Grid>
          <Grid item xs={3} className={classes.formElement}>
            <Typography
              className={clsx(classes.formLabel, classes.disableLabel)}
            >
              Last Updated By
            </Typography>
            <Controller
              name="lastUpdatedBy"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <>
                  <TextField
                    variant="outlined"
                    type="text"
                    placeholder="Last Updated By"
                    fullWidth
                    disabled
                    margin="dense"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error?.message}
                  />
                </>
              )}
            />
          </Grid>
          <Grid item xs={3} className={classes.formElement}>
            <Typography
              className={clsx(classes.formLabel, classes.disableLabel)}
            >
              Location
            </Typography>
            <Controller
              name="location"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <>
                  <TextField
                    variant="outlined"
                    type="text"
                    placeholder="Location"
                    fullWidth
                    disabled
                    margin="dense"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error?.message}
                  />
                </>
              )}
            />
          </Grid>
          <Grid item xs={3}></Grid>
          {parcelType === 'returnParcel' && <Grid item xs={3}></Grid>}
          <br />
          <Grid item xs={6}>
            {photoUrl ? (
              <Image
                className={classes.picture}
                src={[photoUrl, imagePlaceholder]}
                alt="code-image"
              />
            ) : (
              <img
                src={imagePlaceholder}
                alt="code"
                className={classes.picture}
              />
            )}
          </Grid>
          <br></br>
          <Grid item xs={12}>
            <Typography component={'h3'} className={classes.errorInfo}>
              Validation failed with AI. No code detected
            </Typography>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 26,
    lineHeight: '40px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
      lineHeight: '28px',
    },
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(3),
  },
  errorInfo: {
    color: theme.palette.error.main,
    fontSize: 18,
  },
  deleteButton: {
    backgroundColor: theme.palette.error.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: darken(theme.palette.error.main, 0.1),
      color: theme.palette.common.white,
    },
  },
  modalTitle: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 21,
    lineHeight: '32px',
  },
  modalText: {
    fontSize: 18,
    margin: '10px 0px',
  },
  breadcrumb: {
    marginBottom: theme.spacing(10),
  },
  breadcrumbLink: {
    color: 'inherit',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  breadcrumbText: {
    color: 'inherit',
    fontSize: 'inherit',
  },
  filterContainer: {
    width: '100%',
    background: '#fff',
    borderRadius: '5px 5px 0 0',
    padding: '12px 16px 15px',
    margin: 0,
    border: '1px solid',
    borderBottom: '0px',
    borderColor: theme.palette.grey[300],
  },
  filterOptionContainer: {
    width: '100%',
    background: '#fff',
    borderRadius: '0 0 5px 5px',
    padding: '12px 16px 15px',
    margin: 0,
    marginBottom: theme.spacing(3),
    border: '1px solid',
    borderColor: theme.palette.grey[300],
  },
  filterText: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '21px',
    lineHeight: '32px',
  },
  formElement: {},
  formLabel: {
    fontSize: 16,
    lineHeight: '28px',
  },

  select: {
    marginTop: '8px',
    marginBottom: '4px',
  },
  datePicker: {
    backgroundColor: 'pink',
    '&& .MuiPickersDay-root.Mui-selected': {
      backgroundColor: 'red !important',
      '&:hover': {
        backgroundColor: 'red !important',
      },
      '&:focus': {
        backgroundColor: 'red !important',
      },
    },
  },

  modalHeader: {
    borderBottom: '1px solid #E0E0E0',
  },
  disableLabel: {
    color: 'rgba(0, 0, 0, 0.38)',
  },

  picture: {
    width: '100%',
    maxWidth: '100%',
    height: 288,
    objectFit: 'contain',
    [theme.breakpoints.down('xs')]: {
      height: 200,
    },
    '& img': {
      objectFit: 'contain',
    },
  },
  cancelButton: {
    color: theme.palette.grey[500],
  },
}));

export default CorrectItem;
