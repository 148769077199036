import * as React from 'react';
import {makeStyles, darken} from '@material-ui/core';
import {useQuery} from 'react-query';
import {toast} from 'react-hot-toast';
import {useHistory} from 'react-router-dom';

import {useForm, Controller} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {Row} from 'react-table';
import * as yup from 'yup';
import DataGrid from 'components/DataGrid';
import {retailerService} from 'services/retailerService';
import {ERROR_MESSAGE} from 'utils/messages';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {
  Typography,
  Grid,
  TextField,
  Box,
  Select,
  MenuItem,
} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Button from 'components/Button';

type FilterInputs = {
  location: string;
  status: string;
};

// Add method to handle empty string
yup.addMethod(yup.string, 'stripEmptyString', function () {
  return this.transform(value => (value === '' ? undefined : value));
});

const filterSchema = yup.object().shape({
  location: yup.string(),
  status: yup.string(),
});

const statusOptions = [
  {
    key: 'active',
    value: 'active',
    text: 'Active',
  },
  {
    key: 'inactive',
    value: 'inactive',
    text: 'Inactive',
  },
];

const Retailers: React.FC = () => {
  const history = useHistory();

  const {
    control,
    handleSubmit,
    reset,
    getValues,
    formState: {errors},
  } = useForm<FilterInputs>({
    mode: 'all',
    resolver: yupResolver(filterSchema),
    defaultValues: {
      location: '',
      status: 'active',
    },
  });

  const classes = useStyles();

  const {data, refetch, isFetching} = useQuery(
    ['retailers'],
    () => {
      return retailerService.searchRetailers({
        status: getValues('status'),
        city: getValues('location'),
      });
    },
    {retry: false}
  );

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const resetFilters = () => {
    reset({
      location: '',
      status: 'active',
    });
  };

  const handleClearFilter = async (event: React.MouseEvent) => {
    resetFilters();
    refetch({
      throwOnError: true,
      cancelRefetch: true,
    });
  };

  const onSubmit = async (data: FilterInputs) => {
    refetch({
      throwOnError: true,
      cancelRefetch: true,
    });
  };

  return (
    <div>
      <Typography component={'h1'} className={classes.title}>
        Retailers
      </Typography>
      <Box>
        <Grid container className={classes.button} spacing={2}>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              minWidth="0px"
              fullWidth
              onClick={() => history.push(`/retailer/add`)}
              // disabled={Object.keys(errors).length > 0}
            >
              {'Add Retailer'}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container className={classes.filterContainer} spacing={2}>
          <Grid item xs={9}>
            <Typography className={classes.filterText} component={'h2'}>
              Filter
            </Typography>
          </Grid>
          <Grid
            container
            xs={3}
            spacing={2}
            alignItems="center"
            justify="flex-end"
          >
            <Grid item>
              <Button
                variant="outlined"
                color="inherit"
                fullWidth
                minWidth="0px"
                onClick={handleClearFilter}
                className={classes.cancelButton}
              >
                Clear
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                minWidth="0px"
                fullWidth
                disabled={Object.keys(errors).length > 0}
              >
                {'Apply'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={classes.filterOptionContainer} spacing={2}>
          <Grid item xs={3} className={classes.formElement}>
            <Typography className={classes.formLabel}>
              Retailer Status
            </Typography>
            <Controller
              name="status"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <Select
                  value={value}
                  onChange={onChange}
                  displayEmpty
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  className={classes.select}
                >
                  {statusOptions.map(country => (
                    <MenuItem value={country.value} key={country.key}>
                      {country.text}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Grid>
          <Grid item xs={3} className={classes.formElement}>
            <Typography className={classes.formLabel}>
              Location (City)
            </Typography>
            <Controller
              name="location"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <>
                  <TextField
                    variant="outlined"
                    type="text"
                    placeholder="Add Location"
                    fullWidth
                    margin="dense"
                    value={value}
                    onChange={onChange}
                    // onChange={e => {
                    //   if (!/^[A-Z0-9 ]*$/.test(e.target.value)) return;
                    //   onChange(e);
                    // }}
                    error={!!error}
                    helperText={error?.message}
                  />
                </>
              )}
            />
          </Grid>
        </Grid>
      </form>
      <div className={classes.tableContainer}>
        <DataGrid
          columns={[
            {
              Header: 'Retailer',
              accessor: 'name',
            },
            {
              Header: 'Location',
              accessor: 'city',
              Cell: (row: any) => {
                return (
                  <div className={classes.retailerLocation}>
                    <span>
                      {row.row.original.address.addressLine1
                        ? row.row.original.address.addressLine1 + ', '
                        : ''}
                    </span>
                    <span>
                      {row.row.original.address.city
                        ? row.row.original.address.city
                        : ''}
                    </span>
                  </div>
                );
              },
            },
          ]}
          data={data || []}
          recordCount={isFetching ? 0 : data?.length || 0}
          defaultPageSize={5}
          isLoading={isFetching}
          onPaginationChange={() => {}}
          extraActions={[
            {
              label: 'Action',
              type: 'icon-button',
              buttonContent: <VisibilityIcon />,
              handler: (row: Row<any>) => {
                if (row.original.id) {
                  history.push(`/retailer/${row.original.id}`);
                  // history.push(`/look-up/${mode}/${row.original.id}`);
                } else {
                  toast.error(ERROR_MESSAGE);
                }
              },
            },
          ]}
          // maxHeight="65vh"
          noDataMessage={'No items available'}
        />
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 26,
    lineHeight: '40px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
      lineHeight: '28px',
    },
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(3),
  },
  button: {
    marginBottom: theme.spacing(3),
  },

  filterContainer: {
    width: '100%',
    background: '#fff',
    borderRadius: '5px 5px 0 0',
    padding: '12px 16px 15px',
    margin: 0,
    border: '1px solid',
    borderBottom: '0px',
    borderColor: theme.palette.grey[300],
  },
  filterOptionContainer: {
    width: '100%',
    background: '#fff',
    borderRadius: '0 0 5px 5px',
    padding: '12px 16px 15px',
    margin: 0,
    marginBottom: theme.spacing(3),
    border: '1px solid',
    borderColor: theme.palette.grey[300],
  },
  filterText: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '21px',
    lineHeight: '32px',
  },
  formElement: {},
  formLabel: {
    fontSize: 16,
    lineHeight: '28px',
  },
  hash: {
    backgroundColor: theme.palette.grey[400],
  },
  adornmentTextField: {
    width: '100%',
    '& span': {
      backgroundColor: theme.palette.grey[400],
    },
  },
  select: {
    marginTop: '8px',
    marginBottom: '4px',
  },
  datePicker: {
    backgroundColor: 'pink',
    '&& .MuiPickersDay-root.Mui-selected': {
      backgroundColor: 'red !important',
      '&:hover': {
        backgroundColor: 'red !important',
      },
      '&:focus': {
        backgroundColor: 'red !important',
      },
    },
  },
  textFieldSkeleton: {
    width: '100%',
    height: 40,
    borderRadius: 4,
    marginTop: 8,
    marginBottom: 4,
  },
  tableContainer: {
    marginTop: theme.spacing(3),
  },
  name: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.primary.main,
  },
  dashboardText: {
    fontSize: 14,
    lineHeight: '20px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      lineHeight: '14px',
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginBottom: theme.spacing(1),
  },
  subjectText: {
    fontSize: 21,
    lineHeight: '32px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
      lineHeight: '24px',
    },
    fontWeight: theme.typography.fontWeightBold,
  },
  subjectInput: {
    margin: 0,
  },
  cancelButton: {
    color: theme.palette.grey[500],
  },
  editButton: {
    minWidth: '0px',
    width: 'auto',
    padding: 9,
    fontSize: 18,
    backgroundColor: theme.palette.primary.light,
    '&:hover': {
      backgroundColor: darken(theme.palette.primary.light, 0.1),
    },
  },
  updateButton: {
    minWidth: '0px',
    width: 'auto',
  },
  toolbar: {
    borderRadius: '5px',
  },
  editorError: {
    fontSize: '0.75rem',
    color: theme.palette.error.main,
    margin: '4px 14px 0px',
  },
  content: {
    backgroundColor: '#fff',
    height: 'calc(100vh - 220px)',
    minHeight: '300px',
    padding: 30,
  },
  retailerLocation: {
    'white-space': 'nowrap',
    overflow: 'hidden',
    'text-overflow': 'ellipsis',
    maxWidth: '18vw',
  },
}));

export default Retailers;
