export const startOfDayTimestamp = (date: Date | string): number => {
  if (typeof date === 'string') {
    return new Date(date).valueOf();
  }
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate()
  ).valueOf();
};

export const getDate = (date?: any): Date => {
  if (!date) {
    return new Date();
  }
  try {
    return new Date(date);
  } catch (error) {
    return new Date();
  }
};
