import {useMemo} from 'react';
import {ICurrency} from 'types';
import {useConfigData} from './useConfigData';
import {sort} from 'utils/Array';

const formatCurrencyListToOpitons = (
  currencyList: ICurrency[]
): {key: string; value: string; text: string; symbol: string}[] => {
  return sort(currencyList, 'currencyCode').map(currency => ({
    key: currency.id,
    text: currency.currencyCode,
    value: currency.currencyCode,
    symbol: currency.currencySymbol,
  }));
};

export const useCurrencyOptions = () => {
  const {currencyList} = useConfigData();
  const currencyOptions = useMemo(() => {
    return formatCurrencyListToOpitons(currencyList);
  }, [currencyList]);

  return currencyOptions;
};
