import * as React from 'react';
import {makeStyles, darken} from '@material-ui/core';
import {useForm, Controller} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  addDays,
  differenceInMilliseconds,
  isValid,
  subDays,
  differenceInSeconds,
  format,
} from 'date-fns';

import DataGrid from 'components/DataGrid';
import {requiredFieldMessage} from 'utils/messages';
import {startOfDayTimestamp, getDate} from 'utils/Date';
import {DATE_FORMAT} from 'utils/regex';

import {Typography, Grid, TextField, Select, MenuItem} from '@material-ui/core';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';

import Button from 'components/Button';
import {consumerActivitieService} from 'services/consumerActivitieService';
import {ConsumerActivitySearchResponses_Mock} from 'types';
import {useCountryOptions} from 'hooks/useCountryOptions';

type FilterInputs = {
  status: 'active' | 'inactive';
  name: string;
  sfId: string;
  acivityFrom: Date | null;
  acivityTo: Date | null;
  country: string;
  city: string;
  activity: 'loggedIn';
};

const filterSchema = yup.object().shape({
  status: yup.string().trim().required(requiredFieldMessage('Status')),
  name: yup.string(),
  sfId: yup.string(),
  city: yup.string(),
  country: yup.string(),

  acivityFrom: yup
    .date()
    .nullable()
    .default(undefined)
    .typeError('Invalid date')
    .test('createdFromDateInvalid', 'Invalid date', function () {
      // validate Date
      const yupContext = this as any;
      if (typeof yupContext.originalValue === 'string') {
        return DATE_FORMAT.test(yupContext.originalValue);
      }
      return true;
    })
    .test(
      'createdToMin',
      'Registered From should not be a future date',
      function () {
        // Min date validation
        if (isValid(this.parent.acivityFrom)) {
          return (
            differenceInMilliseconds(new Date(), this.parent.acivityFrom) >= 0
          );
        }
        return true;
      }
    ),

  acivityTo: yup
    .date()
    .typeError('Invalid date ')
    .nullable()
    .default(undefined)
    .test('createdFromDateInvalid', 'Invalid date', function () {
      // validate Date
      const yupContext = this as any;
      if (typeof yupContext.originalValue === 'string') {
        return DATE_FORMAT.test(yupContext.originalValue);
      }
      return true;
    })
    .test(
      'createdToMin',
      'Registered To should not be a future date',
      function () {
        // Min date validation
        if (isValid(this.parent.acivityTo)) {
          return (
            differenceInMilliseconds(new Date(), this.parent.acivityTo) >= 0
          );
        }
        return true;
      }
    )
    .test(
      'createdToMin',
      'Registered To cannot be before Registered From',
      function () {
        // Min date validation
        if (
          isValid(this.parent.acivityFrom) &&
          isValid(this.parent.acivityTo)
        ) {
          return (
            differenceInMilliseconds(
              startOfDayTimestamp(this.parent.acivityTo),
              startOfDayTimestamp(this.parent.acivityFrom)
            ) >= 0
          );
        }
        return true;
      }
    ),
});

const statusOptions = [
  {
    key: 'active',
    value: 'active',
    text: 'Active',
  },
  {
    key: 'inactive',
    value: 'inactive',
    text: 'Inactive',
  },
];
const activityOptions = [
  {
    key: 'loggedIn',
    value: 'loggedIn',
    text: 'Login',
  },
  // {
  //   key: 'loggedOut',
  //   value: 'loggedOut',
  //   text: 'Logout',
  // },
];

const ConsumerActivities: React.FC = () => {
  const countryOptions = useCountryOptions();
  const {
    control,
    handleSubmit,
    reset,
    watch,
    getValues,
    setValue,
  } = useForm<FilterInputs>({
    mode: 'all',
    resolver: yupResolver(filterSchema),
    defaultValues: {
      status: 'active',
      name: '',
      sfId: '',
      country: '',
      acivityFrom: subDays(new Date(startOfDayTimestamp(new Date())), 7),
      acivityTo: new Date(startOfDayTimestamp(new Date())),
      city: '',
      activity: 'loggedIn',
      // acivityFrom: subDays(new Date(startOfDayTimestamp(new Date())), 30),
      // acivityTo: new Date(startOfDayTimestamp(new Date())),
    },
  });

  const classes = useStyles();
  const [data, setData] = React.useState<ConsumerActivitySearchResponses_Mock>({
    users: [],
  });
  const [isFetching, setIsFetching] = React.useState(false);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    getConsumers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getConsumers = async () => {
    try {
      setIsFetching(true);
      const response = await consumerActivitieService.search({
        status: getValues('status'),
        name: getValues('name').trim() || '',
        sfId: getValues('sfId').trim() || '',
        acivityFrom: getValues('acivityFrom')
          ? startOfDayTimestamp(getValues('acivityFrom') || '')
          : undefined,
        acivityTo: getValues('acivityFrom')
          ? startOfDayTimestamp(
              addDays(new Date(getValues('acivityTo') || ''), 1)
            )
          : undefined,
        country: getValues('country').trim(),
        city: getValues('city').trim(),
        // activiy: getValues('activity').trim(),
      });
      setData(response);
    } finally {
      setIsFetching(false);
    }
  };

  const resetFilters = () => {
    reset({
      status: 'active',
      name: '',
      sfId: '',
      country: '',
      acivityFrom: null,
      acivityTo: null,
      city: '',
      activity: 'loggedIn',
    });
  };

  const handleClearFilter = async (event: React.MouseEvent) => {
    resetFilters();
    getConsumers();
  };

  const onSubmit = async (data: FilterInputs) => {
    getConsumers();
  };

  return (
    <div>
      <Typography component={'h1'} className={classes.title}>
        Consumer Activities
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container className={classes.filterContainer} spacing={2}>
          <Grid item xs={9}>
            <Typography className={classes.filterText} component={'h2'}>
              Filter
            </Typography>
          </Grid>
          <Grid
            container
            xs={3}
            spacing={2}
            alignItems="center"
            justify="flex-end"
          >
            <Grid item>
              <Button
                variant="outlined"
                color="inherit"
                fullWidth
                minWidth="0px"
                onClick={handleClearFilter}
                className={classes.cancelButton}
              >
                Clear
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                minWidth="0px"
                fullWidth
              >
                {'Apply'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={classes.filterOptionContainer} spacing={2}>
          <Grid item xs={3} className={classes.formElement}>
            <Typography className={classes.formLabel}>{'Status'}</Typography>
            <Controller
              name="status"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <>
                  <Select
                    value={value}
                    onChange={onChange}
                    displayEmpty
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    className={classes.select}
                    error={!!error}
                  >
                    {statusOptions.map(country => (
                      <MenuItem value={country.value} key={country.key}>
                        {country.text}
                      </MenuItem>
                    ))}
                  </Select>
                  {error?.message && (
                    <Typography
                      component={'p'}
                      className={classes.errorMessage}
                    >
                      {error.message}
                    </Typography>
                  )}
                </>
              )}
            />
          </Grid>

          <Grid item xs={3} className={classes.formElement}>
            <Typography className={classes.formLabel}>First Name</Typography>
            <Controller
              name="name"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <>
                  <TextField
                    variant="outlined"
                    type="text"
                    placeholder="Add First Name"
                    fullWidth
                    margin="dense"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error?.message}
                  />
                </>
              )}
            />
          </Grid>

          <Grid item xs={3} className={classes.formElement}>
            <Typography className={classes.formLabel}>Activity From</Typography>
            <Controller
              name="acivityFrom"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    inputFormat="dd-MMM-yyyy"
                    className={classes.datePicker}
                    disableFuture
                    allowSameDateSelection
                    value={value}
                    clearable
                    onChange={onChange}
                    maxDate={getDate(watch('acivityTo'))}
                    renderInput={(params: any) => (
                      <TextField
                        variant="outlined"
                        type="text"
                        placeholder="Add Registered From"
                        fullWidth
                        margin="dense"
                        value={value}
                        onChange={(event: any) => {
                          if (!event.target.value) {
                            setValue('acivityFrom', null);
                            return;
                          }
                          onChange(event);
                        }}
                        {...params}
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                </LocalizationProvider>
              )}
            />
          </Grid>
          <Grid item xs={3} className={classes.formElement}>
            <Typography className={classes.formLabel}>Activity To</Typography>
            <Controller
              name="acivityTo"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    inputFormat="dd-MMM-yyyy"
                    className={classes.datePicker}
                    disableFuture
                    allowSameDateSelection
                    minDate={getDate(watch('acivityFrom'))}
                    value={value}
                    clearable
                    onChange={onChange}
                    renderInput={(params: any) => (
                      <TextField
                        variant="outlined"
                        type="text"
                        placeholder="Add Registered To"
                        fullWidth
                        margin="dense"
                        value={value}
                        onChange={(event: any) => {
                          if (!event.target.value) {
                            setValue('acivityTo', null);
                            return;
                          }
                          onChange(event);
                        }}
                        {...params}
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                </LocalizationProvider>
              )}
            />
          </Grid>

          <Grid item xs={3} className={classes.formElement}>
            <Typography className={classes.formLabel}>Country</Typography>
            <Controller
              name="country"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <Select
                  value={value}
                  onChange={onChange}
                  displayEmpty
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  className={classes.select}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {countryOptions.map(country => (
                    <MenuItem value={country.value} key={country.key}>
                      {country.text}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Grid>

          <Grid item xs={3} className={classes.formElement}>
            <Typography className={classes.formLabel}>City</Typography>
            <Controller
              name="city"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <>
                  <TextField
                    variant="outlined"
                    type="text"
                    placeholder="Enter City"
                    fullWidth
                    margin="dense"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error?.message}
                  />
                </>
              )}
            />
          </Grid>

          <Grid item xs={3} className={classes.formElement}>
            <Typography className={classes.formLabel}>Stamp Free ID</Typography>
            <Controller
              name="sfId"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <>
                  <TextField
                    variant="outlined"
                    type="text"
                    placeholder="Add Stamp Free ID"
                    fullWidth
                    margin="dense"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error?.message}
                  />
                </>
              )}
            />
          </Grid>

          <Grid item xs={3} className={classes.formElement}>
            <Typography className={classes.formLabel}>{'Activity'}</Typography>
            <Controller
              name="activity"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <>
                  <Select
                    value={value}
                    onChange={onChange}
                    displayEmpty
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    className={classes.select}
                    error={!!error}
                  >
                    {activityOptions.map(country => (
                      <MenuItem value={country.value} key={country.key}>
                        {country.text}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              )}
            />
          </Grid>
        </Grid>
      </form>
      <div className={classes.tableContainer}>
        <DataGrid
          columns={[
            {
              Header: 'Name',
              accessor: (row: any) =>
                `${row.consumerUser.firstName || ''} ${
                  row.consumerUser.lastName || ''
                }`,
              maxWidth: 10,
            },
            {
              Header: 'Country',
              accessor: 'country',
              maxWidth: 10,
            },
            {
              Header: 'City',
              accessor: 'city',
              maxWidth: 10,
            },
            {
              Header: 'Stamp Free ID',
              accessor: 'sfId',
              maxWidth: 10,
            },
            {
              Header: 'Activity',
              accessor: (row: any) => `Login`,
              maxWidth: 10,
            },
            {
              Header: 'Date (Time)',
              accessor: (row: any) =>
                `${format(new Date(row.logIn), 'dd-MMM-yyyy (HH:mm:ss)')}`,
              maxWidth: 10,
            },
            {
              Header: 'Duration',
              accessor: (row: any) =>
                `${Math.floor(
                  differenceInSeconds(
                    new Date(row.logOut),
                    new Date(row.logIn)
                  ) / 3600
                )
                  .toString()
                  .padStart(2, '0')}:${Math.floor(
                  (differenceInSeconds(
                    new Date(row.logOut),
                    new Date(row.logIn)
                  ) %
                    3600) /
                    60
                )
                  .toString()
                  .padStart(2, '0')}:${(
                  differenceInSeconds(
                    new Date(row.logOut),
                    new Date(row.logIn)
                  ) % 60
                )
                  .toString()
                  .padStart(2, '0')}`,
              maxWidth: 10,
            },
          ]}
          data={data?.users || []}
          recordCount={isFetching ? 0 : data?.users?.length || 0}
          defaultPageSize={5}
          isLoading={isFetching}
          onPaginationChange={() => {}}
          // extraActions={[
          //   {
          //     label: 'Action',
          //     type: 'icon-button',
          //     buttonContent: <VisibilityIcon />,
          //     handler: (row: Row<any>) => {
          //       if (row.original.id) {
          //         history.push(`/consumer/${row.original.id}`);
          //       } else {
          //         toast.error(ERROR_MESSAGE);
          //       }
          //     },
          //   },
          // ]}
          // maxHeight="65vh"
          noDataMessage={'No items available'}
        />
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 26,
    lineHeight: '40px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
      lineHeight: '28px',
    },
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(3),
  },
  errorMessage: {
    color: theme.palette.error.main,
    margin: '0 14px',
    fontSize: '0.75rem',
  },
  tabs: {
    marginBottom: '43px',
  },
  tab: {
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: 14,
    lineHeight: '20px',
  },
  filterContainer: {
    width: '100%',
    background: '#fff',
    borderRadius: '5px 5px 0 0',
    padding: '12px 16px 15px',
    margin: 0,
    border: '1px solid',
    borderBottom: '0px',
    borderColor: theme.palette.grey[300],
  },
  filterOptionContainer: {
    width: '100%',
    background: '#fff',
    borderRadius: '0 0 5px 5px',
    padding: '12px 16px 15px',
    margin: 0,
    marginBottom: theme.spacing(3),
    border: '1px solid',
    borderColor: theme.palette.grey[300],
  },
  filterText: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '21px',
    lineHeight: '32px',
  },
  formElement: {},
  formLabel: {
    fontSize: 16,
    lineHeight: '28px',
  },
  hash: {
    backgroundColor: theme.palette.grey[400],
  },
  adornmentTextField: {
    width: '100%',
    '& span': {
      backgroundColor: theme.palette.grey[400],
    },
  },
  select: {
    marginTop: '8px',
    marginBottom: '4px',
  },
  datePicker: {
    backgroundColor: 'pink',
    '&& .MuiPickersDay-root.Mui-selected': {
      backgroundColor: 'red !important',
      '&:hover': {
        backgroundColor: 'red !important',
      },
      '&:focus': {
        backgroundColor: 'red !important',
      },
    },
  },
  textFieldSkeleton: {
    width: '100%',
    height: 40,
    borderRadius: 4,
    marginTop: 8,
    marginBottom: 4,
  },
  tableContainer: {
    marginTop: theme.spacing(3),
  },
  name: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.primary.main,
  },
  dashboardText: {
    fontSize: 14,
    lineHeight: '20px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      lineHeight: '14px',
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginBottom: theme.spacing(1),
  },
  subjectText: {
    fontSize: 21,
    lineHeight: '32px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
      lineHeight: '24px',
    },
    fontWeight: theme.typography.fontWeightBold,
  },
  subjectInput: {
    margin: 0,
  },
  cancelButton: {
    color: theme.palette.grey[500],
  },
  editButton: {
    minWidth: '0px',
    width: 'auto',
    padding: 9,
    fontSize: 18,
    backgroundColor: theme.palette.primary.light,
    '&:hover': {
      backgroundColor: darken(theme.palette.primary.light, 0.1),
    },
  },
  updateButton: {
    minWidth: '0px',
    width: 'auto',
  },
  toolbar: {
    borderRadius: '5px',
  },
  editorError: {
    fontSize: '0.75rem',
    color: theme.palette.error.main,
    margin: '4px 14px 0px',
  },
  content: {
    backgroundColor: '#fff',
    height: 'calc(100vh - 220px)',
    minHeight: '300px',
    padding: 30,
  },
}));

export default ConsumerActivities;
