import axios from 'lib/axios';
import type {Address, Pudo, PudoDetailsResponse_Mock} from 'types';

type UpdateLocationDTO = Pick<
  Address,
  | 'postcode'
  | 'country'
  | 'addressLine1'
  | 'addressLine2'
  | 'addressLine3'
  | 'city'
> & {firstName: string; status: string};

type LocationDTO = UpdateLocationDTO & {email: string};

type UpdatePudoDTO = Omit<
  Pudo,
  'updatedBy' | 'locations' | 'createdAt' | 'updatedAt' | 'id'
>;

type addCarriers = {
  carriers: string[];
};

type AddPudoDTO = {
  name: string;
  iconUrl: string;
  status: 'active' | 'inactive';
  pudoUsers: LocationDTO[];
  carriers: addCarriers;
};

class PudoService {
  async search({
    status,
    location,
    name,
  }: {
    status: string;
    location?: string;
    name?: string;
  }) {
    const response = await axios.post('cc/pudos', {
      status,
      ...(location ? {location} : {}),
      ...(name ? {name} : {}),
    });
    return response.data;
  }

  async get(id: string) {
    const response = await axios.get<PudoDetailsResponse_Mock>(`cc/pudo/${id}`);
    return response.data;
  }

  async delete(pudoId: string, carrierId: string) {
    const response = await axios.delete(
      `/cc/pudo/${pudoId}/carrier/${carrierId}`
    );
    return response.data;
  }

  async addCarriers({carriers, pudoId}: {carriers: string[]; pudoId: string}) {
    const response = await axios.post(`cc/pudo/${pudoId}/carrier`, {
      carriers,
    });
    return response.data;
  }

  async editLocation(
    pudoId: string,
    locationId: string,
    data: UpdateLocationDTO
  ) {
    const response = await axios.put(
      `cc/pudo/${pudoId}/location/${locationId}`,
      data
    );
    return response.data;
  }

  async addLocation(pudoId: string, data: LocationDTO) {
    const response = await axios.post(`/cc/pudo/${pudoId}/location`, data);
    return response.data;
  }

  async editPudo(pudoId: string, data: UpdatePudoDTO) {
    const response = await axios.put(`/cc/pudo/${pudoId}`, data);
    return response.data;
  }

  async addPudo(data: AddPudoDTO) {
    const response = await axios.post(`/cc/pudo`, data);
    return response.data;
  }
}

export const pudoService = new PudoService();
