import {useMemo} from 'react';
import {Carrier_List_Mock} from 'types';
import {useQuery} from 'react-query';
import {carrierService} from 'services/carrierService';

const formatCarrierListToOptions = (
  carrierList: Carrier_List_Mock[]
): {key: string; value: string; text: string}[] => {
  return carrierList.map(carrier => ({
    key: carrier.id,
    text: carrier.name,
    value: carrier.id,
  }));
};

export const useCarrierOptions = () => {
  const {data: carriersData, status: carriersStatus} = useQuery(
    ['carriers'],
    () => {
      return carrierService.getCarriers();
    },
    {retry: false}
  );

  const carrierOptions = useMemo(() => {
    return carriersData?.carriers
      ? formatCarrierListToOptions(carriersData?.carriers)
      : [];
  }, [carriersData]);

  return {carriersStatus, carrierOptions};
};
