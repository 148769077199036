import axios from 'lib/axios';

import type {Location} from 'types';

type LocationDTO = Pick<
  Location,
  'postcode' | 'country' | 'addressLine1' | 'addressLine2' | 'addressLine3'
> & {city: string};

class LocationService {
  async add(carrierId: string, data: LocationDTO) {
    const response = await axios.post<Location>(
      `/cc/carrier/${carrierId}/location`,
      data
    );
    return response.data;
  }

  async edit(carrierId: string, locationId: string, data: LocationDTO) {
    const response = await axios.put<Location>(
      `/cc/carrier/${carrierId}/location/${locationId}`,
      data
    );
    return response.data;
  }

  async delete(carrierId: string, locationId: string) {
    const response = await axios.delete(
      `/cc/carrier/${carrierId}/location/${locationId}`
    );
    return response.data;
  }
}

export const locationService = new LocationService();
