import * as React from 'react';
import {makeStyles, Divider} from '@material-ui/core';
import clsx from 'clsx';
import {useQuery} from 'react-query';
import {toast} from 'react-hot-toast';
import {useForm, Controller} from 'react-hook-form';
import {Link, useLocation, useHistory, useParams} from 'react-router-dom';
import {Storage} from 'aws-amplify';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {lookupService} from 'services/lookupService';
import imagePlaceholder from 'assets/image-placeholder.png';
import {countDecimals} from 'utils/Number';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import {Skeleton} from '@material-ui/lab';
import {
  Typography,
  Grid,
  TextField,
  FormControlLabel,
  Radio,
  RadioGroup,
  Select,
  MenuItem,
  Breadcrumbs,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';

import Button from 'components/Button';
import Image from 'components/Image';
import ScreenSpinner from 'components/ScreenSpinner';
import {containDigits} from 'utils/String';
import {getS3ObjectId} from 'utils/helper';
import {RetailerProduct, ReturnParcelItem} from 'types';
import {useCountryOptions} from 'hooks/useCountryOptions';
import {useCarrierOptions} from 'hooks/useCarrierOptions';
import {currency} from 'utils/currency';

type ReturnReason = {
  id: string;
  reason: string;
};

type CodeDetails = {
  codeType: string;
  codeStatus: string;
  itemType: string;
  carrier: string;
  country: string;
  city: string;
  postcode: string;
  parcelStatus: string;
  speedOfService: string;
  shippingCost: string;
  currencyCode: string;
  shippingStatus: string;
  recipientName: string;
  createdDate: Date | null;
  uniqueId: string;
  description: string;
  quantity: string;
  retailer: string;
  returnReasons: ReturnReason[];
  senderName: string;
  senderAddress: string;
  items: string[];
  collectParcel: string;
};

const parcelStatusOptions = [
  {
    key: 'PENDING',
    value: 'pending',
    text: 'Pending',
  },
  {
    key: 'COLLECTED',
    value: 'collected',
    text: 'Collected',
  },
  {
    key: 'RECEIVED',
    value: 'received',
    text: 'Received',
  },
  {
    key: 'VALIDATED',
    value: 'validated',
    text: 'Validated',
  },
  {
    key: 'SORT',
    value: 'sort',
    text: 'Sort',
  },
  {
    key: 'AT LOCAL DEPOT',
    value: 'atLocalDepot',
    text: 'At local depot',
  },
  {
    key: 'OUT FOR DELIVERY',
    value: 'outForDelivery',
    text: 'Out for delivery',
  },
  {
    key: 'UNDELIVERED / READY FOR COLLECTION',
    value: 'undeliverd/readyForCollection',
    text: 'Undelivered / Ready for collection',
  },
  {
    key: 'DELIVERED',
    value: 'delivered',
    text: 'Delivered',
  },
];

// Add method to handle empty string
yup.addMethod(yup.string, 'stripEmptyString', function () {
  return this.transform(value => (value === '' ? undefined : value));
});

const schema = yup.object().shape({
  country: yup.string().notRequired(),
  city: yup
    .string()
    .notRequired()
    .when(['country', 'codeType'], {
      is: (country: string, codeType: string) =>
        country.toUpperCase() !== 'UNITED KINGDOM' && codeType === 'sendParcel',
      then: yup.string().trim(),
    })
    .test({
      name: 'testCityLength',
      test: function (city?: string) {
        if (!city) return true;
        if (city.length < 3 || city.length > 50) {
          return this.createError({
            message: 'City should have minimum 3 and maximum 50 characters',
          });
        }
        return true;
      },
    }),
  speedOfService: yup.string().when('codeType', {
    is: (codeType: string) => codeType === 'sendParcel',
    then: yup.string().trim().notRequired(),
  }),
  shippingCost: yup.string().when('codeType', {
    is: (codeType: string) => codeType === 'sendParcel',
    then: yup
      .string()
      .notRequired()
      .test({
        name: 'shippingCostValidation',
        test: function (shippingCost?: string) {
          if (!shippingCost) return true;
          const formattedShippingCost = Number(shippingCost);
          if (isNaN(formattedShippingCost)) {
            return this.createError({
              message: 'Please add valid Shipping Cost',
            });
          }
          if (formattedShippingCost <= 0) {
            return this.createError({
              message: 'Shipping Cost should be greater than 0',
            });
          }
          if (countDecimals(formattedShippingCost) > 2) {
            return this.createError({
              message: 'Maximum 2 decimal places allowed',
            });
          }
          return true;
        },
      }),
  }),
  recipientName: yup.string().notRequired(),
  quantity: yup.string().when(['country', 'codeType'], {
    is: (country: string, codeType: string) =>
      country.toUpperCase() !== 'UNITED KINGDOM' && codeType === 'sendParcel',
    then: yup.string().trim().notRequired(),
  }),
  description: yup
    .string()
    .when(['country', 'codeType'], {
      is: (country: string, codeType: string) =>
        country.toUpperCase() !== 'UNITED KINGDOM' && codeType === 'sendParcel',
      then: yup.string().trim().notRequired(),
    })
    .test({
      name: 'testDescriptionLength',
      test: function (description?: string) {
        if (!description) return true;
        if (description.length < 3 || description.length > 255) {
          return this.createError({
            message:
              'Custom Description should have minimum 3 and maximum 255 characters',
          });
        }
        return true;
      },
    }),
  postcode: yup
    .string()
    .notRequired()
    .max(15, 'Maximum 15 characters are allowed'),
});

const LookupItem: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const codeType = location.pathname.includes('returned')
    ? 'returnParcel'
    : 'sendParcel';
  const {codeId} = useParams<{codeId: string}>();
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: {submitCount},
  } = useForm<CodeDetails>({
    resolver: yupResolver(schema),
    defaultValues: {
      codeType: '',
      codeStatus: '',
      itemType: '',
      carrier: '',
      country: '',
      city: '',
      postcode: '',
      parcelStatus: '',
      speedOfService: '',
      shippingCost: '',
      shippingStatus: '',
      recipientName: '',
      createdDate: null,
      uniqueId: '',
      description: '',
      quantity: '',
      retailer: '',
      returnReasons: [],
      senderName: '',
      senderAddress: '',
      items: [],
      collectParcel: '',
      currencyCode: 'GBP',
    },
  });

  const countryOptions = useCountryOptions();
  const {carrierOptions, carriersStatus} = useCarrierOptions();

  const classes = useStyles();
  const [code, setCode] = React.useState('');
  const [updating, setUpdating] = React.useState(false);
  const [records, setRecords] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [selectedReasons, setSelectedReasons] = React.useState<any[]>([]);

  const {data, status, refetch} = useQuery(
    ['look-up-item', codeType, codeId],
    () => {
      return lookupService.getCodeDetails(codeType, codeId);
    },
    {
      retry: false,
    }
  );

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    setData();

    (async () => {
      let recordsWithUrl: any[] = [];
      if (submitCount !== 0) {
        setLoading(false);
        return;
      }
      if (data && data.statusRecords && data.statusRecords.length > 0) {
        let promiseArray = [];
        for (const record of data.statusRecords) {
          promiseArray.push(
            new Promise(async resolve => {
              let signedUrl = null;
              try {
                if (record.url) {
                  signedUrl = await Storage.get(getS3ObjectId(record.url));
                }
              } catch (error) {
                signedUrl = null;
              }
              const newAction =
                parcelStatusOptions.find(
                  parcelStatus => parcelStatus.value === record.action
                )?.text || '';
              resolve({
                ...record,
                url: signedUrl,
                action: newAction,
              });
            })
          );
        }
        recordsWithUrl = await Promise.all(promiseArray);
      }

      // Fetch image used while activating code by consumer
      if (codeType === 'returnParcel' && data?.s3Image) {
        const imageSignedUrl = await Storage.get(getS3ObjectId(data.s3Image), {
          bucket: data.s3Image.startsWith('public/FAILED_')
            ? process.env.REACT_APP_EASYCODE_VERIFICAION_FAILED_BUCKET
            : process.env.REACT_APP_EASYCODE_VERIFICAION_BUCKET,
        });
        recordsWithUrl.push({
          url: imageSignedUrl,
          user: {
            firstName: data.sender.firstName,
            lastName: data.sender.lastName,
            id: data.sender.id,
          },
          createdAt: data.createdAt,
          action: data.s3Image.startsWith('public/FAILED_')
            ? 'Pending'
            : 'Validated',
        });
      } else if (codeType === 'sendParcel' && data?.s3Image) {
        const imageSignedUrl = await Storage.get(getS3ObjectId(data.s3Image), {
          bucket: data.s3Image.startsWith('public/FAILED_')
            ? process.env.REACT_APP_DIGICODE_VERIFICAION_FAILED_BUCKET
            : process.env.REACT_APP_DIGICODE_VERIFICAION_BUCKET,
        });
        recordsWithUrl.push({
          url: imageSignedUrl,
          user: {
            firstName: data.user.firstName,
            lastName: data.user.lastName,
            id: data.user.id,
          },
          createdAt: data.createdAt,
          action: data.s3Image.startsWith('public/FAILED_')
            ? 'Pending'
            : 'Validated',
        });
      }
      setRecords(recordsWithUrl);
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const setData = () => {
    if (!data) return;
    setValue('codeType', codeType);
    if (codeType === 'sendParcel') {
      setCode(data.sfCode || '');
      setValue('codeStatus', data.status === 'expired' ? 'EXPIRED' : 'ACTIVE');
      setValue('carrier', data.carrier.id);
      setValue(
        'itemType',
        data?.product ? `${data.product.type} - ${data.product.name}` : ''
      );
      setValue(
        'collectParcel',
        data.collectParcel === true
          ? 'Yes'
          : data.collectParcel === false
          ? 'No'
          : '-'
      );
      setValue(
        'country',
        data.country?.toUpperCase() === 'UNITED KINGDOM' ||
          data.country?.toUpperCase() === 'UK'
          ? 'United Kingdom'
          : data.country || ''
      );
      setValue('city', data.city || '');
      setValue('postcode', data.postcode || '');
      setValue('parcelStatus', data.parcelStatus || '');
      setValue('speedOfService', data.deliveryTime || '');
      setValue(
        'shippingCost',
        data.amount
          ? data?.payment?.currencyCode === 'KZT'
            ? `${data.amount}`
            : `${data.amount / 100}`
          : ''
      );
      setValue('currencyCode', data?.payment?.currencyCode ?? 'GBP');
      setValue(
        'shippingStatus',
        data.status.toUpperCase() === 'EXPIRED' ||
          data.status.toUpperCase() === 'FAILED'
          ? 'EXPIRED'
          : ['ACTIVE', 'MATCHED', 'PAID', 'RESERVED'].includes(
              data.status.toUpperCase()
            )
          ? 'PAID'
          : data.status.toUpperCase() === 'PENDING'
          ? 'PENDING'
          : ''
      );
      setValue('recipientName', data.recipientName || '');
      setValue('createdDate', new Date(data.createdAt));
      setValue('uniqueId', data.id);
      setValue('quantity', data.quantity || '');
      setValue('description', data.description || '');
    } else {
      setCode(data.sfCode || '');
      setValue('codeStatus', data.status === 'expired' ? 'EXPIRED' : 'ACTIVE');
      setValue('retailer', data.retailer.name || '');
      setValue('itemType', data.parcelType || '');
      setValue('carrier', data.carrier.id);
      setValue(
        'country',
        data.country?.toUpperCase() === 'UNITED KINGDOM' ||
          data.country?.toUpperCase() === 'UK'
          ? 'United Kingdom'
          : data.country || ''
      );
      setValue(
        'collectParcel',
        data.collectParcel === true
          ? 'Yes'
          : data.collectParcel === false
          ? 'No'
          : ''
      );
      setValue('city', data.city || '');
      setValue('postcode', data.postcode || '');
      setValue('parcelStatus', data.parcelStatus || '');
      setValue(
        'senderName',
        `${data.sender.firstName || ''} ${data.sender.lastName || ''}`
      );
      setValue(
        'senderAddress',
        `${data.sender.addressDetail ? data.sender.addressDetail + ', ' : ''}${
          data.sender.city ? data.sender.city + ', ' : ''
        }${data.sender.postcode ? data.sender.postcode + ', ' : ''}${
          data.sender.country?.name || ''
        }`
      );
      setValue('createdDate', new Date(data.createdAt));
      setValue('uniqueId', data.id);
      setValue('items', data.items);
      setSelectedReasons(
        data.items.map((item: ReturnParcelItem) => ({
          id: item.id,
          reason: item.reason,
        }))
      );
    }
  };

  const handleCancel = (event: React.MouseEvent) => {
    history.push('/look-up');
  };

  const onSubmit = async (data: CodeDetails) => {
    try {
      setUpdating(true);
      const {
        parcelStatus,
        city,
        country,
        description,
        quantity,
        recipientName,
        itemType,
        postcode,
        speedOfService,
        currencyCode,
      } = data;
      const shippingCost = data.shippingCost.trim();
      await lookupService.updateCodeDetails({
        codeType: codeType.trim(),
        codeId: codeId.trim(),
        city: city.trim(),
        country: country.trim(),
        description: description.trim(),
        parcelStatus: parcelStatus.trim(),
        quantity: quantity.trim(),
        recipientName: recipientName.trim(),
        shippingCost:
          currencyCode === 'KZT'
            ? Number(shippingCost)
            : Math.round(Number(shippingCost) * 100),
        parcelType: codeType === 'returnParcel' ? itemType.trim() : '',
        postcode: postcode.trim(),
        speedOfService: codeType === 'sendParcel' ? speedOfService.trim() : '',
        returnReasons: codeType === 'returnParcel' ? selectedReasons : [],
      });
      refetch({
        throwOnError: true,
        cancelRefetch: true,
      });

      toast.success('Item details updated successfully', {duration: 3000});
    } finally {
      setUpdating(false);
    }
  };

  const openImage = (url: any) => {
    if (!url) return;
    window.open(url, '_blank');
  };

  const handleReasonChange = (event: any, rowIndex: number) => {
    const {value} = event.target;
    setSelectedReasons(prevState => {
      const newState = [...prevState];
      newState[rowIndex].reason = value;
      return newState;
    });
  };

  return (
    <div>
      {(status === 'loading' || updating || loading) && <ScreenSpinner />}
      <Typography component={'h1'} className={classes.title}>
        {code}
      </Typography>
      <Breadcrumbs className={classes.breadcrumb} aria-label="breadcrumb">
        <Link className={classes.breadcrumbLink} to="/look-up">
          Lookup
        </Link>
        <Typography className={classes.breadcrumbText}>
          {codeType === 'returnParcel' ? 'Returned Items' : 'Sent Items'}
        </Typography>
        <Typography className={classes.breadcrumbText}>{code}</Typography>
      </Breadcrumbs>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container className={classes.filterContainer} spacing={2}>
          <Grid item xs={9}>
            <Typography className={classes.filterText} component={'h2'}>
              Parcel Details
            </Typography>
          </Grid>
          {getValues('codeStatus') === 'ACTIVE' && (
            <Grid
              container
              xs={3}
              spacing={2}
              alignItems="center"
              justify="flex-end"
            >
              <Grid item>
                <Button
                  variant="outlined"
                  color="inherit"
                  fullWidth
                  className={classes.cancelButton}
                  minWidth="0px"
                  onClick={handleCancel}
                >
                  {'Close'}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  minWidth="0px"
                  fullWidth
                >
                  {'Save'}
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid container className={classes.filterOptionContainer} spacing={2}>
          <Grid item xs={3} className={classes.formElement}>
            <Typography
              className={clsx(classes.formLabel, classes.disableLabel)}
            >
              {'Code Status'}
            </Typography>
            <Controller
              name="codeStatus"
              control={control}
              render={({field: {value}, fieldState: {error}}) => (
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={value}
                >
                  <FormControlLabel
                    value="ACTIVE"
                    disabled
                    control={<Radio color="primary" />}
                    label={`Active${value === 'ACTIVE' ? ' (selected)' : ''}`}
                  />
                  <FormControlLabel
                    value="EXPIRED"
                    disabled
                    control={<Radio color="primary" />}
                    label={`Expired${value === 'EXPIRED' ? ' (selected)' : ''}`}
                  />
                </RadioGroup>
              )}
            />
          </Grid>
          {codeType === 'returnParcel' && (
            <Grid item xs={3} className={classes.formElement}>
              <Typography
                className={clsx(classes.formLabel, classes.disableLabel)}
              >
                Retailer
              </Typography>
              <Controller
                name="retailer"
                control={control}
                render={({field: {value, onChange}, fieldState: {error}}) => (
                  <TextField
                    variant="outlined"
                    type="text"
                    placeholder="Add Retailer"
                    fullWidth
                    margin="dense"
                    disabled
                    value={value}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid>
          )}
          <Grid item xs={3} className={classes.formElement}>
            <Typography
              className={clsx(classes.formLabel, classes.disableLabel)}
            >
              Carrier
            </Typography>
            {carriersStatus === 'loading' ? (
              <Skeleton variant="rect" className={classes.textFieldSkeleton} />
            ) : (
              <Controller
                name="carrier"
                control={control}
                render={({field: {value, onChange}, fieldState: {error}}) => (
                  <Select
                    value={value}
                    displayEmpty
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    className={classes.select}
                    disabled
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {carrierOptions?.map(carrier => (
                      <MenuItem value={carrier.value} key={carrier.key}>
                        {carrier.text}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            )}
          </Grid>
          <Grid item xs={3} className={classes.formElement}>
            <Typography
              className={clsx(classes.formLabel, classes.disableLabel)}
            >
              {codeType === 'sendParcel' ? 'Item Type' : 'Parcel Type'}
            </Typography>
            <Controller
              name="itemType"
              control={control}
              render={({field: {value}, fieldState: {error}}) => (
                <>
                  <TextField
                    variant="outlined"
                    type="text"
                    placeholder="Add Item Type"
                    fullWidth
                    margin="dense"
                    disabled
                    value={value}
                    error={!!error}
                    helperText={error?.message}
                  />
                </>
              )}
            />
          </Grid>
          <Grid item xs={3} className={classes.formElement}>
            <Typography
              className={clsx(classes.formLabel, classes.disableLabel)}
            >
              Collect Parcel
            </Typography>
            <Controller
              name="collectParcel"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <TextField
                  variant="outlined"
                  type="text"
                  placeholder="Collect Parcel"
                  fullWidth
                  margin="dense"
                  disabled
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={3} className={classes.formElement}>
            <Typography
              className={clsx(
                classes.formLabel,
                getValues('codeStatus') === 'EXPIRED' && classes.disableLabel
              )}
            >
              Country
            </Typography>
            <Controller
              name="country"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <>
                  <Select
                    value={value}
                    onChange={onChange}
                    displayEmpty
                    fullWidth
                    disabled={getValues('codeStatus') === 'EXPIRED'}
                    margin="dense"
                    variant="outlined"
                    className={classes.select}
                    error={!!error}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {countryOptions.map(country => (
                      <MenuItem value={country.value} key={country.key}>
                        {country.text}
                      </MenuItem>
                    ))}
                  </Select>
                  {error?.message && (
                    <Typography
                      component={'p'}
                      className={classes.errorMessage}
                    >
                      {error.message}
                    </Typography>
                  )}
                </>
              )}
            />
          </Grid>
          <Grid item xs={3} className={classes.formElement}>
            <Typography
              className={clsx(
                classes.formLabel,
                getValues('codeStatus') === 'EXPIRED' && classes.disableLabel
              )}
            >
              City
            </Typography>
            <Controller
              name="city"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <TextField
                  variant="outlined"
                  type="text"
                  placeholder="Add City"
                  fullWidth
                  margin="dense"
                  disabled={getValues('codeStatus') === 'EXPIRED'}
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={3} className={classes.formElement}>
            <Typography
              className={clsx(
                classes.formLabel,
                getValues('codeStatus') === 'EXPIRED' && classes.disableLabel
              )}
            >
              Postcode
            </Typography>
            <Controller
              name="postcode"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <TextField
                  variant="outlined"
                  type="text"
                  placeholder="Add Postcode"
                  fullWidth
                  disabled={getValues('codeStatus') === 'EXPIRED'}
                  margin="dense"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={3} className={classes.formElement}>
            <Typography
              className={clsx(
                classes.formLabel,
                getValues('codeStatus') === 'EXPIRED' && classes.disableLabel
              )}
            >
              Parcel Status
            </Typography>
            <Controller
              name="parcelStatus"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <Select
                  value={value}
                  onChange={onChange}
                  displayEmpty
                  fullWidth
                  disabled={getValues('codeStatus') === 'EXPIRED'}
                  margin="dense"
                  variant="outlined"
                  className={classes.select}
                >
                  {parcelStatusOptions.map(parcelStatus => (
                    <MenuItem value={parcelStatus.value} key={parcelStatus.key}>
                      {parcelStatus.text}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Grid>
          {codeType === 'returnParcel' && (
            <Grid item xs={3} className={classes.formElement}>
              <Typography
                className={clsx(classes.formLabel, classes.disableLabel)}
              >
                Sender Name
              </Typography>
              <Controller
                name="senderName"
                control={control}
                render={({field: {value, onChange}, fieldState: {error}}) => (
                  <TextField
                    variant="outlined"
                    type="text"
                    placeholder="Sender Name"
                    fullWidth
                    disabled
                    margin="dense"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid>
          )}
          {codeType === 'returnParcel' && (
            <Grid item xs={3} className={classes.formElement}>
              <Typography
                className={clsx(classes.formLabel, classes.disableLabel)}
              >
                Sender Address
              </Typography>
              <Controller
                name="senderAddress"
                control={control}
                render={({field: {value, onChange}, fieldState: {error}}) => (
                  <TextField
                    variant="outlined"
                    type="text"
                    placeholder="Sender Address"
                    fullWidth
                    disabled
                    margin="dense"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid>
          )}
          {codeType === 'sendParcel' && (
            <Grid item xs={3} className={classes.formElement}>
              <Typography
                className={clsx(
                  classes.formLabel,
                  getValues('codeStatus') === 'EXPIRED' && classes.disableLabel
                )}
              >
                Speed of Service
              </Typography>
              <Controller
                name="speedOfService"
                control={control}
                render={({field: {value, onChange}, fieldState: {error}}) => (
                  <TextField
                    type="text"
                    value={value}
                    onChange={onChange}
                    fullWidth
                    disabled={getValues('codeStatus') === 'EXPIRED'}
                    margin="dense"
                    variant="outlined"
                    helperText={error?.message}
                    error={!!error}
                    placeholder="Speed of Service"
                  />
                )}
              />
            </Grid>
          )}
          {codeType === 'sendParcel' && (
            <Grid item xs={3} className={classes.formElement}>
              <Typography
                className={clsx(
                  classes.formLabel,
                  getValues('codeStatus') === 'EXPIRED' && classes.disableLabel
                )}
              >
                Shipping Cost
              </Typography>
              <Controller
                name="shippingCost"
                control={control}
                render={({field: {value, onChange}, fieldState: {error}}) => (
                  <TextField
                    variant="outlined"
                    type="text"
                    placeholder="Add Shipping Cost"
                    fullWidth
                    disabled={getValues('codeStatus') === 'EXPIRED'}
                    margin="dense"
                    value={value}
                    onChange={event => {
                      if (/^\d*\.?\d*$/.test(event.target.value))
                        onChange(event);
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton
                            aria-label="toggle password visibility"
                            size="small"
                          >
                            {currency[getValues('currencyCode')]?.symbol}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid>
          )}
          {codeType === 'sendParcel' && (
            <Grid item xs={3} className={classes.formElement}>
              <Typography
                className={clsx(classes.formLabel, classes.disableLabel)}
              >
                Shipping Status
              </Typography>
              <Controller
                name="shippingStatus"
                control={control}
                render={({field: {value, onChange}, fieldState: {error}}) => (
                  <TextField
                    variant="outlined"
                    type="text"
                    placeholder="Shipping Status"
                    disabled
                    className={
                      value === 'PAID'
                        ? classes.paidInput
                        : value === 'FAILED'
                        ? classes.failedInput
                        : classes.pendingInput
                    }
                    fullWidth
                    margin="dense"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid>
          )}
          {codeType === 'sendParcel' && (
            <Grid item xs={3} className={classes.formElement}>
              <Typography
                className={clsx(
                  classes.formLabel,
                  getValues('codeStatus') === 'EXPIRED' && classes.disableLabel
                )}
              >
                Recipient Name
              </Typography>
              <Controller
                name="recipientName"
                control={control}
                render={({field: {value, onChange}, fieldState: {error}}) => (
                  <TextField
                    variant="outlined"
                    type="text"
                    placeholder="Add Recipient Name"
                    fullWidth
                    disabled={getValues('codeStatus') === 'EXPIRED'}
                    margin="dense"
                    value={value}
                    onChange={event => {
                      if (containDigits(event.target.value)) return;
                      onChange(event);
                    }}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid>
          )}
          <Grid item xs={3} className={classes.formElement}>
            <Typography
              className={clsx(classes.formLabel, classes.disableLabel)}
            >
              Created Date
            </Typography>
            <Controller
              name="createdDate"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    inputFormat="dd-MMM-yyyy"
                    className={classes.datePicker}
                    disabled
                    value={value}
                    onChange={onChange}
                    renderInput={(params: any) => (
                      <TextField
                        variant="outlined"
                        type="text"
                        placeholder="Add Created Date"
                        fullWidth
                        margin="dense"
                        value={value}
                        onChange={onChange}
                        error={!!error}
                        helperText={error?.message}
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              )}
            />
          </Grid>
          <Grid item xs={3} className={classes.formElement}>
            <Typography
              className={clsx(classes.formLabel, classes.disableLabel)}
            >
              Unique ID
            </Typography>
            <Controller
              name="uniqueId"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <TextField
                  variant="outlined"
                  type="text"
                  placeholder="UniqueID"
                  fullWidth
                  disabled
                  margin="dense"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
          {codeType === 'sendParcel' &&
            getValues('country') !== 'United Kingdom' && (
              <Grid item xs={3} className={classes.formElement}>
                <Typography
                  className={clsx(
                    classes.formLabel,
                    getValues('codeStatus') === 'EXPIRED' &&
                      classes.disableLabel
                  )}
                >
                  Quantity
                </Typography>
                <Controller
                  name="quantity"
                  control={control}
                  render={({field: {value, onChange}, fieldState: {error}}) => (
                    <TextField
                      variant="outlined"
                      type="text"
                      placeholder="Add Quantity"
                      fullWidth
                      disabled={getValues('codeStatus') === 'EXPIRED'}
                      margin="dense"
                      value={value}
                      onChange={event => {
                        event.target.value = event.target.value.replace(
                          /[^0-9]/g,
                          ''
                        );
                        onChange(event);
                      }}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              </Grid>
            )}
          {codeType === 'sendParcel' &&
            getValues('country') !== 'United Kingdom' && (
              <Grid item xs={12} className={classes.formElement}>
                <Typography
                  className={clsx(
                    classes.formLabel,
                    getValues('codeStatus') === 'EXPIRED' &&
                      classes.disableLabel
                  )}
                >
                  Custom Description
                </Typography>
                <Controller
                  name="description"
                  control={control}
                  render={({field: {value, onChange}, fieldState: {error}}) => (
                    <TextField
                      variant="outlined"
                      type="text"
                      placeholder="Add Description"
                      fullWidth
                      multiline
                      disabled={getValues('codeStatus') === 'EXPIRED'}
                      rows={4}
                      margin="dense"
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              </Grid>
            )}
          {codeType === 'returnParcel' && getValues('items').length !== 0 && (
            <Grid container className={classes.itemDetailContainer} spacing={2}>
              <Grid item xs={12} className={classes.tableTitleContainer}>
                <Typography className={classes.filterText} component={'h1'}>
                  Item Details
                </Typography>
              </Grid>
              <Divider className={classes.divider} variant="middle" />
              <Grid item xs={8}>
                <TableContainer
                  component={Paper}
                  className={`${classes.tableContainer} ${classes.noBorder}`}
                  style={{borderRadius: 0}}
                >
                  <Table>
                    <TableHead className={classes.tableHeader}>
                      <TableRow className={`${classes.tableRow}`}>
                        <TableCell
                          className={`${classes.tableCell}`}
                          style={{
                            fontSize: '16px',
                            fontWeight: 600,
                            lineHeight: '24px',
                          }}
                        >
                          Item Name
                        </TableCell>
                        <TableCell
                          className={`${classes.tableCell}`}
                          style={{
                            fontSize: '16px',
                            fontWeight: 600,
                            lineHeight: '24px',
                          }}
                        >
                          Reason for Return
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.items.map(
                        (
                          row: {
                            retailerProduct: RetailerProduct;
                            reason: string;
                          },
                          index: number
                        ) => (
                          <TableRow
                            key={index}
                            className={`${classes.tableRow}`}
                          >
                            <TableCell
                              className={classes.tableCell}
                              style={{
                                color:
                                  getValues('codeStatus') === 'EXPIRED'
                                    ? 'rgba(0, 0, 0, 0.38)'
                                    : 'inherit',
                              }}
                            >
                              {' '}
                              {row.retailerProduct?.name}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              style={{
                                color:
                                  getValues('codeStatus') === 'EXPIRED'
                                    ? 'rgba(0, 0, 0, 0.38)'
                                    : 'inherit',
                              }}
                            >
                              <Select
                                className={classes.tableSelect}
                                value={selectedReasons[index].reason || ''}
                                onChange={event =>
                                  handleReasonChange(event, index)
                                }
                                disableUnderline
                                disabled={getValues('codeStatus') === 'EXPIRED'}
                              >
                                {data.returnReason.map(
                                  (reason: any, reasonIndex: number) => (
                                    <MenuItem key={reasonIndex} value={reason}>
                                      {reason}
                                    </MenuItem>
                                  )
                                )}
                              </Select>
                            </TableCell>
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          )}
          {records.map((record: any, index: number) => (
            <Grid
              container
              className={clsx(
                classes.recordContainer,
                index !== records.length - 1 ? classes.recordBorder : ''
              )}
              spacing={2}
            >
              <Grid item xs={6}>
                <Image
                  src={[record.url, imagePlaceholder]}
                  onClick={() => {
                    openImage(record.url);
                  }}
                  alt="mail item"
                  className={classes.picture}
                />
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={6} className={classes.formElement}>
                    <Typography
                      className={clsx(classes.formLabel, classes.disableLabel)}
                    >
                      Uploaded Date
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        inputFormat="dd-MMM-yyyy"
                        className={classes.datePicker}
                        disabled
                        onChange={() => {}}
                        value={new Date(record.createdAt)}
                        renderInput={(params: any) => (
                          <TextField
                            variant="outlined"
                            type="text"
                            placeholder="Updated Date"
                            fullWidth
                            margin="dense"
                            value={new Date(record.createdAt)}
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={6} className={classes.formElement}>
                    <Typography
                      className={clsx(classes.formLabel, classes.disableLabel)}
                    >
                      Uploaded By
                    </Typography>
                    <TextField
                      variant="outlined"
                      type="text"
                      placeholder="Updated By"
                      fullWidth
                      disabled
                      margin="dense"
                      value={`${record.user?.firstName || ''} ${
                        record.user?.lastName || ''
                      } ${record.user?.id ? `(${record.user.id})` : ''}`}
                    />
                  </Grid>
                  <Grid item xs={6} className={clsx(classes.formElement)}>
                    <Typography
                      className={clsx(classes.formLabel, classes.disableLabel)}
                    >
                      Parcel Status
                    </Typography>
                    <TextField
                      variant="outlined"
                      type="text"
                      placeholder="Parcel Status"
                      fullWidth
                      disabled
                      margin="dense"
                      value={record.action || ''}
                    />
                  </Grid>
                  <Grid item xs={6} className={clsx(classes.formElement)}>
                    <Typography
                      className={clsx(classes.formLabel, classes.disableLabel)}
                    >
                      Location
                    </Typography>
                    <TextField
                      variant="outlined"
                      type="text"
                      placeholder="Location"
                      fullWidth
                      disabled
                      margin="dense"
                      value={record.location || '-'}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </form>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 26,
    lineHeight: '40px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
      lineHeight: '28px',
    },
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(3),
  },
  disableLabel: {
    color: 'rgba(0, 0, 0, 0.38)',
  },
  errorMessage: {
    color: theme.palette.error.main,
    margin: '0 14px',
    fontSize: '0.75rem',
  },
  cancelButton: {
    color: theme.palette.grey[500],
  },

  recordContainer: {
    padding: '12px 0px 15px',
    margin: '8px 0px 0px',
  },
  recordBorder: {
    borderBottom: '1px solid #e0e0e0',
  },
  paidInput: {
    '& input': {
      color: theme.palette.success.main,
      fontWeight: theme.typography.fontWeightBold,
      textTransform: 'capitalize',
    },
  },
  capitalizeText: {
    textTransform: 'capitalize',
  },
  failedInput: {
    '& input': {
      color: theme.palette.error.main,
      fontWeight: theme.typography.fontWeightBold,
      textTransform: 'capitalize',
    },
  },
  pendingInput: {
    '& input': {
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightBold,
      textTransform: 'capitalize',
    },
  },
  breadcrumb: {
    marginBottom: theme.spacing(10),
  },
  breadcrumbLink: {
    color: 'inherit',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  formElement: {},
  breadcrumbText: {
    color: 'inherit',
    fontSize: 'inherit',
  },
  filterContainer: {
    width: '100%',
    background: '#fff',
    borderRadius: '5px 5px 0 0',
    padding: '12px 16px 15px',
    margin: 0,
    border: '1px solid',
    borderBottom: '0px',
    borderColor: theme.palette.grey[300],
  },
  filterOptionContainer: {
    width: '100%',
    background: '#fff',
    borderRadius: '0 0 5px 5px',
    padding: '12px 16px 15px',
    margin: 0,
    marginBottom: theme.spacing(3),
    border: '1px solid',
    borderColor: theme.palette.grey[300],
  },
  filterText: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '21px',
    lineHeight: '32px',
  },
  formLabel: {
    fontSize: 16,
    lineHeight: '28px',
  },
  adornmentTextField: {
    '& span': {
      backgroundColor: theme.palette.grey[400],
    },
  },
  select: {
    marginTop: '8px',
    marginBottom: '4px',
  },
  datePicker: {
    backgroundColor: 'pink',
    '&& .MuiPickersDay-root.Mui-selected': {
      backgroundColor: 'red !important',
      '&:hover': {
        backgroundColor: 'red !important',
      },
      '&:focus': {
        backgroundColor: 'red !important',
      },
    },
  },
  textFieldSkeleton: {
    width: '100%',
    height: 40,
    borderRadius: 4,
    marginTop: 8,
    marginBottom: 4,
  },
  picture: {
    width: '100%',
    maxWidth: '100%',
    height: 288,
    [theme.breakpoints.down('xs')]: {
      height: 200,
    },
    '& img': {
      objectFit: 'contain',
    },
  },
  tableContainer: {
    maxWidth: '100%',
    boxShadow: 'none !important',
    marginLeft: '8px',
  },
  tableHeader: {
    backgroundColor: '#EBF6FF',
    fontFamily: 'neue-haas-grotesk-display, sans-serif',
  },
  tableTitleContainer: {
    width: '100%',
    background: '#fff',
    padding: '12px 16px 15px',
    marginLeft: '8px',
    border: 'none',
    marginBottom: '18px',
  },
  tableRow: {
    border: '1px solid',
    borderColor: theme.palette.grey[300],
  },
  tableCell: {
    fontFamily: 'neue-haas-grotesk-display, sans-serif !important',
    border: '1px solid',
    borderColor: theme.palette.grey[300],
    padding: 0,
    fontSize: '16px',
    lineHeight: '28px',
  },
  noBorder: {
    border: 'none',
  },
  tableSelect: {
    width: '100%',
    backgroundColor: 'transparent',
    border: 'none !important',
    fontSize: '14px !important',
    fontFamily: 'neue-haas-grotesk-display, sans-serif !important',
    '&:hover': {
      backgroundColor: 'inherit',
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'inherit',
    },
    '& .MuiSelect-select.MuiSelect-select': {
      padding: '2.5px',
    },
  },
  divider: {
    marginLeft: '-8px',
    marginBottom: '22px',
    width: 'calc(100% + 16px)',
  },
  itemDetailContainer: {
    marginTop: '27px',
  },
}));

export default LookupItem;
