export const NO_SPACE_REGEX = /^\S*$/;

export const UK_POSTCODE_REGEX = /^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$/;

export const GERMANY_FRANCE_POSTCODE_REGEX = /^[0-9]{1,5}$/;

export const DATE_FORMAT = /^[0-9]{2}-[a-zA-Z]{3}-[0-9]{4}$/;

export const ALPHABET_SPACE_NUM = /^[A-Za-z0-9 ]*$/;

export const DELIVERY_TIME_REGEX = /^[0-9]{1,2}[ ]{0,1}-[ ]{0,1}[0-9]{1,2}$/;

export const NAME_REGEX = /^[a-z'\s]+$/i;

export const NUMBER_REGEX = /^\d*\.?\d*$/;
