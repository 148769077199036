import * as React from 'react';
import {makeStyles, darken} from '@material-ui/core';
import {useQuery} from 'react-query';
import {toast} from 'react-hot-toast';
import {useForm, Controller} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {Row} from 'react-table';
import {useHistory} from 'react-router-dom';
import * as yup from 'yup';
import {
  addDays,
  format,
  parseISO,
  subDays,
  differenceInMilliseconds,
  isValid,
} from 'date-fns';

import DataGrid from 'components/DataGrid';
import {lookupService} from 'services/lookupService';
import {productTypeService} from 'services/productTypeService';
import {retailerService} from 'services/retailerService';
import {SFCODE_LENGTH_MESSAGE, ERROR_MESSAGE} from 'utils/messages';
import {useAuth} from 'hooks/useAuth';
import {startOfDayTimestamp, getDate} from 'utils/Date';
import {DATE_FORMAT} from 'utils/regex';
import {capitalizeString} from 'utils/String';

import {Hash} from 'react-feather';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {
  Typography,
  Grid,
  TextField,
  Box,
  Tab,
  Tabs,
  FormControlLabel,
  Radio,
  RadioGroup,
  Select,
  MenuItem,
} from '@material-ui/core';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import VisibilityIcon from '@material-ui/icons/Visibility';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import {Skeleton} from '@material-ui/lab';

import Button from 'components/Button';
import AdornmentTextField from 'components/AdornmentTextField';
import {Retailer} from 'types';
import {useCountryOptions} from 'hooks/useCountryOptions';
import {useCarrierOptions} from 'hooks/useCarrierOptions';

type Mode = 'returned' | 'sent';
type FilterInputs = {
  codeStatus: 'active' | 'expired';
  code: string;
  postcode: string;
  country: string;
  carrier: string;
  retailer: string;
  itemType: string;
  createdFrom: Date;
  createdTo: Date;
};

// Add method to handle empty string
yup.addMethod(yup.string, 'stripEmptyString', function () {
  return this.transform(value => (value === '' ? undefined : value));
});

const filterSchema = yup.object().shape({
  codeStatus: yup.string(),
  code: (yup.string() as any)
    .stripEmptyString()
    .min(3, SFCODE_LENGTH_MESSAGE)
    .max(8, SFCODE_LENGTH_MESSAGE)
    .test({
      name: 'validateSfCode',
      test: function (code?: string) {
        if (!code) return true;
        const pattern = /^[A-Z]{3}\d{0,5}$/;
        return pattern.test(code);
      },
    }),
  country: yup.string(),
  carrier: yup.string(),
  itemType: yup.string(),
  postcode: yup
    .string()
    .notRequired()
    .max(15, 'Maximum 15 characters are allowed'),

  createdFrom: yup
    .date()
    .typeError('Invalid date')
    .test('createdFromDateInvalid', 'Invalid date', function () {
      // validate Date
      const yupContext = this as any;
      if (typeof yupContext.originalValue === 'string') {
        return DATE_FORMAT.test(yupContext.originalValue);
      }
      return true;
    })
    .test(
      'createdToMin',
      'Created From should not be a future date',
      function () {
        // Min date validation
        if (isValid(this.parent.createdFrom)) {
          return (
            differenceInMilliseconds(new Date(), this.parent.createdFrom) >= 0
          );
        }
        return true;
      }
    ),

  createdTo: yup
    .date()
    .typeError('Invalid date')
    .test('createdFromDateInvalid', 'Invalid date', function () {
      // validate Date
      const yupContext = this as any;
      if (typeof yupContext.originalValue === 'string') {
        return DATE_FORMAT.test(yupContext.originalValue);
      }
      return true;
    })
    .test(
      'createdToMin',
      'Created To should not be a future date',
      function () {
        // Min date validation
        if (isValid(this.parent.createdTo)) {
          return (
            differenceInMilliseconds(new Date(), this.parent.createdTo) >= 0
          );
        }
        return true;
      }
    )
    .test(
      'createdToMin',
      'Created To cannot be before Created From',
      function () {
        // Min date validation
        if (isValid(this.parent.createdFrom)) {
          return (
            differenceInMilliseconds(
              this.parent.createdTo,
              this.parent.createdFrom
            ) >= 0
          );
        }
        return true;
      }
    ),
});

const Lookup: React.FC = () => {
  const history = useHistory();

  const {authUser} = useAuth();
  const countryOptions = useCountryOptions();
  const {carriersStatus, carrierOptions} = useCarrierOptions();

  const {
    control,
    handleSubmit,
    reset,
    watch,
    getValues,
    setValue,
  } = useForm<FilterInputs>({
    resolver: yupResolver(filterSchema),
    defaultValues: {
      codeStatus: 'active',
      code: '',
      postcode: '',
      country: '',
      carrier: '',
      retailer: '',
      itemType: '',
      createdFrom: subDays(new Date(startOfDayTimestamp(new Date())), 30),
      createdTo: new Date(startOfDayTimestamp(new Date())),
    },
  });

  const classes = useStyles();
  const [mode, setMode] = React.useState<Mode>('sent');
  const [defaultCarrierId, setDefaultCarrierId] = React.useState('');
  const [defaultRetailerId, setDefaultRetailerId] = React.useState('');
  const [selectedRetailerId, setSelectedRetailerId] = React.useState('');

  const {data, status: dataStatus, refetch, isFetching} = useQuery(
    ['look-up', defaultCarrierId, mode],
    () => {
      return lookupService.search({
        parcelType: mode === 'sent' ? 'sendParcel' : 'returnParcel',
        codeStatus: getValues('codeStatus'),
        createdFrom: startOfDayTimestamp(getValues('createdFrom')),
        createdTo: startOfDayTimestamp(
          addDays(new Date(getValues('createdTo')), 1)
        ),
        carrier: getValues('carrier'),
        retailer: getValues('retailer'),
        postcode: getValues('postcode').trim(),
        code: getValues('code').trim(),
        country: getValues('country').trim(),
        itemType: getValues('itemType').trim(),
      });
    },
    {
      enabled: mode === 'sent' ? !!defaultCarrierId : !!defaultRetailerId,
      retry: false,
    }
  );

  const {data: retailersData} = useQuery(
    ['retailers'],
    () => {
      return retailerService.getRetailers();
    },
    {
      retry: false,
    }
  );

  const {data: productTypesData, status: productTypesStatus} = useQuery(
    ['product-types', watch('carrier')],
    () => {
      return productTypeService.getCarrierProducts(getValues('carrier'));
    },
    {
      enabled: !!defaultCarrierId,
      retry: false,
    }
  );

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    if (authUser?.attributes['custom:user_role'] === 'carrierAdmin') {
      setDefaultCarrierId(authUser?.attributes['custom:carrier_id'] || '');
      setValue('carrier', authUser?.attributes['custom:carrier_id'] || '');
    } else if (carrierOptions?.length > 0) {
      setDefaultCarrierId(carrierOptions[0]?.value);
      setValue('carrier', carrierOptions[0]?.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carrierOptions]);

  React.useEffect(() => {
    if (retailersData && retailersData.length > 0) {
      const retailerId = retailersData[0]?.id;
      setDefaultRetailerId(retailerId);
      setSelectedRetailerId(retailerId);
      setValue('retailer', retailerId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retailersData]);

  const handleModeChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    const newMode = newValue === 0 ? 'sent' : 'returned';

    if (newMode !== mode) resetFilters();
    setMode(newMode);
  };

  const resetFilters = () => {
    setSelectedRetailerId(defaultRetailerId);
    reset({
      codeStatus: 'active',
      code: '',
      postcode: '',
      country: '',
      itemType: '',
      createdFrom: subDays(new Date(startOfDayTimestamp(new Date())), 30),
      createdTo: new Date(startOfDayTimestamp(new Date())),
      carrier: defaultCarrierId,
      retailer: defaultRetailerId,
    });
  };

  const handleClearFilter = async (event: React.MouseEvent) => {
    resetFilters();
    refetch({
      throwOnError: true,
      cancelRefetch: true,
    });
  };

  const onSubmit = async (data: FilterInputs) => {
    refetch({
      throwOnError: true,
      cancelRefetch: true,
    });
  };

  return (
    <div>
      <Typography component={'h1'} className={classes.title}>
        Lookup
      </Typography>
      <Box>
        <Tabs
          value={mode === 'sent' ? 0 : 1}
          className={classes.tabs}
          onChange={handleModeChange}
          indicatorColor="primary"
        >
          <Tab label="Sent Items" className={classes.tab} id="sent-items-tab" />
          <Tab
            label="Returned Items"
            className={classes.tab}
            id="returned-items-tab"
          />
        </Tabs>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container className={classes.filterContainer} spacing={2}>
          <Grid item xs={9}>
            <Typography className={classes.filterText} component={'h2'}>
              Filter
            </Typography>
          </Grid>
          <Grid
            container
            xs={3}
            spacing={2}
            alignItems="center"
            justify="flex-end"
          >
            <Grid item>
              <Button
                variant="outlined"
                color="inherit"
                fullWidth
                minWidth="0px"
                onClick={handleClearFilter}
                className={classes.cancelButton}
              >
                Clear
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                minWidth="0px"
                fullWidth
              >
                {'Apply'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={classes.filterOptionContainer} spacing={2}>
          <Grid item xs={3} className={classes.formElement}>
            <Typography className={classes.formLabel}>
              {'Code Status'}
            </Typography>
            <Controller
              name="codeStatus"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={value}
                  onChange={onChange}
                >
                  <FormControlLabel
                    value="active"
                    control={<Radio color="primary" />}
                    label={`Active${value === 'active' ? ' (selected)' : ''}`}
                  />
                  <FormControlLabel
                    value="expired"
                    control={<Radio color="primary" />}
                    label={`Expired${value === 'expired' ? ' (selected)' : ''}`}
                  />
                </RadioGroup>
              )}
            />
          </Grid>
          <Grid item xs={3} className={classes.formElement}>
            <Typography className={classes.formLabel}>
              {'Stamp Free Code'}
            </Typography>
            <Controller
              name={'code'}
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <>
                  <AdornmentTextField
                    name={mode === 'returned' ? 'returnParcel' : 'sendParcel'}
                    placeholder="Add Code"
                    value={value}
                    onChange={event => {
                      // event.target.value = event.target.value.replace(
                      //   /[^0-9]/g,
                      //   ''
                      // );
                      onChange(event);
                    }}
                    margin="dense"
                    className={classes.adornmentTextField}
                    adornmentIcon={<Hash className={classes.hash} />}
                    error={!!error}
                    helperText={error?.message}
                  />
                </>
              )}
            />
          </Grid>
          <Grid item xs={3} className={classes.formElement}>
            <Typography className={classes.formLabel}>Postcode</Typography>
            <Controller
              name="postcode"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <>
                  <TextField
                    variant="outlined"
                    type="text"
                    placeholder="Add Postcode"
                    fullWidth
                    margin="dense"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error?.message}
                  />
                </>
              )}
            />
          </Grid>
          <Grid item xs={3} className={classes.formElement}>
            <Typography className={classes.formLabel}>Country</Typography>
            <Controller
              name="country"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <Select
                  value={value}
                  onChange={onChange}
                  displayEmpty
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  className={classes.select}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {countryOptions.map(country => (
                    <MenuItem value={country.value} key={country.key}>
                      {country.text}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Grid>
          {mode === 'sent' && (
            <Grid item xs={3} className={classes.formElement}>
              <Typography className={classes.formLabel}>Carrier</Typography>
              {carriersStatus === 'loading' ? (
                <Skeleton
                  variant="rect"
                  className={classes.textFieldSkeleton}
                />
              ) : (
                <Controller
                  name="carrier"
                  control={control}
                  render={({field: {value, onChange}, fieldState: {error}}) => (
                    <Select
                      value={value}
                      onChange={e => {
                        setValue('itemType', '');
                        onChange(e);
                      }}
                      displayEmpty
                      fullWidth
                      margin="dense"
                      disabled={
                        authUser?.attributes['custom:user_role'] ===
                        'carrierAdmin'
                      }
                      variant="outlined"
                      className={classes.select}
                    >
                      {carrierOptions.map(carrier => (
                        <MenuItem value={carrier.value} key={carrier.key}>
                          {carrier.text}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              )}
            </Grid>
          )}
          {mode === 'returned' && (
            <Grid item xs={3} className={classes.formElement}>
              <Typography className={classes.formLabel}>Retailer</Typography>
              {carriersStatus === 'loading' ? (
                <Skeleton
                  variant="rect"
                  className={classes.textFieldSkeleton}
                />
              ) : (
                <Controller
                  name="retailer"
                  control={control}
                  render={({field: {value, onChange}, fieldState: {error}}) => (
                    <Select
                      value={value}
                      onChange={(e: any) => {
                        setSelectedRetailerId(e.target.value);
                        setValue('itemType', '');
                        onChange(e);
                      }}
                      displayEmpty
                      fullWidth
                      margin="dense"
                      variant="outlined"
                      className={classes.select}
                    >
                      {retailersData &&
                        retailersData.map((retailer: Retailer) => (
                          <MenuItem value={retailer.id} key={retailer.id}>
                            {retailer.name}
                          </MenuItem>
                        ))}
                    </Select>
                  )}
                />
              )}
            </Grid>
          )}
          <Grid item xs={3} className={classes.formElement}>
            <Typography className={classes.formLabel}>Item Type</Typography>
            {productTypesStatus === 'loading' ? (
              <Skeleton variant="rect" className={classes.textFieldSkeleton} />
            ) : (
              <Controller
                name="itemType"
                control={control}
                render={({field: {value, onChange}, fieldState: {error}}) => (
                  <Select
                    value={value}
                    onChange={onChange}
                    displayEmpty
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    className={classes.select}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {mode === 'sent'
                      ? productTypesData &&
                        productTypesData?.itemTypes.map(item => (
                          <MenuItem value={item.id} key={item.id}>
                            {`${item.name} ${item.type}`}
                          </MenuItem>
                        ))
                      : retailersData &&
                        retailersData
                          .find(
                            (retailer: Retailer) =>
                              retailer.id === selectedRetailerId
                          )
                          ?.parcelType.map(type => (
                            <MenuItem value={type.title} key={type.title}>
                              {type.title}
                            </MenuItem>
                          ))}
                  </Select>
                )}
              />
            )}
          </Grid>
          <Grid item xs={3} className={classes.formElement}>
            <Typography className={classes.formLabel}>Created From</Typography>
            <Controller
              name="createdFrom"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    inputFormat="dd-MMM-yyyy"
                    className={classes.datePicker}
                    disableFuture
                    allowSameDateSelection
                    value={value}
                    onChange={onChange}
                    maxDate={getDate(watch('createdTo'))}
                    renderInput={(params: any) => (
                      <TextField
                        variant="outlined"
                        type="text"
                        placeholder="Add Postcode"
                        fullWidth
                        margin="dense"
                        value={value}
                        onChange={onChange}
                        {...params}
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                </LocalizationProvider>
              )}
            />
          </Grid>
          <Grid item xs={3} className={classes.formElement}>
            <Typography className={classes.formLabel}>Created To</Typography>
            <Controller
              name="createdTo"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    inputFormat="dd-MMM-yyyy"
                    className={classes.datePicker}
                    disableFuture
                    allowSameDateSelection
                    minDate={getDate(watch('createdFrom'))}
                    value={value}
                    onChange={onChange}
                    renderInput={(params: any) => (
                      <TextField
                        variant="outlined"
                        type="text"
                        placeholder="Add Postcode"
                        fullWidth
                        margin="dense"
                        value={value}
                        {...params}
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                </LocalizationProvider>
              )}
            />
          </Grid>
        </Grid>
      </form>
      <div className={classes.tableContainer}>
        <DataGrid
          columns={[
            {
              Header: 'Code#',
              accessor: (row: any) => row.sfCode,
            },
            {
              Header: 'Item Type',
              accessor: row =>
                mode === 'sent' ? 'Send Parcel' : 'Return Parcel',
            },
            {
              Header: 'Item Status',
              accessor: 'deliveryStatus',
              Cell: ({value}) => (value ? capitalizeString(value) : ''),
            },
            {
              Header: 'Created Date',
              accessor: 'createdAt',
              Cell: ({value}) => format(parseISO(value), 'dd-MMM-yyyy'),
            },
            {
              Header: 'Last Updated',
              accessor: 'updatedAt',
              Cell: ({value}) => format(parseISO(value), 'dd-MMM-yyyy'),
              sortDescFirst: true,
            },
            {
              Header: 'Last Updated By',
              accessor: (row: any) =>
                row.updatedBy
                  ? `${
                      row.updatedBy.firstName
                        ? row.updatedBy.firstName
                        : row.updatedBy.name
                    } ${row.updatedBy.lastName || ''}`
                  : '',
            },
          ]}
          data={data?.codeList || []}
          recordCount={isFetching ? 0 : data?.codeList?.length || 0}
          defaultPageSize={5}
          isLoading={
            dataStatus === 'loading' ||
            isFetching ||
            carriersStatus === 'loading'
          }
          onPaginationChange={() => {}}
          extraActions={[
            {
              label: 'Action',
              type: 'icon-button',
              buttonContent: <VisibilityIcon />,
              handler: (row: Row<any>) => {
                if (row.original.id) {
                  history.push(`/look-up/${mode}/${row.original.id}`);
                } else {
                  toast.error(ERROR_MESSAGE);
                }
              },
            },
          ]}
          // maxHeight="65vh"
          noDataMessage={'No items available'}
        />
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 26,
    lineHeight: '40px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
      lineHeight: '28px',
    },
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(3),
  },
  tabs: {
    marginBottom: '43px',
  },
  tab: {
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: 14,
    lineHeight: '20px',
  },
  filterContainer: {
    width: '100%',
    background: '#fff',
    borderRadius: '5px 5px 0 0',
    padding: '12px 16px 15px',
    margin: 0,
    border: '1px solid',
    borderBottom: '0px',
    borderColor: theme.palette.grey[300],
  },
  filterOptionContainer: {
    width: '100%',
    background: '#fff',
    borderRadius: '0 0 5px 5px',
    padding: '12px 16px 15px',
    margin: 0,
    marginBottom: theme.spacing(3),
    border: '1px solid',
    borderColor: theme.palette.grey[300],
  },
  filterText: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '21px',
    lineHeight: '32px',
  },
  formElement: {},
  formLabel: {
    fontSize: 16,
    lineHeight: '28px',
  },
  hash: {
    backgroundColor: theme.palette.grey[400],
  },
  adornmentTextField: {
    width: '100%',
    '& span': {
      backgroundColor: theme.palette.grey[400],
    },
  },
  select: {
    marginTop: '8px',
    marginBottom: '4px',
  },
  datePicker: {
    backgroundColor: 'pink',
    '&& .MuiPickersDay-root.Mui-selected': {
      backgroundColor: 'red !important',
      '&:hover': {
        backgroundColor: 'red !important',
      },
      '&:focus': {
        backgroundColor: 'red !important',
      },
    },
  },
  textFieldSkeleton: {
    width: '100%',
    height: 40,
    borderRadius: 4,
    marginTop: 8,
    marginBottom: 4,
  },
  tableContainer: {
    marginTop: theme.spacing(3),
  },
  name: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.primary.main,
  },
  dashboardText: {
    fontSize: 14,
    lineHeight: '20px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      lineHeight: '14px',
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginBottom: theme.spacing(1),
  },
  subjectText: {
    fontSize: 21,
    lineHeight: '32px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
      lineHeight: '24px',
    },
    fontWeight: theme.typography.fontWeightBold,
  },
  subjectInput: {
    margin: 0,
  },
  cancelButton: {
    color: theme.palette.grey[500],
  },
  editButton: {
    minWidth: '0px',
    width: 'auto',
    padding: 9,
    fontSize: 18,
    backgroundColor: theme.palette.primary.light,
    '&:hover': {
      backgroundColor: darken(theme.palette.primary.light, 0.1),
    },
  },
  updateButton: {
    minWidth: '0px',
    width: 'auto',
  },
  toolbar: {
    borderRadius: '5px',
  },
  editorError: {
    fontSize: '0.75rem',
    color: theme.palette.error.main,
    margin: '4px 14px 0px',
  },
  content: {
    backgroundColor: '#fff',
    height: 'calc(100vh - 220px)',
    minHeight: '300px',
    padding: 30,
  },
}));

export default Lookup;
