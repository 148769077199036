import * as React from 'react';
import {useTheme, useMediaQuery, makeStyles} from '@material-ui/core';
import {useTable, usePagination, Column, Row} from 'react-table';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {uniq, sortBy} from 'lodash';

import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  IconButton,
  Button,
  Box,
  Typography,
} from '@material-ui/core';

import Spinner from 'components/Spinner';

type ExtraActionConfig = {
  handler: (row: Row<any>) => void;
  label: string;
  type: 'button' | 'icon-button';
  buttonContent: React.ReactNode;
};

export type DataGridProps = {
  columns: Column[];
  data: object[];
  recordCount?: number;
  defaultPageSize?: number;
  isLoading?: boolean;
  searchTerm?: string;
  onPaginationChange: (queryParams: {
    pageIndex: number;
    pageSize: number;
  }) => any;
  extraActions?: ExtraActionConfig[];
  maxHeight?: number | string;
  noDataMessage?: string;
};

const DataGrid: React.FC<DataGridProps> = ({
  columns,
  data,
  recordCount: externalRecordCount,
  defaultPageSize = 10,
  isLoading,
  searchTerm,
  onPaginationChange,
  extraActions,
  maxHeight = 600,
  noDataMessage = 'No data found',
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const smAndDown = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page: rows,
    prepareRow,

    // pagination
    state: {pageIndex, pageSize},
    gotoPage,
    setPageSize,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: defaultPageSize,
      },
      // manualPagination: true,
      // Set to -1 if you don't know or don't want to present
      // the number of pages available.
      pageCount: -1,
    },
    usePagination
  );

  // Parent component can use 'onPaginationChange' to fetch new data
  // when pagination changes
  React.useEffect(() => {
    onPaginationChange({pageIndex, pageSize});
  }, [onPaginationChange, pageIndex, pageSize]);

  // Reset pagination state whenever an external query result factor
  // such as a search term changes.
  //
  // This avoids being on a page that no longer exists after the query
  // result is different due to change in a search term or other external
  // factor.
  //
  // In case any new external factor influences the query result
  // add it here.
  React.useEffect(() => {
    if (searchTerm && searchTerm.length > 0) {
      gotoPage(0);
      setPageSize(defaultPageSize);
    }
  }, [gotoPage, setPageSize, defaultPageSize, searchTerm]);

  // we need to know record count for pagination
  // !!! only update record count if it's a number higher than 0
  // this is important because during loading data, record count
  // might become 0 which causes MUI warnings
  //
  // In such cases, just don't update the record count unless the new
  // data has been returned and the count is a valid number again.
  const [recordCount, setRecordCount] = React.useState(0);
  React.useEffect(() => {
    if (typeof externalRecordCount === 'number') {
      setRecordCount(externalRecordCount);
    }
  }, [externalRecordCount]);

  return (
    <React.Fragment>
      <PerfectScrollbar>
        <Box maxHeight={smAndDown ? 600 : maxHeight}>
          <Table stickyHeader {...getTableProps()}>
            <TableHead>
              {headerGroups.map(headerGroup => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <TableCell
                      className={classes.cell}
                      {...column.getHeaderProps()}
                    >
                      {column.render('Header')}
                    </TableCell>
                  ))}

                  {extraActions
                    ? extraActions.map(action => (
                        <TableCell key={action.label}>{action.label}</TableCell>
                      ))
                    : null}
                </TableRow>
              ))}
            </TableHead>

            <TableBody {...getTableBodyProps()}>
              {isLoading ? (
                <TableRow>
                  <TableCell
                    colSpan={
                      columns.length + (extraActions ? extraActions.length : 0)
                    }
                  >
                    <Spinner />
                  </TableCell>
                </TableRow>
              ) : rows.length < 1 ? (
                <TableRow>
                  <TableCell
                    colSpan={
                      columns.length + (extraActions ? extraActions.length : 0)
                    }
                  >
                    <Typography variant="h6" component="p" align="center">
                      {noDataMessage}
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                rows.map(row => {
                  prepareRow(row);

                  return (
                    <TableRow {...row.getRowProps()}>
                      {row.cells.map(cell => (
                        <TableCell
                          className={classes.cell}
                          {...cell.getCellProps()}
                        >
                          {cell.render('Cell')}
                        </TableCell>
                      ))}

                      {extraActions
                        ? extraActions.map(action => (
                            <TableCell key={action.label}>
                              {action.type === 'button' ? (
                                <Button
                                  variant="contained"
                                  color="primary"
                                  size="small"
                                  onClick={() => action.handler(row)}
                                >
                                  {action.buttonContent}
                                </Button>
                              ) : null}

                              {action.type === 'icon-button' ? (
                                <IconButton onClick={() => action.handler(row)}>
                                  {action.buttonContent}
                                </IconButton>
                              ) : null}
                            </TableCell>
                          ))
                        : null}
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>

      <TablePagination
        rowsPerPageOptions={uniq(sortBy([10, 25, 50, 100, defaultPageSize]))}
        component="div"
        count={recordCount}
        page={pageIndex}
        onChangePage={(_, page) => gotoPage(page)}
        rowsPerPage={pageSize}
        onChangeRowsPerPage={e => setPageSize(Number(e.target.value))}
      />
    </React.Fragment>
  );
};

export * from './usePagination';

const useStyles = makeStyles(theme => ({
  cell: {
    maxWidth: '10vw',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

export default React.memo(DataGrid);
