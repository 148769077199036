import * as React from 'react';
import {makeStyles, darken} from '@material-ui/core';
import {useQuery} from 'react-query';
import {toast} from 'react-hot-toast';
import {useHistory, Link, useParams} from 'react-router-dom';
import {useForm, Controller} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import clsx from 'clsx';
import {format, parseISO} from 'date-fns';
import * as yup from 'yup';
import {productTypeService} from 'services/productTypeService';
import {CARRIER_INACTIVE_WARNING, requiredFieldMessage} from 'utils/messages';
import {countDecimals} from 'utils/Number';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {
  Typography,
  Grid,
  TextField,
  Select,
  IconButton,
  InputAdornment,
  MenuItem,
  Breadcrumbs,
  Modal,
} from '@material-ui/core';
import {Alert} from '@mui/material';
import {ReactComponent as DisableIcon} from 'assets/Icon_product_disable.svg';
import {ReactComponent as EnableIcon} from 'assets/Icon_product_enable.svg';
import {Trash2, Edit3} from 'react-feather';
import DaterangeIcon from '@material-ui/icons/DateRange';
import Button from 'components/Button';
import ScreenSpinner from 'components/ScreenSpinner';
import {capitalizeString, removeWhiteSpaces} from 'utils/String';
import {carrierService} from 'services/carrierService';
import {locationService} from 'services/locationService';
import {useCountryOptions} from 'hooks/useCountryOptions';
import {useCurrencyOptions} from 'hooks/useCurrencyOptions';
import {NUMBER_REGEX} from 'utils/regex';

type Status = 'active' | 'inactive';
type CarrierDetails = {
  carrierName: string;
  link: string;
  status: Status;
  lastUpdatedBy: string;
  lastUpdatedOn: string;
  carrierId: string;
  collectionCharges: string;
  collectParcel: number;
  currencyCode: string;
};

type LocationDetails = {
  id: string;
  country: string;
  name: string;
  postcode: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
};
type ProductType = 'letter' | 'parcel';
type ProductDetails = {
  id: string;
  productType: ProductType;
  name: string;
  description: string;
  currency: string;
  price: string;
  deliveryTime: string;
  status: 'active' | 'inactive';
};

type DeletePopupTitle = 'location' | 'product';

// Add method to handle empty string
yup.addMethod(yup.string, 'stripEmptyString', function () {
  return this.transform(value => (value === '' ? undefined : value));
});

const filterSchema = yup.object().shape({
  carrierName: (yup.string().trim() as any)
    .stripEmptyString()
    .required(requiredFieldMessage('Carrier Name'))
    .min(3, 'Carrier Name should have minimum 3 and maximum 100 characters')
    .max(100, 'Carrier Name should have minimum 3 and maximum 100 characters'),
  link: yup
    .string()
    .trim()
    .required(requiredFieldMessage('Link of T&C'))
    .max(500, 'Link of T&C should have maximum 500 characters'),
  status: yup.string().required(requiredFieldMessage('Status')),
  collectParcel: yup.number().required(requiredFieldMessage('Collect Parcel')),
  collectionCharges: yup.string().test({
    name: 'collectionChargesValidation',
    test: function (collectionCharges?: string) {
      const collectParcel = this.parent?.collectParcel;
      if (collectParcel && !collectionCharges) {
        return this.createError({
          message: requiredFieldMessage('Collection charges'),
        });
      }
      if (!collectionCharges) {
        return true;
      }
      const formattedCollectionCharges = Number(collectionCharges);
      if (isNaN(formattedCollectionCharges)) {
        return this.createError({
          message: 'Please add valid amount',
        });
      }
      if (formattedCollectionCharges <= 0) {
        return this.createError({
          message: 'Collection charges should be greater than 0',
        });
      }
      if (countDecimals(formattedCollectionCharges) > 2) {
        return this.createError({
          message: 'Maximum 2 decimal places allowed',
        });
      }
      return true;
    },
  }),
});

const locaitonSchema = yup.object().shape({
  country: yup.string().required(requiredFieldMessage('Country')),
  name: yup
    .string()
    .trim()
    .required(requiredFieldMessage('City'))
    .min(3, 'City should have minimum 3 and maximum 50 characters')
    .max(50, 'City should have minimum 3 and maximum 50 characters'),
  postcode: yup
    .string()
    .trim()
    .required(requiredFieldMessage('Postcode'))
    .max(15, 'Maximum 15 characters are allowed'),
  addressLine1: yup
    .string()
    .trim()
    .required(requiredFieldMessage('Address Line 1'))
    .max(250, 'Address Line 1 must be at most 250 characters'),
  addressLine2: yup
    .string()
    .trim()
    .test({
      name: 'testAddressLineLength',
      test: function (addressLine?: string) {
        if (!addressLine) return true;
        if (addressLine.length > 250) {
          return this.createError({
            message: 'Address Line 2 must be at most 250 characters',
          });
        }
        return true;
      },
    }),
  addressLine3: yup
    .string()
    .trim()
    .test({
      name: 'testAddressLineLength',
      test: function (addressLine?: string) {
        if (!addressLine) return true;
        if (addressLine.length > 250) {
          return this.createError({
            message: 'Address Line 3 must be at most 250 characters',
          });
        }
        return true;
      },
    }),
});

const productSchema = yup.object().shape({
  productType: yup.string().required(requiredFieldMessage('Product Type')),
  name: yup
    .string()
    .trim()
    .required(requiredFieldMessage('Product Type'))
    .min(3, 'Product name should have minimum 3 and maximum 50 characters')
    .max(50, 'Product name should have minimum 3 and maximum 50 characters'),
  description: yup
    .string()
    .trim()
    .test({
      name: 'testDescriptionLength',
      test: function (description?: string) {
        if (!description) return true;
        if (description.length < 3) {
          return this.createError({
            message:
              'Description should have minimum 3 and maximum 255 characters',
          });
        } else if (description.length > 255) {
          return this.createError({
            message:
              'Description should have minimum 3 and maximum 255 characters',
          });
        }
        return true;
      },
    }),
  currency: yup.string().trim().required(requiredFieldMessage('Currency')),
  price: yup
    .string()
    .required(requiredFieldMessage('Price'))
    .test({
      name: 'shippingCostValidation',
      test: function (shippingCost?: string) {
        const formattedShippingCost = Number(shippingCost);
        if (isNaN(formattedShippingCost)) {
          return this.createError({
            message: 'Please add valid Price',
          });
        }
        if (formattedShippingCost <= 0) {
          return this.createError({
            message: 'Price should be greater than 0',
          });
        }
        if (countDecimals(formattedShippingCost) > 2) {
          return this.createError({
            message: 'Maximum 2 decimal places allowed',
          });
        }
        return true;
      },
    }),
  deliveryTime: yup
    .string()
    .trim()
    .required(requiredFieldMessage('Speed of Delivery')),
});

const statusOptions = [
  {
    key: 'active',
    value: 'active',
    text: 'Active',
  },
  {
    key: 'inactive',
    value: 'inactive',
    text: 'Inactive',
  },
];

const CollectParcelOptions = [
  {
    key: 'Yes',
    value: 1,
    text: 'Yes',
  },
  {
    key: 'No',
    value: 0,
    text: 'No',
  },
];

const productTypeOptions = [
  {
    key: 'letter',
    value: 'letter',
    text: 'Letter',
  },
  {
    key: 'parcel',
    value: 'parcel',
    text: 'Parcel',
  },
];

const speedOfServiceOptions = [
  {
    key: '24hr Parcel',
    value: '24hr Parcel',
    text: '24hr Parcel',
  },
  {
    key: '48hr Parcel',
    value: '48hr Parcel',
    text: '48hr Parcel',
  },
];

const Carrier: React.FC = () => {
  const {id: carrierId} = useParams<{id: string}>();
  const isEdit = () => carrierId !== 'add';

  const history = useHistory();
  const countryOptions = useCountryOptions();
  const currencyOptions = useCurrencyOptions();
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
  } = useForm<CarrierDetails>({
    mode: 'all',
    resolver: yupResolver(filterSchema),
    defaultValues: {
      carrierName: '',
      link: '',
      status: 'active',
      collectionCharges: '',
      collectParcel: 0,
      currencyCode: 'GBP',
    },
  });

  const {
    control: locationControl,
    handleSubmit: handleLocationSubmit,
    reset: locationReset,
    setValue: setLocationValue,
  } = useForm<LocationDetails>({
    resolver: yupResolver(locaitonSchema),
    mode: 'all',
    defaultValues: {
      id: '',
      country: 'United Kingdom',
      name: '',
      postcode: '',
      addressLine1: '',
      addressLine2: '',
      addressLine3: '',
    },
  });

  const {
    control: productControl,
    handleSubmit: handleproductSubmit,
    reset: productReset,
    setValue: setProductValue,
    watch: productWatch,
  } = useForm<ProductDetails>({
    resolver: yupResolver(productSchema),
    mode: 'all',
    defaultValues: {
      id: '',
      productType: 'parcel',
      name: '',
      description: '',
      currency: 'GBP',
      price: '',
      deliveryTime: '',
    },
  });

  const classes = useStyles();

  const [showLocationPopup, setShowLocationPopup] = React.useState(false);

  const [showProductPopup, setShowProductPopup] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [
    inActiveConfirmationPopup,
    setInActiveConfirmationPopup,
  ] = React.useState(false);
  const [
    showDeleteConfirmationPopup,
    setShowDeleteConfirmationPopup,
  ] = React.useState(false);
  const [
    deleteConfirmationContent,
    setDeleteConfirmationContent,
  ] = React.useState<{title: DeletePopupTitle; item: string}>({
    title: 'location',
    item: '',
  });
  const [locations, setLocations] = React.useState<LocationDetails[]>([]);
  const [products, setProducts] = React.useState<ProductDetails[]>([]);
  const [selectedLocation, setSelectedLocation] = React.useState('');
  const [selectedProduct, setSelectedProduct] = React.useState('');
  const collectParcel = watch('collectParcel');
  const currencyCode = watch('currencyCode');
  const selectedCurrency = React.useMemo(() => {
    return currencyOptions.find(co => co.value === currencyCode);
  }, [currencyCode, currencyOptions]);
  const productCurrencyCode = productWatch('currency');
  const productCurrency = React.useMemo(() => {
    return currencyOptions.find(co => co.value === productCurrencyCode);
  }, [productCurrencyCode, currencyOptions]);

  const {data: carrierData, refetch, isFetching} = useQuery(
    ['Carrier', carrierId],
    () => {
      return carrierService.get(carrierId);
    },
    {
      enabled: isEdit(),
    }
  );

  React.useEffect(() => {
    if (collectParcel === 0) {
      setValue('collectionCharges', '');
    }
  }, [collectParcel, setValue]);

  const setData = () => {
    if (!carrierData) return;
    setValue('carrierId', carrierData.id);
    setValue('carrierName', carrierData.name);
    setValue('link', carrierData.url);
    setValue('status', carrierData.status);
    setValue('collectParcel', Number(carrierData.collectParcel));
    setValue(
      'collectionCharges',
      carrierData.collectionCharges
        ? (carrierData.collectionCharges / 100).toFixed(2)
        : ''
    );
    setValue('lastUpdatedBy', carrierData.updatedBy || '');
    setValue(
      'lastUpdatedOn',
      format(parseISO(carrierData.updatedAt), 'dd-MMM-yyyy')
    );
    if (
      carrierData.products.length > 0 &&
      carrierData.products[0]?.currencyCode
    ) {
      setValue('currencyCode', carrierData.products[0]?.currencyCode);
    }
    setLocations(
      carrierData.locations.map(location => ({
        id: location.id,
        addressLine1: location.addressLine1 || '',
        addressLine2: location.addressLine2 || '',
        addressLine3: location.addressLine3 || '',
        country: location.country || '',
        name: location.city,
        postcode: location.postcode || '',
      }))
    );
    setProducts(
      carrierData.products.map(product => ({
        currency: product.currencyCode,
        deliveryTime: product.deliveryTime || '',
        id: product.id,
        name: product.name,
        productType: product.type,
        price: `${product.price}`,
        description: product.description || '',
        status: product.status || 'active',
      }))
    );
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    return () => {
      reset();
      locationReset();
      productReset();
    };
  }, [locationReset, productReset, reset]);

  React.useEffect(() => {
    setData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carrierData]);

  const onSubmit = async (data: CarrierDetails) => {
    if (locations.length === 0) {
      toast.error('Please add at least 1 Location.');
      return;
    }
    if (products.length === 0) {
      toast.error('Please add at least 1 Product.');
      return;
    }
    setIsLoading(true);
    try {
      const {carrierName, link, status, carrierId} = data;
      const collectParcel = Boolean(data.collectParcel);
      const collectionCharges =
        Boolean(collectParcel) && data.collectionCharges
          ? Math.round(Number(data.collectionCharges.trim()) * 100)
          : null;
      if (isEdit()) {
        await carrierService.edit(carrierId, {
          name: carrierName.trim(),
          link: link.trim(),
          status,
          collectParcel,
          ...(collectionCharges ? {collectionCharges} : {}),
        });
        refetch({
          throwOnError: true,
          cancelRefetch: true,
        });

        toast.success('Item details updated successfully', {duration: 3000});
      } else {
        await carrierService.add({
          name: carrierName.trim(),
          link: link.trim(),
          status,
          collectParcel,
          ...(collectionCharges ? {collectionCharges} : {}),
          type: 'domestic',
          locations: locations.map(location => ({
            addressLine1: location.addressLine1,
            ...(location.addressLine2
              ? {addressLine2: location.addressLine2}
              : {}),
            ...(location.addressLine3
              ? {addressLine3: location.addressLine3}
              : {}),
            city: location.name,
            country: location.country,
            postcode: removeWhiteSpaces(location.postcode),
          })),
          products: products.map(product => ({
            currency: product.currency,
            deliveryTime: product.deliveryTime,
            price: Number(product.price),
            status: 'active',
            title: product.name,
            type: product.productType,
            ...(product.description ? {description: product.description} : {}),
          })),
        });
        reset();
        closePopup();
        setLocations([]);
        setProducts([]);
        toast.success('Carrier details added successfully', {duration: 3000});
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const editProduct = (product: ProductDetails) => {
    setProductValue('id', product.id);
    setProductValue('currency', product.currency);
    setProductValue('description', product.description);
    setProductValue('deliveryTime', product.deliveryTime);
    setProductValue('name', product.name);
    setProductValue('productType', product.productType);
    setProductValue('price', (Number(product.price) / 100).toFixed(2));
    setProductValue('status', product.status);
    setSelectedProduct(product.id);
    setShowProductPopup(true);
  };

  const editLocation = (location: LocationDetails) => {
    setLocationValue('id', location.id);
    setLocationValue('country', location.country);
    setLocationValue('name', location.name);
    setLocationValue('postcode', location.postcode);
    setLocationValue('addressLine1', location.addressLine1);
    setLocationValue('addressLine2', location.addressLine2);
    setLocationValue('addressLine3', location.addressLine3);
    setSelectedLocation(location.id);
    setShowLocationPopup(true);
  };

  const toggleDeleteConfirmationPopup = (
    type: DeletePopupTitle,
    selectedValue: string,
    show: boolean
  ) => {
    setShowDeleteConfirmationPopup(show);
    setDeleteConfirmationContent({title: type, item: selectedValue});
  };

  const handleDelete = async () => {
    try {
      setIsLoading(true);
      if (isEdit()) {
        if (deleteConfirmationContent.title === 'location') {
          await locationService.delete(
            carrierId,
            deleteConfirmationContent.item
          );
          setLocations(
            locations.filter(
              location => location.id !== deleteConfirmationContent.item
            )
          );
        } else {
          const productData = products.find(
            product => product.id === deleteConfirmationContent.item
          );
          const updateObj = {
            currency: productData!.currency,
            deliveryTime: productData!.deliveryTime,
            type: productData!.productType,
            title: productData!.name,
            price: Math.floor(Number(productData!.price)),
            status:
              productData!.status === 'active'
                ? ('inactive' as const)
                : ('active' as const),
            ...(productData!.description
              ? {description: productData!.description}
              : {}),
          };
          await productTypeService.edit(carrierId, productData!.id, updateObj);
          setProducts(
            products.map(product => {
              if (product.id === deleteConfirmationContent.item) {
                return {
                  ...product,
                  ...productData,
                };
              }
              return product;
            })
          );
        }
        refetch({
          throwOnError: true,
          cancelRefetch: true,
        });
      } else {
        if (deleteConfirmationContent.title === 'location') {
          setLocations(
            locations.filter(
              location => location.id !== deleteConfirmationContent.item
            )
          );
        } else {
          setProducts(
            products.filter(
              product => product.id !== deleteConfirmationContent.item
            )
          );
        }
      }
    } finally {
      setShowDeleteConfirmationPopup(false);
      setIsLoading(false);
    }
  };

  const deleteConfirmationPopup = () => {
    return (
      <>
        <Modal
          open={showDeleteConfirmationPopup}
          className={classes.popupWrapper}
        >
          <form className={classes.modalForm}>
            <Grid
              container
              className={classes.formContainerWhiteBackground}
              spacing={2}
            >
              <Grid item xs={9} component={'h3'}>
                {deleteConfirmationContent.title === 'location'
                  ? 'Delete Location'
                  : !isEdit()
                  ? 'Delete Product'
                  : products.find(
                      product => product.id === deleteConfirmationContent.item
                    )?.status === 'active'
                  ? 'Disable Product'
                  : 'Enable Product'}
              </Grid>
              <Grid
                container
                xs={3}
                spacing={2}
                alignItems="center"
                justify="flex-end"
              >
                <Grid item>
                  <Button
                    variant="outlined"
                    color="inherit"
                    fullWidth
                    minWidth="0px"
                    onClick={() => setShowDeleteConfirmationPopup(false)}
                    className={classes.cancelButton}
                  >
                    No
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    minWidth="0px"
                    fullWidth
                    onClick={handleDelete}
                  >
                    Yes
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              className={classes.filterOptionContainer}
              spacing={2}
            >
              <Grid item xs={12} className={classes.formElement}>
                {`Are you sure you want to ${
                  deleteConfirmationContent.title === 'location'
                    ? 'delete the location'
                    : !isEdit()
                    ? 'delete the product'
                    : products.find(
                        product => product.id === deleteConfirmationContent.item
                      )?.status === 'active'
                    ? 'disable the product'
                    : 'enable the product'
                }?`}
              </Grid>
            </Grid>
          </form>
        </Modal>
      </>
    );
  };

  const inActiveStatusConfirmation = () => {
    setInActiveConfirmationPopup(false);
    setValue('status', 'active');
  };

  const inActiveStatusConfirmationPopup = () => {
    return (
      <>
        <Modal
          open={inActiveConfirmationPopup}
          className={classes.popupWrapper}
        >
          <form className={classes.modalForm}>
            <Grid
              container
              className={classes.formContainerWhiteBackground}
              spacing={2}
            >
              <Grid item xs={9} component={'h3'}>
                Revoke confirmation
              </Grid>
              <Grid
                container
                xs={3}
                spacing={2}
                alignItems="center"
                justify="flex-end"
              >
                <Grid item>
                  <Button
                    variant="outlined"
                    color="inherit"
                    fullWidth
                    minWidth="0px"
                    onClick={() => inActiveStatusConfirmation()}
                    className={classes.cancelButton}
                  >
                    No
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    minWidth="0px"
                    fullWidth
                    onClick={() => setInActiveConfirmationPopup(false)}
                  >
                    Yes
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              className={classes.filterOptionContainer}
              spacing={2}
            >
              <Grid item xs={12} className={classes.formElement}>
                Are you sure you want to make the Carrier inactive/active?
              </Grid>
            </Grid>
          </form>
        </Modal>
      </>
    );
  };

  const closePopup = () => {
    locationReset();
    productReset();
    setShowLocationPopup(false);
    setShowProductPopup(false);
    setSelectedLocation('');
    setSelectedProduct('');
  };

  const handleLocation = async (data: LocationDetails) => {
    setIsLoading(true);
    try {
      const locationData: LocationDetails = {
        id: data.id,
        name: data.name.trim(),
        postcode: removeWhiteSpaces(data.postcode.trim()),
        country: data.country.trim(),
        addressLine1: data.addressLine1.trim(),
        addressLine2: data.addressLine2.trim(),
        addressLine3: data.addressLine3.trim(),
      };
      if (isEdit()) {
        if (!!selectedLocation) {
          await locationService.edit(carrierId, selectedLocation, {
            city: locationData.name,
            postcode: locationData.postcode,
            country: locationData.country,
            addressLine1: locationData.addressLine1,
            ...(locationData.addressLine2
              ? {addressLine2: locationData.addressLine2}
              : {}),
            ...(locationData.addressLine3
              ? {addressLine3: locationData.addressLine3}
              : {}),
          });
          setLocations(
            locations.map(location => {
              if (location.id === selectedLocation) {
                return {...location, ...locationData};
              }
              return location;
            })
          );
        } else {
          const addedLocation = await locationService.add(carrierId, {
            city: locationData.name,
            postcode: locationData.postcode,
            country: locationData.country,
            addressLine1: locationData.addressLine1,
            ...(locationData.addressLine2
              ? {addressLine2: locationData.addressLine2}
              : {}),
            ...(locationData.addressLine3
              ? {addressLine3: locationData.addressLine3}
              : {}),
          });
          setLocations([
            ...locations,
            {
              ...locationData,
              id: addedLocation.id,
            },
          ]);
        }
      } else {
        if (!!selectedLocation) {
          setLocations(
            locations.map(location => {
              if (location.id === selectedLocation) {
                return {...location, ...locationData};
              }
              return location;
            })
          );
        } else {
          setLocations([...locations, {...locationData, id: `${Date.now()}`}]);
        }
      }
    } finally {
      closePopup();
      setIsLoading(false);
    }
  };

  const toggleLocationPopUp = () => {
    return (
      <>
        <Modal open={showLocationPopup} className={classes.popupWrapper}>
          <form
            className={classes.modalForm}
            onSubmit={handleLocationSubmit(handleLocation)}
          >
            <Grid
              container
              className={classes.formContainerWhiteBackground}
              spacing={2}
            >
              <Grid item xs={9} component={'h3'}>
                {selectedLocation ? 'Edit Location' : 'Add Location'}
              </Grid>
              <Grid
                container
                xs={3}
                spacing={2}
                alignItems="center"
                justify="flex-end"
              >
                <Grid item>
                  <Button
                    variant="outlined"
                    color="inherit"
                    fullWidth
                    minWidth="0px"
                    onClick={() => closePopup()}
                    className={classes.cancelButton}
                  >
                    Close
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    minWidth="0px"
                    fullWidth
                    type="submit"
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              className={classes.filterOptionContainer}
              spacing={2}
            >
              <Grid item xs={4} className={classes.formElement}>
                <Typography className={classes.formLabel}>Country</Typography>
                <Controller
                  name="country"
                  control={locationControl}
                  render={({field: {value, onChange}, fieldState: {error}}) => (
                    <>
                      <Select
                        value={value}
                        onChange={onChange}
                        displayEmpty
                        fullWidth
                        margin="dense"
                        variant="outlined"
                        className={classes.select}
                        error={!!error}
                      >
                        {countryOptions.map(country => (
                          <MenuItem value={country.value} key={country.key}>
                            {country.text}
                          </MenuItem>
                        ))}
                      </Select>
                      {error?.message && (
                        <Typography
                          component={'p'}
                          className={classes.errorMessage}
                        >
                          {error.message}
                        </Typography>
                      )}
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={4} className={classes.formElement}>
                <Typography className={classes.formLabel}>City</Typography>
                <Controller
                  name="name"
                  control={locationControl}
                  render={({field: {value, onChange}, fieldState: {error}}) => (
                    <>
                      <TextField
                        variant="outlined"
                        type="text"
                        placeholder="Add City"
                        fullWidth
                        margin="dense"
                        value={value}
                        onChange={onChange}
                        error={!!error}
                        helperText={error?.message}
                      />
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={4} className={classes.formElement}>
                <Typography className={classes.formLabel}>Postcode</Typography>
                <Controller
                  name="postcode"
                  control={locationControl}
                  render={({field: {value, onChange}, fieldState: {error}}) => (
                    <>
                      <TextField
                        variant="outlined"
                        type="text"
                        placeholder="Add Postcode"
                        fullWidth
                        margin="dense"
                        value={value}
                        onChange={onChange}
                        error={!!error}
                        helperText={error?.message}
                      />
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={4} className={classes.formElement}>
                <Typography className={classes.formLabel}>
                  Address Line 1
                </Typography>
                <Controller
                  name="addressLine1"
                  control={locationControl}
                  render={({field: {value, onChange}, fieldState: {error}}) => (
                    <>
                      <TextField
                        variant="outlined"
                        type="text"
                        placeholder="Add Address Line 1"
                        fullWidth
                        margin="dense"
                        value={value}
                        onChange={onChange}
                        error={!!error}
                        helperText={error?.message}
                      />
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={4} className={classes.formElement}>
                <Typography className={classes.formLabel}>
                  Address Line 2
                </Typography>
                <Controller
                  name="addressLine2"
                  control={locationControl}
                  render={({field: {value, onChange}, fieldState: {error}}) => (
                    <>
                      <TextField
                        variant="outlined"
                        type="text"
                        placeholder="Add Address Line 2"
                        fullWidth
                        margin="dense"
                        value={value}
                        onChange={onChange}
                        error={!!error}
                        helperText={error?.message}
                      />
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={4} className={classes.formElement}>
                <Typography className={classes.formLabel}>
                  Address Line 3
                </Typography>
                <Controller
                  name="addressLine3"
                  control={locationControl}
                  render={({field: {value, onChange}, fieldState: {error}}) => (
                    <>
                      <TextField
                        variant="outlined"
                        type="text"
                        placeholder="Add Address Line 3"
                        fullWidth
                        margin="dense"
                        value={value}
                        onChange={onChange}
                        error={!!error}
                        helperText={error?.message}
                      />
                    </>
                  )}
                />
              </Grid>
            </Grid>
          </form>
        </Modal>
      </>
    );
  };

  const handleProduct = async (data: ProductDetails) => {
    setIsLoading(true);
    try {
      const productData: ProductDetails = {
        id: data.id,
        currency: data.currency.trim(),
        deliveryTime: data.deliveryTime.trim(),
        description: data.description.trim(),
        price: `${Math.round(Number(data.price.trim()) * 100)}`,
        name: data.name.trim(),
        productType: data.productType,
        status: data.status,
      };
      if (isEdit()) {
        if (!!selectedProduct) {
          await productTypeService.edit(carrierId, selectedProduct, {
            currency: productData.currency,
            deliveryTime: productData.deliveryTime,
            type: productData.productType,
            title: productData.name,
            price: Math.floor(Number(productData.price)),
            status: productData.status,
            ...(productData.description
              ? {description: productData.description}
              : {}),
          });
          setProducts(
            products.map(product => {
              if (product.id === selectedProduct) {
                return {
                  ...product,
                  ...productData,
                  price: productData.price,
                };
              }
              product.currency = data.currency;
              return product;
            })
          );
        } else {
          const newProduct = await productTypeService.add(carrierId, {
            currency: productData.currency,
            deliveryTime: productData.deliveryTime,
            type: productData.productType,
            title: productData.name,
            price: Math.floor(Number(productData.price)),
            status: 'active',
            ...(productData.description
              ? {description: productData.description}
              : {}),
          });
          setProducts([
            ...products.map(p => ({...p, currency: data.currency})),
            {
              ...productData,
              id: newProduct.id,
              status: 'active',
            },
          ]);
        }
      } else {
        if (!!selectedProduct) {
          setProducts(
            products.map(product => {
              if (product.id === selectedProduct) {
                return {
                  ...product,
                  ...productData,
                  price: productData.price,
                };
              }
              product.currency = data.currency;
              return product;
            })
          );
        } else {
          setProducts([
            ...products.map(p => ({...p, currency: data.currency})),
            {
              ...productData,
              id: `${Date.now()}`,
              status: 'active',
            },
          ]);
        }
      }
      // Update currency for the carrier whenever product is updated so that we can keep the currency used by carrier and the carrier products in sync
      setValue('currencyCode', data.currency);
    } finally {
      closePopup();
      setIsLoading(false);
    }
  };

  const toggleProductPopUp = () => {
    return (
      <>
        <Modal open={showProductPopup} className={classes.popupWrapper}>
          <form
            className={classes.modalForm}
            onSubmit={handleproductSubmit(handleProduct)}
          >
            <Grid
              container
              className={classes.formContainerWhiteBackground}
              spacing={2}
            >
              <Grid item xs={9} component={'h3'}>
                {selectedProduct ? 'Edit Product' : 'Add Product'}
              </Grid>
              <Grid
                container
                xs={3}
                spacing={2}
                alignItems="center"
                justify="flex-end"
              >
                <Grid item>
                  <Button
                    variant="outlined"
                    color="inherit"
                    fullWidth
                    minWidth="0px"
                    onClick={() => closePopup()}
                    className={classes.cancelButton}
                  >
                    Close
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    minWidth="0px"
                    fullWidth
                    type="submit"
                  >
                    {'Save'}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              className={classes.filterOptionContainer}
              spacing={2}
            >
              <Grid item xs={4} className={classes.formElement}>
                <Typography className={classes.formLabel}>
                  Product Type
                </Typography>
                <Controller
                  name="productType"
                  control={productControl}
                  render={({field: {value, onChange}, fieldState: {error}}) => (
                    <>
                      <Select
                        value={value}
                        onChange={onChange}
                        displayEmpty
                        fullWidth
                        margin="dense"
                        variant="outlined"
                        className={classes.select}
                        error={!!error}
                      >
                        {productTypeOptions.map(type => (
                          <MenuItem value={type.value} key={type.key}>
                            {type.text}
                          </MenuItem>
                        ))}
                      </Select>
                      {error?.message && (
                        <Typography
                          component={'p'}
                          className={classes.errorMessage}
                        >
                          {error.message}
                        </Typography>
                      )}
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={4} className={classes.formElement}>
                <Typography className={classes.formLabel}>
                  Product Title
                </Typography>
                <Controller
                  name="name"
                  control={productControl}
                  render={({field: {value, onChange}, fieldState: {error}}) => (
                    <>
                      <TextField
                        variant="outlined"
                        type="text"
                        placeholder="Add Product title"
                        fullWidth
                        margin="dense"
                        value={value}
                        onChange={onChange}
                        error={!!error}
                        helperText={error?.message}
                      />
                    </>
                  )}
                />
              </Grid>

              <Grid item xs={4} className={classes.formElement}>
                <Typography className={classes.formLabel}>
                  Description
                </Typography>
                <Controller
                  name="description"
                  control={productControl}
                  render={({field: {value, onChange}, fieldState: {error}}) => (
                    <>
                      <TextField
                        variant="outlined"
                        type="text"
                        placeholder="Add Description"
                        fullWidth
                        margin="dense"
                        value={value}
                        onChange={onChange}
                        error={!!error}
                        helperText={error?.message}
                      />
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={4} className={classes.formElement}>
                <Typography className={classes.formLabel}>Currency</Typography>
                <Controller
                  name="currency"
                  control={productControl}
                  render={({field: {value, onChange}, fieldState: {error}}) => (
                    <>
                      <Select
                        value={value}
                        onChange={onChange}
                        displayEmpty
                        fullWidth
                        margin="dense"
                        variant="outlined"
                        className={classes.select}
                        error={!!error}
                      >
                        {currencyOptions.map(currency => (
                          <MenuItem value={currency.value} key={currency.key}>
                            {currency.text}
                          </MenuItem>
                        ))}
                      </Select>
                      {error?.message && (
                        <Typography
                          component={'p'}
                          className={classes.errorMessage}
                        >
                          {error.message}
                        </Typography>
                      )}
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={4} className={classes.formElement}>
                <Typography className={classes.formLabel}>Price</Typography>
                <Controller
                  name="price"
                  control={productControl}
                  render={({field: {value, onChange}, fieldState: {error}}) => (
                    <>
                      <TextField
                        variant="outlined"
                        type="text"
                        placeholder="Add Price"
                        fullWidth
                        margin="dense"
                        value={value}
                        onChange={event => {
                          if (NUMBER_REGEX.test(event.target.value))
                            onChange(event);
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <IconButton
                                aria-label="toggle password visibility"
                                size="small"
                              >
                                {productCurrency?.symbol ?? null}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        error={!!error}
                        helperText={error?.message}
                      />
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={4} className={classes.formElement}>
                <Typography className={classes.formLabel}>
                  Speed of Delivery
                </Typography>
                <Controller
                  name="deliveryTime"
                  control={productControl}
                  render={({field: {value, onChange}, fieldState: {error}}) => (
                    <TextField
                      type="text"
                      value={value}
                      onChange={onChange}
                      fullWidth
                      margin="dense"
                      variant="outlined"
                      placeholder="Add Speed of Delivery"
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </form>
        </Modal>
      </>
    );
  };

  const handleAddProduct = () => {
    // if (products.length >= 9) {
    //   toast.error('Maximum 9 products are allowed');
    //   return;
    // }
    setShowProductPopup(true);
  };

  return (
    <div>
      {(isLoading || isFetching) && <ScreenSpinner />}
      <Typography component={'h1'} className={classes.title}>
        {isEdit() ? 'Carrier Details' : 'Add Carrier'}
      </Typography>
      <Breadcrumbs className={classes.breadcrumb} aria-label="breadcrumb">
        <Typography className={classes.breadcrumbText}>Configure</Typography>
        <Link className={classes.breadcrumbLink} to="/carrier">
          Carrier
        </Link>
        <Typography className={classes.breadcrumbText}>
          {isEdit() ? carrierId : 'Add'}
        </Typography>
      </Breadcrumbs>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container className={classes.formContainer} spacing={2}>
          <Grid item xs={9}></Grid>
          <Grid
            container
            xs={3}
            spacing={2}
            alignItems="center"
            justify="flex-end"
          >
            <Grid item>
              <Button
                variant="outlined"
                color="inherit"
                fullWidth
                minWidth="0px"
                onClick={() => history.push('/carrier')}
                className={classes.cancelButton}
              >
                Close
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                minWidth="0px"
                fullWidth
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={classes.filterOptionContainer} spacing={2}>
          <Grid item xs={3} className={classes.formElement}>
            <Typography className={classes.formLabel}>Carrier Name</Typography>
            <Controller
              name="carrierName"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <>
                  <TextField
                    variant="outlined"
                    type="text"
                    placeholder="Add Name"
                    fullWidth
                    margin="dense"
                    value={value}
                    onChange={e => {
                      if (!/^[a-zA-Z0-9\w -]*$/.test(e.target.value)) return;
                      onChange(e);
                    }}
                    error={!!error}
                    helperText={error?.message}
                  />
                </>
              )}
            />
          </Grid>
          <Grid item xs={3} className={classes.formElement}>
            <Typography className={classes.formLabel}>
              {'Link of T&C'}
            </Typography>
            <Controller
              name="link"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <>
                  <TextField
                    variant="outlined"
                    type="text"
                    placeholder="Add Link"
                    fullWidth
                    margin="dense"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error?.message}
                  />
                </>
              )}
            />
          </Grid>

          {isEdit() && (
            <Grid item xs={3} className={classes.formElement}>
              <Typography className={classes.formLabelDisabled}>
                Last Updated On
              </Typography>
              <Controller
                name="lastUpdatedOn"
                control={control}
                render={({field: {value, onChange}, fieldState: {error}}) => (
                  <>
                    <TextField
                      variant="outlined"
                      type="text"
                      placeholder="Last Updated On"
                      fullWidth
                      margin="dense"
                      disabled
                      value={value}
                      onChange={onChange}
                      InputProps={{
                        endAdornment: <DaterangeIcon />,
                      }}
                      error={!!error}
                      helperText={error?.message}
                    />
                  </>
                )}
              />
            </Grid>
          )}
          {isEdit() && (
            <Grid item xs={3} className={classes.formElement}>
              <Typography className={classes.formLabelDisabled}>
                Last Updated By
              </Typography>
              <Controller
                name="lastUpdatedBy"
                control={control}
                render={({field: {value, onChange}, fieldState: {error}}) => (
                  <>
                    <TextField
                      variant="outlined"
                      type="text"
                      placeholder="Last Updated By"
                      fullWidth
                      margin="dense"
                      value={value}
                      disabled
                      onChange={onChange}
                      error={!!error}
                      helperText={error?.message}
                    />
                  </>
                )}
              />
            </Grid>
          )}
          <Grid item xs={3} className={classes.formElement}>
            <Typography className={classes.formLabel}>Status</Typography>
            <Controller
              name="status"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <Select
                  value={value}
                  onChange={event => {
                    event.target.value === 'inactive' &&
                      setInActiveConfirmationPopup(true);
                    onChange(event);
                  }}
                  displayEmpty
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  className={classes.select}
                >
                  {statusOptions.map(status => (
                    <MenuItem value={status.value} key={status.key}>
                      {status.text}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Grid>

          <Grid item xs={3} className={classes.formElement}>
            <Typography className={classes.formLabel}>
              Collect Parcel
            </Typography>
            <Controller
              name="collectParcel"
              control={control}
              render={({field: {value, onChange}, fieldState: {error}}) => (
                <Select
                  value={value}
                  onChange={onChange}
                  displayEmpty
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  className={classes.select}
                >
                  {CollectParcelOptions.map(status => (
                    <MenuItem value={status.value} key={status.key}>
                      {status.text}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Grid>

          {watch('collectParcel') === 1 && (
            <Grid item xs={3} className={classes.formElement}>
              <Typography className={classes.formLabel}>
                Collection charges
              </Typography>
              <Controller
                name="collectionCharges"
                control={control}
                render={({field: {value, onChange}, fieldState: {error}}) => (
                  <>
                    <TextField
                      variant="outlined"
                      type="text"
                      placeholder="Collection charges"
                      fullWidth
                      margin="dense"
                      value={value}
                      onChange={event => {
                        if (NUMBER_REGEX.test(event.target.value))
                          onChange(event);
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton
                              aria-label="toggle password visibility"
                              size="small"
                            >
                              {selectedCurrency?.symbol ?? null}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      error={!!error}
                      helperText={error?.message}
                    />
                  </>
                )}
              />
            </Grid>
          )}
          {isEdit() && (
            <Grid item xs={3} className={classes.formElement}>
              <Typography className={classes.formLabelDisabled}>
                Carrier ID
              </Typography>
              <Controller
                name="carrierId"
                control={control}
                render={({field: {value, onChange}, fieldState: {error}}) => (
                  <>
                    <TextField
                      variant="outlined"
                      type="text"
                      placeholder="Add Number"
                      fullWidth
                      margin="dense"
                      value={value}
                      disabled={true}
                      onChange={onChange}
                      error={!!error}
                      helperText={error?.message}
                    />
                  </>
                )}
              />
            </Grid>
          )}
          {isEdit() && carrierData?.status === 'inactive' && (
            <Grid item xs={12}>
              <Alert severity="warning">{CARRIER_INACTIVE_WARNING}</Alert>
            </Grid>
          )}
        </Grid>

        <Grid
          container
          className={classes.formContainerWhiteBackground}
          spacing={2}
        >
          <Grid item xs={9} component={'h2'}>
            {'Location'}
          </Grid>
          <Grid
            container
            xs={3}
            spacing={2}
            alignItems="center"
            justify="flex-end"
          >
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                minWidth="0px"
                fullWidth
                onClick={() => setShowLocationPopup(true)}
              >
                Add Location
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          className={clsx(classes.filterOptionContainer, classes.listWrapper)}
          spacing={2}
        >
          {locations.length > 0 ? (
            locations.map(location => (
              <Grid item key={location.id} xs={3}>
                <div className={classes.editableItemInner}>
                  <Typography className={classes.itemTitle}>
                    {location.name}
                  </Typography>
                  <Typography className={classes.itemTitle}>
                    {location.postcode}
                  </Typography>
                  <div className={classes.actionButtonWrapper}>
                    <IconButton
                      className={
                        locations.length === 1
                          ? classes.disableIconButton
                          : classes.iconButton
                      }
                      onClick={() => editLocation(location)}
                    >
                      <Edit3 />
                    </IconButton>
                    <IconButton
                      className={classes.iconButton}
                      disabled={locations.length === 1}
                      onClick={() =>
                        toggleDeleteConfirmationPopup(
                          'location',
                          location.id,
                          true
                        )
                      }
                    >
                      <Trash2 />
                    </IconButton>
                  </div>
                </div>
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <Typography className={classes.noLocationText}>
                Add Location by clicking <span>“Add Location”</span> button
              </Typography>
            </Grid>
          )}
        </Grid>

        <Grid
          container
          className={classes.formContainerWhiteBackground}
          spacing={2}
        >
          <Grid item xs={9} component={'h2'}>
            {'Product'}
          </Grid>
          <Grid
            container
            xs={3}
            spacing={2}
            alignItems="center"
            justify="flex-end"
          >
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                minWidth="0px"
                fullWidth
                onClick={() => handleAddProduct()}
              >
                Add Product
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          className={clsx(classes.filterOptionContainer, classes.listWrapper)}
          spacing={2}
        >
          {products.length > 0 ? (
            products.map((product, index) => (
              <Grid item key={product.id} xs={3}>
                <div className={classes.editableItemInner}>
                  <Typography
                    className={clsx(
                      classes.itemTitle,
                      product.status === 'inactive' && classes.productDisabled
                    )}
                  >
                    {product.name}
                  </Typography>
                  <Typography
                    className={clsx(
                      classes.itemTitle,
                      product.status === 'inactive' && classes.productDisabled
                    )}
                  >
                    {capitalizeString(product.productType)}
                  </Typography>
                  <div className={classes.actionButtonWrapper}>
                    <IconButton
                      className={classes.iconButton}
                      onClick={() => editProduct(product)}
                    >
                      <Edit3 />
                    </IconButton>
                    {isEdit() ? (
                      <IconButton
                        className={classes.productDisableIconButton}
                        style={{padding: 0}}
                        onClick={() =>
                          toggleDeleteConfirmationPopup(
                            'product',
                            product.id,
                            true
                          )
                        }
                      >
                        {product.status === 'active' ? (
                          <EnableIcon />
                        ) : (
                          <DisableIcon />
                        )}
                      </IconButton>
                    ) : (
                      <IconButton
                        className={classes.iconButton}
                        disabled={products.length === 1}
                        onClick={() =>
                          toggleDeleteConfirmationPopup(
                            'product',
                            product.id,
                            true
                          )
                        }
                      >
                        <Trash2 />
                      </IconButton>
                    )}
                  </div>
                </div>
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <Typography className={classes.noLocationText}>
                Add Product by clicking <span>"Add Product"</span> button
              </Typography>
            </Grid>
          )}
        </Grid>
      </form>
      {toggleLocationPopUp()}
      {toggleProductPopUp()}
      {deleteConfirmationPopup()}
      {inActiveStatusConfirmationPopup()}
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 26,
    lineHeight: '40px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
      lineHeight: '28px',
    },
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(3),
  },
  button: {
    marginBottom: theme.spacing(3),
  },

  formContainer: {
    width: '100%',
    borderRadius: '5px 5px 0 0',
    padding: '12px 16px 15px',
    margin: 0,
    border: '1px solid',
    borderBottom: '0px',
    borderColor: theme.palette.grey[300],
  },
  formContainerWhiteBackground: {
    width: '100%',
    background: '#fff',
    borderRadius: '5px 5px 0 0',
    padding: '12px 16px 15px',
    margin: 0,
    border: '1px solid',
    borderBottom: '0px',
    borderColor: theme.palette.grey[300],
  },
  filterOptionContainer: {
    width: '100%',
    background: '#fff',
    borderRadius: '0 0 5px 5px',
    padding: '12px 16px 15px',
    margin: 0,
    marginBottom: theme.spacing(3),
    border: '1px solid',
    borderColor: theme.palette.grey[300],
  },

  listWrapper: {
    maxHeight: '250px',
    overflowX: 'hidden',
    overflowY: 'auto',
  },

  filterText: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '21px',
    lineHeight: '32px',
  },

  formElement: {},
  formLabel: {
    fontSize: 16,
    lineHeight: '28px',
  },
  formLabelDisabled: {
    color: '#9E9E9E',
    fontSize: 16,
    lineHeight: '28px',
  },

  hash: {
    backgroundColor: theme.palette.grey[400],
  },
  adornmentTextField: {
    width: '100%',
    '& span': {
      backgroundColor: theme.palette.grey[400],
    },
  },
  select: {
    marginTop: '8px',
    marginBottom: '4px',
  },
  datePicker: {
    backgroundColor: 'pink',
    '&& .MuiPickersDay-root.Mui-selected': {
      backgroundColor: 'red !important',
      '&:hover': {
        backgroundColor: 'red !important',
      },
      '&:focus': {
        backgroundColor: 'red !important',
      },
    },
  },
  textFieldSkeleton: {
    width: '100%',
    height: 40,
    borderRadius: 4,
    marginTop: 8,
    marginBottom: 4,
  },
  tableContainer: {
    marginTop: theme.spacing(3),
  },
  name: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.primary.main,
  },
  dashboardText: {
    fontSize: 14,
    lineHeight: '20px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      lineHeight: '14px',
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginBottom: theme.spacing(1),
  },
  subjectText: {
    fontSize: 21,
    lineHeight: '32px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
      lineHeight: '24px',
    },
    fontWeight: theme.typography.fontWeightBold,
  },
  subjectInput: {
    margin: 0,
  },
  cancelButton: {
    color: theme.palette.grey[500],
  },
  editButton: {
    minWidth: '0px',
    width: 'auto',
    padding: 9,
    fontSize: 18,
    backgroundColor: theme.palette.primary.light,
    '&:hover': {
      backgroundColor: darken(theme.palette.primary.light, 0.1),
    },
  },
  updateButton: {
    minWidth: '0px',
    width: 'auto',
  },
  toolbar: {
    borderRadius: '5px',
  },
  editorError: {
    fontSize: '0.75rem',
    color: theme.palette.error.main,
    margin: '4px 14px 0px',
  },
  content: {
    backgroundColor: '#fff',
    height: 'calc(100vh - 220px)',
    minHeight: '300px',
    padding: 30,
  },
  breadcrumb: {
    marginBottom: theme.spacing(10),
  },
  breadcrumbLink: {
    color: 'inherit',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  breadcrumbText: {
    color: 'inherit',
    fontSize: 'inherit',
  },
  editableItemInner: {
    background: '#F5F5F5',
    borderRadius: '5px',
    position: 'relative',
    minHeight: '57px',
    padding: '7px 81px 7px 19px',
  },
  actionButtonWrapper: {
    position: 'absolute',
    right: '9px',
    top: '16px',
    '& button': {
      padding: '6px',
      width: '26px',
      height: '26px',
      background: '#fff',
      marginRight: '9px',
      '&:last-child': {
        marginRight: '0',
      },
    },
    '& svg': {
      width: '20px',
      height: '20px',
    },
  },
  itemTitle: {
    fontSize: '14px',
    lineHeight: '22px',
    color: '#212121',
    'white-space': 'nowrap',
    overflow: 'hidden',
    'text-overflow': 'ellipsis',
  },
  popupWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& form': {
      maxWidth: '830px',
      padding: '0 15px',
      margin: '0 auto',
      flex: 'auto',
    },
  },
  noLocationText: {
    textAlign: 'center',
    fontSize: '21px',
    margin: '13.25px 0px',
    '& span': {
      fontWeight: theme.typography.fontWeightBold,
    },
  },
  errorMessage: {
    color: theme.palette.error.main,
    margin: '0 14px',
    fontSize: '0.75rem',
  },
  disableProductIcon: {
    backgroundColor: '#9E9E9E',
    color: '#fff',
  },
  iconButton: {
    border: '1px solid #757575',
  },
  disableIconButton: {
    border: '1px solid rgba(0, 0, 0, 0.26)',
  },
  productDisableIconButton: {
    '& svg': {
      width: '26px',
      height: '26px',
      '&:hover': {
        fill: 'rgba(0, 0, 0, 0.04)',
      },
    },
  },
  modalForm: {
    outline: 'none',
    boxShadow: 'none',
  },
  productDisabled: {
    color: '#9E9E9E',
  },
}));

export default Carrier;
