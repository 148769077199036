import en from 'locales/en';
import {Language, Localization} from 'locales/helper';
import ptBR from 'locales/pt-BR';
export type {Localization, MessageType, Language} from 'locales/helper';
export {convertStringToLanguage} from 'locales/helper';

const languageMap: Record<Language, Localization> = {
  en: en,
  ptBR: ptBR,
};
export default languageMap;
