import {makeStyles} from '@material-ui/core';
import clsx from 'clsx';

import {TextField, TextFieldProps, InputAdornment} from '@material-ui/core';

export type AdornmentTextFieldProps = TextFieldProps & {
  adornmentIcon: React.ReactNode;
};

const AdornmentTextField: React.FC<AdornmentTextFieldProps> = ({
  className,
  variant = 'outlined',
  adornmentIcon,
  ...rest
}) => {
  const classes = useStyles({...rest});

  return (
    <TextField
      className={clsx(classes.root, className)}
      variant={variant}
      InputProps={{
        className: classes.input,
        startAdornment: (
          <InputAdornment position="start">
            <span className={classes.adornment}>{adornmentIcon}</span>
          </InputAdornment>
        ),
      }}
      {...rest}
    />
  );
};

const useStyles = makeStyles(theme => ({
  root: {},
  input: {
    paddingLeft: 0,
  },
  adornment: ({margin}: Partial<AdornmentTextFieldProps>) => ({
    height: margin === 'dense' ? 40 : 55,
    width: margin === 'dense' ? 40 : 55,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
}));

export default AdornmentTextField;
