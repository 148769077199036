import * as React from 'react';
import {Auth} from 'aws-amplify';
import {toast} from 'react-hot-toast';

import axios from 'lib/axios';
import {AuthUser} from 'types';
import {userService} from 'services/userService';

import {FETCH_USER_ERROR} from 'utils/messages';

import SplashScreen from 'components/SplashScreen';
import {getErrorMessage} from 'utils/errors';

interface AuthContextValue {
  error: any;
  authUser: AuthUser | null;
  signInWithEmailAndPassword: (
    email: string,
    password: string
  ) => Promise<AuthUser | undefined>;
  signOut: () => Promise<void>;
  getUserToken: () => Promise<string | undefined>;
  forgotPassword: (email: string) => Promise<void>;
  resetPassword: (
    email: string,
    code: string,
    newPassword: string
  ) => Promise<void>;
}

const AuthContext = React.createContext<AuthContextValue | undefined>(
  undefined
);

const AuthProvider: React.FC = ({children}) => {
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState<any>(null);
  const [authUser, setAuthUser] = React.useState<AuthUser | null>(null);

  React.useEffect(() => {
    const restoreSession = async () => {
      try {
        axios.interceptors.request.use(
          async config => {
            const token = await getUserToken();
            if (!token) {
              throw new Error('Not authorized');
            }
            config.headers.Authorization = `Bearer ${token}`;
            return config;
          },
          error => {
            return Promise.reject(error);
          }
        );
        const session = await Auth.currentSession();

        let user: AuthUser | null = null;
        if (session.isValid()) {
          user = await Auth.currentAuthenticatedUser();
        }
        await setAxiosAuthHeaders(user);
        if (user) {
          try {
            let userResponse = await userService.getCurrentUserDetails();
            user.firstName = userResponse.firstName;
            user.lastName = userResponse.lastName;
          } catch (error) {
            toast.error(FETCH_USER_ERROR);
          }
        }
        setAuthUser(user);
      } catch (_) {
        // silent
      } finally {
        setLoading(false);
      }
    };

    restoreSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (error) {
      toast.error(getErrorMessage(error));
    }
  }, [error]);

  const setAxiosAuthHeaders = async (user: AuthUser | null) => {
    if (user) {
      const token = await getUserToken();
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    } else {
      delete axios.defaults.headers.common.Authorization;
    }
  };

  const signInWithEmailAndPassword = async (
    email: string,
    password: string
  ) => {
    try {
      const user: AuthUser = await Auth.signIn(email, password);

      await setAxiosAuthHeaders(user);
      if (user) {
        try {
          let userResponse = await userService.getCurrentUserDetails();
          user.firstName = userResponse.firstName;
          user.lastName = userResponse.lastName;
        } catch (error) {
          toast.error(FETCH_USER_ERROR);
        }
      }
      setAuthUser(user);

      return user;
    } catch (error) {
      throw error;
    }
  };

  const signOut = async () => {
    try {
      await Auth.signOut();

      setAxiosAuthHeaders(null);
      setAuthUser(null);
    } catch (error) {
      setError(error);
    }
  };

  const getUserToken = async () => {
    try {
      const token = (await Auth.currentSession()).getIdToken().getJwtToken();

      return token;
    } catch (error) {
      setError(error);
      signOut();
    }
  };

  const forgotPassword = async (email: string) => {
    try {
      await Auth.forgotPassword(email);
    } catch (error) {
      throw error;
    }
  };

  const resetPassword = async (
    email: string,
    code: string,
    newPassword: string
  ) => {
    try {
      await Auth.forgotPasswordSubmit(email, code, newPassword);
    } catch (error) {
      throw error;
    }
  };

  if (loading) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        error,
        authUser,
        signInWithEmailAndPassword,
        getUserToken,
        signOut,
        forgotPassword,
        resetPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = React.useContext(AuthContext);

  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
};

export {AuthProvider, useAuth};
