import axios from 'lib/axios';

import {removeWhiteSpaces} from 'utils/String';

class TransactionService {
  async get({
    codeType,
    postcode,
    country,
    city,
    carrier,
    retailer,
    createdFrom,
    createdTo,
  }: {
    codeType: 'sendParcel' | 'returnParcel';
    postcode?: string;
    country?: string;
    city?: string;
    carrier?: string; // mandatory for digicode
    retailer?: string; // mandatory for easycode
    createdFrom: number;
    createdTo: number;
  }) {
    const response = await axios.post('/cc/transactions', {
      codeType,
      createdFrom,
      createdTo,
      ...(postcode ? {postcode: removeWhiteSpaces(postcode)} : {}),
      ...(country ? {country} : {}),
      ...(city ? {city} : {}),
      ...(carrier ? {carrier} : {}),
      ...(retailer ? {retailer} : {}),
    });
    return response.data;
  }
}

export const transactionService = new TransactionService();
