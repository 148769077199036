import axios from 'lib/axios';

import type {CarrierDetailsResponse, CarriersResponse_Mock} from 'types';
import {sort} from 'utils/Array';

type Product = {
  type: 'parcel' | 'letter';
  title: string;
  description?: string;
  currency: string;
  price: number;
  deliveryTime: string;
  status: 'active' | 'inactive';
};

type Location = {
  country: string;
  city: string;
  postcode: string;
  addressLine1: string;
  addressLine2?: string;
  addressLine3?: string;
};

class CarrierService {
  async getCarriers() {
    const response = await axios.get<CarriersResponse_Mock>('/carriers');
    for (const carrier of response.data.carriers) {
      if (carrier.clientFlag) {
        carrier.name = `Client - ${carrier.name}`;
      }
    }
    response.data.carriers = sort(response.data.carriers, 'name');
    return response.data;
  }

  async search({status, city}: {status: string; city?: string}) {
    const response = await axios.post<CarrierDetailsResponse[]>(
      '/cc/carriers',
      {
        status,
        ...(city ? {city} : {}),
      }
    );
    return response.data;
  }

  async add(data: {
    name: string;
    link: string;
    status: 'active' | 'inactive';
    collectionCharges?: number;
    collectParcel: boolean;
    locations: Location[];
    products: Product[];
    type: 'domestic' | 'international';
  }) {
    const response = await axios.post('/cc/carrier', data);
    return response.data;
  }

  async get(id: string) {
    const response = await axios.get<CarrierDetailsResponse>(
      `/cc/carrier/${id}`
    );
    return response.data;
  }

  async edit(
    id: string,
    data: {
      name: string;
      link: string;
      status: 'active' | 'inactive';
      collectionCharges?: number;
      collectParcel: boolean;
    }
  ) {
    const response = await axios.put(`/cc/carrier/${id}`, data);
    return response.data;
  }
}

export const carrierService = new CarrierService();
