import axios from 'lib/axios';

import type {
  Retailer,
  RetailerDetails,
  RetailerParcelType,
  RetailersResponse_Mock,
} from 'types';
import {sort} from 'utils/Array';
import {removeWhiteSpaces} from 'utils/String';

class RetailerService {
  async getRetailers() {
    const response = await axios.post<RetailersResponse_Mock>(
      '/admin/retailers/list',
      {
        status: 'active',
      }
    );

    return sort(response.data, 'name');
  }

  async searchRetailers({
    status,
    city,
  }: {
    status: string;
    city: string;
  }): Promise<Retailer[]> {
    const response = await axios.post('/cc/retailers', {
      status,
      city,
    });
    return response.data;
  }

  async getRetailerDetails(retailerId: string): Promise<RetailerDetails> {
    const response = await axios.get(`/cc/retailers/${retailerId}`);
    return response.data.response;
  }

  async add({
    retailerName,
    country,
    city,
    postcode,
    addressLine1,
    status,
    parcelType,
    returnReasons,
    iconURL,
    carriers,
  }: {
    retailerName: string;
    country: string;
    city: string;
    postcode: string;
    addressLine1: string;
    status: string;
    parcelType: Array<RetailerParcelType>;
    returnReasons: Array<string>;
    carriers: Array<string>;
    iconURL?: string;
  }) {
    await axios.post(`/cc/retailer`, {
      retailerName,
      country,
      city,
      postcode: removeWhiteSpaces(postcode),
      iconURL,
      addressLine1,
      status,
      parcelType,
      returnReasons,
      carriers,
    });
  }

  async updateRetailerDetails({
    retailerId,
    retailerName,
    country,
    city,
    iconURL,
    postcode,
    addressLine1,
    status,
    parcelType,
    returnReasons,
  }: {
    retailerId: string;
    retailerName: string;
    country: string;
    city: string;
    iconURL?: string;
    postcode: string;
    addressLine1: string;
    status: 'active' | 'inactive';
    parcelType: Array<RetailerParcelType>;
    returnReasons: Array<string>;
  }) {
    await axios.put(`/cc/retailers/${retailerId}`, {
      retailerName,
      country,
      city,
      postcode: removeWhiteSpaces(postcode),
      iconURL,
      addressLine1,
      status,
      parcelType,
      returnReasons,
    });
  }

  async addCarrierRetailer(retailerId: string, carriers: Array<string>) {
    const response = await axios.post(
      `/cc/retailers/${retailerId}/carrierRetailer`,
      {carriers}
    );
    return response.data;
  }

  async deleteCarrierRetailer(retailerId: string, carrierId: string) {
    const response = await axios.delete(
      `/cc/retailers/${retailerId}/${carrierId}`
    );
    return response.data;
  }
}

export const retailerService = new RetailerService();
